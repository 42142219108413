//components
import Table from '../../table/table'
import Pagination from '../../pagination/pagination'
import CreateBillModal from '../../modals/bill/createBillModal/createBillModal'
import DeleteBillConfirmationModal from '../../modals/bill/deleteBillConfirmationModal/deleteBillConfirmationModal'

//react
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

//types
import { columnTypes, currency, dropdownOption, employeeResponse, extraColumnDataResponse, extraColumnsResponse, tableBodyItem, tableBodyItemContent, tableHeader, tableTypes } from '../../../../types/general/generalTypes'
import { bill, billStatus, expenseCategory, transactionType } from '../../../../types/finance/general'
import { accountResponse } from '../../../../types/finance/accountTypes'

//network
import { authorizedRequest } from '../../../../utils/queries'
import {  companyExtraColumnsUrl } from '../../../../utils/urls/general/company/company'
import { singleBillUrl } from '../../../../utils/urls/finance/bill/bills'
import { companyAccountUrl } from '../../../../utils/urls/finance/account/account'
import { companySearchEmployeesUrl } from '../../../../utils/urls/employees/search'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector, useAppDispatch } from '../../../../customHooks/redux'
import { setDeleteBillId, setDeleteBillModalIsOpen, setCreateBillModalIsOpen } from '../../../../redux/general/modals'

//other
import { createDropdownOption, getTableViewData, formatExtraColumnResponse, updateCellValue } from '../../../../assets/general/generalFunctions'
import { formatBillResponse } from '../../../../assets/finance/general/generalFunctions'


type billTableProps = {
    loadBills?: {
        billUrl: string
    }
    preloadedBills?: {
        bills: bill[]
        setBills: (value: bill[]) => void
    }
}

const BillTable: FC<billTableProps> = ({ loadBills, preloadedBills }) => {

	const { t } = useTranslation('', { keyPrefix: 'finance.billList.billTable' })
	const tCurrency = useTranslation('', { keyPrefix: 'general.currency' }).t
	const tExpense = useTranslation('', { keyPrefix: 'general.expenseCategory' }).t
	const tStatus = useTranslation('', { keyPrefix: 'general.billStatus' }).t
	const tTransaction = useTranslation('', { keyPrefix: 'general.transactionType' }).t

	const [ columns, setColumns ] = useState<tableHeader[]>([])
	const [ data, setData ] = useState<Record<string, tableBodyItem>[]>([])

	const [ loading, setLoading ] = useState(false)
	const [ lastPage, setLastPage ] = useState(false)
	const [ page, setPage ] = useState(1) 

	const [ bills, setBills ] = useState<bill[]>([])

	const [ deleteModalIsOpen, setDeleteModalIsOpen ] = useState(false) 
	const [ createModalIsOpen, setCreateModalIsOpen ] = useState(false) 
    
	const {userCompanyData} = useAppSelector(state => state.general)
	const deleteBillModal = useAppSelector(state => state.generalModals.deleteBillModal)
	const createBillModal = useAppSelector(state => state.generalModals.createBillModal)

	const dispatch = useAppDispatch()

	const companyId = userCompanyData?.companyId || -1

	const formatTableData = (bill: bill): Record<string, tableBodyItem> => {
		return {
			id: {
				content: {
					type: columnTypes.element,
					value: <Link to={`/ri-business/finance/bill/${bill.id}`}>{bill.id}</Link>
				},
				objectId: bill.id
			},
			title: {
				content: {
					type: columnTypes.string,
					value: bill.title
				},
				objectId: bill.id
			},
			amount: {
				content: {
					type: columnTypes.number,
					value: bill.amount
				},
				objectId: bill.id
			},
			currency: {
				content: {
					type: columnTypes.dropdown,
					value: {
						title: tCurrency(bill.currency),
						key: bill.currency
					}
				},
				objectId: bill.id
			},
			endDate: {
				content: {
					type: columnTypes.date,
					value: bill.endDate ? bill.endDate : undefined
				},
				objectId: bill.id
			},
			description: {
				content: {
					type: columnTypes.string,
					value: bill.description
				},
				objectId: bill.id
			},
			category: {
				content: {
					type: columnTypes.dropdown,
					value: {
						title: tExpense(bill.category),
						key: bill.category
					}
				},
				objectId: bill.id
			},
			status: {
				content: {
					type: columnTypes.dropdown,
					value: {
						title: tStatus(bill.status),
						key: bill.status
					}
				},
				objectId: bill.id
			},
			transactionType: {
				content: {
					type: columnTypes.dropdown,
					value: {
						title: tTransaction(bill.transactionType),
						key: bill.transactionType
					}
				},
				objectId: bill.id
			},
			account: {
				content: {
					type: columnTypes.dropdown,
					value: bill.account ? {
						title: bill.account.name,
						key: `${bill.account.id}`
					} : null
				},
				objectId: bill.id
			},
			employee: {
				content: {
					type: columnTypes.dropdown,
					value: bill.employee ? {
						title: bill.employee.name,
						key: `${bill.employee.id}`,
						avatar: bill.employee.avatar
					} : null
				},
				objectId: bill.id
			}
		}
	}
    
	const onCellValueChange = async (objectId: number, columnKey: string, newValue: tableBodyItemContent) => {
		updateCellValue(
			objectId,
			companyId,
			columnKey,
			newValue,
			singleBillUrl,
			[
				{
					frontend: 'title',
					backend: 'title',
					type: columnTypes.string
				},
				{
					frontend: 'amount',
					backend: 'amount',
					type: columnTypes.number
				},
				{
					frontend: 'currency',
					backend: 'currency',
					type: columnTypes.string
				},
				{
					frontend: 'endDate',
					backend: 'due_date',
					type: columnTypes.date
				},
				{
					frontend: 'description',
					backend: 'description',
					type: columnTypes.string
				},
				{
					frontend: 'category',
					backend: 'category',
					type: columnTypes.string
				},
				{
					frontend: 'status',
					backend: 'status',
					type: columnTypes.string
				},
				{
					frontend: 'transactionType',
					backend: 'type',
					type: columnTypes.string
				},
				{
					frontend: 'account',
					backend: 'account_id',
					type: columnTypes.dropdown
				},
				{
					frontend: 'employee',
					backend: 'employee_id',
					type: columnTypes.dropdown
				}
			],
			bills,
			setBills,
			preloadedBills?.setBills,
			formatBillResponse,
			tableTypes.bill
		)
	}

	const loadAccounts = async (query: string, page: number) => {
		const { result }: { result: accountResponse[] } = await authorizedRequest(companyAccountUrl(companyId) + `?needle=${query}&page=${page}&per_page=${10}`, 'GET')

		if (result.length > 0) {

			const formatedEmployees: dropdownOption[] = result.map(account => {
				return {
					key: `${account.id}`,
					title: account.name
				}
			})

			return formatedEmployees
		}

		return []	
	}

	const loadEmployees = async (query: string, page: number) => {
		const { result }: { result: employeeResponse[] } = await authorizedRequest(companySearchEmployeesUrl(companyId) + `?needle=${query}&page=${page}&per_page=${10}`, 'GET')

		if (result.length > 0) {

			const formatedEmployees: dropdownOption[] = result.map(employee => {
				return {
					key: `${employee.employee_id}`,
					title: employee.name
				}
			})

			return formatedEmployees
		}

		return []	
	}

	const getColumns = async () => {
		const {result}: {result: extraColumnsResponse[]}  = await authorizedRequest(companyExtraColumnsUrl(companyId) + '?table_type=bill', 'GET')

		const editAccess = bills.some((bill) => bill.editAccess)

		console.log(bills)

		setColumns([
			{ key: 'id', title: t('id'), type: columnTypes.number, editAccess: false },
			{ key: 'title', title: t('title'), type: columnTypes.string, editAccess },
			{ key: 'amount', title: t('amount'), type: columnTypes.number, editAccess },
			{ key: 'currency', title: t('currency'), type: columnTypes.dropdown, dropdown: {
				placeholder: '',
				selectedOption: null,
				dropdownOptions: createDropdownOption(tCurrency, currency),
				clear: false
			}, editAccess },
			{ key: 'endDate', title: t('endDate'), type: columnTypes.date, editAccess },
			{ key: 'description', title: t('description'), type: columnTypes.string, editAccess },
			{ key: 'category', title: t('category'), type: columnTypes.dropdown, dropdown: {
				placeholder: '',
				selectedOption: null,
				dropdownOptions: createDropdownOption(tExpense, expenseCategory),
				clear: false
			}, editAccess },
			{ key: 'status', title: t('status'), type: columnTypes.dropdown, dropdown: {
				placeholder: '',
				selectedOption: null,
				dropdownOptions: createDropdownOption(tStatus, billStatus),
				clear: false
			}, editAccess },
			{ key: 'transactionType', title: t('transactionType'), type: columnTypes.dropdown, dropdown: {
				placeholder: '',
				selectedOption: null,
				dropdownOptions: createDropdownOption(tTransaction, transactionType),
				clear: false
			}, editAccess },
			{ key: 'account', title: t('account'), type: columnTypes.dropdown, dropdown: {
				placeholder: '',
				selectedOption: null,
				dropdownOptions: [],
				loadOptions: loadAccounts
			}, editAccess },
			{ key: 'employee', title: t('employee'), type: columnTypes.dropdown, dropdown: {
				placeholder: '',
				selectedOption: null,
				dropdownOptions: [],
				loadOptions: loadEmployees
			}, editAccess },
			...result.map(formatExtraColumnResponse), 
		])
	}

	useEffect(() => {
		getColumns()
		getTableViewData(
			data,
			tableTypes.bill,
			bills,
			formatTableData,
			companyId
		)
			.then((newData) => {
				setData([...data, ...newData])
			})
	}, [bills])

	const loadData = async (page: number) => {
		if(loadBills){
			try {
				setLoading(true)
    
				const { result } = await authorizedRequest(loadBills.billUrl + `?page=${page}&per_page=${10}`, 'GET')
    
				if (result.length > 0) {
					const formatedBills: bill[] = result.map(formatBillResponse)
					return formatedBills
				}
    
				return []
    
			} finally {
				setLoading(false)
			}
		}
	}

	const handleLoadMore = () => {

		if (!loading && !lastPage) {
			loadData(page)
				.then((result) => {

					if(result){
						setBills([...bills, ...result])
						if (result.length > 0) {
							setPage(page + 1)
						} else {
							setLastPage(true)
						}
					}
				})
		}
	}

	useEffect(() => {
		if(loadBills){
			handleLoadMore()
		}
	}, [loadBills])

	useEffect(() => {
		if(preloadedBills){
			setBills(preloadedBills.bills)
		}
	}, [preloadedBills])

	useEffect(() => {
		if(!deleteBillModal.modalIsOpen){
			setTimeout(() => {
				setDeleteModalIsOpen(false)
			}, 400)
		}
	}, [deleteBillModal])


	useEffect(() => {
		if(!createBillModal.modalIsOpen){
			setTimeout(() => {
				setCreateModalIsOpen(false)
			}, 400)
		}
	}, [createBillModal])

	return (
		loadBills ? 
			<>
				<Pagination onLoadMore={handleLoadMore} onlyLoadOn='bottom' loading={loading} showLoader={true}>
					<Table
						columns={columns}
						data={data}
						onCellValueChange={onCellValueChange}
						tableType={tableTypes.bill}
						deleteAction={bills.some((bill) => bill.deleteAccess) ? (billId) => {
							dispatch(setDeleteBillModalIsOpen(true))
							dispatch(setDeleteBillId(billId))
							setDeleteModalIsOpen(true)
						} : undefined}
						createAction={bills.some((bill) => bill.createAccess) ? () => {
							dispatch(setCreateBillModalIsOpen(true))
							setCreateModalIsOpen(true)
						} : undefined}
						addColumn={bills.some((bill) => bill.editAccess)}
					/>
				</Pagination>
				{ createModalIsOpen && <CreateBillModal bills={bills} setBills={setBills} /> }
				{ deleteModalIsOpen && <DeleteBillConfirmationModal bills={bills} setBills={setBills} /> }
			</>
			:
			<Table
				columns={columns}
				data={data}
				onCellValueChange={onCellValueChange}
				tableType={tableTypes.bill}
				deleteAction={bills.some((bill) => bill.deleteAccess) ? (billId) => {
					dispatch(setDeleteBillModalIsOpen(true))
					dispatch(setDeleteBillId(billId))
				} : undefined}
				addColumn={bills.some((bill) => bill.editAccess)}
			/>
	)
}

export default BillTable