//components
import Table from '../../table/table'
import Pagination from '../../pagination/pagination'
import AddOfferingInventoryItemModal from '../../../sales/offering/offeringInventoryItemList/modals/addOfferingInventoryItemModal/addOfferingInventoryItemModal'
import CreateInventoryItemModal from '../../../warehouse/general/modals/inventoryItemModals/createInventoryItemModal/createInventoryItemModal'
import DeleteOfferingInventoryItemModal from '../../../sales/offering/offeringInventoryItemList/modals/deleteOfferingInventoryItemModal/deleteOfferingInventoryItemModal'
import DeleteInventoryItemConfirmationModal from '../../../warehouse/general/modals/inventoryItemModals/deleteInventoryItemConfirmationModal/deleteInventoryItemConfirmationModal'

//react
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

//types
import {
	columnTypes,
	extraColumnDataResponse,
	extraColumnsResponse,
	inventoryItem,
	tableBodyItem,
	tableBodyItemContent,
	tableHeader,
	tableTypes,
} from '../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../utils/queries'
import {
	companyExtraColumnDataUrl,
	companyExtraColumnsUrl,
} from '../../../../utils/urls/general/company/company'
import { companyWarehouseInventoryUrl } from '../../../../utils/urls/warehouses/warehouses/warehouses'
import { singleInventoryItemUrl } from '../../../../utils/urls/warehouses/inventoryItems/inventoryItem'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector, useAppDispatch } from '../../../../customHooks/redux'
import { setCreateInventoryItemModalIsOpen, setDeleteInventoryItemId, setDeleteInventoryItemModalIsOpen } from '../../../../redux/warehouse/general/modals'
import { setAddOfferingInventoryItemModalIsOpen, setDeleteOfferingInventoryItemId, setDeleteOfferingInventoryItemModalIsOpen } from '../../../../redux/sales/offering/modals'

//other
import {
	formatExtraColumnResponse,
	formatInventoryItemResponse,
	getTableViewData,
	updateCellValue,
} from '../../../../assets/general/generalFunctions'
import { arrowIcon } from '../../../../assets/general/generalIcons'
import OfferingTable from '../offeringTable/offeringTable'
import { inventoryItemProviderOfferingsUrl } from '../../../../utils/urls/warehouses/warehouse'

type inventoryItemTableProps = {
	preloadedInventoryItems?: {
        inventoryItems: inventoryItem[]
        setInventoryItems: (value: inventoryItem[]) => void
	}
	loadInventoryItems?:{
	inventoryItemsUrl: string
	}
    type: 'offering' | 'general'
}

const InventoryItemTable: FC<inventoryItemTableProps> = ({preloadedInventoryItems, loadInventoryItems, type }) => {
	const { t } = useTranslation('', {
		keyPrefix: 'general.inventoryItemTable',
	})

	const [columns, setColumns] = useState<tableHeader[]>([])
	const [data, setData] = useState<Record<string, tableBodyItem>[]>([])

	const [inventoryItems, setInventoryItems] = useState<inventoryItem[]>([])
    
	const [loading, setLoading] = useState(false)
	const [lastPage, setLastPage] = useState(false)
	const [page, setPage] = useState(1)

	const [ deleteModalIsOpen, setDeleteModalIsOpen ] = useState(false) 
	const [ createModalIsOpen, setCreateModalIsOpen ] = useState(false) 

	const { userCompanyData } = useAppSelector((state) => state.general)

	const { addOfferingInventoryItemModal, deleteOfferingInventoryItemModal } = useAppSelector((state) => state.offeringModal.offeringInventoryItemList)
	const { createInventoryItemModal, deleteInventoryItemModal } = useAppSelector((state) => state.warehouseGeneralModal)


	const dispatch = useAppDispatch()

	const companyId = userCompanyData?.companyId || -1

	const formatTableData = (inventoryItem: inventoryItem): Record<string, tableBodyItem> => {
		return {
			id: {
				content: {
					type: columnTypes.element,
					value: <Link to={`/ri-business/warehouse/inventory-item/${inventoryItem.id}`}>{inventoryItem.id}</Link>
				},
				objectId: inventoryItem.id,
			},
			name: {
				content: {
					type: columnTypes.string,
					value: inventoryItem.name
				},
				objectId: inventoryItem.id,
			},
			quantity: {
				content: {
					type: columnTypes.number,
					value: inventoryItem.quantity
				},
				objectId: inventoryItem.id,
			},
			description: {
				content: {
					type: columnTypes.string,
					value: inventoryItem.description
				},
				objectId: inventoryItem.id,
			},
			providerOfferings: {
				content: {
					type: columnTypes.element,
					value: <div className='nested-table-arrow'>{arrowIcon}</div>
				},
				objectId: inventoryItem.id,
				extraTable: {
					open: false,
					content: <OfferingTable
						loadOfferings={{
							offeringUrl: inventoryItemProviderOfferingsUrl(inventoryItem.id)
						}}
						type='inventoryItem'
					/>
				}
			}
		}
	}

	const onCellValueChange = async (
		objectId: number,
		columnKey: string,
		newValue: tableBodyItemContent
	) => {
		updateCellValue(
			objectId,
			companyId,
			columnKey,
			newValue,
			singleInventoryItemUrl,
			[
				{
					frontend: 'name',
					backend: 'name',
					type: columnTypes.string
				},
				{
					frontend: 'description',
					backend: 'description',
					type: columnTypes.string
				}
			],
			inventoryItems,
			setInventoryItems,
			preloadedInventoryItems?.setInventoryItems,
			formatInventoryItemResponse,
			tableTypes.inventoryItem
		)
	}

	const getColumns = async () => {
		const { result }: { result: extraColumnsResponse[] } =
			await authorizedRequest(
				companyExtraColumnsUrl(companyId) + '?table_type=inventoryItem',
				'GET'
			)
		const editAccess = inventoryItems.some((inventoryItem) => inventoryItem.editAccess)

		console.warn(editAccess, inventoryItems)

		setColumns([
			{
				key: 'id',
				title: 'id',
				type: columnTypes.number,
				editAccess: false,
			},
			{ 
				key: 'name',
				title: t('name'),
				type: columnTypes.string,
				editAccess
			},
			{
				key: 'quantity',
				title: t('quantity'),
				type: columnTypes.string,
				editAccess: false,
			},
			{
				key: 'description',
				title: t('description'),
				type: columnTypes.string,
				editAccess,
			},
			{
				key: 'providerOfferings',
				title: t('providerOfferings'),
				type: columnTypes.string,
				editAccess,
			},
			...result.map(formatExtraColumnResponse),
		])
	}

	const loadData = async (page: number) => {
		try {
			setLoading(true)
			const { result } = await authorizedRequest(companyWarehouseInventoryUrl(companyId) + `?page=${page}&per_page=${10}`, 'GET')
			if (result.length > 0) {
				const formatedInventoryItems: inventoryItem[] = result.map(formatInventoryItemResponse)
				return formatedInventoryItems
			}

			return []
		} finally {
			setLoading(false)
		}
	}
	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			loadData(page)
				.then((result) => {
					if (result.length > 0) {
						setInventoryItems([...inventoryItems, ...result])
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				})
		}
	}
	useEffect(() => {
		if (loadInventoryItems) {
			handleLoadMore()
		}
	}, [loadInventoryItems])

	useEffect(() => {
		if (preloadedInventoryItems) {
			setInventoryItems(preloadedInventoryItems.inventoryItems)
		}
	}, [preloadedInventoryItems])

	useEffect(() => {
		getColumns()
		getTableViewData(
			data,
			tableTypes.inventoryItem,
			inventoryItems,
			formatTableData,
			companyId
		).then((newData) => {
			setData([...data, ...newData])
		})
	}, [inventoryItems])



	useEffect(() => {
		if(!deleteInventoryItemModal.modalIsOpen && !deleteOfferingInventoryItemModal.modalIsOpen){
			setTimeout(() => {
				setDeleteModalIsOpen(false)
			}, 400)
		}
	}, [deleteInventoryItemModal, deleteOfferingInventoryItemModal])

	useEffect(() => {
		if(!createInventoryItemModal.modalIsOpen){
			setTimeout(() => {
				setCreateModalIsOpen(false)
			}, 400)
		} else if(!addOfferingInventoryItemModal.modalIsOpen){
			setTimeout(() => {
				setCreateModalIsOpen(false)
			}, 400)
		} 
	}, [createInventoryItemModal, addOfferingInventoryItemModal])


	return loadInventoryItems ? 
		<>
			<Pagination
				onLoadMore={handleLoadMore}
				onlyLoadOn="bottom"
				loading={loading}
				showLoader={true}
			>

				<Table
					columns={columns}
					data={data}
					onCellValueChange={onCellValueChange}
					tableType={tableTypes.inventoryItem}
					deleteAction={inventoryItems.some((inventoryItem) => inventoryItem.deleteAccess) ? (inventoryItemId) => {
						if(type === 'offering'){
							dispatch(setDeleteOfferingInventoryItemModalIsOpen(true))
							dispatch(setDeleteOfferingInventoryItemId(inventoryItemId))
						}else if(type === 'general'){
							dispatch(setDeleteInventoryItemModalIsOpen(true))
							dispatch(setDeleteInventoryItemId(inventoryItemId))
						}

						setDeleteModalIsOpen(true)
					}: undefined}
					createAction={inventoryItems.some((inventoryItem) => inventoryItem.createAccess) ? () => {
						if(type === 'offering'){
							dispatch(setAddOfferingInventoryItemModalIsOpen(true))
						}else if(type === 'general'){
							dispatch(setCreateInventoryItemModalIsOpen(true))
						}

						setCreateModalIsOpen(true)
					} : undefined}
					addColumn={inventoryItems.some((inventoryItem) => inventoryItem.editAccess)}
				/>
			</Pagination> 
			{ type === 'offering' && createModalIsOpen && <AddOfferingInventoryItemModal offeringInventoryItems={inventoryItems} setOfferingInventoryItems={setInventoryItems} /> }
			{ type === 'general' && createModalIsOpen && <CreateInventoryItemModal inventoryItems={inventoryItems} setInventoryItems={setInventoryItems} /> }
         
			{ type === 'offering' && deleteModalIsOpen && <DeleteOfferingInventoryItemModal offeringInventoryItems={inventoryItems} setOfferingInventoryItems={setInventoryItems} /> }
			{ type === 'general' && deleteModalIsOpen && <DeleteInventoryItemConfirmationModal inventoryItems={inventoryItems} setInventoryItems={setInventoryItems} /> }
		</>
		: 
		<Table
			columns={columns}
			data={data}
			onCellValueChange={onCellValueChange}
			tableType={tableTypes.inventoryItem}
			deleteAction={
				inventoryItems.some((inventoryItem) => inventoryItem.deleteAccess)
					? (inventoryItemId) => {
						dispatch(setDeleteInventoryItemModalIsOpen(true))
						dispatch(setDeleteInventoryItemId(inventoryItemId))
					}
					: undefined
			}
			addColumn={inventoryItems.some((inventoryItem) => inventoryItem.editAccess)}
		/>


}

export default InventoryItemTable
