//styles
import './navbarBurger.scss'

//react
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

//components
import NavbarBurgerItem from './navbarBurgerItem/navbarBurgerItem'
import Button from '../../../../../../../components/general/button/button'
import LanguageSelector from '../../../../../../../components/general/languageSelector/languageSelector'

//translation
import { useTranslation } from 'react-i18next'

//other
import { links } from '../navbarData'

const NavbarBurger = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.general.layout.navbar' })

	const navigate = useNavigate()
    
	const [open, setOpen] = useState(false)
	const [openItemIndex, setOpenItemIndex] = useState<number | undefined>(undefined)

	const closeBurger = () => {
		setOpen(false)
	}

	useEffect(() => {
		window.addEventListener('click', closeBurger)

		return () => {
			window.addEventListener('click', closeBurger)
		}
	}, [])

	return (
		<div className="navbar-burger-wrapper">
			<div className={`navbar-burger-container ${open ? 'open' : ''}`} onClick={(e) => {
				e.stopPropagation()
				setOpen(!open)
				console.log(open)
			}}>
				<div className="navbar-burger-item"></div>
				<div className="navbar-burger-item"></div>
				<div className="navbar-burger-item"></div>
			</div>
			<div className={`navbar-burger-content-container ${open ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
				<div className="navbar-burger-content-links">
					{links.map((link, index) => {
						return (
							<NavbarBurgerItem
								open={index === openItemIndex}
								setOpen={(value) => {
									setOpenItemIndex(value ? index : undefined)
								}}
								title={t(link.title)}
								href={link.href}
								dropdownElements={link.dropdownElements}
							/>
						)
					})}
				</div>
				<div className="navbar-burger-content-container-footer">
					<LanguageSelector/>
					<Button active={true} text={t('login')} onClick={()=> navigate('/login')}/>
					<Button active={false} outlined={true}  text={t('signUp')}  onClick={()=> navigate('/register')}/>
				</div>
			</div>
		</div>
	)
}

export default NavbarBurger