// styles and icons
import { listIcon, tableIcon } from '../../../../assets/general/generalIcons'

// react
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import Pagination from '../../../general/pagination/pagination'
import ColumnLayout from '../../../general/columnLayout/columnLayout'
import ListSummaryCard from '../../../general/listSummaryCard/listSummaryCard'
import BillListItem from '../../../general/billListItem/billListItem'
import ImportBillFileSelectorModal from '../../../general/modals/bill/importBillFileSelectorModal/importBillFileSelectorModal'
import ExportBillFileModal from '../../../general/modals/bill/exportBillFileModal/exportBillFileModal'
import BillTablePreviewModal from '../../../general/modals/bill/billTablePreviewModal/billTablePreviewModal'
import CreateBillModal from '../../../general/modals/bill/createBillModal/createBillModal'
import DeleteBillConfirmationModal from '../../../general/modals/bill/deleteBillConfirmationModal/deleteBillConfirmationModal'
import ListCardSkeleton from '../../../general/skeletons/listCard/listCardSkeleton'
import OverviewCardSkeleton from '../../../general/skeletons/overviewCard/overviewCardSkeleton'
import PayBillConfirmationModal from '../../../general/modals/bill/payBillConfirmationModal/payBillConfirmationModal'
import SwitchButtons from '../../../general/switchButtons/switchButtons'
import BillTable from '../../../general/tableViews/billTable/billTable'

// types
import { currency, filterBar, filters, switchButton } from '../../../../types/general/generalTypes'
import { bill, billResponse, expenseCategory } from '../../../../types/finance/general'

//redux
import { useAppDispatch } from '../../../../customHooks/redux'
import { setCreateBillModalIsOpen } from '../../../../redux/general/modals'

//network
import { authorizedRequest } from '../../../../utils/queries'
import {
	singleCustomerBillFiltersUrl,
	singleCustomerBillsOverviewUrl,
	singleCustomerBillsUrl
} from '../../../../utils/urls/sales/customer'

// other
import { formatFilters, createFilteringRequest, currencyToFormattedString, getItemsPerColumn } from '../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type customerBillListProps = {
	setFilterBar: (filterBar: filterBar | undefined) => void
}

const CustomerBillList: FC<customerBillListProps> = ({ setFilterBar }) => {
	const { t } = useTranslation('', { keyPrefix: 'sales.customer.customerBillList' })

	const dispatch = useAppDispatch()

	const [billsOverview, setBillsOverview] = useState<{ title: string, text: string }[]>([])
	const [bills, setBills] = useState<bill[]>([])
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())
	const [activeViewId, setActiveViewId] = useState(0)

	const [searchRequest, setSearchRequest] = useState('')
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const [isOverviewLoading, setOverviewLoading] = useState<boolean>(false)

	const { relationshipId } = useParams()

	const loadFilterBar = async () => {
		const { result } = await authorizedRequest(singleCustomerBillFiltersUrl(Number(relationshipId)), 'GET')

		const filterResult = result.filters
		const createAccess = result.create_access

		const formatedFilters: filters[] = formatFilters(filterResult)
		console.log(result, formatedFilters)

		setFilterBar({
			// importButton: () => {
			// 	dispatch(setImportBillFileSelectorModalIsOpen(true))
			// },
			// exportButton: () => {
			// 	dispatch(setExportBillFileModalIsOpen(true))
			// },
			filters: formatedFilters,
			addButton: createAccess && {
				text: t('create'),
				active: true,
				onClick: () => dispatch(setCreateBillModalIsOpen(true))
			},
			onSearch: onSearch
		})
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		const filteringRequest = createFilteringRequest(filters)

		const { result } = await authorizedRequest(singleCustomerBillsUrl(Number(relationshipId)) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')

		if (result.length > 0) {
			const formatedBills: bill[] = result.map((bill: billResponse) => {
				console.log(bill)
				return {
					id: bill.id,
					amount: bill.amount,
					currency: bill.currency,
					endDate: new Date(bill.due_date * 1000),
					title: bill.title,
					description: bill.description,
					categorie: expenseCategory[bill.category as keyof typeof expenseCategory],
					status: bill.status,
					deleteAccess: bill.delete_access
				}
			})
			return formatedBills
		}

		return []
	}

	const loadOverview = async () => {
		setOverviewLoading(true)
		const { result } = await authorizedRequest(singleCustomerBillsOverviewUrl(Number(relationshipId)), 'GET')

		setBillsOverview([
			{
				title: t('totalBills'),
				text: result.total_bills
			},
			...result.amount.map((amount: { amount: number, currency: currency }) => {
				return {
					title: t('total'),
					text: currencyToFormattedString(amount.amount, amount.currency)
				}
			})
		])

		setOverviewLoading(false)
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		loadOverview()
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		setLoading(true)
		loadData(1, searchValue, filters)
			.then((result) => {
				setBills([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
				setLoading(false)
			})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			setLoading(true)
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					setBills([...bills, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
					setLoading(false)
				})
		}
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)
		loadFilterBar()
		return () => {
			setFilterBar(undefined)
		}
	}, [])


	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])
	const renderComponent = () => {
		switch (activeViewId) {
		case 0:
			return (
				<ColumnLayout amount={itemsPerColumn}>
					{
						isOverviewLoading ? <OverviewCardSkeleton /> :
							<ListSummaryCard
								title={t('billsOverview')}
								data={billsOverview}
							/>
					}
					{bills.map(bill => (<BillListItem key={`bill-item-${bill.id}`} {...bill} />))}
					{ loading ? <ListCardSkeleton/> : null }
				</ColumnLayout>
			)
		case 1:
			return (
				<BillTable
					preloadedBills={{
						bills: bills,
						setBills: setBills,
					}}
				/>
			)
		}
	}
	const viewButtons: switchButton[] = [
		{
			id: 0,
			icon: listIcon,
		},
		{
			id: 1,
			icon: tableIcon,
		},
	]

	return (
		<Pagination onLoadMore={handleLoadMore} showLoader={false}>
			<SwitchButtons
				switchButtons={viewButtons}
				activeSwitchButtonId={activeViewId}
				setActiveSwitchButtonId={setActiveViewId}
				size="medium"
			/>
			<Pagination
				onLoadMore={handleLoadMore}
				loading={loading}
				showLoader={false}
			>
				{renderComponent()}
			</Pagination>
			<CreateBillModal setBills={setBills} bills={bills} />
			<ImportBillFileSelectorModal />
			<ExportBillFileModal />
			<BillTablePreviewModal setBills={setBills} bills={bills} />
			<DeleteBillConfirmationModal setBills={setBills} bills={bills} />
			<PayBillConfirmationModal setBills={setBills} bills={bills} />
		</Pagination>
	)
}

export default CustomerBillList
