//styles and icons
import { listIcon, tableIcon } from '../../../../assets/general/generalIcons'


//components
import DocumentList from '../../../general/documentList/documentList'
import Pagination from '../../../general/pagination/pagination'
import DeleteCustomerDocumentConfirmationModal from './modals/deleteCustomerDocumentConfirmationModal/deleteCustomerDocumentConfirmationModal'
import ImportCustomerDocumentFileSelectorModal from './modals/importCustomerDocumentFileSelectorModal/importCustomerDocumentFileSelectorModal'
import DocumentTable from '../../../general/tableViews/documentTable/documentTable'
import SwitchButtons from '../../../general/switchButtons/switchButtons'

//react
import { FC, useState, useEffect } from 'react'
import { useParams } from 'react-router'

//types
import { filterBar, filters, document, switchButton } from '../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../utils/queries'
import { singleCustomerDocumentsUrl, singleCustomerDocumentFiltersUrl } from '../../../../utils/urls/sales/customer'

//redux
import { useAppDispatch } from '../../../../customHooks/redux'
import { setDeleteCustomerDocumentId, setDeleteCustomerDocumentModalIsOpen, setImportCustomerDocumentFileSelectorModalIsOpen } from '../../../../redux/sales/customer/modals'

//other
import { blobStringToBlob, createFilteringRequest, formatFilters } from '../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'


type customerDocumentsProps = {
	setFilterBar: (filterBar: filterBar | undefined) => void
}

type customerDocumentsResponse = {
	file: string
	file_name: string
	file_type: string
	id: number
}


const CustomerDocuments: FC<customerDocumentsProps> = ({ setFilterBar }) => {
	const { t } = useTranslation('', { keyPrefix: 'sales.customer.customerDocuments' })
	const dispatch = useAppDispatch()

	const [documents, setDocuments] = useState<document[]>([])
	const [activeViewId, setActiveViewId] = useState(0)

	const [searchRequest, setSearchRequest] = useState('')
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const {relationshipId} = useParams()

	const loadFilterBar = async () => {
		const { result } = await authorizedRequest(singleCustomerDocumentFiltersUrl(Number(relationshipId)), 'GET')

		const filterResult = result.filters

		const formatedFilters: filters[] = formatFilters(filterResult)

		setFilterBar({
			filters: formatedFilters,
			addButton: {
				text: t('add'),
				active: true,
				onClick: () => {
					dispatch(setImportCustomerDocumentFileSelectorModalIsOpen(true))
				}
			},
			onSearch: onSearch
		})
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		const filteringRequest = createFilteringRequest(filters)
		const { result } = await authorizedRequest(singleCustomerDocumentsUrl(Number(relationshipId)) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')
		console.log(result, result.length)
		if (result.length > 0) {
			const formatedDocumentsData: document[] = result.map((document: customerDocumentsResponse) => {
				console.log(document)
				return {
					file: blobStringToBlob(document.file, document.file_type),
					fileName: document.file_name,
					fileType: document.file_type,
					id: document.id
				}
			})
			return formatedDocumentsData
		}
		return []
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		setLoading(true)
		loadData(1, searchValue, filters)
			.then((result) => {
				if (result.length > 0) {
					setDocuments([...documents, ...result])
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
				setLoading(false)
			})
	}

	const handleLoadMore = () => {
		console.log(loading, lastPage)
		if (!loading && !lastPage) {
			setLoading(true)
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					if (result.length > 0) {
						setDocuments([...documents, ...result])
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
					setLoading(false)
				})
		}
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()

		return () => {
			setFilterBar(undefined)
		}
	}, [])

	const handleDeleteCustomerDocument = (id: number) => {
		dispatch(setDeleteCustomerDocumentId(id))
		dispatch(setDeleteCustomerDocumentModalIsOpen(true))
	}
	const renderComponent = () => {
		switch (activeViewId) {
		case 0:
			return (
				<DocumentList
					fileList={documents.map((doc) => ({ ...doc }))}
					handleDelete={handleDeleteCustomerDocument}
				/>
			)
		case 1:
			return (
				<DocumentTable
					preloadedDocuments={{
						documents: documents,
						setDocuments: setDocuments,
					}}
					parent={{type: 'provider',id: Number(relationshipId) }}
				/>
			)
		}
	}
	const viewButtons: switchButton[] = [
		{
			id: 0,
			icon: listIcon,
		},
		{
			id: 1,
			icon: tableIcon,
		},
	]

	return (
		<Pagination onLoadMore={handleLoadMore} loading={loading}>
			<SwitchButtons
				switchButtons={viewButtons}
				activeSwitchButtonId={activeViewId}
				setActiveSwitchButtonId={setActiveViewId}
				size="medium"
			/>
			<Pagination
				onLoadMore={handleLoadMore}
				loading={loading}
				showLoader={false}
			>
				{renderComponent()}
			</Pagination>
			<DeleteCustomerDocumentConfirmationModal documentsData={documents} setDocumentsData={setDocuments}/>
			<ImportCustomerDocumentFileSelectorModal documentsData={documents} setDocumentsData={setDocuments} customerId={Number(relationshipId)} />
		</Pagination>
	)
}

export default CustomerDocuments