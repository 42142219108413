//styles and icons
import { arrowIcon } from '../../../../assets/general/generalIcons'

//components
import Table from '../../table/table'
import Pagination from '../../pagination/pagination'
import BillTable from '../billTable/billTable'


//react
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

//types
import {
	columnTypes,
	extraColumnsResponse,
	tableBodyItem,
	tableBodyItemContent,
	tableHeader,
	tableTypes,
} from '../../../../types/general/generalTypes'
import { budget } from '../../../../types/finance/budgetTypes'

//network
import { authorizedRequest } from '../../../../utils/queries'
import { companyExtraColumnsUrl } from '../../../../utils/urls/general/company/company'
import { singleBudgetBillsUrl, singleBudgetUrl } from '../../../../utils/urls/finance/budget/budget'


//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector, useAppDispatch } from '../../../../customHooks/redux'
import {
	setDeleteBudgetId,
	setDeleteBudgetModalIsOpen,
} from '../../../../redux/finance/budgetList/modals'

//other
import {
	getTableViewData,
	formatExtraColumnResponse,
	updateCellValue,
} from '../../../../assets/general/generalFunctions'
import { formatBudgetResponse } from '../../../../assets/projects/projectsFunctions'

type budgetTableProps = {
	preloadedBudgets?: {
		budgets: budget[]
		setBudgets: (value: budget[]) => void
	}
	loadBudgets?: {
		budgetUrl: string
		showClosed: boolean
	}
}
type valuesType = {
	key: string
	value: number
}

const BudgetTable: FC<budgetTableProps> = ({
	preloadedBudgets,
	loadBudgets,
}) => {
	const { t } = useTranslation('', {
		keyPrefix: 'finance.budgetList.budgetTable',
	})

	const [columns, setColumns] = useState<tableHeader[]>([])
	const [data, setData] = useState<Record<string, tableBodyItem>[]>([])
	const [valuesKey, setValuesKey] = useState<valuesType[]>([])

	const [loading, setLoading] = useState(false)
	const [lastPage, setLastPage] = useState(false)
	const [page, setPage] = useState(1)

	const [budgets, setBudgets] = useState<budget[]>([])

	const { userCompanyData } = useAppSelector((state) => state.general)

	const dispatch = useAppDispatch()

	const companyId = userCompanyData?.companyId || -1

	const formatTableData = (budget: budget): Record<string, tableBodyItem> => {
		const combinedValues = budget.values.map((transaction) => ({
			key: transaction.currency,
			value: transaction.amount,
		}))
		const newKeys = combinedValues.map(({ key, value }) => ({
			key,
			value,
		}))
		setValuesKey(prev => {
			const existingKeys = new Set(prev.map(item => item.key))
			const updatedKeys = [...prev, ...newKeys.filter(item => !existingKeys.has(item.key))]
			return updatedKeys
		})
		const formattedData: Record<string, tableBodyItem> = {
			id: {
				content: {
					type: columnTypes.element,
					value: (
						<Link to={`/ri-business/sales/budget/${budget.id}`}>
							{budget.id}
						</Link>
					),
				},
				objectId: budget.id,
			},
			title: {
				content: { type: columnTypes.string, value: budget.title },
				objectId: budget.id,
			},
			description: {
				content: { type: columnTypes.string, value: budget.description },
				objectId: budget.id,
			},
			bills: {
				content: {
					type: columnTypes.element,
					value: <div className="nested-table-arrow">{arrowIcon}</div>,
				},
				objectId: budget.id,
				extraTable: {
					open: false,
					content: (
						<BillTable
							loadBills={{
								billUrl: singleBudgetBillsUrl(budget.id),
							}}
						/>
					),
				},
			},
			...Object.fromEntries(
				combinedValues.map(({ key, value }) => [
					`amount_${key.toLowerCase()}`,
					{
						content: { type: columnTypes.number, value },
						objectId: budget.id,
					}
				])
			),
		}
		
		return formattedData
	}
	const onCellValueChange = async (
		objectId: number,
		columnKey: string,
		newValue: tableBodyItemContent
	) => {
		updateCellValue(
			objectId,
			companyId,
			columnKey,
			newValue,
			singleBudgetUrl,
			[
				{
					frontend: 'title',
					backend: 'title',
					type: columnTypes.string,
				},
				{
					frontend: 'description',
					backend: 'description',
					type: columnTypes.string,
				},	
				...valuesKey.map(({key}) => ({
					frontend: `amount_${key.toLowerCase()}`,
					backend: `amount_${key.toLowerCase()}`,
					type: columnTypes.string,
				})),

			],
			budgets,
			setBudgets,
			preloadedBudgets?.setBudgets,
			formatBudgetResponse,
			tableTypes.budget
		)
	}


	const getColumns = async () => {
		const { result }: { result: extraColumnsResponse[] } =
			await authorizedRequest(
				companyExtraColumnsUrl(companyId) + '?table_type=budget',
				'GET'
			)

		const editAccess = budgets.some((budget) => budget.editAccess)

		setColumns([
			{
				key: 'id',
				title: t('id'),
				type: columnTypes.number,
				editAccess: false,
			},
			{ key: 'title', title: t('title'), type: columnTypes.string, editAccess },
			{
				key: 'description',
				title: t('description'),
				type: columnTypes.string,
				editAccess,
			},
			{
				key: 'bills',
				title: t('bills'),
				editAccess,
			},
			...valuesKey.map(({ key }) => ({
				key: `amount_${key.toLowerCase()}`,
				title:`Total ${key}`,
				type: columnTypes.number,
				editAccess,
			})),
			

			...result.map(formatExtraColumnResponse),
		])
	}

	useEffect(() => {
		getColumns()
		getTableViewData(
			data,
			tableTypes.budget,
			budgets,
			formatTableData,
			companyId
		).then((data) => {
			setData([...data])
		})
	}, [budgets])

	const loadData = async (page: number) => {
		if (loadBudgets) {
			try {
				setLoading(true)
				const { result } = await authorizedRequest(
					loadBudgets.budgetUrl +
						`?page=${page}&per_page=${10}&show_closed=${
							loadBudgets.showClosed
						}`,
					'GET'
				)
				if (result.length > 0) {
					const formatedData: budget[] = result.map(formatBudgetResponse)
					return formatedData
				}

				return []
			} finally {
				setLoading(false)
			}
		}
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			loadData(page).then((result) => {
				if (result) {
					setBudgets([...budgets, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				}
			})
		}
	}

	useEffect(() => {
		if (loadBudgets) {
			handleLoadMore()
		}
	}, [loadBudgets])

	useEffect(() => {
		if (preloadedBudgets) {
			setBudgets(preloadedBudgets.budgets)
		}
	}, [preloadedBudgets])

	return loadBudgets ? (
		<Pagination
			onLoadMore={handleLoadMore}
			onlyLoadOn="bottom"
			loading={loading}
			showLoader={true}
		>
			<Table
				columns={columns}
				setColumns={setColumns}
				data={data}
				setData={setData}
				onCellValueChange={onCellValueChange}
				tableType={tableTypes.inventoryItem}
				deleteAction={
					budgets.some((budget) => budget.deleteAccess)
						? (budgetId) => {
							dispatch(setDeleteBudgetModalIsOpen(true))
							dispatch(setDeleteBudgetId(budgetId))
						}
						: undefined
				}
				addColumn={budgets.some((budget) => budget.editAccess)}
			/>
		</Pagination>
	) : (
		<Table
			columns={columns}
			setColumns={setColumns}
			data={data}
			setData={setData}
			onCellValueChange={onCellValueChange}
			tableType={tableTypes.inventoryItem}
			deleteAction={
				budgets.some((budget) => budget.deleteAccess)
					? (budgetId) => {
						dispatch(setDeleteBudgetModalIsOpen(true))
						dispatch(setDeleteBudgetId(budgetId))
					}
					: undefined
			}
			addColumn={budgets.some((budget) => budget.editAccess)}
		/>
	)
}

export default BudgetTable
