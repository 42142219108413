//icons
import { warehouseProduct } from '../../../../assets/general/generalIcons'

// react
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import InfoDetailsTable from '../../../general/infoDetailsTable/infoDetailsTable'
import AvatarPicker from '../../../general/avatarPicker/avatarPicker'
import InventoryItemImageList from './inventoryItemImageList/inventoryItemImageList'
import Loading from '../../../general/loading/loading'

// network
import { authorizedRequest } from '../../../../utils/queries'
import { singleInventoryItemUrl } from '../../../../utils/urls/warehouses/inventoryItems/inventoryItem'

// types
import { currency, infoDetailsTableDataItem, infoType, inventoryItemResponse, tableTypes } from '../../../../types/general/generalTypes'

// other
import { blobStringToBase64, currencyToFormattedString, getCost } from '../../../../assets/general/generalFunctions'

// translation
import { useTranslation } from 'react-i18next'
import ExtraColumnDetails from '../../../general/extraColumnDetails/extraColumnDetails'

type details = {
	name: string
	description: string
	avatar: string | null
	costs: {
		minAmount?: number,
		maxAmount?: number,
		currency: currency
	}[]
	editAccess: boolean
}


type inventoryItemDetailsProps = {
	setInventoryItemName: Dispatch<SetStateAction<string>>
}

type errors = {
	name?: string
}

const InventoryItemDetails: FC<inventoryItemDetailsProps> = ({ setInventoryItemName }) => {

	const [details, setDetails] = useState<details>({
		name: '',
		description: '',
		avatar: null,
		costs: [
			{
				minAmount: 0,
				maxAmount: 0,
				currency: currency.USD,
			},
			{
				minAmount: 0,
				maxAmount: 0,
				currency: currency.EUR,
			},
			{
				minAmount: 0,
				maxAmount: 0,
				currency: currency.UAH,
			},
		],
		editAccess: false,
	})
	const [editDetails, setEditDetails] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [errors, setErrors] = useState<errors>({})

	const { inventoryItemId } = useParams()

	const { t } = useTranslation('', { keyPrefix: 'warehouse.inventoryItem.inventoryItemDetails' })
	const tError = useTranslation('', { keyPrefix: 'warehouse.general.modals.inventoryItemModals.createInventoryItemModal' }).t

	const checkErrors = () => {
		let hasErrors = false
		if (!details?.name.trim()) {
			setErrors(prevErrors => ({...prevErrors, name: tError('createNewInventoryItem')}))
			hasErrors = true
		}
		return hasErrors
	}

	const loadData = async () => {
		const { result }: { result: inventoryItemResponse } = await authorizedRequest(singleInventoryItemUrl(Number(inventoryItemId)), 'GET')
		const avatar = result.avatar ? await blobStringToBase64(result.avatar, 'image/png') : null

		setInventoryItemName(result.name)

		setDetails({
			name: result.name,
			description: result.description,
			avatar: avatar,
			editAccess: result.edit_access,
			costs: getCost(result.costs)
		})
	}

	const editData = async () => {
		const hasErrors = checkErrors()
		if (hasErrors) return
		if (details) {
			await authorizedRequest(singleInventoryItemUrl(Number(inventoryItemId)), 'PUT', 'accessToken', {
				name: details.name,
				description: details.description,
				avatar: details.avatar
			})
			setEditDetails(false)
		}
	}

	const infoDetailsTableData: infoDetailsTableDataItem[] = [
		{
			title: t('inventoryItemName'),
			data: {
				inputField: {
					type: 'text',
					value: details.name,
					onChange: (e) => {
						setDetails({ ...details, name: e.target.value })
						setInventoryItemName(e.target.value)
						setEditDetails(true)
					},
					disabled: !details.editAccess,
					info: errors.name ? {
						type: infoType.error,
						text: errors.name
					} : undefined
				}
			}
		},
		{
			title: t('description'),
			data: {
				textArea: {
					value: details.description,
					setValue: (value) => {
						setDetails({ ...details, description: value })
						setEditDetails(true)
					},
					disabled: !details.editAccess
				}
			}
		},
		...details.costs.filter(cost => cost.minAmount || cost.maxAmount).map((cost) => {
			return {
				title: '',
				data: {
					inputField: {
						type: 'text',
						value: `${t('cost')}: ${cost.minAmount ? currencyToFormattedString(cost.minAmount, cost.currency) : ''}${cost.minAmount && cost.maxAmount ? ' - ' : ''}${cost.maxAmount ? currencyToFormattedString(cost.maxAmount, cost.currency) : ''}`,
						disabled: true
					}
				}
			}
		})
	]

	useEffect(() => {
		setErrors({})
	},[details])

	useEffect(() => {

		if (editDetails) {
			editData()
			setTimeout(() => {
				setEditDetails(false)
			}, 500)
		}

	}, [details])

	useEffect(() => {
		loadData()
	}, [])


	return (
		<>
			<AvatarPicker
				currenctAvatar={details?.avatar || null}
				defaultAvatar={warehouseProduct}
				submitAvatar={(avatar) => {
					if (details) {
						setDetails({ ...details, avatar: avatar })
						setEditDetails(true)
					}
				}}
			/>
			<InfoDetailsTable data={infoDetailsTableData} />
			<ExtraColumnDetails type={tableTypes.inventoryItem}  objectId={Number(inventoryItemId)} setLoading={setLoading}/>

			<InventoryItemImageList />
			{!details || loading  && <Loading style={{ top: '40vh', left: '50vw' }} />}
		</>
	)
}

export default InventoryItemDetails