//react
import { FC, useState, useEffect } from 'react'

//types
import { file } from '../../../../../types/general/generalTypes'

type textViewerProps = {
  file: file
}

const TextViewer: FC<textViewerProps> = ({ file }) => {
	const [content, setContent] = useState<string>('')

	useEffect(() => {
		if (file) {
			const reader = new FileReader()
			reader.onload = (e) => {
				setContent(e.target?.result as string)
			}
			reader.readAsText(file.file)
		}
	}, [file])

	return <pre>{content}</pre>
}

export default TextViewer
