//styles
import './feedbackModal.scss'

//react
import { useState } from 'react'

//components
import SwitchButtons from '../../../switchButtons/switchButtons'
import TextArea from '../../../textArea/textArea'
import Modal from '../../../modals/modal/modal'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../customHooks/redux'
import { setFeedbackModalIsOpen } from '../../../../../redux/general/modals'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { feedbackUrl } from '../../../../../utils/urls/general/internal/internal'

//types
import { switchButton } from '../../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'


const FeedbackModal = () => {
	const { t } = useTranslation('', { keyPrefix: 'general.layout.navbar' })

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [feedbackMessage, setFeedbackMessage] = useState<string>('')
	const [activeToolBarIconId, setActiveToolBarIconId] = useState<number>(0)

	const { modalIsOpen } = useAppSelector((state) => state.generalModals.feedbackModal)  
  
	const dispatch = useAppDispatch()

	const buttons: switchButton[] = [
		{
			id: 0,
			text: t('reportTheBug'),
		},
		{
			id: 1,
			text: t('requestFeedback'),
		},
		{
			id: 2,
			text: t('messageForTheTeam')
		},
	]

	const submitFeedbackMessage = async () => {
		setIsLoading(true)


		await authorizedRequest(feedbackUrl, 'POST', 'accessToken', {
			type_id: activeToolBarIconId,
			message: feedbackMessage
		})
    
		setIsLoading(false)

		closeModal()
	}

	const closeModal = () => {
		dispatch(setFeedbackModalIsOpen(false))
		setFeedbackMessage('')
		setActiveToolBarIconId(1)
	}
  
	return (
		<Modal
			closeModal={closeModal}
			open={modalIsOpen}
			title={t('feedback')}
			isLoading={isLoading}
			submitButton={{
				text: t('send'),
				onClick: submitFeedbackMessage
			}}
		>
			<div className='feedback'>
				<SwitchButtons
					switchButtons={buttons}
					activeSwitchButtonId={activeToolBarIconId}
					setActiveSwitchButtonId={setActiveToolBarIconId}
				/>

				<TextArea
					value={feedbackMessage}
					setValue={(value) => setFeedbackMessage(value) }
					label={t('message')}
				/>
			</div>
		</Modal>
	)
}

export default FeedbackModal