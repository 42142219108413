import { employee } from '../employees/hierarchyTypes'
import { currency, employeeResponse, error, errorResponse } from '../general/generalTypes'
import { account, accountResponse } from './accountTypes'

export enum expenseCategory  {
    utilities = 'utilities',
    rent_lease_payments = 'rent_lease_payments',
    insurance_premiums = 'insurance_premiums',
    employee_related_expenses = 'employee_related_expenses',
    supplies_and_office_expenses = 'supplies_and_office_expenses',
    inventory_and_cost_of_goods_sold = 'inventory_and_cost_of_goods_sold',
    professional_services = 'professional_services',
    loan_payments = 'loan_payments',
    equipment_maintenance = 'equipment_maintenance',
    taxes = 'taxes',
    communication_services = 'communication_services',
    marketing_and_advertising = 'marketing_and_advertising',
    travel_and_accommodation = 'travel_and_accommodation',
    licenses_and_permits = 'licenses_and_permits',
    bank_and_financial_charges = 'bank_and_financial_charges',
    maintenance_and_repairs = 'maintenance_and_repairs',
    training_and_development = 'training_and_development',
    subscriptions_and_memberships = 'subscriptions_and_memberships',
    miscellaneous_expenses = 'miscellaneous_expenses'
}

export enum transactionType{
	expense='expense',
	income='income'
}

export const transactionStatusColor: transactionTypeStatusColor = {
	[transactionType.expense]: { color: '#FF6464', bg: '#F1BDBD' },
	[transactionType.income]: { color: '#1db954', bg: '#E0FAE9' }
}

export type transactionTypeStatusColor = {
	[key in transactionType]: {color: string; bg: string}
}

export type transaction = {
	id: number
	accountId: number
	amount: number
	currency: currency
	transactionType: transactionType
	description: string
	date: Date
	deleteAccess: boolean
}

export type transactionResponse = {
	id: number
	account_id: number
	amount: number
	currency: currency
	transaction_type: transactionType
	description: string
	date: number
}

export enum billStatus {
	pending='pending',
	paid='paid'
}

export type bill = {
    id: number
    amount: number
    currency: currency
    endDate: Date | undefined | null
    title: string
    description: string
    category: expenseCategory
    status: billStatus
    deleteAccess: boolean
	editAccess?: boolean
	createAccess: boolean
    transactionType: transactionType
	account: account | null
	employee: employee | null
	errors: error[]
}

export const billStatusColor: transactionTypeStatusColor = {
	[transactionType.expense]: { color: '#FF6464', bg: '#F1BDBD' },
	[transactionType.income]: { color: '#1db954', bg: '#E0FAE9' }
}

export type billResponse = {
	amount: number
	bill_type: transactionType
	category: string
	currency: currency
	description: string
	due_date: number
	id: number
	account: accountResponse | null
	employee: employeeResponse | null
	status: billStatus
	title: string
	delete_access: boolean
	edit_access: boolean
	create_access: boolean
	errors: errorResponse[]
}
