//styles & icons
import { errorAlert } from '../../../assets/general/generalIcons'
import './register.scss'

//react
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

//components
import CheckBox from '../../../components/general/checkBox/checkBox'
import InputField from '../../../components/general/inputField/inputField'
import Button from '../../../components/general/button/button'
import AuthenticationHeader from '../../../components/authentication/general/authenticationHeader/authenticationHeader'
import Loading from '../../../components/general/loading/loading'
import LanguageSelector from '../../../components/general/languageSelector/languageSelector'

//redux
import { useAppSelector } from '../../../customHooks/redux'

//network
import { unauthorizedRequest } from '../../../utils/queries'
import { registerUrl } from '../../../utils/old_urls/registrationUrls'
import { hierarchyHireEmployeeInvitationConfirmUrl } from '../../../utils/urls/employees/hierarchy'

//translation
import { useTranslation } from 'react-i18next'

//type
import { infoType } from '../../../types/general/generalTypes'

type registerDataTypes = {
	firstName: string
	lastName: string
	email: string
	password: string
	confirmPassword: string
	phoneNumber: string
	termsAndPrivacyPolicy: boolean
}

type formErrors = {
	firstName?: string
	lastName?: string
	email?: string
	password?: {
		error: string;
		passwordInfo?: string[]
	}
	confirmPassword?: string
	phoneNumber?: string
	termsAndPrivacyPolicy?: string
}

const Register = () => {

	const { t } = useTranslation('', { keyPrefix: 'authentication.register' })

	const { language } = useAppSelector((state) => state.general)
	const [registerData, setRegisterData] = useState<registerDataTypes>({
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		confirmPassword: '',
		phoneNumber: '',
		termsAndPrivacyPolicy: false
	})

	const [loading, setLoading] = useState(false)

	const [isChecked, setIsChecked] = useState(false)
	const [passwordVisible, setPasswordVisible] = useState(false)
	const [errors, setErrors] = useState<formErrors>({})

	const navigate = useNavigate()

	const { token } = useParams()

	const getUserDataFromToken = async () => {
		const { result } = await unauthorizedRequest(hierarchyHireEmployeeInvitationConfirmUrl + `?token=${token}`, 'GET')
		setRegisterData({...registerData, email: result})
	}

	useEffect(() => {
		if (token) {
			getUserDataFromToken()
		}
	}, [token])

	const checkErrors = () => {
		let result = true

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

		if (registerData.firstName === '') {
			setErrors(prevErrors => ({ ...prevErrors, firstName: t('pleaseEnterYourFirstName') }))
			result = false
		}
		if (registerData.lastName === '') {
			setErrors(prevErrors => ({ ...prevErrors, lastName: t('pleaseEnterYourLastName') }))
			result = false
		}
		if (registerData.email === '' || !emailRegex.test(registerData.email)) {
			setErrors(prevErrors => ({ ...prevErrors, email: t('pleaseEnterYourEmail') }))
			result = false
		}
		if (registerData.password === '' || registerData.password.length < 8 || /[A-Z]/g.test(registerData.password) === false || /[0-9]/g.test(registerData.password) === false) {
			setErrors(prevErrors => ({ ...prevErrors, password: { error: t('pleaseEnterYourPassword'), passwordInfo: [t('thePasswordHasToBe'), t('thePasswordHasToInclude')] } }))
			result = false
		}
		if (registerData.confirmPassword === '' || registerData.password !== registerData.confirmPassword) {
			setErrors(prevErrors => ({ ...prevErrors, confirmPassword: t('pleaseMakeSureYourPasswordMatch') }))
			result = false
		}
		if (registerData.phoneNumber === '') {
			setErrors(prevErrors => ({ ...prevErrors, phoneNumber: t('pleaseEnterYourPhoneNumber') }))
			result = false
		}
		if (isChecked === false) {
			setErrors(prevErrors => ({ ...prevErrors, termsAndPrivacyPolicy: t('pleaseAcceptOurTermsAndServices') }))
			result = false
		}

		return result
	}
	const handleSubmit = async () => {
		setLoading(true)
		if (checkErrors()) {
			const registerResponse = unauthorizedRequest(registerUrl, 'POST', { first_name: registerData.firstName, last_name: registerData.lastName, email: registerData.email, password: registerData.password, phone_number: registerData.phoneNumber, language: language })
			registerResponse.then((responce) => {
				if (responce === 409) {
					setErrors({
						...errors,
						email: t('thisEmailIsAlready')
					})
				} else {
					new Promise<void>((resolveOuter) => {
						setErrors({})
						localStorage.setItem('verificationToken', responce.result.token)
						resolveOuter()
					}).then(() => {
						if (token) {
							navigate(`/accept-invitation/confirmation/${registerData.email}/${token}`)
						} else {
							navigate(`/confirmation/${registerData.email}`)
						}
					})
				}
			}).finally(() => {
				setLoading(false)
			})
		} else {
			setLoading(false)
		}
	}


	const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
		setRegisterData({
			...registerData,
			[e.currentTarget.name]: e.currentTarget.value
		})
	}

	const handlePhoneNumberChange = (e: React.FormEvent<HTMLInputElement>): void => {
		setRegisterData({
			...registerData,
			[e.currentTarget.name]: e.currentTarget.value.toLowerCase().replace(/[a-z]/g, '')
		})
	}

	const handleTermsOfUseNavigation = (): void => {
		navigate('/terms-of-use')
	}

	const handlePrivacyPolicyNavigation = (): void => {
		navigate('/privacy-policy')
	}

	useEffect(() => {
		setErrors({})
	}, [registerData])

	return (
		<div className="registration">
			<form onSubmit={(e) => {
				e.preventDefault()
				handleSubmit()
			}} className="registration-form">
				<div className="registration-wrraper">
					<AuthenticationHeader
						title={t('createAnAccount')}
						subtitle={{
							text: t('haveAnAccount'),
							link: {
								text: t('singIn'),
								url: token ? `/accept-invitation/login/${token}` : '/login'
							}
						}}
					/>
					<div className="registration-info">
						<div className="fields-container">
							<div className="user-name">
								<InputField
									placeholder={t('firstName')}
									value={registerData.firstName}
									onChange={handleChange}
									type="text"
									name='firstName'
									info={errors.firstName ? {
										type: infoType.error,
										text: errors.firstName
									} : undefined}
								/>
								<InputField
									placeholder={t('lastName')}
									name='lastName'
									value={registerData.lastName}
									onChange={handleChange}
									type="text"
									info={errors.lastName ? {
										type: infoType.error,
										text: errors.lastName
									} : undefined}
								/>
							</div>
							<InputField
								placeholder={t('email')}
								name='email'
								value={registerData.email}
								onChange={handleChange}
								type="email"
								info={errors.email ? {
									type: infoType.error,
									text: errors.email
								} : undefined}
								disabled={token ? true : false} 
							/>
							<InputField
								type={passwordVisible ? 'text' : 'password'}
								placeholder={t('password')}
								name='password'
								minLength={6}
								value={registerData.password}
								onChange={handleChange}
								info={errors?.password?.error ? {
									type: infoType.error,
									text: errors.password.error
								} : undefined}
								errorInfoList={errors?.password?.passwordInfo}
								passwordVisibility={
									{
										onClick: () => { setPasswordVisible(!passwordVisible) },
										visible: passwordVisible
									}
								}
							/>

							<InputField
								minLength={6}
								type={passwordVisible ? 'text' : 'password'}
								placeholder={t('repeatPassword')}
								name='confirmPassword'
								value={registerData.confirmPassword}
								onChange={handleChange}
								info={errors.confirmPassword ? {
									type: infoType.error,
									text: errors.confirmPassword
								} : undefined}
							/>


							<InputField
								placeholder={t('phoneNumber')}
								name='phoneNumber'
								value={registerData.phoneNumber}
								onChange={handlePhoneNumberChange}
								type="tel"
								info={errors.phoneNumber ? {
									type: infoType.error,
									text: errors.phoneNumber
								} : undefined}
							/>
							<div className='registration-footer'>
								<div className="registration-footer-content">

									<CheckBox
										isChecked={isChecked}
										onClick={() => setIsChecked(!isChecked)}
									/>
									<p className='registration-footer-text' onClick={() => setIsChecked(!isChecked)}>
										<span>
											{t('termsAndPrivacyLabel')}{' '}
										</span>
										<span className='terms-and-privacy-link' onClick={handleTermsOfUseNavigation}>
											{t('termsOfUser')}{' '}
										</span>
										<span>
											{t('and')}{' '}
										</span>
										<span className='terms-and-privacy-link' onClick={handlePrivacyPolicyNavigation}>
											{t('privacyPolicy')}
										</span>
									</p>
								</div>
								{errors.termsAndPrivacyPolicy ? (<div className='error-wrapper'>
									<div className='error-text'>
										{errorAlert}
										<p>{errors.termsAndPrivacyPolicy}</p>
									</div>
								</div>) : null}
							</div>
						</div>

						<Button active={!loading} text={t('create')} onClick={handleSubmit} />
					</div>
				</div>
			</form>
			{loading ? <Loading /> : null}
			<div className='registration-langueage'>
				<LanguageSelector />
			</div>
		</div>
	)
}

export default Register
