//components
import ConfirmationModal from '../../../../modals/confirmationModal/confirmationModal'

//react
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import { deleteOrderUrl } from '../../../../../../utils/old_urls/general/generalUrls'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../../customHooks/redux'
import { setDeleteOrderId, setDeleteOrdernModalIsOpen } from '../../../../../../redux/general/order/modals'

//translation
import { useTranslation } from 'react-i18next'
import { order } from '../../../../../../types/general/generalTypes'

type deleteOrderModalProps = {
	orders?: order[]
	setOrders?: (value: order[]) => void
}

const DeleteOrderModal: FC<deleteOrderModalProps> = ({orders, setOrders}) => {
	const { t } = useTranslation('', { keyPrefix: 'order.orderDetails.modals.deleteOrderModal' })
	const { modalIsOpen, orderId } = useAppSelector((state) => state.orderModals.deleteOrderModal)

	const [loading, setLoading] = useState<boolean>(false)

	const navigate = useNavigate()

	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setDeleteOrdernModalIsOpen(false))
		dispatch(setDeleteOrderId(null))
	}

	const handleSubmit = () => {
		const body = {
			'sale_order': true
		}
		if(orderId){
			setLoading(true)
			authorizedRequest(deleteOrderUrl(orderId), 'DELETE', 'accessToken',body)
				.then((response) => {
					setLoading(false)
					closeModal()

					if(setOrders && orders){
						setOrders([...orders.filter(order => order.id !== orderId)])
					}else{
						navigate(-1)
					}
				})
		}
	}

	return (
		<ConfirmationModal
			title={t('deleteThisOrder')}
			buttons={[{ text: t('yes'), onClickHandler: handleSubmit }, { text: t('no'), onClickHandler: closeModal }]} 
			isOpen={modalIsOpen} 
			closeModal={closeModal}
			loading={loading}
		/>
	)
}

export default DeleteOrderModal