// react
import { FC } from 'react'
import { useParams } from 'react-router-dom'

// component
import FileSelectorModal from '../../../../../general/modals/fileSelectorModal/fileSelectorModal'

// type
import { file, document } from '../../../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import { singleProviderDocumentsUrl } from '../../../../../../utils/urls/purchases/provider'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setImportProviderDocumentFileSelectorModalIsOpen, setImportProviderDocumentFileSelectorFile } from '../../../../../../redux/purchase/provider/modals'

//other
import { blobStringToBlob, blobToBase64 } from '../../../../../../assets/general/generalFunctions'

type importProviderDocumentFileSelectorModalProps = {
	setDocumentsData: (value: document[]) => void
	documentsData: document[]
	providerId?: number
}

type providerDocumentUploadResponse = {
	id: number
	file: string
	file_type: string
	file_name: string
}

const ImportProviderDocumentFileSelectorModal : FC<importProviderDocumentFileSelectorModalProps> = ({ documentsData, setDocumentsData, providerId }) => {
	const { modalIsOpen } = useAppSelector((state) => state.providerModal.providerDocuments.importProviderDocumentFileSelectorModal)
	const dispatch = useAppDispatch()

	const { relationshipId } = useParams()

	const closeModal = () => {
		dispatch(setImportProviderDocumentFileSelectorModalIsOpen(false))
	}

	const setFiles = async (files: file[]) => {		
		if (files.length === 0) return

		const filesData = await Promise.all(files.map(async (file) => {
			const base64File = await blobToBase64(file.file)
			return {
				'name': file.fileName,
				'file_type': file.fileType,
				'file': base64File,
				'size': file.file.size
			}
		}))

		const data = {
			files: filesData
		}

		const filteredFiles = data.files.filter(Boolean)

		if (filteredFiles.length > 0) {
			authorizedRequest(singleProviderDocumentsUrl(Number(relationshipId ? relationshipId : providerId)), 'POST', 'accessToken', data)
				.then((response) => {
					dispatch(setImportProviderDocumentFileSelectorModalIsOpen(false))
					const responseData: document[] = response.result.map((item: providerDocumentUploadResponse) => {
						return {
							file: blobStringToBlob(item.file, item.file_type),
							fileName: item.file_name,
							fileType: item.file_type,
							id: item.id
						}
					})
					setDocumentsData([...documentsData, ...responseData])
				})
				.catch((error) => {
					console.log('error', error)
				})
			dispatch(setImportProviderDocumentFileSelectorFile(files[0]))
		}

	}

	return (
		<FileSelectorModal 
			isOpen={modalIsOpen} 
			setIsOpen={closeModal}
			setFiles={(value) => { setFiles(value) }}
			supportedFormats={[
				{title: 'csv', mime: 'text/csv'},
				{title: 'xls', mime: 'application/vnd.ms-excel'},
				{title: '.docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'},
				{title: '.pdf', mime: 'application/pdf'}, // jpg png .mp4 .mp3 All
				{title: '.jpeg', mime: 'image/jpeg'},
				{title: '.jpg', mime: 'image/jpg'},
				{title: '.png', mime: 'image/png'},
				{title: '.mp4', mime: 'video/mp4'},
				{title: '.webm', mime: 'video/webm'},
				{title: '.mp3', mime: 'audio/mpeg'},
				{title: '.weba', mime: 'audio/webm'},
				{title: '.ppt', mime: 'application/vnd.ms-powerpoint'},
				{title: '.zip', mime: 'application/zip'}
			]}			
		/>
	)
}

export default ImportProviderDocumentFileSelectorModal