//react
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

// components
import AppFeature from '../../components/general/appFeature/appFeature'
import Layout from '../../components/general/layout/layout'
import ParticlesBg from '../../components/general/particlesBg/particlesBg'
import HeaderAppFeature from '../../components/general/headerAppInformation/headerAppFeature'
import LandingTextSection from '../../components/general/landingTextSection/landingTextSection'

//translations
import { useTranslation } from 'react-i18next'

//other
import { links } from '../../components/general/layout/navbar/navbarData'
import { youtubeIcon } from '../../assets/icons/icons'

type pageSection = {
	title: string
	icon: JSX.Element
	image: string
	links:{
		text: string
		href: string
		title: string
		description: string
		icon?: JSX.Element
	}[]
}

const AppInformation = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.appInformation' })
	const { page, section } = useParams()
	const [pageData, setPageData] = useState<pageSection>()

	const pageTranslation = page && page.replace(/^./, (match) => match.toUpperCase())

	useEffect(() => {
		[links[0], links[1]].forEach((link) => {
			link.dropdownElements?.forEach((pageSection) => {
				if (pageSection.title === page) {
					setPageData(pageSection)
				}
			})
		})
	}, [page, section])
	
	useEffect(()=>{
		if(pageData){
			const element = document.getElementById(`app-info-${section?.replace(/:/g, '')}`)
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' })
			}

			if(!section){
				window.scrollTo({ top: 0, behavior: 'smooth' })
			}
		}
	},[pageData, section])

	const findIcon = (
		links: {dropdownElements?: { title: string; icon: React.ReactNode }[]}[],
		page?: string): React.ReactNode | null => 
		links.flatMap(link => link.dropdownElements || []).find(pageSection => pageSection.title === page)?.icon || null
    
	const icon = findIcon(links, page) 

	const iconPositions: {
		[key: string]: { left: string; top: string };
	} = {
		projects: { left: '1.5vw', top: '-0.5vw' },
		sales: { left: '16vw', top: '6vw' },
		purchases: { left: '13vw', top: '-2vw' },
		employees: { left: '22vw', top: '3vw' },
		finance: {  left: '13vw', top: '-2vw' },
		warehouses: { left: '24vw', top: '3vw'  },
		cloudStorage: { left: '10vw', top: '3vw'  },
		securityAndFunctionality: { left: '0vw', top: '0vw'  },
		documentTemplates: { left: '5vw', top: '0vw'  },
		timeManagement: { left: '20vw', top: '3vw'  },
		emailMarketing: { left: '3vw', top: '0vw'  },
	}

	const iconPosition: { left: string; top: string } | undefined =
  page && page in iconPositions ? iconPositions[page] : undefined

	return (
		<Layout>
			<HeaderAppFeature 
				page={page}
				heading={[t(`main${pageTranslation}`), t(`main${pageTranslation}Black`)]}
				text={t(`main${pageTranslation}Description`)}
				buttons={[{text: t('mainButton'), link: '/register', variant: 'solid'}, {text: t('mainCreditCard'), link: '', variant: 'outlined'}]}
				imagesData={[
					{
						image: pageData ? require(`../../assets/images/appFeature/headerFeature/${pageData.image}Img1.png`): null,
						icon: page !== 'sales' && page !== 'projects' ? {
							icon: icon,
							iconPosition: iconPosition
						} : null
					},
					{
						image: pageData ? require(`../../assets/images/appFeature/headerFeature/${pageData.image}Img2.png`) : null,
						icon: page == 'sales' || page == 'projects' ? {
							icon: icon,
							iconPosition: iconPosition
						} : null
					}
				]}
			/>
			{
				pageData ?
					pageData.links.map((pageSection, idx)=>{
						return (
							<AppFeature
								key={`app-info-${idx}`}
								id={`app-info-${pageSection.text}`}
								smallText={t(pageSection.text)}
								heading={t(pageSection.title)}
								text={t(pageSection.description)}
								image={require(`../../assets/images/appFeature/${pageData.image}/Macbook${idx}.png`)}
								buttons={[{text: t('mainButton'), link: '/register', variant: 'solid'}]}
								underButtonText={t('mainCreditCard')}
								contentRight={idx%2 == 0 ? false : true}
								icon={pageSection.icon}
							/>
						)
					})
					: null
			}
			<LandingTextSection
				translation='apps.landing.appInformation'
				text='specialOfferText'
				title='specialOfferTitle'
				button={{status: true, icon: youtubeIcon}}
			/>
			<ParticlesBg/>
		</Layout>
	)
}

export default AppInformation