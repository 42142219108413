//styles
import './templateSelectionModal.scss'

//react
import { FC, useEffect, useState } from 'react'

//components
import Modal from '../../modal/modal'
import TemplateList from '../../../templateList/templateList'
import Pagination from '../../../pagination/pagination'
import DeleteTemplateModal from '../deleteTemplateModal/deleteTemplateModal'
import Table from '../../../table/table'
import Button from '../../../button/button'
import FileSelectorModal from '../../fileSelectorModal/fileSelectorModal'
import FilePreviewModal from '../../filePreviewModal/filePreviewModal'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { companyTemplateFieldsUrl, companyTemplatesUrl, singleTemplateUrl } from '../../../../../utils/urls/general/templates/templates'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../customHooks/redux'
import { setDeleteTemplateId, setDeleteTemplateModalIsOpen } from '../../../../../redux/general/modals'

//types
import { columnTypes, file, template, templateTypes } from '../../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

//other
import { blobStringToBlob, blobToBase64, formatTemplateResponse } from '../../../../../assets/general/generalFunctions'

type templateSelectorModalProps = {
    isOpen: boolean
	setIsOpen: (value: boolean) => void
    templateType: templateTypes
	objectId: number
}

type availableField = {
	key: string
	title: string
	value: string
}


const TemplateSelectionModal: FC<templateSelectorModalProps> = ({ isOpen, setIsOpen, templateType, objectId }) => {
	const {t} = useTranslation('', { keyPrefix: 'general.modals.templateSelectionModal' })

	const dispatch = useAppDispatch()
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const closeModal = () => setIsOpen(false)

	const [templates, setTemplates] = useState<template[]>([])

	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)

	const [ selectedTemplateId, setSelectedTemplateId ] = useState<number | undefined>(undefined)

	const [availableFields, setAvailableFields] = useState<availableField[]>([])

	const [isCreating, setIsCreating] = useState(false)


	const [previewModal, setPreviewModal] = useState<{
		isOpen: boolean
		file: file | undefined
	}>({
		isOpen: false,
		file: undefined
	})


	const data: availableField[] = []


	const processResult = (obj: Record<string, any>, parentKey = '') => {
		for (const key in obj) {
			const fullKey = parentKey ? `${parentKey}.${key}` : key
		
			if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
			// Separator for starting nested object
				data.push({
					key: '',
					title: ' ',
					value: ' '
				})
				data.push({
					key: '',
					title: `--- ${t('startNestedObject')}: ${fullKey}`,
					value: ''
				})
				data.push({
					key: '',
					title: ' ',
					value: ' '
				})

				processResult(obj[key], fullKey)

				// Separator for ending nested object
				data.push({
					key: '',
					title: ' ',
					value: ' '
				})
				data.push({
					key: '',
					title: `--- ${t('endNestedObject')}: ${fullKey}`,
					value: ''
				})
				data.push({
					key: '',
					title: ' ',
					value: ' '
				})
			} else if (Array.isArray(obj[key])) {
			// Separator for starting array processing
				data.push({
					key: '',
					title: ' ',
					value: ' '
				})
				data.push({
					key: '',
					title: `=== ${t('startArray')}: ${fullKey}`,
					value: ''
				})
				data.push({
					key: '',
					title: ' ',
					value: ' '
				})

				// Start loop for array
				data.push({
					key: `{% for item in ${fullKey} %}`,
					title: `${t('startLoop')}: ${key}`,
					value: ''
				})

				// Only show key structure once
				if (obj[key].length > 0) {
					const firstItem = obj[key][0]
					if (typeof firstItem === 'object' && firstItem !== null) {
					// For nested objects within array
						for (const subKey in firstItem) {
							if (Array.isArray(firstItem[subKey])) {
								data.push({
									key: `{% for sub_item in item.${subKey} %}`,
									title: `${t('startNestedLoop')}: ${subKey}`,
									value: ''
								})
								data.push({
									key: '{{ sub_item }}',
									title: `item.${subKey}`,
									value: firstItem[subKey].map((el: any) => el !== null && el !== undefined ? `${el}` : '').join(', ')
								})
								data.push({
									key: '{% endfor %}',
									title: `${t('endNestedLoop')}: ${subKey}`,
									value: ''
								})
							} else {
								data.push({
									key: `{{ item.${subKey} }}`,
									title: `item.${subKey}`,
									value: firstItem[subKey] !== null && firstItem[subKey] !== undefined ? `${firstItem[subKey]}` : ''
								})
							}
						}
					} else {
					// For primitive types in array
						data.push({
							key: '{{ item }}',
							title: `${key}`,
							value: obj[key].map((el: any) => el !== null && el !== undefined ? `${el}` : '').join(', ')
						})
					}
				}

				// End loop for array
				data.push({
					key: '{% endfor %}',
					title: `${t('endLoop')}: ${key}`,
					value: ''
				})

				// Separator for ending array processing
				data.push({
					key: '',
					title: ' ',
					value: ' '
				})
				data.push({
					key: '',
					title: `=== ${t('endArray')} : ${fullKey}`,
					value: ''
				})
				data.push({
					key: '',
					title: ' ',
					value: ' '
				})
			} else {
				data.push({
					key: `{{ ${fullKey} }}`,
					title: key,
					value: obj[key] !== null && obj[key] !== undefined ? `${obj[key]}` : ''
				})
			}
		}

		return data
	}

	const loadData = async (page: number) => {
		const { result } = await authorizedRequest(companyTemplatesUrl(companyId) + `?type=${templateType}&page=${page}&per_page=5`, 'GET')
		
		if (result.length > 0) {
			const formatedDocumentsData: template[] = result.map(formatTemplateResponse)
			return formatedDocumentsData
		}
		return []
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			setLoading(true)
			loadData(page)
				.then((result) => {
					if (result.length > 0) {
						setTemplates([...templates, ...result])
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
					setLoading(false)
				})
		}
	}

	const loadAvailableFields = async () => {
		const { result }: { result: Record<string, any> } = await authorizedRequest(companyTemplateFieldsUrl(companyId) + `?type=${templateType}&object_id=${objectId}`, 'GET')
		console.log(result, processResult(result, ''))
		setAvailableFields([...processResult(result, '')])
	}


	const handleDelete = (templateId: number) => {
		dispatch(setDeleteTemplateId(templateId))
		dispatch(setDeleteTemplateModalIsOpen(true))
	}

	const settingFilesData = async (files: file[]) => {

		const fileBase64 = await blobToBase64(files[0].file)

		const { result } = await authorizedRequest(companyTemplatesUrl(companyId), 'POST', 'accessToken', {
			type: templateType,
			file: fileBase64,
			file_name: files[0].fileName,
			file_type: files[0].fileType
		})

		setTemplates([...templates, {
			id: result.id,
			preview: {
				file: files[0].file,
				fileName: files[0].fileName,
				fileType: files[0].fileType
			}
		}])
		setIsCreating(false)

	}


	const generateDocument = async (templateId: number, objectId: number) => {
		const { result } = await authorizedRequest(singleTemplateUrl(templateId), 'PATCH', 'accessToken', {
			object_id: objectId
		})

		const file = await blobStringToBlob(result.file, result.file_type)

		setPreviewModal({
			isOpen: true,
			file: {
				file: file,
				fileName: result.file_name,
				fileType: result.file_type
			}	
		})

		setSelectedTemplateId(templateId)
	}

	const handleDownload = async (templateId: number,  objectId: number) => {
		await authorizedRequest(singleTemplateUrl(templateId), 'POST', 'accessToken', {
			object_id: objectId
		})

		setPreviewModal({
			isOpen: false,
			file: undefined
		})

		closeModal()
	}

	useEffect(() => {
		handleLoadMore()
		loadAvailableFields()
	}, [])

	return (
		<>
			<Modal
				closeModal={closeModal}
				open={isOpen}
			>
				<div className='template-selector-container'>
					<Button
						text={t('create')}
						onClick={() => {
							setIsCreating(true)
						}}
						active={true}
					/>
					<Pagination onLoadMore={handleLoadMore}>
						<TemplateList
							templates={templates}
							handleGenerate={generateDocument}
							handleDelete={handleDelete}
							objectId={objectId}
						/>
					</Pagination>

				</div>
			</Modal>
			<DeleteTemplateModal templates={templates} setTemplates={setTemplates} />
			<FileSelectorModal
				supportedFormats={[
					{
						title: 'docx',
						mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
					},
					{
						title: 'pdf',
						mime: 'application/pdf'
					}
				]}
				isOpen={isCreating}
				setIsOpen={setIsCreating}
				setFiles={(newFiles) => {
					settingFilesData(newFiles)
				}}
				extraElement={
					<Table
						columns={[
							{
								title: t('title'),
								key: 'title'
							},
							{
								title: t('key'),
								key: 'key'
							},
							{
								title: t('value'),
								key: 'value'
							}
						]}

						data={availableFields.map((field) => {
							return {
								key: {
									content: {
										type: columnTypes.string,
										value: field.key
									}
								},
								title: {
									content: {
										type: columnTypes.string,
										value: field.title
									}
								},
								value: {
									content: {
										type: columnTypes.string,
										value: field.value
									}
								}
							}
						})}
					/>
				}
			/>

			<FilePreviewModal
				isOpen={previewModal.isOpen}
				setIsOpen={(value) => {
					setPreviewModal({
						...previewModal,
						isOpen: value
					})
				}}
				handleDownload={() => {
					if(selectedTemplateId){
						handleDownload(selectedTemplateId, objectId)
					}
				}}
				file={previewModal.file}
			/>
		</>
	)
}

export default TemplateSelectionModal