//styles and icons
import './chat.scss'

//react
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'

//components
import ChatDesktop from '../../../components/general/chat/chatDesktop/chatDesktop'
import ChatBar from '../../../components/general/chat/chatBar/chatBar'
import Layout from '../../../components/general/layout/layout'

//context
import { ChatDesktopProvider } from '../../../components/general/chat/chatDesktop/context/chatDesktopContext'

const Chat = () => {

	const { chatId } = useParams()

	const [ mobile, setMobile ] = useState(screen.width <= 768)

 

	const handleResize = () => {
		setMobile(screen.width <= 768)
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])



	return (
		<Layout>
			<div className="chat-container">
				<div className='chat-container-layout' style={{
					width: chatId === undefined && mobile ? '0' : undefined
				}}>
					{
						chatId ?
							<ChatDesktopProvider>
								<ChatDesktop chatId={/^\d+$/.test(chatId) ? Number(chatId) : -1} />
							</ChatDesktopProvider>
							:
							null
					}
				</div>
				<ChatBar open={chatId === undefined} />
			</div>
		</Layout>
	)
}

export default Chat