// styles and icons
import './createProjectModal.scss'

//react
import { FC, useState } from 'react'

// components
import Modal from '../../../../general/modals/modal/modal'
import InputField from '../../../../general/inputField/inputField'
import Dropdown from '../../../../general/dropdown/dropdown'
import TextArea from '../../../../general/textArea/textArea'

// redux
import { useAppSelector, useAppDispatch } from '../../../../../customHooks/redux'
import { setCreateProjectModalIsOpen, setCreateProjectStageId } from '../../../../../redux/projects/pipeline/modals'

// network
import { authorizedRequest } from '../../../../../utils/queries'
import {companyProjectsUrl} from '../../../../../utils/urls/projects/project'

//types
import { currency, dragStage, projectResponse, project, infoType } from '../../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

//other
import { formatProjectResponse } from '../../../../../assets/projects/projectsFunctions'

type formErrors = {
	title?: string
}


type createProjectModalProps = {
	stagesList: dragStage<project>[]
	setStagesList: (value: dragStage<project>[]) => void
	projects: project[]
	setProjects: (value: project[]) => void
}

const CreateProjectModal:FC<createProjectModalProps> = ({ stagesList, setStagesList, projects, setProjects }) => {
	const { t } = useTranslation('',{keyPrefix: 'projects.pipeline.modals.createProjectModal'})

	const [ title, setTitle ] = useState('')
	const [ description, setDescription ] = useState('')
	const [ amountUah, setAmountUah ] = useState<number>(0)
	const [ amountUsd, setAmountUsd ] = useState<number>(0)
	const [ amountEur, setAmountEur ] = useState<number>(0)
	
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [ errors, setErrors ] = useState<formErrors>({}) 

	const { modalIsOpen, stageId } = useAppSelector(state=> state.pipelineModals.createProjectModal)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1
	
	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setCreateProjectModalIsOpen(false))
		dispatch(setCreateProjectStageId(null))
		setTitle('')
		setDescription('')
		setAmountUah(0)
		setAmountUsd(0)
		setAmountEur(0)
	}

	const checkErrors = () => {
		let hasErrors = false
		if(title === '') {
			hasErrors = true
			setErrors((prevErros) => ({...prevErros, title: t('enterTheTitlePlease')}))
		}      

		return hasErrors
	}

	const createProject = async () => {
		const hasErrors = checkErrors()
		if(hasErrors) return
		
		setIsLoading(true)

		const body = {
			stage_id: stageId,
			title: title,
			description: description,
			amount_uah: amountUah,
			amount_usd: amountUsd,
			amount_eur: amountEur
		}

		const response = await authorizedRequest(companyProjectsUrl(companyId), 'POST', 'accessToken', body)
		const result = response.result as projectResponse
		
		const stageIndex = stagesList.findIndex((stage) => stage.stageId === stageId)

		if(result) {
			if(stageIndex > -1){
				stagesList[stageIndex].quantity += 1
			}

			setProjects([formatProjectResponse(result), ...projects])
			setStagesList([...stagesList])
			setIsLoading(false)
			closeModal()
		}
	}

	
	return (
		<Modal
			closeModal={closeModal} 
			open={modalIsOpen}
			title={t('createAProject')}
			submitButton={{text: t('create'), onClick: createProject}}
			isLoading={isLoading}
		>
			<div className="create-project">
				<div className="create-project-section">
					<InputField
						type='text'
						value={`${amountUah}`}
						label={t('budgetUah')}
						placeholder='0'
						onChange={(e) => {
							setErrors({})
							setAmountUah(Number(e.currentTarget.value.toLowerCase().replace(/[a-z]/g,'')))
						}}
					/>  
					<InputField
						type='text'
						value={`${amountUsd}`}
						label={t('budgetUsd')}
						placeholder='0'
						onChange={(e) => {
							setErrors({})
							setAmountUsd(Number(e.currentTarget.value.toLowerCase().replace(/[a-z]/g,'')))
						}}
					/>  
					<InputField
						type='text'
						value={`${amountEur}`}
						label={t('budgetEur')}
						placeholder='0'
						onChange={(e) => {
							setErrors({})
							setAmountEur(Number(e.currentTarget.value.toLowerCase().replace(/[a-z]/g,'')))
						}}
					/>  
				</div>
				<InputField
					type='text'
					value={title}
					label={t('title')}
					placeholder={t('title')}
					onChange={(e) => {
						setErrors({})
						setTitle(e.target.value)
					}}
					info={errors.title ? {
						type: infoType.error,
						text: errors.title
					} : undefined}
				/>
				<TextArea value={description} setValue={(value) => {setDescription(value)}} label={t('description')} placeholder={t('description')} />
			</div>
		</Modal>
	)
}

export default CreateProjectModal