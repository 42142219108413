// styles and icons
import './createTimeTrackerModal.scss'

// react
import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import Modal from '../../../../../general/modals/modal/modal'
import InputField from '../../../../../general/inputField/inputField'
import FloatInput from '../../../../../general/floatInput/floatInput'
import Dropdown from '../../../../../general/dropdown/dropdown'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setCreateTimeTrackerModalIsOpen } from '../../../../../../redux/projects/task/modals'

// network
import { authorizedRequest } from '../../../../../../utils/queries'
import { singleTaskTimeTracker } from '../../../../../../utils/urls/projects/task'

// types
import { currency, dropdownOption, executor, infoType } from '../../../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'
import { createDropdownOption, translateEnum } from '../../../../../../assets/general/generalFunctions'

//other
import { formatExecutorResponse } from '../../../../../../assets/projects/projectsFunctions'

type createTimeTrackerModalProps = {
	timeTrackers: executor[]
	setTimeTrackers: (value: executor[]) => void
}

type formError = {
	name?: string
	position?: string
}

const CreateTimeTrackerModal : FC<createTimeTrackerModalProps> = ({timeTrackers, setTimeTrackers}) => {
	const { t } = useTranslation('', {keyPrefix: 'projects.task.timeTrackerTab.modals.createTimeTrackerModal'})  
	const tCurrency = useTranslation('', { keyPrefix: 'general.currency' }).t

	const [name, setName] = useState('')
	const [position, setPosition] = useState('')
	const [wage, setWage] = useState<number>(0)
	const [currencyOption, setCurrencyOption] = useState<dropdownOption>({
		key: currency.UAH,
		title: translateEnum(tCurrency, currency.UAH)
	})

	const { modalIsOpen } = useAppSelector(state => state.taskModals.createTimeTrackerModal)
	const [errors, setErrors] = useState<formError>({})
	
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const taskId = parseInt(useParams().taskId || '-1')
	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setCreateTimeTrackerModalIsOpen(false))
		setName('')
		setPosition('')
		setWage(0)
		setCurrencyOption({
			key: currency.UAH,
			title: translateEnum(tCurrency, currency.UAH)
		})
	}

	const checkErrors = () => {
		let hasErrors = false

		if(name === '') {
			hasErrors = true
			setErrors(prevErrors => ({...prevErrors, name: t('enterExecutorsNamePlease')}))
		}
		if(position === '') {
			hasErrors = true
			setErrors(prevErrors => ({...prevErrors, position: t('enterExecutorsPositionPlease')}))
		}
		return hasErrors
	}
	const createTimeTracker = async ()=> {
		setErrors({})
		if(checkErrors()) return
		
		setIsLoading(true)
		
		try {
			const { result } = await authorizedRequest(singleTaskTimeTracker(taskId), 'POST', 'accessToken', {
				name: name,
				position: position,
				wage: wage,
				currency: currencyOption.key
			})

			setTimeTrackers([formatExecutorResponse(result), ...timeTrackers])
			setIsLoading(false)
			closeModal()
			
		} catch(err) {
			console.log(err)
		} finally{
			setIsLoading(false)
		}
	}

	return (
		<Modal 
			title={t('createTimeTracker')}
			open={modalIsOpen}
			closeModal={closeModal} 
			submitButton={{text: t('create'), onClick: createTimeTracker}}
			isLoading={isLoading}
		>
			<div className="create-time-tracker">
				<InputField 
					type="text" 
					info={errors.name ? {
						type: infoType.error,
						text: errors.name
					} : undefined}
					label={t('executorsName')} 
					placeholder={t('executorsName')} 
					value={name} 
					onChange={(e)=> setName(e.target.value)}
				/>
				<InputField type="text" 
					info={errors.position ? {
						type: infoType.error,
						text: errors.position
					} : undefined}
					label={t('executorsPosition')} 
					placeholder={t('executorsPosition')} 
					value={position} 
					onChange={(e)=> setPosition(e.target.value)}
				/>
				<FloatInput 
					value={`${wage}`}
					amountInputHandle={(value) => setWage(parseFloat(value || '0'))}
				/>
				<Dropdown
					placeholder={t('currency')}
					defaultSelectedOption={currencyOption}
					dropdownOptions={createDropdownOption(tCurrency, currency)}
					onSelect={({ key, title }) => setCurrencyOption({ key, title })}
					selectedOption={currencyOption}
					clear={false}
				/>
			</div>
		</Modal>
	)
}

export default CreateTimeTrackerModal