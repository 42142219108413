// styles
import './templateList.scss'
import { close } from '../../../assets/general/generalIcons'

// react
import { FC, useEffect, useState } from 'react'

// components
import ColumnLayout from '../columnLayout/columnLayout'
import FileItem from '../fileItem/fileItem'

// types
import { file, template } from '../../../types/general/generalTypes'

// translation
import { useTranslation } from 'react-i18next'

type templateListProps = {
	templates: template[]
	handleDelete: (id: number) => void
	handleGenerate: (templateId: number, objectId: number) => void
	handleDownload?: (templateId: number, objectId: number) => void
	objectId: number
}
const TemplateList: FC<templateListProps> = ({ templates, handleDelete, handleGenerate, handleDownload, objectId }) => {
	const { t } = useTranslation('', {keyPrefix: 'general.templateList'})
	const [itemsPerRow, setItemsPerRow] = useState(window.innerWidth > 768 ? 2 : window.innerWidth > 425 ? 1 : 1)

	useEffect(() => {

		const handler = () => {
			setItemsPerRow(window.innerWidth > 768 ? 2 : window.innerWidth > 425 ? 1 : 1)
		}

		window.addEventListener('resize', handler)

		return () => {
			window.removeEventListener('resize', handler)
		}

	}, [])

	return (
		<div className="template-list">
			{templates.length === 0 ? <div className='template-list-empty'>{t('addTemplates')}</div> :
				<ColumnLayout amount={itemsPerRow}>
					{templates.map(template => (
						<div className='template-item-container'>
							<div className='file-item-button-delete' onClick={() => handleDelete(template.id)}>
								{close}
							</div>
							<FileItem
								onClick={() => {handleGenerate(template.id, objectId)}}
								handleDownload={handleDownload ? () => {handleDownload(template.id, objectId)}: undefined}
								file={template.preview}
							/>
						</div>
					))}

				</ColumnLayout>

			}
		</div>
	)
}

export default TemplateList