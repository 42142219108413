// styles and icons
import './navbar.scss'
import { logo } from '../../../../assets/icons/icons'

// react
import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

// components
import Button from '../../../../../../components/general/button/button'
import LanguageSelector from '../../../../../../components/general/languageSelector/languageSelector'
import NavbarLink from './navbarLink/navbarLink'
import NavbarBurger from './navbarBurger/navbarBurger'

//translation
import { useTranslation } from 'react-i18next'

//other
import { links } from './navbarData'

const Navbar = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.general.layout.navbar' })
	
	const [ mobile, setMobile ] = useState(screen.width <= 768)
	const [ activeDropdown, setActiveDropdown ] = useState<number | null>()

	const navigate = useNavigate()
	

	const handleResize = () => {
		setMobile(screen.width <= 768)
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<div className="landing-navbar">
			<Link to='/' className='navnar-logo-link'>
				<div className='navbar-logo'>
					{logo}
				</div>
			</Link>
			{mobile ?
				<NavbarBurger/>
				:
				<div className="navbar-actions">
					<ul className="navbar-links">
						<LanguageSelector/>
						{links.map((link, index) => {
							return (
								<NavbarLink 
									link={link.href} 
									text={t(link.title)} 
									dropdownElements={link.dropdownElements}
									open={activeDropdown === index}
									index={index}
									setOpen={(value) => {
										setActiveDropdown(value ? index : undefined)
									}}
								/>
							)
						})}
					</ul>
					<div className="navbar-buttons">
						<Button active={true} inverted={true} text={t('login')} onClick={()=> navigate('/login')}/>
						<Button active={false} inverted={true} outlined={true}  text={t('signUp')}  onClick={()=> navigate('/register')}/>
					</div>
				</div>
			}
			
		</div>
	)
}

export default Navbar