// styles and icons
import './createInventoryItemModal.scss'

//react
import { useState, useRef, FC, FormEvent } from 'react'

//components
import Modal from '../../../../../general/modals/modal/modal'
import InputField from '../../../../../general/inputField/inputField'
import TextArea from '../../../../../general/textArea/textArea'
import CheckBox from '../../../../../general/checkBox/checkBox'
import Dropdown from '../../../../../general/dropdown/dropdown'
import FloatInput from '../../../../../general/floatInput/floatInput'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../../customHooks/redux'
import { setCreateInventoryItemModalIsOpen } from '../../../../../../redux/warehouse/general/modals'

// network
import { authorizedRequest } from '../../../../../../utils/queries'
import { inventoryItemsUrl } from '../../../../../../utils/urls/warehouses/inventoryItems/inventoryItem'

// types
import {
	currency,
	dropdownOption, 
	infoPopupTypes,
	infoType,
	inventoryItem,
	inventoryItemResponse
} from '../../../../../../types/general/generalTypes'

//other
import { createDropdownOption, formatInventoryItemResponse, formatStringIntoTwoDigitsFloat } from '../../../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type formErrors = {
	name?: string
	price?: string
	priceWithVAT?: string
}

type createInventoryItemModalProps = {
	inventoryItems: inventoryItem[]
	setInventoryItems: (value: inventoryItem[]) => void
}

const CreateInventoryItemModal: FC<createInventoryItemModalProps> = ({ inventoryItems, setInventoryItems }) => {
	const { t } = useTranslation('', { keyPrefix: 'warehouse.general.modals.inventoryItemModals.createInventoryItemModal' })
	const tCurrency = useTranslation('', { keyPrefix: 'general.currency' }).t
	
	const dispatch = useAppDispatch()
	const { modalIsOpen } = useAppSelector((state) => state.warehouseGeneralModal.createInventoryItemModal)
	const { userCompanyData } = useAppSelector((state) => state.general)
	const [createOfferingRadio, setCreateOfferingRadio] = useState<boolean>(false)
	const [priceWithoutVAT, setPriceWithoutVAT] = useState<number>(0)
	const [priceWithVAT, setPriceWithVAT] = useState<number>(0)
	const [VAT, setVAT] = useState<number>(0)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [offeringCurrency, setOfferingCurrency] = useState<dropdownOption>({
		title: tCurrency(currency.UAH),
		key: currency.UAH
	})

	const companyId: number = userCompanyData?.companyId || -1

	const formRef = useRef<HTMLFormElement | null>(null)

	const [name, setName] = useState<string>('')
	const [description, setDescription] = useState<string>('')

	const [errors, setErrors] = useState<formErrors>({})

	const infoPopup = {
		content: [{
			title: 'create_inventory_item_title',
			description: 'create_inventory_item_description',
		}],
		type: infoPopupTypes.INFO
	}

	const closeModal = () => {
		dispatch(setCreateInventoryItemModalIsOpen(false))
		setName('')
		setDescription('')
		setPriceWithoutVAT(0)
		setPriceWithVAT(0)
		setVAT(0)
		setOfferingCurrency({title: currency.UAH, key: tCurrency(currency.UAH)})
		setCreateOfferingRadio(false)
	}

	const checkErrors = () => {
		let result = true
		
		if (createOfferingRadio) {
			if (priceWithVAT === undefined || priceWithVAT < 0) {
				setErrors(prevErrors => ({ ...prevErrors, priceWithVAT: t('pleaseEnterThePrice') }))
				result = false
			}
		}

		if (name === '') {
			setErrors(prevErrors => ({ ...prevErrors, name: t('pleaseEnterTheName') }))
			result = false
		}

		return result
	}
	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		setErrors({})
		setIsLoading(true)

		if (checkErrors()) {
			const response = await authorizedRequest(inventoryItemsUrl(companyId), 'POST', 'accessToken', [
				{
					name,
					description,
					create_offering: createOfferingRadio,
					offering_price: priceWithVAT,
					vat: VAT,
					offering_currency: offeringCurrency.key
				}
			])
			const result = response.result[0] as inventoryItemResponse

			const newInventoryItem = formatInventoryItemResponse(result)
			setInventoryItems([newInventoryItem, ...inventoryItems])

			setIsLoading(false)
			closeModal()
		}
	}


	const amountInputHandleWithoutVAT = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.00'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		
		setPriceWithoutVAT(numberValue)

		const newValue = parseFloat(
			(
				numberValue * (1 + VAT / 100)
			).toFixed(2)
		)

		setPriceWithVAT(newValue)
	}

	const amountInputHandleWithVAT = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.00'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		
		setPriceWithVAT(numberValue)

		const newValue = parseFloat(
			(
				numberValue / (1 + VAT / 100)
			).toFixed(2)
		)
		
		setPriceWithoutVAT(newValue)
	}


	const amountInputHandleVAT = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.00'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		setVAT(numberValue)
		setPriceWithoutVAT(
			parseFloat(
				(
					priceWithVAT / (1 + VAT / 100)
				).toFixed(2)
			)
		)
	}

	return (
		<Modal
			closeModal={closeModal}
			open={modalIsOpen}
			titlePopup={infoPopup}
			title={t('createNewInventoryItem')}
			submitButton={{
				text: t('create'),
				onClick: () => {
					if (formRef.current) {
						formRef.current.requestSubmit()
					}
				}
			}}
			isLoading={isLoading}
		>
			<form
				className='create-inventory-item-container'
				ref={formRef}
				onSubmit={handleSubmit}
			>
				<div className="form-elements-wrapper">
					<InputField
						type='text'
						label={t('name')}
						value={name}
						onChange={(e) => {
							setName(e.target.value)
						}}
						info={errors.name ? {
							type: infoType.error,
							text: errors.name
						} : undefined}
					/>
					<TextArea
						label={t('description')}
						value={description}
						setValue={val => setDescription(val)}
					/>
					<CheckBox
						isChecked={createOfferingRadio}
						onClick={() => setCreateOfferingRadio(!createOfferingRadio)}
						label={t('createOffering')}
					/>
					<div style={{ display: 'block' }} />
					{createOfferingRadio ? (
						<>
							<FloatInput
								label={t('priceWithoutVat')}
								amountInputHandle={amountInputHandleWithoutVAT}
								value={`${priceWithoutVAT}`}	
							/>
							<FloatInput
								label={t('priceWithVat')}
								info={errors.priceWithVAT ? {
									type: infoType.error,
									text: errors.priceWithVAT
								} : undefined}
								amountInputHandle={amountInputHandleWithVAT}
								value={`${priceWithVAT}`}	
							/>
							<FloatInput
								label={t('vat')}
								amountInputHandle={amountInputHandleVAT}
								value={`${VAT}%`}	
							/>
							<Dropdown
								label={t('currency')}
								placeholder={t('currency')}
								dropdownOptions={createDropdownOption(tCurrency, currency)}
								onSelect={(option) => {
									setOfferingCurrency(option)
								}}
								selectedOption={offeringCurrency}
							/>
						</>
					) : null}
				</div>
			</form>

		</Modal>
	)
}

export default CreateInventoryItemModal