//styles and icon
import { arrowIcon } from '../../../../assets/general/generalIcons'

//components
import Table from '../../table/table'
import Pagination from '../../pagination/pagination'
import TransactionTable from '../transactionTable/transactionTable'

//react
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

//types
import { columnTypes, extraColumnsResponse, tableBodyItem, tableBodyItemContent, tableHeader, tableTypes } from '../../../../types/general/generalTypes'
import { account } from '../../../../types/finance/accountTypes'

//network
import { authorizedRequest } from '../../../../utils/queries'
import { companyExtraColumnsUrl } from '../../../../utils/urls/general/company/company'
import { singleAccountTransactionsUrl, singleAccountUrl } from '../../../../utils/urls/finance/account/account'

//translationx
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector, useAppDispatch } from '../../../../customHooks/redux'
import { setDeleteAccountId, setDeleteAccountModalIsOpen } from '../../../../redux/general/modals'


//other
import { getTableViewData, formatExtraColumnResponse, updateCellValue, formatAccountResponse } from '../../../../assets/general/generalFunctions'

type accountTableProps = {
    preloadedAccount?: {
        accounts: account[]
        setAccounts: (value: account[]) => void
    }
    loadAccount?: {
        accountUrl: string
    }
}

const AccountTable: FC<accountTableProps> = ({ preloadedAccount, loadAccount }) => {

	const { t } = useTranslation('', { keyPrefix: 'finance.account.accountTable' })

	const [ columns, setColumns ] = useState<tableHeader[]>([])
	const [ data, setData ] = useState<Record<string, tableBodyItem>[]>([])


	const [ loading, setLoading ] = useState(false)
	const [ lastPage, setLastPage ] = useState(false)
	const [ page, setPage ] = useState(1) 

	const [ accounts, setAccounts ] = useState<account[]>([])

	const {userCompanyData} = useAppSelector(state => state.general)

	const dispatch = useAppDispatch()

	const companyId = userCompanyData?.companyId || -1
	
	const formatTableData = (account: account): Record<string, tableBodyItem> => {
		const editAccess = accounts.some((account) => account.editAccess)

		return {
			id: {
				content: {
					type: columnTypes.element,
					value: <Link to={`/ri-business/projcets/projcet/${account.id}`}>{account.id}</Link>
				},
				objectId: account.id
			},
			account_number: {
				content: {
					type: columnTypes.string,
					value: account.accountNumber
				},
				objectId: account.id
			},
			description: {
				content: {
					type: columnTypes.string,
					value: account.description
				},
				objectId: account.id
			},
			name: {
				content: {
					type: columnTypes.string,
					value: account.name
				},
				objectId: account.id
			},
			last_action_date: {
				content: {
					type: columnTypes.date,
					value: account.lastAction
				},
				objectId: account.id
			},
			bank:{
				content: {
					type: columnTypes.string,
					value: account.bank
				},
				objectId: account.id
			},
			transactions: {
				content: {
					type: columnTypes.element,
					value: <div className='nested-table-arrow'>{arrowIcon}</div>
				},
				objectId: account.id,
				extraTable: {
					open: false,
					content: <TransactionTable
						transactionUrl={singleAccountTransactionsUrl(Number(account.id))}
					/>
				}
			},
			
			
		}
	}
    
	const onCellValueChange = async (objectId: number, columnKey: string, newValue: tableBodyItemContent) => {
		updateCellValue(
			objectId,
			companyId,
			columnKey,
			newValue,
			singleAccountUrl,
			[
				{
					frontend: 'name',
					backend: 'name',
					type: columnTypes.string
				},
				{
					frontend: 'description',
					backend: 'description',
					type: columnTypes.string
				},
				{
					frontend: 'accountNumber',
					backend: 'account_number',
					type: columnTypes.string
				},
				{
					frontend: 'bank',
					backend: 'bank',
					type: columnTypes.string
				},
				{
					frontend: 'lastAction',
					backend: 'last_action_date',
					type: columnTypes.number
				}
			],
			accounts,
			setAccounts,
			preloadedAccount?.setAccounts,
			formatAccountResponse,
			tableTypes.account
		)
	}

	const getColumns = async () => {
		const {result}: {result: extraColumnsResponse[]}  = await authorizedRequest(companyExtraColumnsUrl(companyId) + '?table_type=account', 'GET')

		const editAccess = accounts.some((account) => account.editAccess)

		setColumns([
			{ key: 'id', title: 'id', type: columnTypes.number, editAccess: false },
			{ key: 'name', title: t('name'), type: columnTypes.string, editAccess },
			{ key: 'description', title: t('description'), type: columnTypes.string, editAccess },
			{ key: 'account_number', title: t('accountNumber'), type: columnTypes.string, editAccess: false },
			{ key: 'last_action_date', title: t('lastAction'), type: columnTypes.number, editAccess: false },
			{ key: 'bank', title: t('bank'), type: columnTypes.number, editAccess: false  },
			{ key: 'transactions', title: t('transactions'), type: columnTypes.number, editAccess: false  },
			...result.map(formatExtraColumnResponse), 
		])
	}

	useEffect(() => {
		getColumns()

		getTableViewData(
			data,
			tableTypes.account,
			accounts,
			formatTableData,
			companyId
		).then((newData) => {
			setData([...data, ...newData])
		})

	}, [accounts])


	const loadData = async (page: number) => {
		if(loadAccount){
			try {
				setLoading(true)
    
				const { result } = await authorizedRequest(loadAccount.accountUrl + `?page=${page}&per_page=${10}`, 'GET')
    
				if (result.length > 0) {
					const formatedData: account[] = result.map(formatAccountResponse)
					return formatedData
				}
    
				return []
    
			} finally {
				setLoading(false)
			}
		}
	}

	const handleLoadMore = () => {

		if (!loading && !lastPage) {
			loadData(page)
				.then((result) => {

					if(result){
						setAccounts([...accounts, ...result])
						if (result.length > 0) {
							setPage(page + 1)
						} else {
							setLastPage(true)
						}
					}
				})
		}
	}

	useEffect(() => {
		if(loadAccount){
			handleLoadMore()
		}
	}, [loadAccount])

	useEffect(() => {
		if(preloadedAccount){
			setAccounts(preloadedAccount.accounts)
		}
	}, [preloadedAccount])

	return (
		loadAccount ? 
			<Pagination onLoadMore={handleLoadMore} onlyLoadOn='bottom' loading={loading} showLoader={true}>
				<Table
					columns={columns}
					setColumns={setColumns}
					data={data}
					setData={setData}
					onCellValueChange={onCellValueChange}
					tableType={tableTypes.account}
					deleteAction={accounts.some((account) => account.deleteAccess) ? (accountId) => {
						dispatch(setDeleteAccountModalIsOpen(true))
						dispatch(setDeleteAccountId(accountId))
					} : undefined}
					addColumn={accounts.some((account) => account.editAccess)}
				/>
			</Pagination>
			:
			<Table
				columns={columns}
				setColumns={setColumns}
				data={data}
				setData={setData}
				onCellValueChange={onCellValueChange}
				tableType={tableTypes.account}
				deleteAction={accounts.some((account) => account.deleteAccess) ? (accountId) => {
					dispatch(setDeleteAccountModalIsOpen(true))
					dispatch(setDeleteAccountId(accountId))
				} : undefined}
				addColumn={accounts.some((account) => account.editAccess)}
			/>
	)
}

export default AccountTable