//react
import { FC } from 'react'

//components
import ColumnLayout from '../../../general/columnLayout/columnLayout'
import OrderCard from '../../../general/orderCard/orderCard'
import OrderItemSkeleton from '../../../general/skeletons/orderItem/orderItemSkeleton'

//types
import { order } from '../../../../types/general/generalTypes'

type salesOrderCardListProps = {
	orders: order[]
	loading: boolean
	itemsPerColumn: number
}
const SalesOrderCardList: FC<salesOrderCardListProps> = ({
	orders,
	loading,
	itemsPerColumn,
}) => {
	return (
		<ColumnLayout amount={itemsPerColumn}>
			{orders.map((order, index) => {
				return <OrderCard {...order} key={'order' + index} />
			})}
			{loading ? <OrderItemSkeleton /> : null}
		</ColumnLayout>
	)
}

export default SalesOrderCardList