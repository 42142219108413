//styles
import './editableCell.scss'

//components
import InputField from '../../inputField/inputField'
import FloatInput from '../../floatInput/floatInput'
import Dropdown from '../../dropdown/dropdown'
import CalendarContainer from '../../calendarContainer/calendarContainer'
import TextArea from '../../textArea/textArea'

//react
import { useState, FC, useRef, useEffect } from 'react'

//types
import { columnTypes, dropdown, dropdownOption, loadSelectionOptions, selectionOption, tableBodyItemContent } from '../../../../types/general/generalTypes'

//other
import { formatDate } from '../../../../assets/general/generalFunctions'

type EditableCellProps = {
    content: tableBodyItemContent
    dropdown?: dropdown
    loadSelectionOptions?: loadSelectionOptions
    onChange: (value: tableBodyItemContent) => void
    isEditable: boolean
    extraTable?: {
        open: boolean
        setOpen: (value: boolean) => void
    }
}

const EditableCell: FC<EditableCellProps> = ({ content, onChange, isEditable, dropdown, loadSelectionOptions, extraTable }) => {
	const [isEditing, setIsEditing] = useState(false)
	const [currentValue, setCurrentValue] = useState(content)
	const [selectedDropdownOption, setSelectedDropdownOption] = useState<dropdownOption>()

	const [selectionSearchValue, setSelectionSearchValue] = useState('')
	const [selectedOptions, setSelectedOptions] = useState<selectionOption[]>(currentValue?.type === columnTypes.selection ? currentValue.value : [])
	const [wasFocused, setWasFocused] = useState(false)

	const tdRef = useRef<HTMLTableCellElement>(null)

	const handleBlur = (newValue?: tableBodyItemContent | null) => {
		setIsEditing(false)		
		if(newValue && newValue.value !== content.value){
			onChange(newValue)
		}else if (currentValue && currentValue.value !== content.value) {
			onChange(currentValue)
		}
		setWasFocused(false)
	}

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			handleBlur()

			if(currentValue && currentValue !== content){
				onChange(currentValue)
			}
		}
	}

	const renderContent = () => {
		if(currentValue.value === null || currentValue.value === undefined){
			return ''
		}

		switch(currentValue.type){
		case columnTypes.string: 
			return currentValue.value
		case columnTypes.number:
			return `${currentValue.value}`
		case columnTypes.dropdown:
			return <Dropdown
				disabled={true}
				placeholder=''
				dropdownOptions={[]}
				selectedOption={currentValue.value}
			/>
		case columnTypes.date:
			return currentValue.value ? formatDate(currentValue.value, false, false) : ''
		case columnTypes.element:
			return currentValue.value
		case columnTypes.selection:
			return <InputField 
				value={''}
				onChange={(e) => {
					setSelectionSearchValue(e.target.value)
				}}
				disabled={true}
				selection={loadSelectionOptions && {
					loadSelectionOptions,
					setSelectedList: (options) => {
						setCurrentValue({
							type: columnTypes.selection,
							value: options
						})
						setSelectedOptions([...options])
					},
					selectedList: selectedOptions
				}} 
			/>
		}
	}

	const renderInput = () => {

		switch(currentValue.type){
		case columnTypes.string:
			return <TextArea
				onKeyPress={handleKeyPress}
				onBlur={() => {
					handleBlur()
					onChange(currentValue)
				}}
				onFocus={() => setWasFocused(true)}
				value={currentValue.value ? `${currentValue.value}` : ''}
				setValue={(e) => {
					setCurrentValue({
						type: columnTypes.string,
						value: e
					})
				}} 
			/>
		case columnTypes.number:
			return <FloatInput
				onKeyPress={handleKeyPress}
				onFocus={() => setWasFocused(true)}
				onBlur={(e) => {
					console.log('onBlur')
					handleBlur({
						type: columnTypes.number,
						value: Number(e.target.value)
					})
				}}
				value={currentValue.value ? `${currentValue.value}` : ''}
				amountInputHandle={(value) => {
					console.log(value)
					console.log('amountInputHandle')
					if(value.length > 0){
						setCurrentValue({
							type: columnTypes.number,
							value: Number(value)
						})
					}
				}} 
			/>
		case columnTypes.dropdown:
			return dropdown ? 
				<Dropdown
					{...dropdown}

					onSelect={(option) => {
						setCurrentValue({
							type: columnTypes.dropdown,
							value: option
						})
						handleBlur()
						onChange({
							type: columnTypes.dropdown,
							value: option
						})

						setSelectedDropdownOption(option)
					}}
					selectedOption={selectedDropdownOption || dropdown.dropdownOptions.find((option) => option.key === currentValue?.value?.key) || {title: currentValue?.value  ? `${currentValue.value?.title}` : '', key: currentValue?.value  ? `${currentValue?.value.key}` : ''}}
				/>
				: null
		case columnTypes.date:
			return <CalendarContainer
				startDate={{
					date: currentValue.value,
					setDate: (date) => {
						if(date && date !== currentValue?.value){
							setCurrentValue({
								type: columnTypes.date,
								value: date
							})
							handleBlur()
							onChange({
								type: columnTypes.date,
								value: date
							})
						}
					}
				}}
				size={'medium'}
			/>
		case columnTypes.selection:
			return <InputField 
				value={selectionSearchValue}
				onFocus={() => setWasFocused(true)}
				onKeyPress={(e: React.KeyboardEvent) => {
					if (e.key === 'Enter') {
						handleBlur()
						onChange({
							type: columnTypes.selection,
							value: selectedOptions
						})
					}
				}}
				onChange={(e) => {
					setSelectionSearchValue(e.target.value)
				}}
				selection={loadSelectionOptions && {
					loadSelectionOptions,
					setSelectedList: (options) => {
						onChange({
							type: columnTypes.selection,
							value: options
						})
						setCurrentValue({
							type: columnTypes.selection,
							value: options
						})
						setSelectedOptions([...options])
					},
					selectedList: selectedOptions
				}} 
			/>
		default:
			return <InputField
				type='text'
				onFocus={() => setWasFocused(true)}
				onKeyPress={handleKeyPress}
				onBlur={() => {
					handleBlur()
					onChange(currentValue)
				}}
				value={currentValue.value  ? `${currentValue.value}` : ''}
				onChange={(e) => {
					setCurrentValue({
						type: columnTypes.string,
						value: e.target.value
					})
				}} 
			/>
		}
	}

	useEffect(() => {
		if (isEditing && tdRef.current) {
			if(content.type === columnTypes.date || content.type === columnTypes.dropdown){
				setWasFocused(true)
			}

			const allInputs = tdRef.current.querySelectorAll('input, textarea')
			let inputElement: HTMLInputElement | undefined

			// Filter out any input that is inside .calendar-input-field-box
			allInputs.forEach(input => {
				if (!input.closest('.calendar-input-field-box') && !input.closest('.dropdown')) {
					inputElement = input as HTMLInputElement
				}
			})

			const dropdownElement = tdRef.current.querySelector('.dropdown') as HTMLDivElement
			const calendarElement = tdRef.current.querySelector('.calendar-input-field-box') as HTMLDivElement
			if (inputElement) {
				inputElement.focus()
				const length = inputElement.value.length
				inputElement.setSelectionRange(length, length)
			}else if(dropdownElement){
				console.log(dropdownElement)

				dropdownElement.click()
			}else if(calendarElement){
				calendarElement.click()
			}
		}
	}, [isEditing])
    
	const handleClickOutside = (event: MouseEvent) => {
		if ((wasFocused || (content.type === columnTypes.date || content.type === columnTypes.dropdown)) && tdRef.current && !tdRef.current.contains(event.target as Node)) {
			handleBlur()
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
    
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	useEffect(() => {
		if(content.type === columnTypes.element || !isEditable){
			setCurrentValue(content)
		}
	}, [content])


	return (
		<td
			className={`editable-cell ${isEditing ? 'active' : ''}`}
			ref={tdRef}
			onClick={(e) => {
				if(isEditable){
					setIsEditing(true)
				}

				if(extraTable){
					extraTable.setOpen(!extraTable.open)
				}
			}}
			style={{ cursor: isEditable ? 'pointer' : 'default' }}
		>
			{
				isEditing && isEditable ? (
					renderInput()
				) : (
					renderContent()
				)
			}
		</td>
	)
}

export default EditableCell
