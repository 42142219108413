//styles
import './projectReport.scss'

//react
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

//components
import Table from '../../../general/table/table'
import ExportProjectReportModal from './modals/exportProjectReportModal/exportProjectReportModal'

//redux
import { useAppDispatch } from '../../../../customHooks/redux'
import { setExportProjectReportModalIsOpen } from '../../../../redux/projects/project/modals'

//network
import { authorizedRequest } from '../../../../utils/queries'
import { singleProjectReportUrl } from '../../../../utils/urls/projects/project'

//translation
import { useTranslation } from 'react-i18next'

//types
import { columnTypes, currency, filterBar, moneyValue, tableBodyItem } from '../../../../types/general/generalTypes'
import { currencyToFormattedString } from '../../../../assets/general/generalFunctions'

type reportRow = {
    name: string
    description: string
    price: number | string
	cost: number | string
    quantity: number | string
    total_price: number | string
	total_cost: number | string
	total_profit: number | string
    vat: number | string
    currency: currency | string

}

type projectReportProps = {
	setFilterBar: (value: filterBar | undefined) => void
}

const ProjectReport: FC<projectReportProps> = ({ setFilterBar }) => {
	const { t } = useTranslation('', { keyPrefix: 'projects.project.projectReport' })
	const tCurrency = useTranslation('', { keyPrefix: 'general.currency' }).t

	const projectId = parseInt(useParams().projectId || '-1')

	const dispatch = useAppDispatch()
	
	const [reportRows, setReportRows] = useState<reportRow[]>([])

	const [incomeTotal, setIncomeTotal] = useState<moneyValue[]>([])
	const [expenseTotal, setExpenseTotal] = useState<moneyValue[]>([])	
	const [overallTotal, setOverallTotal] = useState<moneyValue[]>([])

	useEffect(() => {

		setFilterBar({
			exportButton: () => {
				dispatch(setExportProjectReportModalIsOpen(true))
			}
		})
        
		authorizedRequest(singleProjectReportUrl(projectId), 'GET')
			.then((response) => {
				const { result } = response

				const income = [
					{
						'currency': currency.UAH,
						'amount': 0
					},
					{
						'currency': currency.USD,
						'amount': 0
					},
					{
						'currency': currency.EUR,
						'amount': 0
					}
				]

				const expense = [
					{
						'currency': currency.UAH,
						'amount': 0
					},
					{
						'currency': currency.USD,
						'amount': 0
					},
					{
						'currency': currency.EUR,
						'amount': 0
					}
				]


				setReportRows(result.map((reportRowItem: reportRow) => {
					if(reportRowItem.currency === 'UAH'){
						income[0].amount += Number(reportRowItem.price) * (parseInt(`${reportRowItem.quantity}`) || 1)
						expense[0].amount += Number(reportRowItem.cost) * (parseInt(`${reportRowItem.quantity}`) || 1)
					}else if(reportRowItem.currency === 'USD'){
						income[1].amount += Number(reportRowItem.price) * (parseInt(`${reportRowItem.quantity}`) || 1)
						expense[1].amount += Number(reportRowItem.cost) * (parseInt(`${reportRowItem.quantity}`) || 1)
					}else{
						income[2].amount += Number(reportRowItem.price) * (parseInt(`${reportRowItem.quantity}`) || 1)
						expense[2].amount += Number(reportRowItem.cost) * (parseInt(`${reportRowItem.quantity}`) || 1)
					}

					return reportRowItem
				}))

				console.log(income)
				console.log(expense)

				income.forEach((incomeItem) => {
					if(incomeItem.amount !== 0){
						setIncomeTotal([...incomeTotal, incomeItem])
					}
				})

				expense.forEach((expenseItem) => {
					if(expenseItem.amount !== 0){
						setExpenseTotal([...expenseTotal, expenseItem])
					}
				})

				income.forEach((incomeItem, index) => {
					if(expense[index].amount + incomeItem.amount !== 0){
						setOverallTotal([...overallTotal, {
							amount: expense[index].amount + incomeItem.amount,
							currency: incomeItem.currency
						}])
					}
				})

			})

		return () => {
			setFilterBar(undefined)
		}
	}, [])

	return (
		<div className="project-report">
			<div className="table-container">
				<Table
					columns={
						[
							{ key: 'name', title: t('name') },
							{ key: 'description', title: t('description') },
							{ key: 'price', title: t('price') },
							{ key: 'cost', title: t('cost') },
							{ key: 'quantity', title: t('quantity') },
							{ key: 'total_price', title: t('totalPrice') },
							{ key: 'total_cost', title: t('totalCost') },
							{ key: 'total_profit', title: t('totalProfit') },
							{ key: 'currency', title: t('currency') },
							{ key: 'vat', title: t('vat') }
						]
					}
					data={
						reportRows.map((reportRowItem) => ({
							name: {
								content: {
									type: columnTypes.string,
									value: reportRowItem.name
								}
							},
							description: {
								content: {
									type: columnTypes.string,
									value: reportRowItem.description
								}
							},
							price: {
								content: {
									type: columnTypes.number,
									value: Number(reportRowItem.price)
								}
							},
							cost: {
								content: {
									type: columnTypes.number,
									value: Number(reportRowItem.cost)
								}
							},
							quantity: {
								content: {
									type: columnTypes.number,
									value: Number(reportRowItem.quantity)
								}
							},
							total_price: {
								content: {
									type: columnTypes.number,
									value: Number(reportRowItem.total_price)
								}
							},
							total_cost: {
								content: {
									type: columnTypes.number,
									value: Number(reportRowItem.total_cost)
								}
							},
							total_profit: {
								content: {
									type: columnTypes.number,
									value: Number(reportRowItem.total_profit)
								}
							},
							currency: {
								content: {
									type: columnTypes.dropdown,
									value: {
										title: tCurrency(reportRowItem.currency),
										key: reportRowItem.currency
									}
								}
							},
							vat: {
								content: {
									type: columnTypes.number,
									value: Number(reportRowItem.vat)
								}
							}
						})) as Record<string, tableBodyItem>[]
					}
					showHeader={true}
				/>
			</div>
			{
				incomeTotal.length > 0 || expenseTotal.length > 0 || overallTotal.length > 0 ?
					<div className="report-total">
						{
							incomeTotal.length > 0 &&
							<div className="report-total-item">
								<span>{t('totalIncome')}</span>
								<div className="report-total-item-value">
									{incomeTotal.map((item, idx) =>
										<span key={`total-${idx + 1}`}>{currencyToFormattedString(item.amount, item.currency)}</span>
									)}
								</div>
							</div>
						}

						{
							expenseTotal.length > 0 &&
							<div className="report-total-item">
								<span>{t('totalExpenses')}</span>
								<div className="report-total-item-value">
									{expenseTotal.map((item, idx) =>
										<span key={`total-${idx + 1}`}>{currencyToFormattedString(item.amount, item.currency)}</span>
									)}
								</div>
							</div>
						}

						{
							overallTotal.length > 0 &&
							<div className="report-total-item">
								<span>{t('total')}</span>
								<div className="report-total-item-value">
									{overallTotal.map((item, idx) =>
										<span key={`total-${idx + 1}`}>{currencyToFormattedString(item.amount, item.currency)}</span>
									)}
								</div>
							</div>
						}            
					</div>
					: null
			}
			<ExportProjectReportModal/>
		</div>

	)

}

export default ProjectReport