// react
import { FC, useState, useEffect } from 'react'

//types
import { file } from '../../../../../types/general/generalTypes'

type imageViewerProps = {
  file: file
}

const ImageViewer: FC<imageViewerProps> = ({ file }) => {
	const [imageSrc, setImageSrc] = useState<string>('')

	useEffect(() => {
		if (file) {
			setImageSrc(URL.createObjectURL(file.file))
		}
	}, [file])

	return <img src={imageSrc} alt={file.fileName} />
}

export default ImageViewer
