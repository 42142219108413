//react
import { FC, useState } from 'react'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import {hierarchyFireEmployeeUrl, hierarchyEmployeesUrl} from '../../../../../utils/urls/employees/hierarchy'

//components
import ConfirmationModal from '../../../../general/modals/confirmationModal/confirmationModal'

// redux
import { useAppSelector, useAppDispatch } from '../../../../../customHooks/redux'
import { setFireEmployeeModalIsOpen, setFireEmployee } from '../../../../../redux/employees/general/modals'
import { setHierarchy } from '../../../../../redux/employees/hierarchy/general'

//types
import { switchButton } from '../../../../../types/general/generalTypes'
import { employee } from '../../../../../types/employees/hierarchyTypes'

//translation
import { useTranslation } from 'react-i18next'

//other 
import { getEmployeeHierarchyData } from '../../../../../assets/employees/hierarchy/hierarchyFunctions'

type fireEmployeeProps = {
	getEmployeeActions: (employee: employee) => switchButton[]
}

const FireEmployee: FC<fireEmployeeProps> = ({ getEmployeeActions }) => {
	const { t } = useTranslation('', { keyPrefix: 'employees.general.modals.fireEmployee' })

	const { fireEmployee } = useAppSelector((state) => state.employeesGeneralModal)
	const { hierarchy } = useAppSelector((state) => state.hierarchyGeneral)

	const [loading, setLoading] = useState<boolean>(false)

	const dispatch = useAppDispatch()

	const closeModal = (): void => {
		dispatch(setFireEmployeeModalIsOpen(false))
		dispatch(setFireEmployee(undefined))
	}

	// fire employee function
	const fireWorker = async (): Promise<void> => {
		setLoading(true)
		await authorizedRequest(hierarchyFireEmployeeUrl, 'DELETE', 'accessToken', {
			employee_id: fireEmployee.employee?.id
		})

		let hierarchyIndex = 0

		// updating hierarchy
		hierarchy.forEach((column, index) => {

			const employee = column.employees.find((employee) => employee.id === fireEmployee.employee?.id)

			if(employee){
				hierarchyIndex = index
			}
		})

		if(hierarchyIndex === 0){
			return
		}

		const activeOnPrevLevel: employee | undefined = hierarchy[hierarchyIndex-1].employees.find((employee) => {
			return employee.active
		})
		

		if(activeOnPrevLevel){
			const updatedColumnEmployees = await getEmployeeHierarchyData(hierarchyEmployeesUrl(activeOnPrevLevel.id), getEmployeeActions)

			const updatedHierarchy = [...hierarchy.slice(0, hierarchyIndex+1).map((column, index) => {
				return {
					...column,
					employees: index === hierarchyIndex ? updatedColumnEmployees.map((employee) => {
						return {
							...employee,
							active: activeOnPrevLevel.id === employee.id || employee.active
						}
					}) : column.employees
				}
			})]

			dispatch(setHierarchy(updatedHierarchy))

		}

		setLoading(false)
		closeModal()
	}

	return (
		<ConfirmationModal
			title={t('fireThisEmployee')} 
			buttons={[{ text: t('yes'), onClickHandler: fireWorker }, { text: t('no'), onClickHandler: closeModal }]} 
			isOpen={fireEmployee.modalIsOpen} 
			closeModal={closeModal}
			loading={loading}
		/>
	)
}

export default FireEmployee