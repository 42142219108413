//styles
import './locationSearchInput.scss'

//components
import InputField from '../inputField/inputField'

//react
import { FC, useEffect } from 'react'

//other
import usePlacesAutoomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'

//types
import { infoType, locationSearchInput } from '../../../types/general/generalTypes'

//translaction
import { useTranslation } from 'react-i18next'

type locationSearchInputProps = locationSearchInput

const LocationSearchInput: FC<locationSearchInputProps> = ({ setLocation, setLoading, setAddress, disabled, error }) => {
	const { t } = useTranslation('', {keyPrefix:'general.locationSearchInput'})

	const {
		ready,
		value,
		setValue,
		suggestions: { status, data },
		clearSuggestions
	} = usePlacesAutoomplete()

	const onSelect = async (value: string) => {
		setValue(value, false)
		clearSuggestions()

		const results = await getGeocode({ address: value })
		const { lat, lng } = await getLatLng(results[0])

		setLocation({ lat, lng })
		setAddress(value)
	}


	useEffect(() => {
		if (!window.google) {
			console.error(' Google Maps Api not loaded')

			return
		}
		
		setLoading && setLoading(true)
		new Promise((resolve) => {
			navigator.geolocation.getCurrentPosition((position) =>{
				const lat = position.coords.latitude
				const lng = position.coords.longitude
	
				getGeocode({ location: { lat, lng } })
					.then((result) => {
						onSelect(result[0].formatted_address)
						resolve(setLoading && setLoading(false))
					}).catch((error) => {
						console.error('getGeocode error : ' + new Error(error))
						setLoading && setLoading(false)
					})
	
			}, (error) => {
				console.error('getCurrentPosition error :' + new Error(error.message))
				setLoading && setLoading(false)
			})
		})
		
	}, [])

	return (
		<div className="location-search-input">
			<InputField
				type='text'
				value={value}
				onChange={(e) => { setValue(e.target.value) }}
				disabled={!ready || disabled}
				info={error ? {
					type: infoType.error,
					text: error
				} : undefined}
			/>
			<div className={`location-search-input-options ${data.length > 0 ? 'location-search-input-options-visible' : ''}`}>
				<div className='location-search-input-options-container'>
					{
						data.map((item) => {
							return (
								<div key={`location-search-input-${item.place_id}`} className="location-search-input-options-container-item" onClick={() => {
									onSelect(item.description)
								}}>
									<p>{item.description}</p>
								</div>
							)
						})
					}
				</div>
			</div>
		</div>
        
	)
}

export default LocationSearchInput