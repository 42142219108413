import { baseUrl } from '../../../network'

export const manageCompanySettingsUrl = (companyId: number)=> baseUrl + `/api/company/${companyId}`
export const getCompanyTasksUrl = (companyId:number)=> baseUrl + `/api/company/${companyId}/tasks`
export const getCompanyTaskCalendarUrl = (companyId:number)=> baseUrl + `/api/company/${companyId}/tasks/calendar`

export const companyExtraColumnsUrl = (companyId: number) => baseUrl + `/api/company/${companyId}/extra-columns`
export const companyExtraColumnDataUrl = (companyId: number) => baseUrl + `/api/company/${companyId}/extra-column-data`

export const singleExtraColumnsUrl = (extraColumnId: number) => baseUrl + `/api/extra-column/${extraColumnId}`
