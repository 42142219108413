// react
import { FC } from 'react'

// component
import FileTableParserModal from '../../fileTableParserModal/fileTableParserModal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setBillTablePreviewModalIsOpen, setImportBillFileSelectorModalFile, setExportBillFileModalIsOpen } from '../../../../../redux/general/modals'

//types
import { bill, billResponse, billStatus, expenseCategory } from '../../../../../types/finance/general'
import { currency } from '../../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { createBillUrl } from '../../../../../utils/old_urls/general/generalUrls'

//other
import { formatDate } from '../../../../../assets/general/generalFunctions'
import { formatBillResponse } from '../../../../../assets/finance/general/generalFunctions'

//translaction
import { useTranslation } from 'react-i18next'

type billTablePreviewModalProps = {
	bills: bill[]
	setBills: (value: bill[]) => void
}

const BillTablePreviewModal : FC<billTablePreviewModalProps> = ({bills, setBills}) => {
	const { t } = useTranslation('', {keyPrefix:'general.modals.bill.billTablePreviewModal'})

	const { file } = useAppSelector((state) => state.generalModals.importBillFileSelectorModal)
	const { modalIsOpen } = useAppSelector((state) => state.generalModals.billTablePreviewModal)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const dispatch = useAppDispatch()

	const closeModal = ()=>{
		dispatch(setExportBillFileModalIsOpen(false))
		dispatch(setBillTablePreviewModalIsOpen(false))
		dispatch(setImportBillFileSelectorModalFile(null))
	}

	return (
		<FileTableParserModal
			file={file}	
			open={modalIsOpen}
			closeModal={closeModal}	
			onTableProcessed={(tableData) => {
				authorizedRequest(createBillUrl(companyId), 'POST', 'accessToken', {
					data: tableData.data.map(( item ) => {
						const startDateArray = (item.start_date.content.value as string).split('.')
						const endDateArray = (item.end_date.content.value as string).split('.')
	
						const indexOfCategory = Object.values(expenseCategory).indexOf(item.category as unknown as expenseCategory)
						const category = Object.keys(expenseCategory)[indexOfCategory]
	
						return {
							amount: parseInt(item.amount.content.value as string),
							category: category,
							currency: item.currency,
							description: item.description,
							date: startDateArray[2] + '-' + startDateArray[1] + '-' + startDateArray[0],
							due_date: endDateArray[2] + '-' + endDateArray[1] + '-' + endDateArray[0],
							status: item.status,
							title: item.title
						}
					})
				}).then((response) => {
					const uploadedBills = response.result.map(formatBillResponse)
					setBills([...uploadedBills, ...bills])
					closeModal()
				})
			}}
			requiredColumns={[
				{
					title: t('title'),
					key: 'title',
					default: 'Imported bill',
					type: String
				},
				{
					title: t('description'),
					key: 'description',
					default: '',
					type: String
				},
				{
					title: t('total'),
					key: 'amount',
					default: '0',
					type: Number
				},
				{
					title: t('date'),
					key: 'start_date',
					default: formatDate(new Date(), false, true),
					type: Date
				},
				{
					title: t('date'),
					key: 'end_date',
					default: formatDate(new Date(), false, true),
					type: Date

				},
				{
					title: t('currency'),
					key: 'currency',
					default: currency.UAH,
					type: currency
				},
				{
					title: t('billCategory'),
					key: 'category',
					default: expenseCategory.miscellaneous_expenses,
					type: expenseCategory

				},
				{
					title: t('status'),
					key: 'status',
					default: billStatus.pending,
					type: billStatus
				}
			]}
		/>
	)
}

export default BillTablePreviewModal