// style
import './timeTrackers.scss'

// react
import  { useEffect, useState } from 'react'

// components
import TimeTrackerItem from '../../../components/general/timeTrackerItem/timeTrackerItem'
import Loading from '../../../components/general/loading/loading'

//network
import { authorizedRequest } from '../../../utils/queries'
import { timeTrackersUrl } from '../../../utils/urls/general/profile/profile'
import { refreshUrl, updateStatusUrl } from '../../../utils/old_urls/generalUrls'

//others
import { formatExecutorResponse } from '../../../assets/projects/projectsFunctions'

//types
import { executor } from '../../../types/general/generalTypes'

const TimeTrackers = () => {
	const [timeTrackers, setTimeTrackers] = useState<executor[]>([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {

		const interval =  setInterval(() => {
			const refreshToken = localStorage.getItem('refreshToken')
			const accessToken = localStorage.getItem('accessToken')

			if (accessToken) {
				authorizedRequest(updateStatusUrl, 'PUT')
			}
            
			if (refreshToken) {
				authorizedRequest(refreshUrl, 'GET', 'refreshToken').then((data) => {
					localStorage.setItem('accessToken', data.result.access_token)
				})
			}
		}, 5000)
        
		return () => {
			clearInterval(interval)
		}
	}, [])

	useEffect(() => {
		setLoading(true)

		const interval = setInterval(() => {
			authorizedRequest(timeTrackersUrl, 'GET').then((response) => {
				const { result } = response
				setTimeTrackers([...result.map(formatExecutorResponse)])
				setLoading(false)
			})
		}, 3000)
        
		return () => {
			clearInterval(interval)
		}
	}, [])

	return (
		<div className="time-trackers">
			{loading && <Loading />}
			{timeTrackers.map((timeTracker) => {
				return (
					<TimeTrackerItem
						taskTitle={timeTracker.taskTitle}
						executorId={timeTracker.id}
						timerStartDate={timeTracker.timerStartDate}
						wage={timeTracker.wage}
						hoursSpent={timeTracker.hoursSpent}
						relevantCurrency={timeTracker.currency}
						editAccess={timeTracker.editAccess}
						deleteAccess={timeTracker.deleteAccess}
						isProductivityTrackingActive={timeTracker.isProductivityTrackerActive}
						timeUsage={timeTracker.timeUsage}
					/>
				)
			})}
		</div>
	)
}

export default TimeTrackers