//styles and icons
import './infoPopup.scss'
import { infoIcon, notification } from '../../../assets/general/generalIcons'

//react
import React, { FC, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

//custom hooks
import { useHover } from '../../../customHooks/useHover'

//types
import { infoPopup, infoPopupTypes } from '../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

type infoPopupProps = infoPopup

const InfoPopup: FC<infoPopupProps> = ({ content, type, isInsideReactFlow }) => {

	const { t } = useTranslation('', { keyPrefix: 'general.infoPopup' })

	const [hovered, eventHandlers] = useHover()

	const iconRef = useRef<HTMLDivElement>(null)
	const textRef = useRef<HTMLDivElement | null>(null)

	const choseIcon = () => {
		switch (type) {
		case infoPopupTypes.RECCOMENDATION:
			return infoIcon
		case infoPopupTypes.INFO:
			return infoIcon
		case infoPopupTypes.ERROR:
			return notification
		default:
			return notification
		}
	}

	//positioning of the tooltip

	const [screenWidth, setScreenWidth] = useState(window.innerWidth)
	const [topPosition, setTopPosition] = useState(0)
	const [leftPosition, setLeftPosition] = useState(0)

	const componentRef = useRef<HTMLDivElement>(null)
	const [absoluteParentContainer, setAbsoluteParentContainer] = useState<HTMLElement | null>(null)

	const sizeInViewWidth = (px: number | undefined): number => {
		if (px === undefined) return 0
		return px / screenWidth * 100
	}

	useEffect(() => {
		const checkParentPosition = (node: HTMLElement | null) => {
			if (!node || node === document.documentElement) {
				setAbsoluteParentContainer(null)
				return
			}
	
			const { position } = window.getComputedStyle(node)

			if (position === 'absolute' || position === 'fixed' || node.className == 'modal-container') {
				setAbsoluteParentContainer(node)
				return
			}
	
			checkParentPosition(node.parentElement)
		}
	
		if (componentRef.current) {
			checkParentPosition(componentRef.current)
		}
	
		return () => {
			setAbsoluteParentContainer(null)
		}
	}, [])
	

	useEffect(() => {
		if (hovered && iconRef.current && textRef.current) {
			if(!absoluteParentContainer){
				if (isInsideReactFlow) {
					textRef.current.style.position = 'absolute'
					textRef.current.style.top = '30px'
					textRef.current.style.left = '30px'
					return
				}
				textRef.current.style.position = 'fixed'
	
				const { x, y } = iconRef.current.getBoundingClientRect()
				// console.log(x, y)
				setScreenWidth(window.innerWidth)
				setTopPosition(sizeInViewWidth(y - textRef.current.clientHeight >= 0 ? y - textRef.current.clientHeight : y + iconRef.current.clientHeight))
				setLeftPosition(sizeInViewWidth(x > document.documentElement.clientWidth / 2 ? x - textRef.current.clientWidth : x))
			}else{
				textRef.current.style.position = 'fixed'
	
				const iconBoundries = iconRef.current.getBoundingClientRect()
				const absoluteParentBoundries = absoluteParentContainer.getBoundingClientRect()

				const x = iconBoundries.x - absoluteParentBoundries.x
				const y = iconBoundries.y - absoluteParentBoundries.y
				// console.warn(x, y, iconBoundries, absoluteParentBoundries)

				setScreenWidth(window.innerWidth)
				setTopPosition(sizeInViewWidth(y - textRef.current.clientHeight >= 0 ? y - textRef.current.clientHeight : y + iconRef.current.clientHeight))
				setLeftPosition(sizeInViewWidth(x > document.documentElement.clientWidth / 2 ? x - textRef.current.clientWidth : x))
			}
		}
	}, [hovered])

	return (
		<div className="info-popup-container" {...eventHandlers as object} onClick={(e) => e.stopPropagation()} ref={componentRef}>
			<div className="info-popup-icon-container" ref={iconRef}>
				<div className="info-popup-icon">{choseIcon()}</div>
			</div>
			<div className={`info-popup-text-container ${hovered ? 'info-popup-visible' : 'info-popup-hidden'}`}
				style={{ top: `${topPosition}vw`, left: `${leftPosition}vw`, fontWeight: '1.3vw' }}
				ref={textRef}
			>
				{
					content.map((contentItem) => {
						// console.log(contentItem.description)

						return (
							<div className={`info-popup-text ${isInsideReactFlow ? 'flow' : ''}`}>
								<p className='info-popup-title'>
									{t(contentItem.title)}
								</p>
								<p className='info-popup-description'>
									{t(contentItem.description)}
								</p>
								{
									contentItem.link ?
										<Link to={`/help${contentItem.link}`}>{t('details')}</Link>
										: null
								}
							</div>
						)
					})
				}

			</div>
		</div>
	)
}

export default React.memo(InfoPopup)
