// styles
import './appFeature.scss'
import { noCreditCardRequiredIcon } from '../../../assets/icons/icons'

// react
import { FC, useRef } from 'react'
import { useNavigate } from 'react-router'

// components
import ScrollAnimation from '../scrollAnimation/scrollAnimation'
import Button from '../../../../../components/general/button/button'
import Title from '../title/title'
import Text from '../text/text'


type appFeatureProps = {
	id?: string
	smallText: string,
	heading: string,
	text: string,
	buttons: {
		text: string
		link: string
		variant: 'solid' | 'outlined'
	}[]
	underButtonText?: string
	contentRight?: boolean
	image: string
	extras?: React.ReactNode[]
	icon?: JSX.Element
}

const AppFeature: FC<appFeatureProps> = ({ id, smallText, heading, text, buttons, contentRight = false, image, extras, icon, underButtonText}) => {
	const ref = useRef(null)
	const navigate = useNavigate()
	return (
		<div className="app-feature-component-wrapper" id={id}>
			<div className={`app-feature-component ${contentRight ? 'app-feature-component-rtl' : 'app-feature-component-ltr'}`} ref={ref}>
					
				<div className='app-feature-component-content'>
					<ScrollAnimation direction={contentRight ? 'to-left' : 'to-right'}>
						<div className='app-feature-component-content-container'>
							<div className='app-feature-component-subtitle-container'>
								{icon}
								<span className='app-feature-component-first-subtitle'>
									{smallText.split(' ')[0]}
								</span> <span className='app-feature-component-rest-subtitle'>
									{smallText.split(' ').length > 1 ? smallText.slice(smallText.indexOf(' ') + 1) : null}
								</span>
							</div>
							<Title>{heading}</Title>
							<Text>{text}</Text>
							{/* <div className='app-feature-component-buttons'>
								{buttons.map((button, idx) => 
									<div key={`app-feature-component-button-${idx}`} className={`app-feature-component-button-${idx+1}`}>
										<Button
											text={idx === 1 
												? (
													<>
														<span>{button.text.split(' ')[0]}</span> {button.text.slice(button.text.indexOf(' ') + 1)}
													</>
												)
												: button.text}
											onClick={()=> navigate(button.link)}
											active={idx === 0 && true}
											outlined={button.variant === 'outlined'}
											arrow={idx === 0 && true}
										/>
										{
											idx == 0 && underButtonText != null ?
												<Text>{noCreditCardRequiredIcon}{underButtonText}</Text>
												: null
										}
									</div>
								)}
							</div> */}
						</div>
					</ScrollAnimation>
				</div>
					
				
				<div className='app-feature-component-img-container'>
					<ScrollAnimation direction={contentRight ? 'to-right' : 'to-left'}>
						<img src={image}/>
					</ScrollAnimation>
				</div>
			</div>
			{extras?.map((elem, index) => {
				return (
					<ScrollAnimation direction={index %2!=0 ? 'to-right' : 'to-left'}>
						{elem}
					</ScrollAnimation>
				)
			})}
		</div>
	)
}

export default AppFeature