//styles and icons
import './guideModal.scss'

//react
import { FC, useEffect, useState, useLayoutEffect, useRef, ReactNode } from 'react'
import { createPortal } from 'react-dom'

//components
import Button from '../../button/button'
import SkipGuidanceConfirmationModal from './skipGuidanceConfirmationModal/skipGuidanceConfirmationModal'

//translation
import { useTranslation } from 'react-i18next'

type guideModalProps = {
    closeModal: () => void
    open: boolean
    children: ReactNode
    isLoading?: boolean
}

type position = {
    left: number
    top: number
}

const GuideModal: FC<guideModalProps> = ({ closeModal, open, children, isLoading }) => {

	const { t } = useTranslation('', {keyPrefix: 'general.modals.guidanceModal'})

	const [skipGuidanceModalIsOpen, setSkipGuidanceModalIsOpen] = useState(false)
	const [parentClone, setParentClone] = useState<HTMLElement | null>(null)

	const contentRef = useRef<HTMLDivElement>(null)
	const modalRef = useRef<HTMLDivElement>(null)
	const observerRef = useRef<MutationObserver | null>(null)
	const animationFrameRef = useRef<number | null>(null)

	const copyClassAssignedStylesRecursive = (sourceNode: HTMLElement, targetNode: HTMLElement) => {
		// Function to copy styles that differ from defaults
		const copyStyles = (source: HTMLElement, target: HTMLElement) => {
			const dummy = document.createElement(source.tagName)
			document.body.appendChild(dummy)
	
			const sourceStyles = window.getComputedStyle(source)
			// console.log(source, sourceStyles)
			const defaultStyles = window.getComputedStyle(dummy)
	
			Array.from(sourceStyles).forEach((property) => {
				if (sourceStyles.getPropertyValue(property) !== defaultStyles.getPropertyValue(property)) {
					target.style.setProperty(property, sourceStyles.getPropertyValue(property))
				}
			})
	
			document.body.removeChild(dummy)
		}
	
		// Copy styles for the sourceNode and its children
		const copyRecursive = (sourceEl: HTMLElement, targetEl: HTMLElement) => {
			if(targetEl.classList.contains('guide-modal-container')) return
			forwardEvents(sourceEl, targetEl)
			// Copy the styles for the current element
			copyStyles(sourceEl, targetEl)
	
			// Recursively copy styles for all children
			const sourceChildren = Array.from(sourceEl.children)

			const targetChildren = Array.from(targetEl.children)
	
			sourceChildren.forEach((sourceChild, index) => {
				const targetChild = targetChildren[index]
				copyRecursive(sourceChild as HTMLElement, targetChild as HTMLElement)
			})
		}
	
		// Start copying recursively from the parent element
		copyRecursive(sourceNode, targetNode)
	}

	const forwardEvents = (sourceNode: HTMLElement, targetNode: HTMLElement) => {
		// Define which events to forward
		const eventsToForward = ['click', 'input', 'change', 'focus', 'blur', 'keydown', 'keyup']
	
		eventsToForward.forEach((eventType) => {
			targetNode.addEventListener(eventType, (event) => {
				let newEvent
	
				// Determine the type of event and create the appropriate event object
				if (event instanceof MouseEvent) {
					newEvent = new MouseEvent(event.type, event)
				} else if (event instanceof KeyboardEvent) {
					newEvent = new KeyboardEvent(event.type, event)
				} else if (event instanceof FocusEvent) {
					newEvent = new FocusEvent(event.type, event)
				} else {
					newEvent = new Event(event.type, event) // Generic event fallback
				}
	
				// Dispatch the event on the original element
				sourceNode.dispatchEvent(newEvent)
			})
		})
	}

	const updateCoordinates = () => {
		if(!open) return

		const contentDom = contentRef?.current
		if (!contentDom) return
    
		const modalDom = modalRef?.current
		if (!modalDom) return

		// get the parent real dom
		const parentDom = contentDom?.parentElement
		if (!parentDom) return

		// show the parent component front of the back-screen
            
		// get the parent position
		const rect = parentDom.getBoundingClientRect()
    
		const position: position = { left: rect.right, top: rect.bottom }
		console.log()
    
		// get the modal content width and height
		const contentWidth = modalDom.getBoundingClientRect().width
		const contentHeight = modalDom.getBoundingClientRect().height
    
		let modalLeft = position.left
		let modalTop  = position.top
    
		const paddingOffset = 20
    
		if (window.innerWidth < position.left + contentWidth) {
			modalLeft -= (position.left + contentWidth - window.innerWidth + paddingOffset)
		}
    
		if (window.innerHeight < position.top + contentHeight) {
			modalTop -= (position.top + contentHeight - window.innerHeight + paddingOffset)
		}
    
		modalDom.style.left = String(modalLeft) + 'px'
		modalDom.style.top = String(modalTop) + 'px'
	}
    
	const skipGuidanceConfirmation = () => {
		setSkipGuidanceModalIsOpen(true)
	}

	// close backdrop
	const deleteBackdrop = () => {
		const backdrop = document.getElementById('guide-modal-backdrop')
        
		if (backdrop) {
			document.body.removeChild(backdrop)
			backdrop.removeEventListener('mousedown', skipGuidanceConfirmation)
		}
	}

	// Create and append backdrop
	const createBackdrop = () => {
		const backdrop = document.createElement('div')
		backdrop.setAttribute('id', 'guide-modal-backdrop')

		backdrop.classList.add('user-guide-modal','user-guide-modal-dark-bg')

		// Add a mousedown event listener to the backdrop
		backdrop.addEventListener('mousedown', skipGuidanceConfirmation)

		document.body.appendChild(backdrop)
		return backdrop
	}

	const deleteCloneAndObserver = () => {
		if (parentClone) {
			parentClone.style.transition = 'all ease 0.3s'
			parentClone.style.opacity = '0'
			setTimeout(() => {
				parentClone.remove()
				setParentClone(null)
			}, 300)
		}
		if (observerRef.current) {
			observerRef.current.disconnect()
			observerRef.current = null
		}
	}


	const startTrackingPosition = (parentClone: HTMLElement) => {
		const track = () => {
			if(animationFrameRef.current === -1) return
			updateClonePosition(parentClone)
			updateCoordinates()
			animationFrameRef.current = requestAnimationFrame(track)
		}
		track()
	}


	const stopTrackingPosition = () => {
		if (animationFrameRef.current) {
			cancelAnimationFrame(animationFrameRef.current)
			animationFrameRef.current = -1
		}
	}


	useEffect(() => {

		if (open) {
			const contentDom = contentRef.current
			if(!contentDom) return 

			const parentDom = contentDom?.parentElement
			
			if (parentDom) {
				// Clone the parent DOM
				const clonedParent = parentDom.cloneNode(true) as HTMLElement
				setParentClone(clonedParent)
				updateClonePosition(clonedParent)
				clonedParent.classList.add('user-guide-modal','guide-background')
	
				// Copy computed styles (for inline styles)
				copyClassAssignedStylesRecursive(parentDom, clonedParent)
	
				document.body.appendChild(clonedParent)

				const modalParent = contentDom.closest('.modal-container')

				if(modalParent){
					modalParent.addEventListener('animationstart', () => startTrackingPosition(clonedParent))
					modalParent.addEventListener('animationend', stopTrackingPosition)
				}

			}

			createBackdrop()
		} else {
			deleteBackdrop()
			deleteCloneAndObserver()
		}
	}, [open])
    
	const updateClonePosition = (parentClone: HTMLElement) => {
		console.log('1')
		const contentDom = contentRef.current
		const parentDom = contentDom?.parentElement
		console.log(parentDom, parentClone)
		if (!parentDom || !parentClone) return
		const rect = parentDom.getBoundingClientRect()
		parentClone.style.left = `${rect.left}px`
		parentClone.style.top = `${rect.top}px`
	}


	useLayoutEffect(() => {
		updateCoordinates()
	})
    
	useEffect(() => {
		window.addEventListener('resize', updateCoordinates)

		// Cleanup the event listener on component unmount
		return () => {
			window.removeEventListener('resize', updateCoordinates)
		}
	}, [])

	return ( 
		<>
			{!isLoading  && parentClone ? createPortal(
				<div
					onMouseDown={(e) => e.stopPropagation()}
					ref={modalRef}
					className={`user-guide-modal guide-modal-container ${open ? 'open' : 'close'}`}
				>
					<div className="modal-content-container">
						{children}
					</div>
					<div className="modal-button">
						<Button
							active={true}
							text={t('skip')}
							onClick={() => {
								setSkipGuidanceModalIsOpen(true)
							}}
						/>
					</div>
				</div>
				, parentClone) : null}
			<div ref={contentRef}></div>
			{
				createPortal(

					<SkipGuidanceConfirmationModal
						isOpen={skipGuidanceModalIsOpen}
						setIsOpen={setSkipGuidanceModalIsOpen}
						closeModal={closeModal}
					/>
				, document.body)
			}
		</>
	)
}

export default GuideModal
