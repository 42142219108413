// react
import { FC, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// components
import Layout from '../../../components/general/layout/layout'
import TaskTracking from '../../../components/general/taskTracking/taskTracking'
import TaskDetails from '../../../components/projects/task/taskDetails/taskDetails'
import TaskDashboard from '../../../components/general/taskDashboard/taskDashboard'
import WarehouseAction from '../../../components/projects/task/warehouseAction/warehouseAction'
import TimeTracker from '../../../components/projects/task/timeTracker/timeTracker'
import ErrorList from '../../../components/general/errorList/errorList'

// types
import {
	button,
	taskResponse,
	error,
	infoPopupTypes,
	stageResponse,
	switchButton,
	filterBar
} from '../../../types/general/generalTypes'

//network 
import { authorizedRequest } from '../../../utils/queries'
import {
	singleTaskStages,
	singleTaskSubtasks,
	singleTaskSubtasksCalendar
} from '../../../utils/urls/projects/task'

//translation
import { useTranslation } from 'react-i18next'

//other
import { formatStageResponse, formatTaskResponse } from '../../../assets/projects/projectsFunctions'
import {calendar, dashboardIcon, listIcon} from '../../../assets/general/generalIcons'
import TaskList from '../../../components/projects/general/taskList/taskList'
import MyTasksCalendar from '../../../components/projects/myTasks/myTasksCalendar/myTasksCalendar'
import SwitchButtons from '../../../components/general/switchButtons/switchButtons'
import CheckBox from '../../../components/general/checkBox/checkBox'

const Task: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'projects.task' })
	const { t:tr } = useTranslation('', { keyPrefix: 'projects.myTasks' })

	const { taskId } = useParams()

	const [activeSection, setActiveSection] = useState('details')
	const [projectId, setProjectId] = useState<number>()
	const [projectName, setProjectName] = useState<string>('')
	const [title, setTitle] = useState<string>('')
	const [errors, setErrors] = useState<error[]>([])
	const [pipelineId, setPipelineId] = useState<number>()
	const [pipelineName, setPipelineName] = useState('')

	const [activeToolBarIconId, setActiveToolBarIcon] = useState(0)

	const toolBarButtons: switchButton[] = [
		{
			id: 0,
			icon: dashboardIcon
		},
		{
			id: 1,
			icon: listIcon
		},
		{
			id: 2,
			icon: calendar
		}
	]

	const [filterBar, setFilterBar] = useState<filterBar | undefined>()
	const [showClosed, setShowClosed] = useState(false)


	const titleUrls = [
		{
			url: '/ri-business/projects/pipelines',
			title: t('pipelines')
		},
		{
			url: `/ri-business/projects/pipeline/${pipelineId}`,
			title: pipelineName
		},
		{
			url: `/ri-business/projects/project/${projectId}`,
			title: projectName
		},
		{
			url: location.pathname,
			title: title
		}
	]

	const infoPopup = {
		content: [{
			title: 'task_info_title',
			description: 'task_info_description',
			link: '/ri-manual/projects/single-task'
		}],
		type: infoPopupTypes.INFO
	}

	const navigate = useNavigate()

	const orderDetailTabButton: button[] = [
		{
			active: 'details' === activeSection,
			text: t('details'),
			onClick: () => { setActiveSection('details') }
		},
		{
			active: 'dashboard' === activeSection,
			text: t('dashboard'),
			onClick: () => { setActiveSection('dashboard') }
		},
		{
			active: 'tracking' === activeSection,
			text: t('tracking'),
			onClick: () => { setActiveSection('tracking') }
		},
		{
			active: 'warehouseAction' === activeSection,
			text: t('warehouseAction'),
			onClick: () => { setActiveSection('warehouseAction') }
		},
		{
			active: 'timeTracker' === activeSection,
			text: t('timeTracker'),
			onClick: () => { setActiveSection('timeTracker') }
		},
		{
			active: 'project' === activeSection,
			text: t('project'),
			onClick: () => { navigate(`/ri-business/projects/project/${projectId}`) }
		},
		{
			active: 'errors' === activeSection,
			text: t('errors'),
			onClick: () => { setActiveSection('errors') }
		}
	]

	const loadTasks = async (stageId: number, searchRequest: string, page: number, showClosed: boolean) => {
		const response = await authorizedRequest(singleTaskSubtasks(Number(taskId)) + `?needle=${searchRequest}&page=${page}&status=${stageId}&show_closed=${showClosed}`, 'GET')
		console.log('responseTasks', response)
		const { result } = response
		return result.map((task: taskResponse) => formatTaskResponse(task))
	}

	const loadStages = async (showClosed: boolean) => {
		const response = await authorizedRequest(singleTaskStages(Number(taskId)) + `?show_closed=${showClosed}`, 'GET')
		const { result } = response
		return result.map((stage: stageResponse) => formatStageResponse(stage))
	}
	
	const loadCalendar = async ()=>{
		const response = await authorizedRequest(singleTaskSubtasksCalendar(Number(taskId)), 'GET')
		const { result } = response
		return result.map((stage: stageResponse) => formatStageResponse(stage))
	}

	const renderToolbar = (activeId: number, showClosedTasks:boolean)=> {
		switch(activeId) {
		case 0:
			return <TaskDashboard showClosed={showClosedTasks} loadStages={loadStages} loadTasks={loadTasks}/>
		case 1:
			return <TaskList setFilterBar={setFilterBar} showClosed={showClosedTasks} loadTasks={loadTasks}/>
		case 2:
			return <MyTasksCalendar showClosed={showClosedTasks} loadTasks={loadCalendar} />
		}
	}

	return (
		<Layout
			header={{
				avatar: true,
			}}
			tabButtons={orderDetailTabButton}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
			filterBar={filterBar}
		>
			{activeSection === 'details' ? <TaskDetails
				setTitle={setTitle}
				setProjectId={setProjectId}
				setErrors={setErrors}
				setProjectName={setProjectName}
				setPipelineId={setPipelineId}
				setPipelineName={setPipelineName} />
				: null}
			{activeSection === 'dashboard' ?
				<div className='my-tasks'>
					<div className="my-tasks-tool-bar">
						<div className="my-tasks-tool-bar-left">
							<SwitchButtons switchButtons={toolBarButtons} activeSwitchButtonId={activeToolBarIconId} setActiveSwitchButtonId={setActiveToolBarIcon} size='medium'/>
							<CheckBox label={tr('showClosed')} isChecked={showClosed} onClick={() => setShowClosed(prev => !prev)}/>
						</div>
					</div>
					{renderToolbar(activeToolBarIconId, showClosed)}
				</div>
				:null
			}
			{activeSection === 'tracking' ? <TaskTracking/> : null}
			{activeSection === 'timeTracker' ? <TimeTracker setFilterBar={setFilterBar}/> : null}
			{activeSection === 'warehouseAction' ? <WarehouseAction/> : null}
			{activeSection === 'errors' ? <ErrorList errors={errors}/> : null}
		</Layout>
	)
}

export default Task