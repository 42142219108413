// styles
import './footer.scss'
import { facebook, instagram, linkedIn, logo } from '../../../../assets/icons/icons'

// react
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

// components
import Button from '../../../../../../components/general/button/button'
import TextUnderline from '../../textUnderline/textUnderline'

//translation
import { useTranslation } from 'react-i18next'

//other
import { links } from '../navbar/navbarData'

const Footer: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.general.layout.footer' })

	const navigate = useNavigate()
	return (
		<div className="footer">

			<div className="footer-text-container">
				<Link to='/' className='footer-logo-link'>
					<div className='footer-logo'>
						{logo}
					</div>
				</Link>
				<div className="footer-text-title">
					{t('builtWithSimplicity')} <br/> {t('designedForResults')}
				</div>
				<div className="footer-text-details">
					{t('details')}
				</div>
				<div className="footer-text-copyRight">
					{t('copyRight')}
				</div>
			</div>
			<div className="footer-links-container">
				{
					links ?
						links.map((item)=>
							<div className="footer-links-content-container">
								<div className="footer-links-content-title">{t(item.title)}</div>
								{
									item.dropdownElements ?
										item.dropdownElements.map((link)=> (
											<TextUnderline color='#fff'>
												<Link to={`${item.href}/${link.title}${link.link ? '/'+link.link : ''}`}>{link.icon}{t(link.title)}</Link>
											</TextUnderline>
										)
										)
										: null
								}
								
							</div>
						)
						: null
				}
			</div>
			<div className="footer-social-media-container">
				<div className="footer-sign-up-container">
					<div className="footer-text-details">
						{t('signUpText')}
					</div>
					<Button text={t('createAccount')} onClick={()=> navigate('/register')} active={true} outlined={true}/>
				</div>

				<div className="footer-text-social-media">
					{t('suggestions')}
				</div>

				<a href="mailto: support@ri-software.com.ua">support@ri-software.com.ua</a>

				<div className="footer-links-icons-container">
					<a href='https://www.facebook.com/profile.php?id=61559840792929' target='_blank' className='footer-icon-container'>{facebook}</a>
					<a href='https://www.instagram.com/ri.software' target='_blank' className='footer-icon-container'>{instagram}</a>
					<a href='https://www.linkedin.com/company/ri-software/' target='_blank' className='footer-icon-container'>{linkedIn}</a>
				</div>
			</div>
		</div>
	)
}

export default Footer