// react
import { Dispatch, FC, SetStateAction } from 'react'

// components
import ConfirmationModal from '../../../../general/modals/confirmationModal/confirmationModal'

// network
import { authorizedRequest } from '../../../../../utils/queries'
import { singleProviderUrl } from '../../../../../utils/urls/purchases/provider'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setDeleteProviderModalIsOpen, setDeleteProviderRelationshipId } from '../../../../../redux/purchase/providersList/modals'

// translation
import { useTranslation } from 'react-i18next'

// types
import { provider } from '../../../../../types/purchase/providerTypes'

type deleteProviderConfirmationModalProps = {
	providerList: provider[]
	setProviderList: Dispatch<SetStateAction<provider[]>>
}

const DeleteProviderConfirmationModal: FC<deleteProviderConfirmationModalProps> = ({providerList, setProviderList}) => {
	const { modalIsOpen, relationshipId } = useAppSelector( ( state ) => state.providerListModal.deleteProviderModal )

	const dispatch = useAppDispatch()

	const { t } = useTranslation('', { keyPrefix: 'purchase.providerList.modals.deleteProviderConfirmationModal' })

	const closeModal = () => {
		dispatch( setDeleteProviderModalIsOpen(false) )
		dispatch( setDeleteProviderRelationshipId(null) )
	}

	const deleteProvider = () => {
		authorizedRequest( singleProviderUrl(relationshipId!), 'DELETE' )
			.then( ( response ) => {
				const updatedProviders = providerList.filter( (provider => provider.id !== relationshipId))
				setProviderList(updatedProviders)
				closeModal()
			})
	}

	return (
		<ConfirmationModal
			title={t('deleteThisProvider')}
			closeModal={closeModal}
			isOpen={modalIsOpen}
			buttons={[ { text: t('yes'), onClickHandler: deleteProvider }, { text: t('no'), onClickHandler: closeModal } ]}
		/>
	)
}

export default DeleteProviderConfirmationModal
