// react
import { FC } from 'react'

// component
import FileTableParserModal from '../../../../../general/modals/fileTableParserModal/fileTableParserModal'

// network
import { authorizedRequest } from '../../../../../../utils/queries'
import { inventoryItemsUrl } from '../../../../../../utils/urls/warehouses/inventoryItems/inventoryItem'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setImportInventoryItemFileSelectorModalFile, setImportInventoryItemFileSelectorModalIsOpen, setInventoryItemTablePreviewModalIsOpen } from '../../../../../../redux/warehouse/general/modals'

// other 
import { currency, inventoryItem } from '../../../../../../types/general/generalTypes'
import { formatInventoryItemResponse } from '../../../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type inventoryItemTablePreviewModalProps = {
	inventoryItems: inventoryItem[]
	setInventoryItems: (value: inventoryItem[]) => void
}


const InventoryItemTablePreviewModal: FC<inventoryItemTablePreviewModalProps> = ({ inventoryItems, setInventoryItems }) => {
	const { t } = useTranslation('', { keyPrefix: 'warehouse.general.modals.inventoryItemModals.inventryItemTablePreviewModal' })

	const { file } = useAppSelector((state) => state.warehouseGeneralModal.importInventoryItemFileSelectorModal)
	const { modalIsOpen } = useAppSelector((state) => state.warehouseGeneralModal.inventoryItemTablePreviewModal)

	const companyId = parseInt(localStorage.getItem('companyId') || '-1')

	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setImportInventoryItemFileSelectorModalIsOpen(false))
		dispatch(setInventoryItemTablePreviewModalIsOpen(false))
		dispatch(setImportInventoryItemFileSelectorModalFile(null))
	}

	return (
		<FileTableParserModal
			file={file}
			open={modalIsOpen}
			closeModal={closeModal}
			onTableProcessed={(tableData) => {
				authorizedRequest(inventoryItemsUrl(companyId), 'POST', 'accessToken', tableData.data.map((item) => {
					return {
						name: item.name,
						description: item.description,
						create_offering: Boolean(item.create_sales_offering.content.value === 'true'),
						offering_price: item.sales_offering_price,
						offering_currency: item.sales_offering_currency
					}
				})
				).then(({result})=> {
					if(result.length > 0) {
						const formatedInventoryItems: inventoryItem[] = result.map(formatInventoryItemResponse)
						setInventoryItems([...formatedInventoryItems, ...inventoryItems])
					}
					closeModal()
				})
			}}
			requiredColumns={[
				{
					title: t('name'),
					key: 'name',
					default: '',
					type: String
				},
				
				{
					title: t('description'),
					key: 'description',
					default: '',
					type: String

				},
				{
					title: t('createSalesOffering'),
					key: 'create_sales_offering',
					default: '',
					type: Boolean
				},
				{
					title: t('salesOfferingPrice'),
					key: 'sales_offering_price',
					default: '0',
					type: Number
				},
				{
					title: t('salesOfferingCurrency'),
					key: 'sales_offering_currency',
					default: 'UAH',
					type: currency
				}
			]}
		/>
	)
}

export default InventoryItemTablePreviewModal