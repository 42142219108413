// styles
import './filePreviewModal.scss'

// react
import { FC, useEffect, useState } from 'react'

// components
import Modal from '../modal/modal'
import WordViewer from './wordViewer/wordViewer'
import ExcelViewer from './excelViewer/excelViewer'
import TextViewer from './textViewer/textViewer'
import ImageViewer from './imageViewer/imageViewer'
import PDFViewer from './pdfViewer/pdfViewer'
import VideoPlayer from '../../videoPlayer/videoPlayer'
import AudioMessage from '../../chat/chatDesktop/messageArea/chatMessage/filesContainer/audioMessage/audioMessage'

// types
import { file } from '../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'
import { blobToBase64 } from '../../../../assets/general/generalFunctions'

type filePreviewModalProps = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	file: file | undefined
    handleDownload?: () => void
}

const FilePreviewModal: FC<filePreviewModalProps> = ({ isOpen, setIsOpen, file, handleDownload }) => {
	const { t } = useTranslation('', {keyPrefix: 'general.modals.filePreviewModal'})

	const [fileUrl, setFileUrl] = useState<string | null>(null)

	const renderViewer = (fileType: string) => {
		if(file){
			switch (fileType) {
			case 'video/mp4':
				return <VideoPlayer videoBlob={file.file}/>
			case 'video/webm':
				return <VideoPlayer videoBlob={file.file}/>
			case 'audio/mpeg':
				return <AudioMessage audioBlob={file.file} />
			case 'audio/webm;codecs=opus':
				return <AudioMessage audioBlob={file.file} />
			case 'application/pdf':
				return <PDFViewer file={file} />
			case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
				return <WordViewer file={file} />
			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
				return <ExcelViewer file={file} />
			case 'text/csv':
				return <ExcelViewer file={file} />
			case 'application/vnd.ms-excel':
				return <ExcelViewer file={file} />
			case 'text/plain':
				return <TextViewer file={file} />
			case 'text/html':
				return <TextViewer file={file} />
			case 'image/jpeg':
				return <ImageViewer file={file} />
			case 'image/png':
				return <ImageViewer file={file} />
			case 'image/gif':
				return <ImageViewer file={file} />
			default:
				return <p>Unsupported file type</p>
			}
		}
        
	}

	useEffect(() => {
		console.log(file)
		if (file) {
			blobToBase64(file?.file).then((url) => {
				setFileUrl(url)
			})
		}
        
		// Cleanup URL object when component is unmounted
		return () => {
			if (fileUrl) {
				URL.revokeObjectURL(fileUrl)
			}
		}
	}, [file])

	const closeModal = () => {
		setIsOpen(false)
	}

	return (
		<Modal
			closeModal={closeModal}
			open={isOpen}
			closeButton={false}
			submitButton={{
				text: t('download'),
				onClick: async () => {
					if(file){
						const link = document.createElement('a')
						link.href = URL.createObjectURL(file.file)
						link.download = file.fileName

						// Append the link to the document and trigger a click
						document.body.appendChild(link)
						link.click()

						// Clean up and remove the link
						document.body.removeChild(link)
						console.log(handleDownload)
						handleDownload && handleDownload()

						closeModal()
					}
				}
			}}
			extraClass='file-preview-modal-wrapper'
		>
			<div className="file-preview-modal-content">
				{file && renderViewer(file.fileType)}
			</div>
		</Modal>
	)
}

export default FilePreviewModal
