//react
import { FC, useState } from 'react'
import Pdf from 'react-pdf-js'

//types
import { file } from '../../../../../types/general/generalTypes'

type pdfViewer = {
    file: file
}

const PDFViewer: FC<pdfViewer> = ({ file }) => {
	const [page, setPage] = useState<number>(1)

	const onDocumentComplete = (pages: number) => {
		setPage(pages)
	}

	return (
		<div>
			<Pdf
				file={URL.createObjectURL(file.file)}
				onDocumentComplete={onDocumentComplete}
				page={page}
			/>
		</div>
	)
}

export default PDFViewer
