// styles
import './triplInfoContainer.scss'

// react
import { FC } from 'react'

// components
import ScrollAnimation from '../scrollAnimation/scrollAnimation'
import Title from '../title/title'
import Text from '../text/text'
import Subtitle from '../subtitle/subtitle'

// translations
import { useTranslation } from 'react-i18next'

type triplInfoContainerType = {
    translation: string
    headerText: string
    headerTitle: string
    text: string
    title: string
    icon: JSX.Element
}

const TriplInfoContainer: FC<triplInfoContainerType> = ({text, title, icon, translation, headerText, headerTitle}) => {
	const { t } = useTranslation('', { keyPrefix: translation })

	return (
        <div className="tripl-info-wrapper">
            <div className="tripl-info-hero">
                <ScrollAnimation direction='to-bottom'>
                    <div className="tripl-info-content">
                        <Title>{t(headerTitle)} <span className='title-black'>{t(`${headerTitle}Black`)}</span></Title>
                        <Text>{t(headerText)}</Text>
                        <div className='tripl-info-items'>
                            <div className="tripl-info-item-wrapper" style={{alignItems: 'flex-start'}}>
                                <div className="tripl-info-item-container">
                                    <div className="tripl-info-item">
                                        <div className="item-header-container">
                                            <div className="icon-container">{icon}</div>
                                            <Subtitle>{t(`${title}1`)}</Subtitle>
                                        </div>
                                        <Text>{t(`${text}1`)}</Text>
                                    </div>
                                </div>
                            </div>
                            <div className="tripl-info-item-wrapper" style={{alignItems: 'center'}}>
                                <div className="tripl-info-item-container white">
                                    <div className="tripl-info-item">
                                        <div className="item-header-container">
                                            <div className="icon-container">{icon}</div>
                                            <Subtitle>{t(`${title}2`)}</Subtitle>
                                        </div>
                                        <Text>{t(`${text}2`)}</Text>
                                    </div>
                                </div>
                            </div>
                            <div className="tripl-info-item-wrapper" style={{alignItems: 'flex-end'}}>
                                <div className="tripl-info-item-container">
                                    <div className="tripl-info-item">
                                        <div className="item-header-container">
                                            <div className="icon-container">{icon}</div>
                                            <Subtitle>{t(`${title}3`)}</Subtitle>
                                        </div>
                                        <Text>{t(`${text}3`)}</Text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
        </div>
	)
}

export default TriplInfoContainer