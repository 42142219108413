// styles
import './project.scss'
import {calendar, dashboardIcon, listIcon} from '../../../assets/general/generalIcons'

// react
import { useState, FC } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

//components
import Layout from '../../../components/general/layout/layout'
import Timeline from '../../../components/projects/project/timeline/timeline'
import TaskList from '../../../components/projects/general/taskList/taskList'
import ProjectDetails from '../../../components/projects/project/projectDetails/projectDetails'
import TaskDashboard from '../../../components/general/taskDashboard/taskDashboard'
import ErrorList from '../../../components/general/errorList/errorList'
import SwitchButtons from '../../../components/general/switchButtons/switchButtons'
import CheckBox from '../../../components/general/checkBox/checkBox'
import MyTasksCalendar from '../../../components/projects/myTasks/myTasksCalendar/myTasksCalendar'
import ProjectDocuments from '../../../components/projects/project/projectDocuments/projectDocuments'
import ProjectReport from '../../../components/projects/project/projectReport/projectReport'
import ChatDesktop from '../../../components/general/chat/chatDesktop/chatDesktop'

//context
import { ChatDesktopProvider } from '../../../components/general/chat/chatDesktop/context/chatDesktopContext'

// types
import {
	button,
	error,
	filterBar,
	infoPopupTypes,
	stageResponse,
	switchButton
} from '../../../types/general/generalTypes'
import { taskResponse } from '../../../types/general/generalTypes'

//other
import { formatStageResponse, formatTaskResponse } from '../../../assets/projects/projectsFunctions'

//network
import { authorizedRequest } from '../../../utils/queries'
import {singleProjectCalendarTasksUrl, singleProjectTasksUrl} from '../../../utils/urls/projects/project'

// translation
import { useTranslation } from 'react-i18next'

const Project: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'projects.project' })

	const navigate = useNavigate()

	const [activeProjectSection, setActiveProjectSection] = useState('details')
	const [budgetId, setBudgetId] = useState<number>()
	const [orderId, setOrderId] = useState<number>()
	const [title, setTitle] = useState('')
	const [errors, setErrors] = useState<error[]>([])
	const [pipelineId, setPipelineId] = useState<number>()
	const [pipelineName, setPipelineName] = useState('')
	const [chatId, setChatId] = useState<number>()

	const [filterBar, setFilterBar] = useState<filterBar | undefined>()

	const [showClosed, setShowClosed] = useState(false)
	const { t:tr } = useTranslation('', { keyPrefix: 'projects.myTasks' })

	const [ activeToolBarIconId, setActiveToolBarIcon ] = useState(0)

	const toolBarButtons: switchButton[] = [
		{
			id: 0,
			icon: dashboardIcon
		},
		{
			id: 1,
			icon: listIcon
		},
		{
			id: 2,
			icon: calendar
		}
	]

	const projectTabButtons: button[] = [
		{
			active: 'details' === activeProjectSection,
			text: t('details'),
			onClick: () => { setActiveProjectSection('details') }
		},
		{
			active: 'documents' === activeProjectSection,
			text: t('documents'),
			onClick: () => { setActiveProjectSection('documents') },
		},
		{
			active: 'timeline' === activeProjectSection,
			text: t('timeline'),
			onClick: () => { setActiveProjectSection('timeline') }
		},
		{
			active: 'task_board' === activeProjectSection,
			text: t('taskboard'),
			onClick: () => { setActiveProjectSection('task_board') }
		},
		{
			active: 'errors' === activeProjectSection,
			text: t('errors'),
			onClick: () => { setActiveProjectSection('errors') }
		},
		{
			active: 'report' === activeProjectSection,
			text: t('report'),
			onClick: () => { setActiveProjectSection('report') }
		},
		{
			active: 'budget' === activeProjectSection,
			text: t('budget'),
			onClick: () => { navigate(`/ri-business/finance/budget/${budgetId}`) }
		},
		...(orderId ? [
			{
				active: 'order' === activeProjectSection,
				text: t('order'),
				onClick: () => { navigate(`/ri-business/order/${orderId}`) }
			}
		] : []),
		{
			active: 'chat' === activeProjectSection,
			text: t('chat'),
			onClick: () => { setActiveProjectSection('chat') }
		},
	]

	const { projectId } = useParams()

	const titleUrls = [
		{
			url: '/ri-business/projects/pipelines',
			title: t('pipelines')
		},
		{
			url: `/ri-business/projects/pipeline/${pipelineId}`,
			title: pipelineName
		},
		{
			url: location.pathname,
			title: title
		}
	]

	const infoPopup = {
		content: [{
			title: 'project_info_title',
			description: 'project_info_description',
			link: '/ri-manual/projects/pipelines/project'
		}],
		type: infoPopupTypes.INFO
	}

	const loadTasks = async (stageId: number, searchRequest: string, page: number, showClosed: boolean) => {
		const response = await authorizedRequest(singleProjectTasksUrl(Number(projectId)) + `?needle=${searchRequest}&page=${page}&status=${stageId}&show_closed=${showClosed}`, 'GET')

		const { result } = response

		return result.map((task: taskResponse) => formatTaskResponse(task))
	}

	const loadStages = async (showClosed: boolean) => {
		const response = await authorizedRequest(singleProjectTasksUrl(Number(projectId)) + `?show_closed=${showClosed}`, 'PATCH')
		const { result } = response
		return result.map((stage: stageResponse) => formatStageResponse(stage))
	}

	const loadTaskCalendar =async ()=>{
		const response = await authorizedRequest(singleProjectCalendarTasksUrl(Number(projectId)) , 'GET')

		const { result } = response

		return result.map((task: taskResponse) => formatTaskResponse(task))
	}

	const renderToolbar = (activeId: number, showClosedTasks:boolean)=> {
		switch(activeId) {
		case 0:
			return <TaskDashboard showClosed={showClosedTasks} loadStages={loadStages} loadTasks={loadTasks}/>
		case 1:
			return <TaskList setFilterBar={setFilterBar} showClosed={showClosedTasks} loadTasks={loadTasks}/>
		case 2:
			return <MyTasksCalendar showClosed={showClosedTasks} loadTasks={loadTaskCalendar} />
		}
	}

	return (
		<Layout
			header={{
				avatar: true,
			}}
			tabButtons={projectTabButtons}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>

			<div className='project-wrapper'>
				{
					activeProjectSection === 'task_board' &&
					<div className="project-tool-bar">
						<SwitchButtons switchButtons={toolBarButtons} activeSwitchButtonId={activeToolBarIconId} setActiveSwitchButtonId={setActiveToolBarIcon} size='medium'/>
						<CheckBox label={tr('showClosed')} isChecked={showClosed} onClick={() => setShowClosed(prev => !prev)}/>
					</div>
				}
				{
					activeProjectSection === 'details' ?
						<ProjectDetails
							setPipelineId={setPipelineId}
							setPipelineName={setPipelineName}
							setChatId={setChatId}
							setBudgetId={(value) => { setBudgetId(value) }}
							setOrderId={(value) => { setOrderId(value) }}
							setTitle={(value) => { setTitle(value) }}
							setErrors={setErrors}
						/>
						: null
				}
				{
					activeProjectSection === 'documents' ?
						<ProjectDocuments setFilterBar={setFilterBar} />
						: null
				}
				{
					activeProjectSection === 'timeline' ?
						<Timeline loadTasks={loadTaskCalendar} />
						: null
				}
				{activeProjectSection === 'task_board' && renderToolbar(activeToolBarIconId, showClosed)}
				{
					activeProjectSection === 'errors' ?
						<ErrorList errors={errors} />
						: null
				}
				{
					activeProjectSection === 'report' ?
						<ProjectReport setFilterBar={setFilterBar} />
						: null
				}
				{
					activeProjectSection === 'chat' && chatId ?
						<ChatDesktopProvider>
							<ChatDesktop chatId={chatId} />
						</ChatDesktopProvider>
						: null
				}
				
			</div>
		</Layout>
	)

}

export default Project