//styles
import './textUnderline.scss'

//react
import { FC, ReactNode } from 'react'

type textUnderlineProps = {
    children: ReactNode
    color: string
}

const TextUnderline: FC<textUnderlineProps> = ({ children, color }) => {
    return (
        <div className="text-underline-container">
            {children}
            <div className="text-underline" style={{
                background: color
            }}></div>
        </div>
    )
}

export default TextUnderline