// styles and icons
import './accountList.scss'
import { listIcon, tableIcon } from '../../../assets/general/generalIcons'


// react
import { FC, useState, useEffect } from 'react'

// components
import Layout from '../../../components/general/layout/layout'
import Pagination from '../../../components/general/pagination/pagination'
import ColumnLayout from '../../../components/general/columnLayout/columnLayout'
import ListSummaryCard from '../../../components/general/listSummaryCard/listSummaryCard'
import AccountListItem from '../../../components/finance/accountList/accountListItem/accountListItem'
import ImportAccountFileSelectorModal from '../../../components/general/modals/account/importAccountFileSelectorModal/importAccountFileSelectorModal'
import ExportAccountFileModal from '../../../components/general/modals/account/exportAccountFileModal/exportAccountFileModal'
import CreateAccountModal from '../../../components/general/modals/account/createAccountModal/createAccountModal'
import DeleteAccountModal from '../../../components/general/modals/account/deleteAccountModal/deleteAccountModal'
import AccountTablePreviewModal from '../../../components/general/modals/account/accountTablePreviewModal/accountTablePreviewModal'
import OverviewCardSkeleton from '../../../components/general/skeletons/overviewCard/overviewCardSkeleton'
import ListCardSkeleton from '../../../components/general/skeletons/listCard/listCardSkeleton'
import AccountTable from '../../../components/general/tableViews/accountTable/accountTable'
import SwitchButtons from '../../../components/general/switchButtons/switchButtons'
//network
import { authorizedRequest } from '../../../utils/queries'
import { companyAccountOverviewUrl, companyAccountUrl, companyAccountFiltersUrl } from '../../../utils/urls/finance/account/account'

//redux
import { useAppDispatch, useAppSelector } from '../../../customHooks/redux'
import { setCreateAccountModalIsOpen } from '../../../redux/general/modals'

//types
import { currency, filterBar, filters, infoPopupTypes, switchButton } from '../../../types/general/generalTypes'
import { account } from '../../../types/finance/accountTypes'

//other
import { formatFilters, createFilteringRequest, currencyToFormattedString, getItemsPerColumn, formatAccountResponse } from '../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'
import { getAccountUrl } from '../../../utils/old_urls/finance/accountUrls'

//hooks
import { useNavigate } from 'react-router-dom'



const AccountList: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'finance.accountList' })
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const dispatch = useAppDispatch()

	const [accountsOverview, setAccountsOverview] = useState<{ title: string, text: string }[]>([])
	const [accounts, setAccounts] = useState<account[]>([])
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())
	const [activeViewId, setActiveViewId] = useState(0)

	const [searchRequest, setSearchRequest] = useState('')
	const [filterBar, setFilterBar] = useState<filterBar>()
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const [isFilterBarLoading, setFilterBarLoading] = useState<boolean>(false)
	const [isOverviewLoading, setOverviewLoading] = useState<boolean>(false)
	const navigate = useNavigate()

	const titleUrls = [
		{
			url: '/ri-business/finance/account-list',
			title: t('title')
		}
	]

	const infoPopup = {
		content: [{
			title: 'account_list_info_title',
			description: 'account_list_info_description',
			link: '/ri-manual/finance/accounts'
		}],
		type: infoPopupTypes.INFO
	}

	const loadFilterBar = async () => {
		try {
			setFilterBarLoading(true)
			const { result } = await authorizedRequest(companyAccountFiltersUrl(companyId), 'GET')

			const filterResult = result.filters
			const createAccess = result.create_access

			const formatedFilters: filters[] = formatFilters(filterResult)

			setFilterBar({
				// importButton: () => {
				// 	dispatch(setImportAccountFileSelectorModalIsOpen(true))
				// },
				// exportButton: () => {
				// 	dispatch(setExportAccountFileModalIsOpen(true))
				// },
				filters: formatedFilters,
				addButton: createAccess && {
					text: t('create'),
					active: true,
					onClick: () => dispatch(setCreateAccountModalIsOpen(true))
				},
				onSearch: onSearch
			})
		} finally {
			setFilterBarLoading(false)
		}
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		try {
			setLoading(true)
			const filteringRequest = createFilteringRequest(filters)
			const { result } = await authorizedRequest(companyAccountUrl(companyId) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')

			if (result.length > 0) {
				const formatedAccounts: account[] = result.map(formatAccountResponse)
				return formatedAccounts
			}

			return []
		} finally {
			setLoading(false)
		}

	}

	const loadOverview = async () => {
		try {
			setOverviewLoading(true)
			const { result } = await authorizedRequest(companyAccountOverviewUrl(companyId), 'GET')

			setAccountsOverview([
				{
					title: 'total',
					text: result.total_accounts
				},
				...result.balance.map((amount: { value: number, currency: currency }) => {
					return {
						title: 'balance',
						text: currencyToFormattedString(amount.value, amount.currency)
					}
				})
			])
		} finally {
			setOverviewLoading(false)
		}
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		loadOverview()
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		loadData(1, searchValue, filters)
			.then((result) => {
				setAccounts([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
				setLoading(false)
			})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					setAccounts([...accounts, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				})
		}
	}

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {

		onSearch(searchRequest, activeFilters)

		loadFilterBar()

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])
	const viewButtons: switchButton[] = [
		{
			id: 0,
			icon: listIcon
		},
		{
			id: 1,
			icon: tableIcon
		}]
	const renderComponent = () => {
		switch (activeViewId) {
		case 0:
			return 	<ColumnLayout amount={itemsPerColumn}>
				{
					isOverviewLoading ? <OverviewCardSkeleton /> : <ListSummaryCard
						title={t('accountsOverview')}
						data={accountsOverview.map(overview => {
							return {
								...overview,
								title: t(overview.title)
							}
						})}
					/>
				}
				{accounts.map(account => (<AccountListItem key={`account-item-${account.id}`} {...account} />))}
				{loading  ? <ListCardSkeleton /> : null}
			</ColumnLayout>
		case 1:
			return (
				<AccountTable
					preloadedAccount={{
						accounts: accounts,
						setAccounts: setAccounts,
					}}
				/>
			)
		}
	}


	return (
		<Layout
			header={{
				avatar: true,
			}}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
			isFilterBarLoading={isFilterBarLoading}
		>
			<div className='account-list'>
				<SwitchButtons
					switchButtons={viewButtons}
					activeSwitchButtonId={activeViewId}
					setActiveSwitchButtonId={setActiveViewId}
					size="medium"
				/>
				<Pagination loading={loading} onLoadMore={handleLoadMore} showLoader={false}>
					{renderComponent()}
				</Pagination>
			</div>
			<CreateAccountModal createAccountUrl={companyAccountUrl(companyId)} accounts={accounts} setAccounts={setAccounts} />
			<DeleteAccountModal accounts={accounts} setAccounts={setAccounts} />
			<ExportAccountFileModal exportAccountUrl={getAccountUrl(companyId)} />
			<ImportAccountFileSelectorModal />
			<AccountTablePreviewModal createAccountUrl={companyAccountUrl(companyId)} />
		</Layout>
	)
}

export default AccountList