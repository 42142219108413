//react
import { FC } from 'react'
import { Link } from 'react-router-dom'

//styles and icons
import './notification.scss'
import { errorNotification, successNotification, warningNotification, infoNotification } from '../../../assets/general/generalIcons'

//components
import Avatar from '../avatar/avatar'

//types
import { notification, notificationType, switchButton } from '../../../types/general/generalTypes'
import SwitchButtons from '../switchButtons/switchButtons'

//other
import { formatDate } from '../../../assets/general/generalFunctions'
import translation from '../../../locales/en/translation.json'

//network
import { baseUrl } from '../../../utils/network'

//translation
import { useTranslation } from 'react-i18next'


type notificationProps = notification & {
	fullWidth?: boolean
	actions?: switchButton[]
}

const Notification: FC<notificationProps> = ({ type, title, description, fullWidth = false, actions, date, avatar, url }) => {
	const { t } = useTranslation('', {keyPrefix: 'notifications.labels'})

	const getNotificationData = (type: notificationType): { color: string, icon: JSX.Element } => {
		switch (type) {
		case notificationType.NEGATIVE:
			return { color: '#FF4E4E', icon: errorNotification }
		case notificationType.POSITIVE:
			return { color: '#4EFF6A', icon: successNotification }
		case notificationType.NEUTRAL:
			return { color: '#FFEE53', icon: warningNotification }
		case notificationType.INFO:
			return { color: '#5792F1', icon: infoNotification }
		default:
			return { color: '#5792F1', icon: infoNotification }
		}
	}

	return (
		<div className="notification-wrapper" style={{ width: fullWidth ? '70vw' : '42vw' }}>
			<div className="notification-side-line" style={{
				background: getNotificationData(type).color
			}} ></div>
			<div className="notification-container">
				<div className="notification-content-container">
					<div className="notification-content-icon">
						{avatar !== undefined ?
							(
								<Avatar blobAvatar={avatar} />
							)
							:
							(
								getNotificationData(type).icon
							)
						}
					</div>
					<div className="notification-content-text-container">
						<div className="notification-content-title">{title}</div>
						<div className="notification-content-description">{description in translation.notifications.labels ?  t(description) : description}</div>
						{
							url ?
								<Link to={'/ri-business/' + url}>{t('details')}</Link>
								: null
						}
					</div>
					<div className='notification-content-time-container'>
						{date && (
							<>
								<div>
									{ formatDate(date, true, true) }
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			<div className="buttons-container">
				{actions && <div className='counterparty-action'>
					<SwitchButtons
						switchButtons={actions}
						orientation='vertical'
					/>
				</div>}
			</div>
		</div>
	)
}

export default Notification