//styles
import './timeUsageData.scss'

//components
import ChartContainer from '../chart/chartContainer/chartContainer'
import Table from '../table/table'

//react
import { FC, useEffect, useState } from 'react'

//types
import { chartItem, chartViewOptions, columnTypes, tableBodyItem, timeUsage } from '../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { time } from 'console'

const formatTimeUsageData = (timeUsage: timeUsage[], totalHours: number, t: TFunction) => {
	const formattedUsage: {app: string, totalHours: number}[] = []
  
	timeUsage.forEach((item, index) => {    
		const duration = (new Date(item.endDate).getTime() - new Date(item.startDate).getTime()) / 3600000
  
		const appIndex = formattedUsage.findIndex((formattedItem) => {
			return formattedItem.app === item.app || (item.app === null && formattedItem.app === 'inactive')
		})
  
		if (appIndex > -1) {
			formattedUsage[appIndex].totalHours += duration
		} else {
			formattedUsage.push({
				app: item.app || 'inactive',
				totalHours: duration
			})
		}
	})
    
	if(totalHours - formattedUsage.reduce((sum, current) => sum + current.totalHours, 0) > 0){
  
		formattedUsage.push({
			app: 'unknown',
			totalHours: totalHours - formattedUsage.reduce((sum, current) => sum + current.totalHours, 0)
		})
	}

	formattedUsage.forEach((item, index) => {
		if(item.app === 'inactive' || item.app === 'unknown'){
			formattedUsage[index].app = t(item.app)
		}
	})
  
	return formattedUsage
}


const convertHoursToHMS = (hours: number): string => {
	const totalSeconds = Math.floor(hours * 3600)
	const h = Math.floor(totalSeconds / 3600)
	const m = Math.floor((totalSeconds % 3600) / 60)
	const s = totalSeconds % 60

	// Ensure double digits for minutes and seconds
	const formattedM = m < 10 ? `0${m}` : `${m}`
	const formattedS = s < 10 ? `0${s}` : `${s}`

	return `${h}:${formattedM}:${formattedS}`
}

type timeUsageProps = {
    timeUsage: timeUsage[]
    hoursSpent: number
}

const TimeUsageData: FC<timeUsageProps> = ({ timeUsage, hoursSpent }) => {
	const {t} = useTranslation('', { keyPrefix: 'projects.task.appsUsed' })

	const [timeUsageTable, setTimeUsageTable] = useState<Record<string, tableBodyItem>[]>([])


	const formatTimeTrackerTableData = (timeTrackers: timeUsage[]): Record<string, tableBodyItem>[] => {
		return formatTimeUsageData(timeTrackers, hoursSpent, t).filter((timeTrackers) => timeTrackers.totalHours > 0).map((timeTracker) => {
			const baseData: Record<string, tableBodyItem> = {
				name: {
					content: {
						type: columnTypes.string,
						value: timeTracker.app
					}
				},
				total: {
					content: {
						type: columnTypes.string,
						value: convertHoursToHMS(timeTracker.totalHours)
					}
				}
			}
    
			return baseData
		})
	}



	const generateChartData = (): Promise<{ xAxisLabels: string[]; chartItems: chartItem[] }> => {
		return new Promise((resolve) => {
			const xAxisLabels: string[] = ['']

			const chartItems: chartItem[] = formatTimeUsageData(timeUsage, hoursSpent, t).filter((formattedItem) => formattedItem.totalHours>0).map((formattedItem) => {
				return {
					label: formattedItem.app || t('inactive'), 
					color: `#${Math.floor(Math.random()*16777215).toString(16)}`,
					data: [{
						value: Number(formattedItem.totalHours.toFixed(2)),
						date: new Date()
					}]
				}
			})

			resolve({ xAxisLabels, chartItems })
		})
	}


	useEffect(() => {
		setTimeUsageTable([...formatTimeTrackerTableData(timeUsage)])
	}, [timeUsage])    
    

	return (
		<div className="time-usage-data">
			{ timeUsage.length === 0 && timeUsageTable.length === 0 ?
				<p>
					{t('noData')}
				</p>
				: null}
			{
				timeUsage.length > 0 &&
                    <ChartContainer
                    	title={t('chartHeader')}
                    	loadData={generateChartData}
                    	viewOptions={[chartViewOptions.PIE]}
                    	className="chart-full-width"
                    	showDurationOptions={false}
                    />
			}
			{
				timeUsageTable.length > 0 && 
                    <Table
                    	columns={[
                    		{ key: 'name', title: t('name')},
                    		{ key: 'total', title: t('total')}
                    	]}
                    	data={timeUsageTable}
                    />
			}
		</div>
	)
}

export default TimeUsageData