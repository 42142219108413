//styles
import './budgetList.scss'
import { listIcon, tableIcon } from '../../../assets/general/generalIcons'


//react
import { useState, useEffect } from 'react'

//components
import Pagination from '../../../components/general/pagination/pagination'
import ColumnLayout from '../../../components/general/columnLayout/columnLayout'
import Layout from '../../../components/general/layout/layout'
import ListSummaryCard from '../../../components/general/listSummaryCard/listSummaryCard'
import BudgetTablePreviewModal from '../../../components/finance/budgetList/modals/budgetTablePreviewModal.tsx/budgetTablePreviewModal'
import ImportBudgetFileSelectorModal from '../../../components/finance/budgetList/modals/importBudgetFileSelectorModal/importBudgetFileSelectorModal'
import ExportBudgetFileModal from '../../../components/finance/budgetList/modals/exportBudgetFileModal/exportBudgetFileModal'
import BudgetListItem from '../../../components/general/budgetListItem/budgetListItem'
import DeleteBudgetConfirmationModal from '../../../components/general/modals/budget/deleteBudgetConfirmationModal/deleteBudgetConfirmationModal'
import CreateBudgetModal from '../../../components/general/modals/budget/createBudgetModal/createBudgetModal'
import OverviewCardSkeleton from '../../../components/general/skeletons/overviewCard/overviewCardSkeleton'
import ListCardSkeleton from '../../../components/general/skeletons/listCard/listCardSkeleton'
import CheckBox from '../../../components/general/checkBox/checkBox'
import BudgetTable from '../../../components/general/tableViews/budgetTable/budgetTable'
import SwitchButtons from '../../../components/general/switchButtons/switchButtons'


//redux
import { useAppDispatch, useAppSelector } from '../../../customHooks/redux'
import {
	setCreateBudgetModalIsOpen
} from '../../../redux/finance/budgetList/modals'

//network
import { authorizedRequest } from '../../../utils/queries'
import { companyBudgetFiltersUrl, companyBudgetOverviewUrl, companyBudgetsUrl } from '../../../utils/urls/finance/budget/budget'

//types
import { budget } from '../../../types/finance/budgetTypes'
import { currency, filters, filterBar, infoPopupTypes, switchButton } from '../../../types/general/generalTypes'

//other
import { formatFilters, createFilteringRequest, currencyToFormattedString, getItemsPerColumn } from '../../../assets/general/generalFunctions'
import { formatBudgetResponse } from '../../../assets/projects/projectsFunctions'

//translation
import { useTranslation } from 'react-i18next'

const BudgetList = () => {
	const { userCompanyData } = useAppSelector((state) => state.general)
	const { t } = useTranslation('', { keyPrefix: 'budgets' })
	const companyId: number = userCompanyData?.companyId || -1

	const dispatch = useAppDispatch()
	const [activeViewId, setActiveViewId] = useState(0)

	const [budgetsOverview, setBudgetsOverview] = useState<{ title: string, text: string }[]>([])
	const [budgets, setBudgets] = useState<budget[]>([])
	const [filteredBudgetList, setFilteredBudgetList] = useState<budget[]>([])
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())

	const [searchRequest, setSearchRequest] = useState('')
	const [filterBar, setFilterBar] = useState<filterBar>()
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const [isFilterBarLoading, setFilterBarLoading] = useState<boolean>(false)
	const [isOverviewLoading, setOverviewLoading] = useState<boolean>(false)
	const [showClosed, setShowClosed] = useState(true)
	
	const titleUrls = [
		{
			url: '/ri-business/finance/budget-list',
			title: t('budgets')
		}
	]

	const infoPopup = {
		content: [{
			title: 'budget_list_info_title',
			description: 'budget_list_info_description',
			link: '/ri-manual/finance/budgets'
		}],
		type: infoPopupTypes.INFO
	}

	const loadFilterBar = async () => {
		try {
			setFilterBarLoading(true)
			const { result } = await authorizedRequest(companyBudgetFiltersUrl(companyId), 'GET')

			const filterResult = result.filters
			const createAccess = result.create_access

			const formatedFilters: filters[] = formatFilters(filterResult)

			setFilterBar({
				// importButton: () => {
				// 	dispatch(setImportBudgetFileSelectorModalIsOpen(true))
				// },
				// exportButton: () => {
				// 	dispatch(setExportBudgetFileModalIsOpen(true))
				// },
				filters: formatedFilters,
				addButton: createAccess && {
					text: 'Add',
					active: true,
					onClick: () => dispatch(setCreateBudgetModalIsOpen(true))
				},
				onSearch: onSearch
			})
		} finally {
			setFilterBarLoading(false)
		}
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		try {
			setLoading(true)
			const filteringRequest = createFilteringRequest(filters)

			const { result } = await authorizedRequest(companyBudgetsUrl(companyId) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')

			if (result.length > 0) {
				const formatedBudgets: budget[] = result.map(formatBudgetResponse)
				return formatedBudgets
			}

			return []
		} finally {
			setLoading(false)
		}
	}

	const loadOverview = async () => {
		try {
			setOverviewLoading(true)
			const { result } = await authorizedRequest(companyBudgetOverviewUrl(companyId), 'GET')

			setBudgetsOverview([
				{
					title: 'Total Amount',
					text: result.total_budgets
				},
				...result.amount.map((amount: { value: number, currency: currency }) => {
					return {
						title: 'Total Worth',
						text: currencyToFormattedString(amount.value, amount.currency)
					}
				})
			])
		} finally {
			setOverviewLoading(false)
		}
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		loadOverview()
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		loadData(1, searchValue, filters)
			.then((result) => {
				setBudgets([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
			})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					setBudgets([...budgets, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				})
		}
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()
	}, [])

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])


	useEffect(() => {
		setFilteredBudgetList(budgets.filter((budget) => showClosed ? budget : !budget.isClosed))
	}, [budgets, showClosed])

	const renderComponent = () => {
		switch (activeViewId) {
		case 0:
			return (
				<ColumnLayout amount={itemsPerColumn}>
					{
						isOverviewLoading ? <OverviewCardSkeleton /> : <ListSummaryCard
							title={t('budgetOverview')}
							data={budgetsOverview}
						/>
					}
					{filteredBudgetList.map(budget => (<BudgetListItem key={`budget-item-${budget.id}`} {...budget} />))}
					{loading ? <ListCardSkeleton /> : null}
				</ColumnLayout>
			)
		case 1:
			return (
				<BudgetTable
					preloadedBudgets={{
						budgets: budgets,
						setBudgets: setBudgets,
					}}
					loadBudgets={{
						budgetUrl: companyBudgetsUrl(companyId),
						showClosed: showClosed,
					}}
				/>
			)
		}
	}
	const viewButtons: switchButton[] = [
		{
			id: 0,
			icon: listIcon,
		},
		{
			id: 1,
			icon: tableIcon,
		},
	]

	return (
		<Layout
			header={{
				avatar: true,
				title: 'Budgets'
			}}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
			isFilterBarLoading={isFilterBarLoading}
		>
			<div className='budget-list'>
				<CheckBox
					isChecked={showClosed}
					onClick={() => {
						setShowClosed(!showClosed)
					}} 
					label={t('showClosed')}
				/>
				<SwitchButtons
					switchButtons={viewButtons}
					activeSwitchButtonId={activeViewId}
					setActiveSwitchButtonId={setActiveViewId}
					size="medium"
				/>
				<Pagination loading={loading} onLoadMore={handleLoadMore} showLoader={false}>
					{renderComponent()}
				</Pagination>
			</div>
			<ExportBudgetFileModal />
			<BudgetTablePreviewModal />
			<ImportBudgetFileSelectorModal />
			<CreateBudgetModal budgets={budgets} setBudgets={setBudgets} />
			<DeleteBudgetConfirmationModal budgets={budgets} setBudgets={setBudgets} />
		</Layout>
	)
}

export default BudgetList