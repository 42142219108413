//components
import Modal from '../../../../../general/modals/modal/modal'
import EditableTable from '../../../../../general/editableTable/editableTable'

//redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setAddOfferingInventoryItemModalIsOpen } from '../../../../../../redux/sales/offering/modals'

//react
import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import { inventoryItemsUrl } from '../../../../../../utils/urls/warehouses/inventoryItems/inventoryItem'
import { offeringItemsUrl } from '../../../../../../utils/urls/sales/offerings'

//translation
import { useTranslation } from 'react-i18next'

//types
import {
	infoPopupTypes,
	inventoryItem,
	inventoryItemResponse,
	itemTypes
} from '../../../../../../types/general/generalTypes'
import { formatInventoryItemResponse } from '../../../../../../assets/general/generalFunctions'


type addOfferingInventoryItemModalProps = {
	offeringInventoryItems: inventoryItem[]
	setOfferingInventoryItems: (value: inventoryItem[]) => void
}

const AddOfferingInventoryItemModal: FC<addOfferingInventoryItemModalProps> = ({ offeringInventoryItems, setOfferingInventoryItems }) => {
	const dispatch = useAppDispatch()
	const { t } = useTranslation('', { keyPrefix: 'sales.offering.offeringInventoryItemList.modals.addOfferingInventoryItemModal' })

	const { modalIsOpen } = useAppSelector((state) => state.offeringModal.offeringInventoryItemList.addOfferingInventoryItemModal)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const [inventoryItemOptions, setInventoryItemOptions] = useState<inventoryItem[]>([])
	const [selectedInventoryItems, setSelectedInventoryItems] = useState<inventoryItem[]>([])

	const { offeringId } = useParams()

	const closeModal = () => {
		setSelectedInventoryItems([])
		dispatch(setAddOfferingInventoryItemModalIsOpen(false))
	}

	const infoPopup = {
		content: [{
			title: 'add_inventory_item_title',
			description: 'add_inventory_item_description',
			link: '/ri-business/warehouse/inventory'
		}],
		type: infoPopupTypes.INFO
	}

	const loadInventoryItemOptions = async (searchQuery: string, page: number) => {

		const { result }: { result: inventoryItemResponse[] } = await authorizedRequest(inventoryItemsUrl(Number(companyId)) + `?page=${page}&per_page=${10}&needle=${searchQuery}`, 'GET')
		if (result.length > 0) {
			const formatedItems: inventoryItem[] = result.map(formatInventoryItemResponse)
			return formatedItems
		}

		return []
	}

	const addInventoryItems = async () => {
		
		if (selectedInventoryItems.length === 0) return

		const body = {
			items: selectedInventoryItems.map((item) => {
				return {
					id: item.id,
					quantity: item.quantity
				}
			}),

		}
		console.log(body)
		const response = await authorizedRequest(offeringItemsUrl(Number(offeringId)), 'POST', 'accessToken', body)
		setOfferingInventoryItems([...offeringInventoryItems, ...selectedInventoryItems])
		console.log(response)

		closeModal()

	}



	return (
		<Modal
			titlePopup={infoPopup}
			title={t('addInventoryItem')}
			open={modalIsOpen}
			closeModal={closeModal}
			submitButton={{
				text: t('add'),
				onClick: () => {
					addInventoryItems()
				}
			}}
		>
			<EditableTable
				items={inventoryItemOptions}
				setItems={(value) => {
					setInventoryItemOptions(value as inventoryItem[])
				}}
				loadItems={loadInventoryItemOptions}
				selected={{
					items: selectedInventoryItems,
					setItems: (value) => {
						setSelectedInventoryItems(value as inventoryItem[])
					}
				}}
				noCost
			/>
		</Modal>
	)
}

export default AddOfferingInventoryItemModal