import React, { useState, useEffect } from 'react'
import * as XLSX from 'xlsx'

interface ExcelViewerProps {
  file: { file: Blob; fileName: string; fileType: string };
}

const ExcelViewer: React.FC<ExcelViewerProps> = ({ file }) => {
	const [htmlContent, setHtmlContent] = useState<string>('')

	useEffect(() => {
		if (file) {
			const reader = new FileReader()
			reader.onload = (e) => {
				const arrayBuffer = e.target?.result as ArrayBuffer
				const workbook = XLSX.read(arrayBuffer, { type: 'array' })

				let html = '<table>'

				workbook.SheetNames.forEach((sheetName: string) => {
					const worksheet = workbook.Sheets[sheetName]
					const range = XLSX.utils.decode_range(worksheet['!ref']!)
          
					html += `<h3>${sheetName}</h3>`
					html += '<table border="1">'

					for (let R = range.s.r; R <= range.e.r; ++R) {
						html += '<tr>'
						for (let C = range.s.c; C <= range.e.c; ++C) {
							const cell_address = { c: C, r: R }
							const cell_ref = XLSX.utils.encode_cell(cell_address)
							const cell = worksheet[cell_ref]

							if (cell) {
								const cellValue = cell.v
								const cellStyle = cell.s // Get cell style
								const style = cellStyle ? `style="background-color: ${cellStyle.bgColor}; color: ${cellStyle.fgColor}"` : ''
								html += `<td ${style}>${cellValue}</td>`
							} else {
								html += '<td></td>'
							}
						}
						html += '</tr>'
					}
					html += '</table>'
				})

				html += '</table>'
				setHtmlContent(html)
			}
			reader.readAsArrayBuffer(file.file)
		}
	}, [file])

	return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
}

export default ExcelViewer
