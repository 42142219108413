//styles
import { arrowIcon } from '../../../../assets/general/generalIcons'

//components
import Table from '../../table/table'
import BillTable from '../billTable/billTable'
import Pagination from '../../pagination/pagination'
import DocumentTable from '../documentTable/documentTable'
import OrderTable from '../orderTable/orderTable'

//react
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

//types
import { provider } from '../../../../types/purchase/providerTypes'
import { columnTypes, extraColumnDataResponse, extraColumnsResponse, tableBodyItem, tableBodyItemContent, tableHeader, tableTypes } from '../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../utils/queries'
import { companyExtraColumnDataUrl, companyExtraColumnsUrl } from '../../../../utils/urls/general/company/company'
import { singleProviderBillsUrl, singleProviderUrl, singleProviderDocumentsUrl, singleProviderOrdersUrl } from '../../../../utils/urls/purchases/provider'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector, useAppDispatch } from '../../../../customHooks/redux'
import { setDeleteProviderModalIsOpen, setDeleteProviderRelationshipId } from '../../../../redux/purchase/providersList/modals'

//other
import { getTableViewData, formatProviderResponse, formatExtraColumnResponse, updateCellValue } from '../../../../assets/general/generalFunctions'

type providerTableProps = {
    preloadedProviders?: {
        providers: provider[]
        setProviders: (value: provider[]) => void
    }
    loadProviders?: {
        providerUrl: string
    }
}

const ProviderTable: FC<providerTableProps> = ({ preloadedProviders, loadProviders }) => {

	const { t } = useTranslation('', { keyPrefix: 'general.providerTable' })

	const [ columns, setColumns ] = useState<tableHeader[]>([])
	const [ data, setData ] = useState<Record<string, tableBodyItem>[]>([])


	const [ loading, setLoading ] = useState(false)
	const [ lastPage, setLastPage ] = useState(false)
	const [ page, setPage ] = useState(1) 

	const [ providers, setProviders ] = useState<provider[]>([])

	const {userCompanyData} = useAppSelector(state => state.general)

	const dispatch = useAppDispatch()

	const companyId = userCompanyData?.companyId || -1

	const formatTableData = (provider: provider): Record<string, tableBodyItem> => {
		const editAccess = providers.some((provider) => provider.editAccess)
    
		return {
			id: {
				content: {
					type: columnTypes.element,
					value: <Link to={`/ri-business/purchase/provider/${provider.id}`}>{provider.id}</Link>
				},
				objectId: provider.id
			},
			name: {
				content: {
					type: columnTypes.string,
					value: provider.name
				},
				objectId: provider.id
			},
			phoneNumber: {
				content: {
					type: columnTypes.string,
					value: provider.phone
				},
				objectId: provider.id
			},
			email: {
				content: {
					type: columnTypes.string,
					value: provider.email
				},
				objectId: provider.id
			},
			description: {
				content: {
					type: columnTypes.string,
					value: provider.description
				},
				objectId: provider.id
			},
			bills: {
				content: {
					type: columnTypes.element,
					value: <div className='nested-table-arrow'>{arrowIcon}</div>
				},
				objectId: provider.id,
				extraTable: {
					open: false,
					content: <BillTable
						loadBills={{
							billUrl: singleProviderBillsUrl(provider.id)
						}}
					/>
				}
			},
			documents: {
				content: {
					type: columnTypes.element,
					value: <div className='nested-table-arrow'>{arrowIcon}</div>
				},
				objectId: provider.id,
				extraTable: {
					open: false,
					content: <DocumentTable
						loadDocuments={{
							documentUrl: singleProviderDocumentsUrl(provider.id)
						}}
						parent={{
							type: 'provider',
							id: provider.id
						}}
						editAccess={editAccess}
						createAccess={editAccess}
						deleteAccess={editAccess}
					/>
				}
			},
			orders: {
				content: {
					type: columnTypes.element,
					value: <div className='nested-table-arrow'>{arrowIcon}</div>
				},
				objectId: provider.id,
				extraTable: {
					open: false,
					content: <OrderTable
						loadOrders={{
							orderUrl: singleProviderOrdersUrl(provider.id)
						}}
						parent={{
							type: 'provider'
						}}
					/>
				}
			}
		}
	}

	const onCellValueChange = async (objectId: number, columnKey: string, newValue: tableBodyItemContent) => {
		updateCellValue(
			objectId,
			companyId,
			columnKey,
			newValue,
			singleProviderUrl,
			[
				{
					frontend: 'name',
					backend: 'name',
					type: columnTypes.string
				},
				{
					frontend: 'phoneNumber',
					backend: 'phone',
					type: columnTypes.string
				},
				{
					frontend: 'email',
					backend: 'email',
					type: columnTypes.string
				},
				{
					frontend: 'description',
					backend: 'description',
					type: columnTypes.string
				}
			],
			providers,
			setProviders,
			preloadedProviders?.setProviders,
			formatProviderResponse,
			tableTypes.provider
		)
	}

	const getColumns = async () => {
		const {result}: {result: extraColumnsResponse[]}  = await authorizedRequest(companyExtraColumnsUrl(companyId) + '?table_type=provider', 'GET')

		const editAccess = providers.some((provider) => provider.editAccess)

		setColumns([
			{ key: 'id', title: t('id'), type: columnTypes.number, editAccess: false },
			{ key: 'name', title: t('name'), type: columnTypes.string, editAccess },
			{ key: 'phoneNumber', title: t('phoneNumber'), type: columnTypes.string, editAccess },
			{ key: 'email', title: t('email'), type: columnTypes.string, editAccess },
			{ key: 'description', title: t('description'), type: columnTypes.string, editAccess },
			{ key: 'bills', title: t('bills'), editAccess },
			{ key: 'documents', title: t('documents'), editAccess },
			{ key: 'orders', title: t('orders'), editAccess },
			...result.map(formatExtraColumnResponse), 
		])
	}

	useEffect(() => {
		getColumns()

		getTableViewData(
			data,
			tableTypes.provider,
			providers,
			formatTableData,
			companyId
		).then((newData) => {
			setData([...data, ...newData])
		})

	}, [providers])


	const loadData = async (page: number) => {
		if(loadProviders){
			try {
				setLoading(true)
    
				const { result } = await authorizedRequest(loadProviders.providerUrl + `?page=${page}&per_page=${10}`, 'GET')
    
				if (result.length > 0) {
					const formatedData: provider[] = result.map(formatProviderResponse)
					return formatedData
				}
    
				return []
    
			} finally {
				setLoading(false)
			}
		}
	}

	const handleLoadMore = () => {

		if (!loading && !lastPage) {
			loadData(page)
				.then((result) => {

					if(result){
						setProviders([...providers, ...result])
						if (result.length > 0) {
							setPage(page + 1)
						} else {
							setLastPage(true)
						}
					}
				})
		}
	}

	useEffect(() => {
		if(loadProviders){
			handleLoadMore()
		}
	}, [loadProviders])

	useEffect(() => {
		if(preloadedProviders){
			setProviders(preloadedProviders.providers)
		}
	}, [preloadedProviders])

	return (
		loadProviders ? 
			<Pagination onLoadMore={handleLoadMore} onlyLoadOn='bottom' loading={loading} showLoader={true}>
				<Table
					columns={columns}
					setColumns={setColumns}
					data={data}
					setData={setData}
					onCellValueChange={onCellValueChange}
					tableType={tableTypes.provider}
					deleteAction={providers.some((provider) => provider.deleteAccess) ? (providerId) => {
						dispatch(setDeleteProviderModalIsOpen(true))
						dispatch(setDeleteProviderRelationshipId(providerId))
					} : undefined}
					addColumn={providers.some((provider) => provider.editAccess)}
				/>
			</Pagination>
			:
			<Table
				columns={columns}
				setColumns={setColumns}
				data={data}
				setData={setData}
				onCellValueChange={onCellValueChange}
				tableType={tableTypes.provider}
				deleteAction={providers.some((provider) => provider.deleteAccess) ? (providerId) => {
					dispatch(setDeleteProviderModalIsOpen(true))
					dispatch(setDeleteProviderRelationshipId(providerId))
				} : undefined}
				addColumn={providers.some((provider) => provider.editAccess)}
			/>
	)
}

export default ProviderTable