//styles
import { arrowIcon } from '../../../../assets/general/generalIcons'

//components
import Table from '../../table/table'
import Pagination from '../../pagination/pagination'
import InventoryItemTable from '../inventoryItemTable/inventoryItemTable'
import CreateOfferingModal from '../../../sales/offeringList/modals/createOfferingModal/createOfferingModal'
import CreateProviderOfferingModal from '../../../purchase/provider/providerOfferingList/modals/createProviderOfferingModal/createProviderOfferingModal'
import DeleteOfferingModal from '../../../sales/offeringList/modals/deleteOfferingModal/deleteOfferingModal'
import DeleteProviderOfferingModal from '../../../purchase/provider/providerOfferingList/modals/deleteProviderOfferingModal/deleteProviderOfferingModal'
import AddInventoryItemProviderOfferingModal from '../../../warehouse/inventoryItem/InventoryItemOfferingList/modals/addInventoryItemProviderOfferingModal/addInventoryItemProviderOfferingModal'
import DeleteInventoryItemProviderOfferingModal from '../../../warehouse/inventoryItem/InventoryItemOfferingList/modals/deleteInventoryItemProviderOfferingModal/deleteInventoryItemProviderOfferingModal'

//react
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

//types
import {
	columnTypes,
	currency,
	extraColumnsResponse,
	offering,
	tableBodyItem,
	tableBodyItemContent,
	tableHeader,
	tableTypes,
} from '../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../utils/queries'
import { companyExtraColumnsUrl } from '../../../../utils/urls/general/company/company'
import { companyOfferingsUrl } from '../../../../utils/old_urls/general/orderUrls'
import { singleOfferingUrl } from '../../../../utils/urls/sales/offerings'
import { getInventoryItemsForOfferingUrl } from '../../../../utils/old_urls/warehouse/generalUrls'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector, useAppDispatch } from '../../../../customHooks/redux'
import {
	setDeleteOfferingModalIsOpen,
	setDeleteOfferingId,
	setCreateOfferingModalIsOpen,
} from '../../../../redux/sales/offeringList/modals'
import {
	setCreateProviderOfferingModalIsOpen,
	setDeleteProviderOfferingId,
	setDeleteProviderOfferingModalIsOpen,
} from '../../../../redux/purchase/provider/modals'
import {
	setDeleteInventoryItemProviderOfferingId,
	setDeleteInventoryItemProviderOfferingModalIsOpen,
} from '../../../../redux/warehouse/inventoryItem/modals'
import { setCreateInventoryItemModalIsOpen } from '../../../../redux/warehouse/general/modals'

//other
import {
	createDropdownOption,
	formatExtraColumnResponse,
	formatOfferingResponse,
	getTableViewData,
	updateCellValue,
} from '../../../../assets/general/generalFunctions'
type offeringTableProps = {
	preloadedOfferings?: {
		offerings: offering[]
		setOfferings: (value: offering[]) => void
	}
	loadOfferings?: {
		offeringUrl: string
	}
	type: 'sales' | 'provider' | 'inventoryItem'
}

const OfferingTable: FC<offeringTableProps> = ({
	preloadedOfferings,
	loadOfferings,
	type,
}) => {
	const { t } = useTranslation('', {
		keyPrefix: 'sales.offeringList.offeringTable',
	})
	const tCurrency = useTranslation('', { keyPrefix: 'general.currency' }).t

	const [columns, setColumns] = useState<tableHeader[]>([])
	const [data, setData] = useState<Record<string, tableBodyItem>[]>([])
	const [offerings, setOfferings] = useState<offering[]>([])
	const [loading, setLoading] = useState(false)
	const [lastPage, setLastPage] = useState(false)
	const [page, setPage] = useState(1)

	const { userCompanyData } = useAppSelector((state) => state.general)

	const { createOfferingModal, deleteOfferingModal } = useAppSelector(
		(state) => state.offeringListModal
	)
	const { createProviderOfferingModal, deleteProviderOfferingModal } =
		useAppSelector((state) => state.providerModal.providerOfferings)
	const {
		addInventoryItemProviderOfferingModal,
		deleteInventoryItemProviderOfferingModal,
	} = useAppSelector((state) => state.inventoryItemModal)

	const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
	const [createModalIsOpen, setCreateModalIsOpen] = useState(false)

	const dispatch = useAppDispatch()

	const companyId = userCompanyData?.companyId || -1

	const formatTableData = (offering: offering) => {
		const response: any = {
			id: {
				content: {
					type: columnTypes.element,
					value: (
						<Link to={`/ri-business/sales/offering/${offering.id}`}>
							{offering.id}
						</Link>
					),
				},
				objectId: offering.id,
			},
			name: {
				content: { type: columnTypes.string, value: offering.name },
				objectId: offering.id,
			},
			price: {
				content: { type: columnTypes.number, value: offering.price.amount },
				objectId: offering.id,
			},
			currency: {
				content: { type: columnTypes.string, value: offering.price.currency },
				objectId: offering.id,
			},
			quantity: {
				content: { type: columnTypes.number, value: offering.quantity },
				objectId: offering.id,
			},
			description: {
				content: { type: columnTypes.string, value: offering.description },
				objectId: offering.id,
			},
			vat: {
				content: { type: columnTypes.number, value: offering.vat },
				objectId: offering.id,
			},
		}

		if (type === 'sales') {
			response['inventoryItems'] = {
				content: <div className="nested-table-arrow">{arrowIcon}</div>,
				objectId: offering.id,
				extraTable: {
					open: false,
					content: (
						<InventoryItemTable
							loadInventoryItems={{
								inventoryItemsUrl: getInventoryItemsForOfferingUrl(offering.id),
							}}
							type="offering"
						/>
					),
				},
			}
		}

		return response
	}

	const onCellValueChange = async (
		objectId: number,
		columnKey: string,
		newValue: tableBodyItemContent
	) => {
		updateCellValue(
			objectId,
			companyId,
			columnKey,
			newValue,
			singleOfferingUrl,
			[
				{
					frontend: 'name',
					backend: 'name',
					type: columnTypes.string,
				},
				{
					frontend: 'price',
					backend: 'price',
					type: columnTypes.number,
				},
				{
					frontend: 'currency',
					backend: 'currency',
					type: columnTypes.string,
				},
				{
					frontend: 'description',
					backend: 'description',
					type: columnTypes.string,
				},
				{
					frontend: 'vat',
					backend: 'vat',
					type: columnTypes.number,
				},
			],
			offerings,
			setOfferings,
			preloadedOfferings?.setOfferings,
			formatOfferingResponse,
			tableTypes.offering
		)
	}

	const getColumns = async () => {
		const { result }: { result: extraColumnsResponse[] } =
			await authorizedRequest(
				companyExtraColumnsUrl(companyId) + '?table_type=offering',
				'GET'
			)
		const editAccess = offerings.some((offering) => offering.editAccess)

		console.warn(editAccess, offerings)

		const defaultColumns: tableHeader[] = [
			{
				key: 'id',
				title: 'id',
				type: columnTypes.number,
				editAccess: false,
			},
			{
				key: 'name',
				title: t('name'),
				type: columnTypes.string,
				editAccess,
			},
			{
				key: 'price',
				title: t('price'),
				type: columnTypes.number,
				editAccess,
			},
			{
				key: 'currency',
				title: t('currency'),
				type: columnTypes.dropdown,
				dropdown: {
					placeholder: '',
					selectedOption: null,
					dropdownOptions: createDropdownOption(tCurrency, currency),
				},
				editAccess,
			},
			{
				key: 'quantity',
				title: t('quantity'),
				type: columnTypes.string,
				editAccess: false,
			},
			{
				key: 'description',
				title: t('description'),
				type: columnTypes.string,
				editAccess,
			},
			{
				key: 'vat',
				title: t('vat'),
				type: columnTypes.string,
				editAccess,
			},
		]

		if (type === 'sales') {
			defaultColumns.push({
				key: 'inventoryItems',
				title: t('inventoryItems'),
				editAccess,
			})
		}

		setColumns([...defaultColumns, ...result.map(formatExtraColumnResponse)])
	}

	const loadData = async (page: number) => {
		try {
			setLoading(true)
			const { result } = await authorizedRequest(
				companyOfferingsUrl(companyId) + `?page=${page}&per_page=${10}`,
				'GET'
			)
			if (result.length > 0) {
				const formatedOfferings: offering[] = result.map(formatOfferingResponse)
				return formatedOfferings
			}

			return []
		} finally {
			setLoading(false)
		}
	}
	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			loadData(page).then((result) => {
				if (result.length > 0) {
					setOfferings([...offerings, ...result])
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
			})
		}
	}
	useEffect(() => {
		if (loadOfferings) {
			handleLoadMore()
		}
	}, [loadOfferings])

	useEffect(() => {
		if (preloadedOfferings) {
			setOfferings(preloadedOfferings.offerings)
		}
	}, [preloadedOfferings])

	useEffect(() => {
		getColumns()

		getTableViewData(
			data,
			tableTypes.offering,
			offerings,
			formatTableData,
			companyId
		).then((newData) => {
			setData([...data, ...newData])
		})
	}, [offerings])

	useEffect(() => {
		if (
			!deleteOfferingModal.modalIsOpen &&
			!deleteProviderOfferingModal.modalIsOpen &&
			!deleteInventoryItemProviderOfferingModal.modalIsOpen
		) {
			setTimeout(() => {
				setDeleteModalIsOpen(false)
			}, 400)
		}
	}, [
		deleteOfferingModal,
		deleteProviderOfferingModal,
		deleteInventoryItemProviderOfferingModal,
	])

	useEffect(() => {
		if (!createOfferingModal.modalIsOpen) {
			setTimeout(() => {
				setCreateModalIsOpen(false)
			}, 400)
		} else if (!createProviderOfferingModal.modalIsOpen) {
			setTimeout(() => {
				setCreateModalIsOpen(false)
			}, 400)
		} else if (!addInventoryItemProviderOfferingModal.modalIsOpen) {
			setTimeout(() => {
				setCreateModalIsOpen(false)
			}, 400)
		}
	}, [
		createOfferingModal,
		createProviderOfferingModal,
		addInventoryItemProviderOfferingModal,
	])

	return loadOfferings ? (
		<>
			<Pagination
				onLoadMore={handleLoadMore}
				onlyLoadOn="bottom"
				loading={loading}
				showLoader={true}
			>
				<Table
					columns={columns}
					data={data}
					onCellValueChange={onCellValueChange}
					tableType={tableTypes.offering}
					deleteAction={
						offerings.some((offering) => offering.deleteAccess)
							? (offeringId) => {
								if (type === 'sales') {
									dispatch(setDeleteOfferingModalIsOpen(true))
									dispatch(setDeleteOfferingId(offeringId))
								} else if (type === 'provider') {
									dispatch(setDeleteProviderOfferingId(offeringId))
									dispatch(setDeleteProviderOfferingModalIsOpen(true))
								} else if (type === 'inventoryItem') {
									dispatch(
										setDeleteInventoryItemProviderOfferingModalIsOpen(true)
									)
									dispatch(
										setDeleteInventoryItemProviderOfferingId(offeringId)
									)
								}

								setDeleteModalIsOpen(true)
							}
							: undefined
					}
					createAction={
						offerings.some((offering) => offering.createAccess)
							? () => {
								if (type === 'sales') {
									dispatch(setCreateOfferingModalIsOpen(true))
								} else if (type === 'provider') {
									dispatch(setCreateProviderOfferingModalIsOpen(true))
								} else if (type === 'inventoryItem') {
									dispatch(setCreateInventoryItemModalIsOpen(true))
								}

								setCreateModalIsOpen(true)
							}
							: undefined
					}
					addColumn={offerings.some((offering) => offering.editAccess)}
				/>
			</Pagination>
			{type === 'sales' && createModalIsOpen && (
				<CreateOfferingModal
					offerings={offerings}
					setOfferings={setOfferings}
				/>
			)}
			{type === 'provider' && createModalIsOpen && (
				<CreateProviderOfferingModal
					offerings={offerings}
					setOfferings={setOfferings}
				/>
			)}
			{type === 'inventoryItem' && createModalIsOpen && (
				<AddInventoryItemProviderOfferingModal
					inventroyItemOfferings={offerings}
					setInventroyItemOfferings={setOfferings}
				/>
			)}

			{type === 'sales' && deleteModalIsOpen && (
				<DeleteOfferingModal
					offerings={offerings}
					setOfferings={setOfferings}
				/>
			)}
			{type === 'provider' && deleteModalIsOpen && (
				<DeleteProviderOfferingModal
					offerings={offerings}
					setOfferings={setOfferings}
				/>
			)}
			{type === 'inventoryItem' && deleteModalIsOpen && (
				<DeleteInventoryItemProviderOfferingModal
					inventroyItemOfferings={offerings}
					setInventroyItemOfferings={setOfferings}
				/>
			)}
		</>
	) : (
		<Table
			columns={columns}
			data={data}
			onCellValueChange={onCellValueChange}
			tableType={tableTypes.offering}
			deleteAction={
				offerings.some((offering) => offering.deleteAccess)
					? (offeringId) => {
						dispatch(setDeleteOfferingModalIsOpen(true))
						dispatch(setDeleteOfferingId(offeringId))
					}
					: undefined
			}
			addColumn={offerings.some((offering) => offering.editAccess)}
		/>
	)
}

export default OfferingTable
