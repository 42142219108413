// styles and icons
import './fileItem.scss'
import { excelFile, pdfFile, powerpointFile, wordFile, zipFile } from '../../../assets/employees/chat/chatIcons'

//components
import VideoPlayer from '../videoPlayer/videoPlayer'
import AudioMessage from '../chat/chatDesktop/messageArea/chatMessage/filesContainer/audioMessage/audioMessage'

//types
import { file } from '../../../types/general/generalTypes'

// react
import { useEffect, useState, FC } from 'react'

//other
import { blobToBase64 } from '../../../assets/general/generalFunctions'
import FilePreviewModal from '../modals/filePreviewModal/filePreviewModal'

const getFile = async (file: file, onLoaded?: () => void) => {
	
	const fileData: { types: string[], file: JSX.Element }[] =
		[
			{
				types: ['video/mp4', 'video/webm'],
				file:
					<div className="file-item-container video">
						<VideoPlayer videoBlob={file.file} onLoaded={onLoaded} />
					</div>
			},
			{
				types: ['audio/mpeg', 'audio/webm;codecs=opus'],
				file:
					<div className="file-item-container audio">
						<AudioMessage onLoaded={onLoaded} audioBlob={file.file} />
					</div>
			},
			{
				types: ['image/png', 'image/jpeg', 'image/jpg', 'png'],
				file:
					<div className="file-item-container img">
						<img src={`${await blobToBase64(file.file)}`} onLoad={onLoaded} />
					</div>
			},
			{
				types: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
				file:
					<div className="file-item-container document">
						{wordFile}
						<div className="file-list-modal-files-name-wrapper">
							<p className="file-list-modal-files-name">{file.fileName}</p>
						</div>
					</div>
			},
			{
				types: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv', 'application/vnd.ms-excel'],
				file:
					<div className="file-item-container document">
						{excelFile}
						<div className="file-list-modal-files-name-wrapper">
							<p className="file-list-modal-files-name">{file.fileName}</p>
						</div>
					</div>
			},
			{
				types: ['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'],
				file:
					<div className="file-item-container document">
						{powerpointFile}
						<div className="file-list-modal-files-name-wrapper">
							<p className="file-list-modal-files-name">{file.fileName}</p>
						</div>
					</div>
			},
			{
				types: ['application/zip'],
				file:
					<div className="file-item-container document">
						{zipFile}
						<div className="file-list-modal-files-name-wrapper">
							<p className="file-list-modal-files-name">{file.fileName}</p>
						</div>
					</div>
			},
			{
				types: ['application/pdf'],
				file:
					<div className="file-item-container document">
						{pdfFile}
						<div className="file-list-modal-files-name-wrapper">
							<p className="file-list-modal-files-name">{file.fileName}</p>
						</div>
					</div>
			}
		]

	const fileItem = fileData.find((elem) => elem.types.indexOf(file.fileType) !== -1)?.file

	return fileItem
}

type fileItemProps = {
	file: file
	onClick?: () => void
	onLoaded?: () => void
	handleDownload?: () => void
}

const FileItem: FC<fileItemProps> = ({ file, onClick, onLoaded, handleDownload }) => {

	const [fileItem, setFileItem] = useState<JSX.Element>()

	const [previewModal, setPreviewModal] = useState<{
		isOpen: boolean
		file: file | undefined
	}>({
		isOpen: false,
		file: undefined
	})

	const preview = (file: file) => {
		setPreviewModal({
			isOpen: true,
			file: file
		})
	}
	
	useEffect(() => {
		console.log(file)
		getFile(file, onLoaded)
			.then((result) => {
				setFileItem(result)
			})
	}, [file])

	return (
		<>
			<div onClick={(e) => {onClick ? onClick() : preview(file)}}>
				{fileItem || <></>}
			</div>
			<FilePreviewModal 
				isOpen={previewModal.isOpen}
				setIsOpen={(value) => {
					setPreviewModal({
						...previewModal,
						isOpen: value
					})
				}}
				file={previewModal.file}
				handleDownload={handleDownload}
			/>
		</>

	)
}

export default FileItem