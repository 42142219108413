// styles
import './createLabelModal.scss'

//react
import { useState, useRef, FC } from 'react'

//components
import Modal from '../../../../../../modals/modal/modal'
import InputField from '../../../../../../inputField/inputField'
import { CirclePicker } from 'react-color'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../../../../customHooks/redux'
import { setCreateLabelModalIsOpen } from '../../../../../../../../redux/general/settings/modals'

//network
import { authorizedRequest } from '../../../../../../../../utils/queries'
import { createLabelUrl } from '../../../../../../../../utils/urls/general/labels/labels'

//translation
import { useTranslation } from 'react-i18next'

//types
import { infoType, label, labelResponse } from '../../../../../../../../types/general/generalTypes'

type createLabelsModalProps = {
	labels: label[]
	setLabels: ( value: label[] ) => void
}

type formErrors =  {
    label?: string
}

const CreateLabelModal: FC<createLabelsModalProps> = ({ labels, setLabels }) => {

	const dispatch = useAppDispatch()
	const { modalIsOpen } = useAppSelector((state) => state.settingsModals.createLabel)
	const { settingsActiveCompanyId } = useAppSelector(state => state.settingsGeneral)

	const { t } = useTranslation('', {keyPrefix: 'general.settings.companySettingsSection.modals.createLabelModal'})

	const formRef = useRef<HTMLFormElement | null>(null)

	const [ name, setName ] = useState('')
	const [ color, setColor ] = useState('')
	const [ errors, setErrors ] = useState<formErrors>({})
	
	const maxCountLetters = 25

	const closeModal = () => {
		setName('')
		setColor('')
		setErrors({})
		dispatch(setCreateLabelModalIsOpen(false))
	}

	const checkErrors = () => {
		let hasErrors = false
		if (color === '') {
			hasErrors = true
			setErrors({...errors, label: t('pleaseChooseAColor')})
		}
		if(name === '') {
			hasErrors = true
			setErrors({...errors, label: t('pleaseEnterTheName')})
		}
		if(name.length > 25) {
			hasErrors = true
			setErrors({...errors, label: t('nameIsTooLong')})
		}
		return hasErrors
	}

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>)=> {
		e.preventDefault()

		const hasErrors = checkErrors()
		if(hasErrors) return
		
		const {result}: {result: labelResponse} = await authorizedRequest(createLabelUrl(settingsActiveCompanyId!), 'POST', 'accessToken', {
			text: name,
			color: color,
		})

		const createdLabel: label = result

		setLabels([createdLabel, ...labels])
		closeModal()
	}
	
	return (
		<Modal 
			title={t('createLabel')}
			open={modalIsOpen} 
			closeModal={closeModal} 
			submitButton={{
				text: t('create'),
				onClick: () => {
					if (formRef.current) formRef.current.requestSubmit()
				}
			}}
		>
			<form className='create-label-form' ref={formRef} onSubmit={handleSubmit}>
				<InputField 
					letterCounter={maxCountLetters}
					label={t('name')}
					value={name} 
					onChange={(e)=> {
						if (e.target.value.length > maxCountLetters) return
						setName(e.target.value)
					}}
					info={errors.label ? {
						type: infoType.error,
						text: errors.label
					} : undefined}
				/>
				
				<label className='color-label'>{t('color')}</label>
				<CirclePicker 
					color={color} 
					onChange={(e)=> {
						setColor(e.hex)
					}}
					width='-webkit-fill-available'
				/>
			</form>
		</Modal>
	)

}

export default CreateLabelModal