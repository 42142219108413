import { FC } from 'react'
import Loading from '../../../loading/loading'


type dashboardStageInfoProps = {
    isLoading: boolean
    cardsLength: number
    stage: string
}

const DashboardStageInfo: FC<dashboardStageInfoProps> = ({ isLoading, cardsLength, stage }) => {
	return (
		<h2 style={{ display: 'flex' }} className='stage-info'>
			<p>{stage} -</p>
			{isLoading ?
				<Loading style={{ minWidth: '0.8vw', maxWidth: '0.8vw', minHeight: '0.8vw', maxHeight: '0.8vw', height: '0.8vw', position: 'relative', top: '0', left: '0', background: 'transparent' }} /> : cardsLength}
		</h2>
	)
}

export default DashboardStageInfo