//components
import ConfirmationModal from '../../../../general/modals/confirmationModal/confirmationModal'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { createPurchaseOrderUrl } from '../../../../../utils/old_urls/purchase/generalUrls'

//react
import { FC, useEffect, useState } from 'react'

//types
import { detailedErrorObject, offering } from '../../../../../types/general/generalTypes'

//redux
import { useAppSelector } from '../../../../../customHooks/redux'

//translation
import { useTranslation } from 'react-i18next'

//other
import { formatDetailedErrorObjectResponse } from '../../../../../assets/general/generalFunctions'

type createOrderComfirmationModalProps = {
	isOpen: boolean
    setIsOpen: (value: boolean) => void
    selectedItems: offering[]
    createOrder: (selectedItems: offering[]) => void
    relationshipId: number
}

const CreateOrderComfirmationModal: FC<createOrderComfirmationModalProps> = ({ isOpen, setIsOpen, selectedItems, createOrder, relationshipId }) => {

	const { t } = useTranslation('', { keyPrefix: 'general.modals.createOrderModal.createOrderConfirmationModal'} )

	const { language } = useAppSelector((state) => state.general)

	const [ description, setDescription ] = useState<string>()
	const [ errorObjects, setErrorObjects ] = useState<detailedErrorObject[]>()


	useEffect(() => {
		if(isOpen){
			authorizedRequest(createPurchaseOrderUrl(relationshipId), 'PATCH', 'accessToken', {
				language: language,
				offerings: selectedItems.map((item) => {
					return {
						offering_id: item.id,
						offering_quantity: item.quantity
					}
				})
			})
				.then((response) => {

					const { result } = response

					const { description, objects } = result
				
					setDescription(description ? t(description) : undefined)

					setErrorObjects(objects.map(formatDetailedErrorObjectResponse))

					// if(result.description === 'bill_created' || result.description === null){
					// 	if(result.description === null){
					// 		setErrorObjects(undefined)
					// 	}
					// }
				})
		}
	}, [isOpen])

	const closeModal = (): void => {
		setIsOpen(false)
		setDescription(undefined)
	}

	const handleSubmit = (): void => {
		createOrder(selectedItems)
		closeModal()
	}

	return (
		<ConfirmationModal
			title={t('createOrder')}
			buttons={[{ text: t('yes'), onClickHandler: handleSubmit }, { text: t('no'), onClickHandler: closeModal }]}
			isOpen={isOpen}
			closeModal={closeModal}
			errorObjects={errorObjects}
			description={description}
		/>
	)
}

export default CreateOrderComfirmationModal