//components
import ConfirmationModal from '../../../modals/confirmationModal/confirmationModal'

//react
import { FC, useState } from 'react'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { singleExtraColumnsUrl } from '../../../../../utils/urls/general/company/company'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../customHooks/redux'
import { setDeleteColumnId, setDeleteColumnModalIsOpen } from '../../../../../redux/general/modals'

//translaction
import { useTranslation } from 'react-i18next'

//types
import { tableHeader } from '../../../../../types/general/generalTypes'

type deleteColumnConfirmationModalProps = {
	columns: tableHeader[]
	setColumns: (value: tableHeader[]) => void
}

const DeleteColumnConfirmationModal: FC<deleteColumnConfirmationModalProps> = ({columns, setColumns}) => {
	const { t } = useTranslation('', {keyPrefix: 'general.table.modals.deleteColumnModal'})  

	const { modalIsOpen, columnId } = useAppSelector(state => state.generalModals.deleteColumnModal)

	const [loading, setLoading] = useState<boolean>(false)

	const dispatch = useAppDispatch()

	const closeModal = (): void => {
		dispatch(setDeleteColumnModalIsOpen(false))
		dispatch(setDeleteColumnId(null))
	}

	const handleSubmit = (): void => {
		if(columnId){
			setLoading(true)
			authorizedRequest(singleExtraColumnsUrl(columnId), 'DELETE')
				.then((response) => {
					setColumns([...columns.filter((column) => {
						// Check if there is an id, and if so, ensure it's not the columnId
						if (column.extraColumn?.id) {
							return column.extraColumn.id !== columnId
						}
						// If there is no id, include the column
						return true
					})])
									
					setLoading(false)
					closeModal()
				})
		}
	}

	return (
		<ConfirmationModal 
			title={t('title')} 
			buttons={[{ text: t('yes'), onClickHandler: handleSubmit }, { text: t('no'), onClickHandler: closeModal }]} 
			isOpen={modalIsOpen}
			closeModal={closeModal} 
			loading={loading}
		/>
	)
}

export default DeleteColumnConfirmationModal