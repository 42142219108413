//styles
import './billList.scss'
import { listIcon, tableIcon } from '../../../assets/general/generalIcons'

// react
import { FC, useEffect, useState } from 'react'

// components
import Layout from '../../../components/general/layout/layout'
import Pagination from '../../../components/general/pagination/pagination'
import ExportBillFileModal from '../../../components/general/modals/bill/exportBillFileModal/exportBillFileModal'
import ImportBillFileSelectorModal from '../../../components/general/modals/bill/importBillFileSelectorModal/importBillFileSelectorModal'
import BillTablePreviewModal from '../../../components/general/modals/bill/billTablePreviewModal/billTablePreviewModal'
import CreateBillModal from '../../../components/general/modals/bill/createBillModal/createBillModal'
import DeleteBillConfirmationModal from '../../../components/general/modals/bill/deleteBillConfirmationModal/deleteBillConfirmationModal'
import PayBillConfirmationModal from '../../../components/general/modals/bill/payBillConfirmationModal/payBillConfirmationModal'
import BillCardList from '../../../components/finance/billList/billCardList/billCardList'
import SwitchButtons from '../../../components/general/switchButtons/switchButtons'
import BillTable from '../../../components/general/tableViews/billTable/billTable'

// translations
import { useTranslation } from 'react-i18next'

// types
import { currency, filterBar, filters, infoPopupTypes, switchButton } from '../../../types/general/generalTypes'
import { bill } from '../../../types/finance/general'

//redux
import { useAppDispatch, useAppSelector } from '../../../customHooks/redux'

import { setImportBillFileSelectorModalIsOpen, setExportBillFileModalIsOpen, setCreateBillModalIsOpen } from '../../../redux/general/modals'
//network
import { authorizedRequest } from '../../../utils/queries'
import { billsGetFiltersResourceUrl, billsGetOverviewResourceUrl, companyBillUrl } from '../../../utils/urls/finance/bill/bills'

// other
import { formatFilters, createFilteringRequest, currencyToFormattedString } from '../../../assets/general/generalFunctions'
import { formatBillResponse } from '../../../assets/finance/general/generalFunctions'

const BillList: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'finance.billList' })

	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const dispatch = useAppDispatch()

	const [billsOverview, setBillsOverview] = useState<{ title: string, text: string }[]>([])
	const [bills, setBills] = useState<bill[]>([])

	const [searchRequest, setSearchRequest] = useState('')
	const [filterBar, setFilterBar] = useState<filterBar>()
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const [isFilterBarLoading, setFilterBarLoading] = useState<boolean>(false)
	const [isOverviewLoading, setOverviewLoading] = useState<boolean>(false)


	const [activeViewId, setActiveViewId] = useState(0)

	const viewButtons: switchButton[] = [
		{
			id: 0,
			icon: listIcon
		},
		{
			id: 1,
			icon: tableIcon
		}
	]


	const titleUrls = [
		{
			url: '/ri-business/finance/bill-list',
			title: t('bills')
		}
	]

	const infoPopup = {
		content: [{
			title: 'bill_list_info_title',
			description: 'bill_list_info_description',
			link: '/ri-manual/finance/bills'
		}],
		type: infoPopupTypes.INFO
	}

	const loadFilterBar = async () => {
		try {
			setFilterBarLoading(true)
			const { result } = await authorizedRequest(billsGetFiltersResourceUrl(companyId), 'GET')

			const filterResult = result.filters
			const createAccess = result.create_access
			const formatedFilters: filters[] = formatFilters(filterResult)

			setFilterBar({
				// importButton: () => {
				// 	dispatch(setImportBillFileSelectorModalIsOpen(true))
				// },
				// exportButton: () => {
				// 	dispatch(setExportBillFileModalIsOpen(true))
				// },
				filters: formatedFilters,
				addButton: createAccess && {
					text: t('create'),
					active: true,
					onClick: () => dispatch(setCreateBillModalIsOpen(true))
				},
				onSearch: onSearch
			})
		} finally {
			setFilterBarLoading(false)
		}
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		try {
			setLoading(true)
			const filteringRequest = createFilteringRequest(filters)

			const { result } = await authorizedRequest(companyBillUrl(companyId) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')

			if (result.length > 0) {
				const formatedBills: bill[] = result.map(formatBillResponse)
				return formatedBills
			}

			return []

		} finally {
			setLoading(false)
		}
	}

	const loadOverview = async () => {
		try {
			setOverviewLoading(true)
			const { result } = await authorizedRequest(billsGetOverviewResourceUrl(companyId), 'GET')

			setBillsOverview([
				{
					title: 'totalBills',
					text: result.total_bills
				},
				...result.amount.map((amount: { value: number, currency: currency }) => {
					return {
						title: 'total',
						text: currencyToFormattedString(amount.value, amount.currency)
					}
				})
			])
		} finally {
			setOverviewLoading(false)
		}

	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		loadOverview()
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		loadData(1, searchValue, filters)
			.then((result) => {
				setBills([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
			})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					setBills([...bills, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				})
		}
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()
	}, [])

	const renderComponent = () => {
		switch(activeViewId) {
		case 0: 
			return <BillCardList isOverviewLoading={isOverviewLoading} billsOverview={billsOverview} bills={bills} loading={loading} />
		case 1:
			return <BillTable preloadedBills={{
				bills: bills, 
				setBills: setBills
			}} />
		}
	}

	return (
		<Layout
			header={{
				avatar: true,
			}}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
			isFilterBarLoading={isFilterBarLoading}
		>
			<div className='bill-list'>
				<SwitchButtons switchButtons={viewButtons} activeSwitchButtonId={activeViewId} setActiveSwitchButtonId={setActiveViewId} size='medium' />
				<Pagination loading={loading && bills.length > 0} onLoadMore={handleLoadMore} showLoader={false}>
					{renderComponent()}
				</Pagination>
			</div>
			<ExportBillFileModal />
			<BillTablePreviewModal setBills={setBills} bills={bills} />
			<CreateBillModal setBills={setBills} bills={bills} />
			<ImportBillFileSelectorModal />
			<DeleteBillConfirmationModal setBills={setBills} bills={bills} />
			<PayBillConfirmationModal setBills={setBills} bills={bills} />
		</Layout>
	)
}

export default BillList
