//components
import Table from '../../table/table'
import Pagination from '../../pagination/pagination'

import ImportCustomerDocumentFileSelectorModal from '../../../sales/customer/customerDocuments/modals/importCustomerDocumentFileSelectorModal/importCustomerDocumentFileSelectorModal'
import DeleteCustomerDocumentConfirmationModal from '../../../sales/customer/customerDocuments/modals/deleteCustomerDocumentConfirmationModal/deleteCustomerDocumentConfirmationModal'

import ImportProviderDocumentFileSelectorModal from '../../../purchase/provider/providerDocuments/modals/ImportProviderDocumentFileSelectorModal/ImportProviderDocumentFileSelectorModal'
import DeleteProviderDocumentConfirmationModal from '../../../purchase/provider/providerDocuments/modals/deleteProviderDocumentConfirmationModal/deleteProviderDocumentConfirmationModal'

import ImportEmployeeFileFileSelectorModal from '../../employee/employeeFiles/modals/importEmployeeFileFileSelectorModal/importEmployeeFileFileSelectorModal'
import DeleteEmployeeFileConfirmationModal from '../../employee/employeeFiles/modals/deleteEmployeeFileConfirmationModal/deleteEmployeeFileConfirmationModal'

import ImportProjectDocumentFileSelectorModal from '../../../projects/project/projectDocuments/modals/ImportProviderDocumentFileSelectorModal/ImportProjectDocumentFileSelectorModal'
import DeleteProjectDocumentConfirmationModal from '../../../projects/project/projectDocuments/modals/deleteProviderDocumentConfirmationModal/deleteProjectDocumentConfirmationModal'

//react
import { FC, useEffect, useState } from 'react'

//types
import { columnTypes, document, tableBodyItem, tableBodyItemContent, tableHeader, tableTypes } from '../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../utils/queries'
import { singleCustomerDocumentUrl } from '../../../../utils/urls/sales/customer' 
import { singleProviderDocumentUrl } from '../../../../utils/urls/purchases/provider'
import { singleEmployeeFileUrl } from '../../../../utils/urls/employees/employee'

//redux
import { useAppSelector, useAppDispatch } from '../../../../customHooks/redux'
import { setDeleteCustomerDocumentId, setDeleteCustomerDocumentModalIsOpen, setImportCustomerDocumentFileSelectorModalIsOpen } from '../../../../redux/sales/customer/modals'
import { setDeleteProviderDocumentId, setDeleteProviderDocumentModalIsOpen, setImportProviderDocumentFileSelectorModalIsOpen } from '../../../../redux/purchase/provider/modals'
import { setDeleteEmployeeFileId, setDeleteEmployeeFileModalIsOpen, setImportEmployeeFileFileSelectorModalIsOpen } from '../../../../redux/general/employee/modals'
import { setDeleteProjectDocumentId, setDeleteProjectDocumentModalIsOpen, setImportProjectDocumentFileSelectorModalIsOpen } from '../../../../redux/projects/pipeline/modals'

//translation
import { useTranslation } from 'react-i18next'

//other
import { formatBytes, formatDocumentResponse, getTableViewData } from '../../../../assets/general/generalFunctions'
type documentTableProps = {
    preloadedDocuments?: {
        documents: document[]
        setDocuments: (value: document[]) => void
    }
    loadDocuments?: {
        documentUrl: string 
    }
    parent: {
        id: number
        type: 'customer' | 'provider' | 'employee' | 'project'
    } 
    editAccess?: boolean
    deleteAccess?: boolean
    createAccess?: boolean
}

const DocumentTable: FC<documentTableProps> = ({ preloadedDocuments, loadDocuments, parent, editAccess, deleteAccess, createAccess }) => {

	const { t } = useTranslation('', { keyPrefix: 'general.documentTable' })

	const [ columns, setColumns ] = useState<tableHeader[]>([])
	const [ data, setData ] = useState<Record<string, tableBodyItem>[]>([])

	const [ loading, setLoading ] = useState(false)
	const [ lastPage, setLastPage ] = useState(false)
	const [ page, setPage ] = useState(1) 

	const [ deleteModalIsOpen, setDeleteModalIsOpen ] = useState(false)
	const [ createModalIsOpen, setCreateModalIsOpen ] = useState(false)

	const [ documents, setDocuments ] = useState<document[]>([])
    
	const {userCompanyData} = useAppSelector(state => state.general)

	const { deleteCustomerDocumentModal, importCustomerDocumentFileSelectorModal } = useAppSelector(state => state.customerModal.customerDocuments)
	const { deleteProviderDocumentModal, importProviderDocumentFileSelectorModal } = useAppSelector(state => state.providerModal.providerDocuments)
	const { deleteEmployeeFileModal, importEmployeeFileFileSelectorModal } = useAppSelector(state => state.employeeModals.employeeFiles)
	const { deleteProjectDocumentModal, importProjectDocumentFileSelectorModal } = useAppSelector((state) => state.pipelineModals.projectDocuments)

	const dispatch = useAppDispatch()


	const companyId = userCompanyData?.companyId || -1

	const formatTableData = (document: document): Record<string, tableBodyItem> => {
		const blobUrl = URL.createObjectURL(document.file)
    
		return {
			id: {
				content: {
					type: columnTypes.string,
					value: document.id.toString()
				},
				objectId: document.id
			},
			name: {
				content: {
					type: columnTypes.string,
					value: document.fileName
				},
				objectId: document.id
			},
			type: {
				content: {
					type: columnTypes.string,
					value: document.fileType
				},
				objectId: document.id
			},
			download: {
				content: {
					type: columnTypes.element,
					value: <a href={blobUrl} download={document.fileName}>{t('download')}</a>
				},
				objectId: document.id
			},
			size: {
				content: {
					type: columnTypes.string,
					value: formatBytes(document.file.size)
				},
				objectId: document.id
			}
		}
	}
    
	const onCellValueChange = async (objectId: number, columnKey: string, newValue: tableBodyItemContent) => {
		if(editAccess){

			const index = documents.findIndex((document) => document.id === objectId)

			const document = documents[index]

			const key = {
				name: 'name'
			}[columnKey]

			let url = ''

			switch(parent.type){
			case 'customer':
				url = singleCustomerDocumentUrl(objectId)
				break
			case 'provider':
				url = singleProviderDocumentUrl(objectId)
				break
			case 'employee':
				url = singleEmployeeFileUrl(objectId)
				break
			default: 
				return
			}

			const response = await authorizedRequest(url, 'PUT', 'accessToken', {
				...document,
				[key || '']: newValue.value
			})


			const { result } = response


			documents[index] = formatDocumentResponse(result)

			setDocuments([...documents])
		}
	}

	const getColumns = async () => {

		setColumns([
			{ key: 'id', title: t('id'), editAccess: false },
			{ key: 'name', title: t('name'), type: columnTypes.string, editAccess: editAccess },
			{ key: 'type', title: t('type'), editAccess: false },
			{ key: 'download', title: t('download'),  editAccess: false },
			{ key: 'size', title: t('size'), editAccess: false }
		])

	}


	useEffect(() => {
		getColumns()
		getTableViewData(
			data,
            parent.type as tableTypes,
            documents,
            formatTableData,
            companyId
		).then((newData) => {
			setData([...data, ...newData])
		})
	}, [documents])

	const loadData = async (page: number) => {
		if(loadDocuments){
			try {
				setLoading(true)
    
				const { result } = await authorizedRequest(loadDocuments.documentUrl + `?page=${page}&per_page=${10}`, 'GET')
    
				if (result.length > 0) {
					const formatedData: document[] = result.map(formatDocumentResponse)
					return formatedData
				}
    
				return []
    
			} finally {
				setLoading(false)
			}
		}
	}
	const handleLoadMore = () => {

		if (!loading && !lastPage) {
			loadData(page)
				.then((result) => {

					if(result){
						setDocuments([...documents, ...result])
						if (result.length > 0) {
							setPage(page + 1)
						} else {
							setLastPage(true)
						}
					}
				})
		}
	}

	useEffect(() => {
		if(loadDocuments){
			handleLoadMore()
		}
	}, [loadDocuments])

	useEffect(() => {
		if(preloadedDocuments){
			setDocuments(preloadedDocuments.documents)
		}
	}, [preloadedDocuments])

	useEffect(() => {

		if(parent.type === 'customer' && !deleteCustomerDocumentModal.modalIsOpen){
			setTimeout(() => {
				setDeleteModalIsOpen(false)
			}, 400)
		} else if(parent.type === 'provider' && !deleteProviderDocumentModal.modalIsOpen){
			setTimeout(() => {
				setDeleteModalIsOpen(false)
			}, 400)
		} else if(parent.type === 'employee' && !deleteEmployeeFileModal.modalIsOpen){
			setTimeout(() => {
				setDeleteModalIsOpen(false)
			}, 400)
		}else if(parent.type === 'project' && !deleteProjectDocumentModal.modalIsOpen){
			setTimeout(() => {
				setDeleteModalIsOpen(false)
			}, 400)
		}

	}, [deleteCustomerDocumentModal, deleteProviderDocumentModal, deleteEmployeeFileModal, deleteProjectDocumentModal])


	useEffect(() => {
		if(parent.type === 'customer' && !importCustomerDocumentFileSelectorModal.modalIsOpen){
			setTimeout(() => {
				setCreateModalIsOpen(false)
			}, 400)
		} else if(parent.type === 'provider' && !importProviderDocumentFileSelectorModal.modalIsOpen){
			setTimeout(() => {
				setCreateModalIsOpen(false)
			}, 400)
		} else if(parent.type === 'employee' && !importEmployeeFileFileSelectorModal.modalIsOpen){
			setTimeout(() => {
				setCreateModalIsOpen(false)
			}, 400)
		}else if(parent.type === 'project' && !importProjectDocumentFileSelectorModal.modalIsOpen){
			setTimeout(() => {
				setCreateModalIsOpen(false)
			}, 400)
		}

	}, [importCustomerDocumentFileSelectorModal, importProviderDocumentFileSelectorModal, importEmployeeFileFileSelectorModal, importProjectDocumentFileSelectorModal])


	return (
		loadDocuments ? 
			<>
				<Pagination onLoadMore={handleLoadMore} onlyLoadOn='bottom' loading={loading} showLoader={true}>
					<Table
						columns={columns}
						data={data}
						setData={setData}
						onCellValueChange={onCellValueChange}
						tableType={tableTypes.document}
						deleteAction={deleteAccess ? (id) => {

							if(parent.type === 'customer'){
								dispatch(setDeleteCustomerDocumentId(id))
								dispatch(setDeleteCustomerDocumentModalIsOpen(true))
							}else if(parent.type === 'provider'){
								dispatch(setDeleteProviderDocumentId(id))
								dispatch(setDeleteProviderDocumentModalIsOpen(true))
							}else if(parent.type === 'employee'){
								dispatch(setDeleteEmployeeFileId(id))
								dispatch(setDeleteEmployeeFileModalIsOpen(true))
							}else if(parent.type === 'project'){
								dispatch(setDeleteProjectDocumentId(id))
								dispatch(setDeleteProjectDocumentModalIsOpen(true))
							}

							setDeleteModalIsOpen(true)

						} : undefined}

						createAction={createAccess ? () => {
							if(parent.type === 'customer'){
								dispatch(setImportCustomerDocumentFileSelectorModalIsOpen(true))
							}else if(parent.type === 'provider'){
								dispatch(setImportProviderDocumentFileSelectorModalIsOpen(true))
							}else if(parent.type === 'employee'){
								dispatch(setImportEmployeeFileFileSelectorModalIsOpen(true))
							}else if(parent.type === 'project'){
								dispatch(setImportProjectDocumentFileSelectorModalIsOpen(true))
							}

							setCreateModalIsOpen(true)
						} : undefined}
					/>
				</Pagination>
				{ parent.type === 'customer' && createModalIsOpen && <ImportCustomerDocumentFileSelectorModal documentsData={documents} setDocumentsData={setDocuments} customerId={parent.id} /> }
				{ parent.type === 'customer' && deleteModalIsOpen && <DeleteCustomerDocumentConfirmationModal documentsData={documents} setDocumentsData={setDocuments} /> }

				{ parent.type === 'provider' && createModalIsOpen && <ImportProviderDocumentFileSelectorModal documentsData={documents} setDocumentsData={setDocuments} providerId={parent.id}/> }
				{ parent.type === 'provider' && deleteModalIsOpen && <DeleteProviderDocumentConfirmationModal documentsData={documents} setDocumentsData={setDocuments} /> }

				{ parent.type === 'employee' && createModalIsOpen && <ImportEmployeeFileFileSelectorModal employeeFiles={documents} setEmployeeFiles={setDocuments} /> }
				{ parent.type === 'employee' && deleteModalIsOpen && <DeleteEmployeeFileConfirmationModal files={documents} setFiles={setDocuments} /> }
				
				{ parent.type === 'project' && createModalIsOpen && <ImportProjectDocumentFileSelectorModal documentsData={documents} setDocumentsData={setDocuments} projectId={parent.id}/> }
				{ parent.type === 'project' && deleteModalIsOpen && <DeleteProjectDocumentConfirmationModal documentsData={documents} setDocumentsData={setDocuments} /> }
			</>
			:
			<Table
				columns={columns}
				data={data}
				setData={setData}
				onCellValueChange={onCellValueChange}
				tableType={tableTypes.document}
				deleteAction={deleteAccess ? (id) => {

					if(parent.type === 'customer'){
						dispatch(setDeleteCustomerDocumentId(id))
						dispatch(setDeleteCustomerDocumentModalIsOpen(true))
					}else if(parent.type === 'provider'){
						dispatch(setDeleteProviderDocumentId(id))
						dispatch(setDeleteProviderDocumentModalIsOpen(true))
					}else if(parent.type === 'employee'){
						dispatch(setDeleteEmployeeFileId(id))
						dispatch(setDeleteEmployeeFileModalIsOpen(true))
					}else if(parent.type === 'project'){
						dispatch(setDeleteProjectDocumentId(id))
						dispatch(setDeleteProjectDocumentModalIsOpen(true))
					}

					setDeleteModalIsOpen(true)

				} : undefined}

				createAction={createAccess ? () => {
					if(parent.type === 'customer'){
						dispatch(setImportCustomerDocumentFileSelectorModalIsOpen(true))
					}else if(parent.type === 'provider'){
						dispatch(setImportProviderDocumentFileSelectorModalIsOpen(true))
					}else if(parent.type === 'employee'){
						dispatch(setImportEmployeeFileFileSelectorModalIsOpen(true))
					}else if(parent.type === 'project'){
						dispatch(setImportProjectDocumentFileSelectorModalIsOpen(true))
					}

					setCreateModalIsOpen(true)
				} : undefined}
			/>
	)
}

export default DocumentTable