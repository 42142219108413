import { employee, finance, project, purchase, sale, warehouse, template, timeManagement, emailMarketing, cloud, security, bulb, chat } from '../../../../../../assets/general/generalIcons'
import { helpUserIcon } from '../../../../assets/icons/icons'

export const links = [
	{
		title: 'products',
		href: '/app-information',
		dropdownElements: [
			{
				title: 'projects',
				image: 'projects',
				icon: project,
				links:[
					{
						text: 'pipelines',
						href: '/app-information/projects/pipelines',
						title: 'pipelinesInfoTitle',
						description: 'pipelinesInfoDescription',
						icon: project
					},
					{
						text: 'taskBoards',
						href: '/app-information/projects/taskBoards',
						title: 'taskBoardsInfoTitle',
						description: 'taskBoardsInfoDescription',
						icon: project
					},
					{
						text: 'productivity',
						href: '/app-information/projects/productivity',
						title: 'productivityInfoTitle',
						description: 'productivityInfoDescription',
						icon: project
					},
					{
						text: 'internalChat',
						href: '/app-information/projects/internalChat',
						title: 'internalChatInfoTitle',
						description: 'internalChatInfoDescription',
						icon: project
					}
				]
			},
			{
				title: 'sales',
				image: 'sales',
				icon: sale,
				links:[
					{
						text: 'customers',
						href: '/app-information/sales/customers',
						title: 'customersInfoTitle',
						description: 'customersInfoDescription',
						icon: sale
					},
					{
						text: 'orders',
						href: '/app-information/sales/orders',
						title: 'ordersInfoTitle',
						description: 'ordersInfoDescription',
						icon: sale
					},
					{
						text: 'proposals',
						href: '/app-information/sales/proposals',
						title: 'proposalsInfoTitle',
						description: 'proposalsInfoDescription',
						icon: sale
					},
					{
						text: 'salesStatistics',
						href: '/app-information/sales/salesStatistics',
						title: 'salesStatisticsInfoTitle',
						description: 'salesStatisticsInfoDescription',
						icon: sale
					}
				]
			},
			{
				title: 'purchases',
				image: 'purchases',
				icon: purchase,
				links:[
					{
						text: 'providers',
						href: '/app-information/purchases/providers',
						title: 'providersInfoTitle',
						description: 'providersInfoDescription',
						icon: purchase
					},
					{
						text: 'purchaseFormation',
						href: '/app-information/purchases/purchaseFormation',
						title: 'purchaseFormationInfoTitle',
						description: 'purchaseFormationInfoDescription',
						icon: purchase
					},
					{
						text: 'priceAnalytics',
						href: '/app-information/purchases/priceAnalytics',
						title: 'priceAnalyticsInfoTitle',
						description: 'priceAnalyticsInfoDescription',
						icon: purchase
					}
				]
			},
			{
				title: 'employees',
				image: 'employees',
				icon: employee,
				links:[
					{
						text: 'employeeList',
						href: '/app-information/employees/employeeList',
						title: 'employeeListInfoTitle',
						description: 'employeeListInfoDescription',
						icon: employee
					},
					{
						text: 'organizationalStructure',
						href: '/app-information/employees/organizationalStructure',
						title: 'organizationalStructureInfoTitle',
						description: 'organizationalStructureInfoDescription',
						icon: employee
					},
					{
						text: 'taskControl',
						href: '/app-information/employees/taskControl',
						title: 'taskControlInfoTitle',
						description: 'taskControlInfoDescription',
						icon: employee
					}
				]
			},
			{
				title: 'finance',
				image: 'finance',
				icon: finance,
				links:[
					{
						text: 'projectBudgeting',
						href: '/app-information/finance/projectBudgeting',
						title: 'projectBudgetingInfoTitle',
						description: 'projectBudgetingInfoDescription',
						icon: finance
					},
					{
						text: 'transactions',
						href: '/app-information/finance/transactions',
						title: 'transactionsInfoTitle',
						description: 'transactionsInfoDescription',
						icon: finance
					},
					{
						text: 'invoices',
						href: '/app-information/finance/invoices',
						title: 'invoicesInfoTitle',
						description: 'invoicesInfoDescription',
						icon: finance
					},
					{
						text: 'documents',
						href: '/app-information/finance/documents',
						title: 'documentsInfoTitle',
						description: 'documentsInfoDescription',
						icon: finance
					}
				]
			},
			{
				title: 'warehouses',
				image: 'warehouses',
				icon: warehouse,
				links:[
					{
						text: 'warehouseList',
						href: '/app-information/warehouses/warehouseList',
						title: 'warehouseListInfoTitle',
						description: 'warehouseListInfoDescription',
						icon: warehouse
					},
					{
						text: 'inventory',
						href: '/app-information/warehouses/inventory',
						title: 'inventoryInfoTitle',
						description: 'inventoryInfoDescription',
						icon: warehouse
					},
					{
						text: 'shipmentsAndMovings',
						href: '/app-information/warehouses/shipmentsAndMovings',
						title: 'shipmentsAndMovingsInfoTitle',
						description: 'shipmentsAndMovingsInfoDescription',
						icon: warehouse
					}
				]
			}
		]	
	},
	{
		title: 'solutions',
		href: '/solutions',
		dropdownElements: [
			{
				title: 'emailMarketing',
				image: 'emailMarketing',
				icon: emailMarketing,
				links:[
					{
						text: 'templates',
						href: '/solutions/emailMarketing/templates',
						title: 'templatesInfoTitle',
						description: 'templatesInfoDescription',
						icon: emailMarketing
					},
					{
						text: 'sendingLetters',
						href: '/solutions/emailMarketing/sendingLetters',
						title: 'sendingLettersInfoTitle',
						description: 'sendingLettersInfoDescription',
						icon: emailMarketing
					},
					{
						text: 'personalization',
						href: '/solutions/emailMarketing/personalization',
						title: 'personalizationInfoTitle',
						description: 'personalizationInfoDescription',
						icon: emailMarketing
					},
					{
						text: 'massMailings',
						href: '/solutions/emailMarketing/massMailings',
						title: 'massMailingsInfoTitle',
						description: 'massMailingsInfoDescription',
						icon: emailMarketing
					}
				]
			},
			{
				title: 'timeManagement',
				image: 'timeManagement',
				icon: timeManagement,
				links:[
					{
						text: 'timeControl',
						href: '/solutions/timeManagement/timeControl',
						title: 'timeControlInfoTitle',
						description: 'timeControlInfoDescription',
						icon: timeManagement
					},
					{
						text: 'hourlyPay',
						href: '/solutions/timeManagement/hourlyPay',
						title: 'hourlyPayInfoTitle',
						description: 'hourlyPayInfoDescription',
						icon: timeManagement
					},
					{
						text: 'employeesAvailable',
						href: '/solutions/timeManagement/employeesAvailable',
						title: 'employeesAvailableInfoTitle',
						description: 'employeesAvailableInfoDescription',
						icon: timeManagement
					}
				]
			},
			{
				title: 'documentTemplates',
				image: 'documentTemplates',
				icon: template,
				links:[
					{
						text: 'individualTemplates',
						href: '/solutions/documentTemplates/individualTemplates',
						title: 'individualTemplatesInfoTitle',
						description: 'individualTemplatesInfoDescription',
						icon: template
					},
					{
						text: 'uniqueDocuments',
						href: '/solutions/documentTemplates/uniqueDocuments',
						title: 'uniqueDocumentsInfoTitle',
						description: 'uniqueDocumentsInfoDescription',
						icon: template
					},
					{
						text: 'generateInvoices',
						href: '/solutions/documentTemplates/generateInvoices',
						title: 'generateInvoicesInfoTitle',
						description: 'generateInvoicesInfoDescription',
						icon: template
					}
				]
			},
			{
				title: 'securityAndFunctionality',
				image: 'securityAndFunctionality',
				icon: security,
				links:[
					{
						text: 'accessControl',
						href: '/solutions/securityAndFunctionality/accessControl',
						title: 'accessControlInfoTitle',
						description: 'accessControlInfoDescription',
						icon: security
					},
					{
						text: 'flexibleRoles',
						href: '/solutions/securityAndFunctionality/flexibleRoles',
						title: 'flexibleRolesInfoTitle',
						description: 'flexibleRolesInfoDescription',
						icon: security
					}
				]
			},
			{
				title: 'cloudStorage',
				image: 'cloudStorage',
				icon: cloud,
				links:[
					{
						text: 'saveDocuments',
						href: '/solutions/cloudStorage/saveDocuments',
						title: 'saveDocumentsInfoTitle',
						description: 'saveDocumentsInfoDescription',
						icon: cloud
					},
					{
						text: 'unlimitedStorage',
						href: '/solutions/cloudStorage/unlimitedStorage',
						title: 'unlimitedStorageInfoTitle',
						description: 'unlimitedStorageInfoDescription',
						icon: cloud
					}
				]
			}
		]	
	},
	{
		title: 'help', 
		href: '/help',
		dropdownElements: [
			{
				title: 'support',
				image: '',
				icon: helpUserIcon,
				links: [
					{
						text: 'fillOutTheForm',
						href: '/help/support/form',
						title: '',
						description: '',
						icon: cloud
					}
				]
			},
			{
				title: 'start-of-work',
				icon: bulb,
				image: '',
				links:[
					{
						text: 'steps',
						href: '/help/start-of-work/steps',
						title: '',
						description: '',
						icon: bulb
					}
				]
			},
			{
				title: 'ri-manual',
				link: 'employees',
				icon: chat,
				image: '',
				links:[
					{
						text: 'information',
						href: '/help/ri-manual/employees',
						title: '',
						description: '',
						icon: bulb
					}
				]
			}
		]	
	},
	{
		title: 'prices', 
		href: '/prices',
		dropdownElements: [
			{
				title: 'prices',
				icon: bulb,
				image: '',
				links:[
					{
						text: 'prices',
						href: '/prices/prices',
						title: '',
						description: '',
						icon: sale
					}
				]
			}
		]
	}
]