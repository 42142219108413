//styles
import './home.scss'
import { playAudioIcon } from '../../../assets/employees/chat/chatIcons'

//redux
import { useDispatch } from 'react-redux'
import { setCreateCompanyGuidanceModals } from '../../../redux/general/modals'

// components
import Layout from '../../../components/general/layout/layout'

//translation
import { useTranslation } from 'react-i18next'

const Home = () => {
	const dispatch = useDispatch()

	const { t } = useTranslation('', { keyPrefix: 'home' })

	const titleUrls = [
		{
			url: '/ri-business/',
			title: t('home')
		}
	]

	return (
		<Layout
			header={{
				avatar: true,
			}}
			titleUrls={titleUrls}
		>
			<div className="home">
				<div className="home-guidance-container">
					<div className="home-guidance-item">
						<p className="home-guidance-item-order">
							1.
						</p>
						<p className="home-guidance-item-title">
							create company
						</p>
						<div className="home-guidance-item-action" onClick={() => {
							dispatch(setCreateCompanyGuidanceModals([true, false]))
						}} >
							{playAudioIcon}
						</div>
					</div>
					<div className="home-guidance-item">
						<p className="home-guidance-item-order">
							1.
						</p>
						<p className="home-guidance-item-title">
							create company
						</p>
						<div className="home-guidance-item-action">
							{playAudioIcon}
						</div>
					</div>
					<div className="home-guidance-item">
						<p className="home-guidance-item-order">
							1.
						</p>
						<p className="home-guidance-item-title">
							create company
						</p>
						<div className="home-guidance-item-action">
							{playAudioIcon}
						</div>
					</div>
					<div className="home-guidance-item">
						<p className="home-guidance-item-order">
							1.
						</p>
						<p className="home-guidance-item-title">
							create company
						</p>
						<div className="home-guidance-item-action">
							{playAudioIcon}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Home