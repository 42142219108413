//style
import './timeTracker.scss'

//componenets
import TimeTrackerItem from '../../../general/timeTrackerItem/timeTrackerItem'
import CreateTimeTrackerModal from './modals/createTimeTrackerModal/createTimeTrackerModal'
import DeleteTimeTrackerModal from './modals/deleteTimeTrackerModal/deleteTimeTrackerModal'

//react
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

//types
import { executor, filterBar } from '../../../../types/general/generalTypes'

//redux
import { useAppDispatch } from '../../../../customHooks/redux'
import { setCreateTimeTrackerModalIsOpen } from '../../../../redux/projects/task/modals'

//network
import { singleTaskTimeTracker } from '../../../../utils/urls/projects/task'
import { authorizedRequest } from '../../../../utils/queries'

//other
import { formatExecutorResponse } from '../../../../assets/projects/projectsFunctions'

//translation
import { useTranslation } from 'react-i18next'

type timeTrackerProps = {
    setFilterBar: (value: filterBar | undefined) => void
}

const TimeTracker: FC<timeTrackerProps> = ({ setFilterBar }) => {

	const { t } = useTranslation('', {keyPrefix: 'projects.task.timeTrackerTab'})

	const [ executors, setExecutors ] = useState<executor[]>([])

	const taskId = parseInt(useParams().taskId || '-1')

	const dispatch = useAppDispatch()

	useEffect(() => {
		if(taskId && taskId>-1){
			authorizedRequest(singleTaskTimeTracker(taskId), 'GET')
				.then((response) => {
					const { result } = response
					setExecutors(result.map(formatExecutorResponse))
				})
		}
	}, [])

	useEffect(() => {
		if(taskId && taskId>-1){
			authorizedRequest(singleTaskTimeTracker(taskId), 'PATCH')
				.then((response) => {
					const { result } = response
					setFilterBar({
						addButton: {
							text: t('create'),
							onClick: () => {
								dispatch(setCreateTimeTrackerModalIsOpen(true))
							},
							active: true
						},
						createAccess: result
					})
				})
		}
        
		return () => {
			setFilterBar(undefined)
		}
	}, [])

	return(
		<div className="time-tracker">
			{
				executors.map((executor) => {
					return (
						<TimeTrackerItem
							executorId={executor.id}
							avatar={executor.avatar}
							timerStartDate={executor.timerStartDate}
							name={executor.name}
							position={executor.position.name}
							wage={executor.wage}
							hoursSpent={executor.hoursSpent}
							relevantCurrency={executor.currency}
							deleteAccess={executor.deleteAccess}
							editAccess={executor.editAccess}
							isProductivityTrackingActive={executor.isProductivityTrackerActive}
							timeUsage={executor.timeUsage}
						/>
					)
				})
			}
			<CreateTimeTrackerModal timeTrackers={executors} setTimeTrackers={setExecutors} />
			<DeleteTimeTrackerModal timeTrackers={executors} setTimeTrackers={setExecutors} />
		</div>
	)
}   

export default TimeTracker