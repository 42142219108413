// styles
import './chartContainer.scss'
import { barChart, calendar, linearDiagram, pieChart } from '../../../../assets/general/generalIcons'

// component
import SwitchButtons from '../../switchButtons/switchButtons'
import ChartLegend from './chartLegend/chartLegend'
import BarChart from '../barChart/barChart'
import LinearDiagram from '../linearDiagram/linearDiagram'
import PieChart from '../pieChart/pieChart'
import CalendarContainer from '../../calendarContainer/calendarContainer'
import Loading from '../../loading/loading'

// react
import { FC, useEffect, useState } from 'react'

//translation 
import { useTranslation } from 'react-i18next'

//types
import { chartItem, chartViewOptions, chartDurationOptions, legendItem } from '../../../../types/general/generalTypes'

type view = {
	id: number
	option: chartViewOptions | null
	icon: JSX.Element | null
}

type chartContainerProps = {
	title: string
	subtitle?: string
	className?: string
	loadData: (startDate: Date, endDate: Date, duration: chartDurationOptions) => Promise<{
		xAxisLabels: string[]
		chartItems: chartItem[]
	}>
	viewOptions?: chartViewOptions[]
	onLegendChange?: (activeLegends: legendItem[]) => void
	showDurationOptions?: boolean
}

const ChartContainer: FC<chartContainerProps> = ({ title, subtitle, className, viewOptions, loadData, onLegendChange, showDurationOptions=true }) => {
	const { t } = useTranslation('', { keyPrefix: 'general.statistics.switchButtons' })

	const substractTimeFromDate = (date: Date, duration: chartDurationOptions): Date => {
		const newDate = new Date(date)

		switch (duration) {
		case chartDurationOptions.DAILY:
			newDate.setDate(date.getDate() - 1)
			break
		case chartDurationOptions.WEEKLY:
			newDate.setDate(date.getDate() - 7)
			break
		case chartDurationOptions.MONTHLY:
			newDate.setMonth(date.getMonth() - 1)
			break
		case chartDurationOptions.YEARLY:
			newDate.setFullYear(date.getFullYear() - 1)
			break
		default:
			throw new Error('Invalid duration option')
		}

		return newDate
	}

	const chartViewOptionList: view[] = viewOptions ? viewOptions.map((option, index) => {

		switch (option) {
		case chartViewOptions.PIE:
			return {
				id: index,
				option: option,
				icon: pieChart
			}
		case chartViewOptions.BAR:
			return {
				id: index,
				option: option,
				icon: barChart
			}
		case chartViewOptions.LINEAR:
			return {
				id: index,
				option: option,
				icon: linearDiagram
			}
		default:
			return {
				id: -1,
				option: null,
				icon: null
			}

		}

	}) : []



	const chartDurationOptionList = [
		{
			id: 0,
			option: chartDurationOptions.DAILY,
			text: t('daily')
		},
		{
			id: 1,
			option: chartDurationOptions.WEEKLY,
			text: t('weekly')
		},
		{
			id: 2,
			option: chartDurationOptions.MONTHLY,
			text: t('monthly')
		},
		{
			id: 3,
			option: chartDurationOptions.YEARLY,
			text: t('yearly'),
		}
	]


	const [chartData, setChartData] = useState<{ xAxisLabels: string[], chartItems: chartItem[] }>({
		xAxisLabels: [],
		chartItems: []
	})

	const [legend, setLegend] = useState<legendItem[]>([])

	const [activeLegends, setActiveLegends] = useState<legendItem[]>([])

	const [activeViewOptionId, setActiveViewOptionId] = useState<number>(chartViewOptionList[0].id)
	const [activeDurationOptionId, setActiveDurationOptionId] = useState<number>(chartDurationOptionList[0].id)

	const [activeViewOption, setActiveViewOption] = useState<view>(chartViewOptionList[0])

	const [visibleCalendar, setVisibleCalendar] = useState(false)

	const [durationStartDate, setDurationStartDate] = useState<Date>(substractTimeFromDate(new Date(), chartDurationOptions.MONTHLY))
	const [durationEndDate, setDurationEndDate] = useState<Date>(new Date())
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const toggleLegend = (legendItem: legendItem, remove: boolean) => {
		setActiveLegends((legendsList) => {

			let result: legendItem[] = []

			if (remove) {
				result = legendsList.filter((legend) => legend.label !== legendItem.label)
			} else {
				result = [...legendsList, legendItem]
			}

			return result
		})
	}


	useEffect(() => {
		const hideCalendar = () => {
			setVisibleCalendar(false)
		}

		document.addEventListener('click', hideCalendar)

		return () => {
			document.removeEventListener('click', hideCalendar)
		}
	}, [])

	useEffect(() => {
		onLegendChange && onLegendChange(activeLegends)
	}, [activeLegends])

	useEffect(() => {
		const legend = chartData.chartItems.map((item, index) => {
			return {
				label: item.label,
				color: item.color
			}
		})

		setLegend([...legend])
		setActiveLegends(legend.map((legendItem) => legendItem))
	}, [chartData])

	useEffect(() => {
		setIsLoading(true)
		loadData(durationStartDate, durationEndDate, chartDurationOptionList[activeDurationOptionId].option)
			.then((result) => {
				setIsLoading(false)
				setChartData(result)
			}).finally(() => setIsLoading(false))
	}, [durationStartDate, durationEndDate, activeDurationOptionId])

	useEffect(() => {
		setActiveViewOption(chartViewOptionList[activeViewOptionId])
	}, [activeViewOptionId])

	return (
		<div className={`chart-container ${className || ''}`} >
			<div className="chart-container-head">
				<h2 className='chart-container-title'>{title}</h2>
				<p className='chart-container-sub-title'>{subtitle}</p>
			</div>
			<div className="chart-container-action">
				{isLoading ? <Loading /> : (
					showDurationOptions && (<>
						{chartViewOptionList.length > 0 ? <SwitchButtons switchButtons={chartViewOptionList} activeSwitchButtonId={activeViewOptionId} setActiveSwitchButtonId={setActiveViewOptionId} size='small' /> : <div></div>}
						<div className="chart-container-action-duration">
							<SwitchButtons switchButtons={[...chartDurationOptionList, {
								id: 4, icon: calendar, onClick: (e) => {
									e.stopPropagation()
									setVisibleCalendar(!visibleCalendar)
								}
							}]} activeSwitchButtonId={activeDurationOptionId} setActiveSwitchButtonId={setActiveDurationOptionId} size='small' />
							<div className={`chart-container-action-duration-calendar ${visibleCalendar ? 'visible' : ''}`}>
								<CalendarContainer
									size='small'
									startDate={{
										date: durationStartDate,
										setDate: (value) => {
											setDurationStartDate(value || new Date())
										}
									}}
									dueDate={{
										date: durationEndDate,
										setDate: (value) => {
											setDurationEndDate(value || new Date())
										}
									}}
									popup={true}
								/>
							</div>
						</div>
					</>)
				)}

			</div>
			<div className="chart-container-charts">
				{chartData.chartItems.length > 0 && chartData.xAxisLabels.length > 0 && activeViewOption.option === chartViewOptions.BAR ? <BarChart chartData={chartData} activeData={activeLegends} /> : null}
				{chartData.chartItems.length > 0 && chartData.xAxisLabels.length > 0 && activeViewOption.option === chartViewOptions.PIE ? <PieChart chartData={chartData} activeData={activeLegends} /> : null}
				{chartData.chartItems.length > 0 && chartData.xAxisLabels.length > 0 && activeViewOption.option === chartViewOptions.LINEAR ? <LinearDiagram chartData={chartData} activeData={activeLegends} /> : null}

			</div>
			<ChartLegend legend={legend} activeLegends={activeLegends} toggleLegend={toggleLegend} />
		</div>
	)
}

export default ChartContainer

