// styles
import './manualLayout.scss'

// react
import {FC} from 'react'

// components
import Layout from '../layout/layout'
import SectionWithLines from '../sectionWithLines/sectionWithLines'
import ParticlesBg from '../particlesBg/particlesBg'
import ManualItem from '../manualItem/manualItem'
import ScrollAnimation from '../scrollAnimation/scrollAnimation'

//types
import {manualData} from '../../../types/types'
import Subtitle from '../subtitle/subtitle'
import Title from '../title/title'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

type manualLayoutProps = {
    heading: string,
	data: manualData[]
}

const ManualLayout: FC<manualLayoutProps>  = ({heading, data}) => {
	window.scrollTo({ top: 0, behavior: 'smooth' })
	const location = useLocation()
	const navigate = useNavigate()
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual' })


	const titles = ['employees', 'projects', 'purchases', 'sales', 'finance', 'warehouses']
	return (
		<Layout>
			<div className="manual-layout-wrapper">
				<ScrollAnimation direction='to-bottom'>
					<div className="manual-layout-container">
						<div className="manual-layout-selector-wrapper">
							<div className="manual-layout-selector-container">
								{
									titles.map((title)=>{
										return(
											<div 
												className={`manual-layout-selector-title-container ${location.pathname.includes(`/${title}`) ? 'active' : null}`}
												onClick={() => navigate(`/help/ri-manual/${title}`)} 
											>
												<Subtitle>{t(`${title}Title`)}</Subtitle>
											</div>
										)
									})
								}
							</div>
						</div>
						<div className="manual-layout-information-container">
							<Title>{heading}</Title>
							<div className="manual-layout-text-wrapper">
								<div className="manual-layout-text-container">
									{
										data.map(({title, text, images, process, link})=> (
											<ManualItem
												title={title}
												text={text}
												images={images}
												process={process}
												link={link}
											/>
										))
									}
								</div>
							</div>
						</div>
						
					</div>
				</ScrollAnimation>
			</div>
			<ParticlesBg/>
		</Layout>
	)
}

export default ManualLayout