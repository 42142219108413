// styles
import './createBudgetModal.scss'

//react
import {FC, FormEvent, useRef, useState} from 'react'

//components
import Modal from '../../modal/modal'
import InputField from '../../../inputField/inputField'
import TextArea from '../../../textArea/textArea'
import FloatInput from '../../../floatInput/floatInput'

//types
import {budget, budgetResponse} from '../../../../../types/finance/budgetTypes'
import { infoPopupTypes, infoType } from '../../../../../types/general/generalTypes'

// network
import {authorizedRequest} from '../../../../../utils/queries'
import {createBudgetUrl} from '../../../../../utils/old_urls/finance/budgetListUrls'

// redux
import {useAppDispatch, useAppSelector} from '../../../../../customHooks/redux'
import {setCreateBudgetModalIsOpen} from '../../../../../redux/finance/budgetList/modals'

//translations
import { useTranslation } from 'react-i18next'

//other
import { formatStringIntoTwoDigitsFloat } from '../../../../../assets/general/generalFunctions'

type formErrors = {
	title?: string
	amount?: string
}

type createBudgetModalProps = {
    budgets: budget[]
    setBudgets: (value: budget[]) => void
}

const CreateBudgetModal: FC<createBudgetModalProps> = ({budgets, setBudgets}) => {
	const { t } = useTranslation('', {keyPrefix:'general.modals.budget.createBudgetModal'})

	const [title, setTitle] = useState<string>('')
	const [description, setDescription] = useState<string>('')
	const [amountUAH, setAmountUAH] = useState<number>(0)
	const [amountUSD, setAmountUSD] = useState<number>(0)
	const [amountEUR, setAmountEUR] = useState<number>(0)

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const dispatch = useAppDispatch()
	const { modalIsOpen } = useAppSelector((state) => state.budgetListModal.createBudgetModal)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const formRef = useRef<HTMLFormElement | null>(null)
	const [errors, setErrors] = useState<formErrors>({})

	const infoPopup = {
		content: [{
			title: 'create_budget_title',
			description: 'create_budget_description',
		}],
		type: infoPopupTypes.INFO
	}

	const amountInputHandleUAH = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.01'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		setAmountUAH(numberValue)
	}
	const amountInputHandleUSD = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.01'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		setAmountUSD(numberValue)
	}
	const amountInputHandleEUR = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.01'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		setAmountEUR(numberValue)
	}

	const createBudget = async () => {
		setIsLoading(true)
		const { result } = await authorizedRequest(createBudgetUrl(companyId), 'POST', 'accessToken', {
			data: [
				{
					company_id: companyId,
					title: title,
					description: description,
					amount_uah: amountUAH,
					amount_usd: amountUSD,
					amount_eur: amountEUR,
				},
			]
		})

		const updatedBudgets: budget[] = result.map((budget: budgetResponse) => {
			return {
				id: budget.id,
				values: budget.values,
				title: budget.title,
				description: budget.description,
				deleteAccess: budget.delete_access
			}
		})

		setBudgets([...updatedBudgets,...budgets])
		setIsLoading(false)
		closeModal()
	}

	const closeModal = () => {
		setAmountEUR(0)
		setAmountUSD(0)
		setAmountUAH(0)
		setDescription('')
		setTitle('')
		setErrors({})

		dispatch(setCreateBudgetModalIsOpen(false))
	}

	const checkErrors = () => {
		let result = true

		if (title === '') {
			setErrors(prevErrors => ({ ...prevErrors, title: t('pleaseEnterTitle') }))
			result = false
		}
		if(amountEUR === 0 && amountUAH === 0 && amountUSD === 0) {
			setErrors(prevErrors => ({ ...prevErrors, amount: t('pleaseEnterBudgetAmount') }))
			result = false
		}

		return result
	}

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		if (checkErrors()) {
			createBudget()
			setErrors({})
		}
	}
	
	return (
		<Modal
			closeModal={closeModal}
			open={modalIsOpen}
			titlePopup={infoPopup}
			title={t('title')}
			submitButton={{
				text: t('create'),
				onClick: () => {
					if (formRef.current) formRef.current.requestSubmit()
				}
			}}
			isLoading={isLoading}
		>
			<form
				className='create-budget-container'
				ref={formRef}
				onSubmit={handleSubmit}
			>
				<InputField
					type='text'
					placeholder={t('titleInput')}
					label={t('titleInput')}
					value={title}
					onChange={e => setTitle(e.target.value)}
					info={errors.title ? {
						type: infoType.error,
						text: errors.title
					} : undefined}
				/>
				<TextArea
					label={t('description')}
					placeholder={t('description')}
					value={description}
					setValue={value => setDescription(value)}

				/>
				<div className="create-budget-values">
					<div className="create-budget-value">
						<FloatInput
							label={t('total')}
							info={errors.amount ? {
								type: infoType.error,
								text: errors.amount
							} : undefined}
							amountInputHandle={amountInputHandleUAH}
							value={`${amountUAH}`}
						/>
						<InputField
							disabled={true}
							value='UAH'
						/>
					</div>
					<div className="create-budget-value">
						<FloatInput
							label={t('total')}
							info={errors.amount ? {
								type: infoType.error,
								text: errors.amount
							} : undefined}
							amountInputHandle={amountInputHandleUSD}
							value={`${amountUSD}`}
						/>
						<InputField
							disabled={true}
							value='USD'
						/>
					</div>
					<div className="create-budget-value">
						<FloatInput
							label={t('total')}
							info={errors.amount ? {
								type: infoType.error,
								text: errors.amount
							} : undefined}
							amountInputHandle={amountInputHandleEUR}
							value={`${amountEUR}`}
						/>
						<InputField
							disabled={true}
							value='EUR'
						/>
					</div>
				</div>
			</form>
		</Modal>
	)
}

export default CreateBudgetModal