import { configureStore } from '@reduxjs/toolkit'

import generalReducer from './general/general'
import generalModalsReducer from './general/modals'
import profileModalsReducer from './general/profile/modals'
import profileGeneralReducer from './general/profile/general'
import orderModalsReducer from './general/order/modals'

//employees

//hierarchy
import hierarchyGeneralReducer from './employees/hierarchy/general'


// chat
import chatBarReducer from './employees/chat/chatBar'
import chatModalReducer from './employees/chat/modals'

//general
import employeesGeneralModalsReducer from './employees/general/modals'
import settingsGeneralReducer from './general/settings/general'
import settingsModalsReducer from './general/settings/modals'
import employeeModalsReducer from './general/employee/modals'

//projects

//pipelineList
import pipelineListModalsReducer from './projects/pipelineList/modals'

//pipeline
import pipelineModalsReducer from './projects/pipeline/modals'

//task
import taskModalsReducer from './projects/task/modals'

//project
import projectModalsReducer from './projects/project/modals'

//sales
import offeringModalReducer from './sales/offering/modals'

//orderList
import salesOrderListModalReducer from './sales/salesOrderList/modals'

// customerList
import customerListModalReducer from './sales/customerList/modals'

//customer
import customerModalReducer from './sales/customer/modals'

// offeringList
import offeringListReducer from './sales/offeringList/modals'


//purchase

//orders
import purchaseOrderListModalReducer from './purchase/purchaseOrderList/modals'
// providerList
import providerListModalReducer from './purchase/providersList/modals'
// provider
import providerModalReducer from './purchase/provider/modals'

//finance

//transaction
import financeGeneralModalReducer from  './finance/general/modals'
//accountList
import accontListModalReducer from './general/modals'
//budgetList
import budgetListModalReducer from './finance/budgetList/modals'

//warehouseList
import warehouseListModalReducer from './warehouse/warehouseList/modals'
import warehouseGeneralModalReducer from './warehouse/general/modals'

//inventoryItem
import inventoryItemModalReducer from './warehouse/inventoryItem/modals'

export const store = configureStore({
	reducer: {
		general: generalReducer,
		generalModals: generalModalsReducer,
		settingsGeneral: settingsGeneralReducer,
		settingsModals: settingsModalsReducer,
		employeeModals: employeeModalsReducer,
      
		profileGeneral: profileGeneralReducer,
		profileModals: profileModalsReducer,
		
		orderModals: orderModalsReducer,

		employeesGeneralModal: employeesGeneralModalsReducer,

		hierarchyGeneral: hierarchyGeneralReducer,

		chatBar: chatBarReducer,
		chatModal: chatModalReducer,

		pipelineListModals: pipelineListModalsReducer,
		pipelineModals: pipelineModalsReducer,
		taskModals: taskModalsReducer,
		projectModals: projectModalsReducer,

		customerListModal: customerListModalReducer,
		customerModal: customerModalReducer,
		salesOrderListModal: salesOrderListModalReducer,
		offeringListModal: offeringListReducer,
		offeringModal: offeringModalReducer,

		purchaseOrderListModal: purchaseOrderListModalReducer,
		providerListModal: providerListModalReducer,
		providerModal: providerModalReducer,
		
		financeGeneralModal: financeGeneralModalReducer,

		accontListModal: accontListModalReducer,
		budgetListModal: budgetListModalReducer,

		warehouseListModal: warehouseListModalReducer,
		warehouseGeneralModal: warehouseGeneralModalReducer,

		inventoryItemModal: inventoryItemModalReducer
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		})
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch