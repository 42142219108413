//styles
import { employee, finance, purchase, sale } from '../../../../assets/general/generalIcons'

// components
import Layout from '../../components/general/layout/layout'
import ParticlesBg from '../../components/general/particlesBg/particlesBg'
import HeaderAppFeature from '../../components/general/headerAppInformation/headerAppFeature'
import PriceSection from '../../components/prices/priceSection/priceSection'

//translations
import { useTranslation } from 'react-i18next'

//react
import { useEffect } from 'react'

const Prices = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.prices' })

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}, [])
	
	return (
		<Layout>
			<HeaderAppFeature
				heading={['', t('prices')]}
				text={t('headerDescription')}
				buttons={[{text: t('headerButton'), link: '', variant: 'solid'}]}
				imagesData={[]}
				alternativeIcons={[
					{
						icon: sale,
						iconPosition: {left: '3vw', top: '60vw'}
					},
					{
						icon: finance,
						iconPosition: {left: '87vw', top: '28vw'}
					},
					{
						icon: employee,
						iconPosition: {left: '31vw', top: '8vw'}
					},
					{
						icon: purchase,
						iconPosition: {left: '13vw', top: '25vw'}
					},
					{
						icon: purchase,
						iconPosition: {left: '86vw', top: '64vw'}
					},
				]}
			/>
            <PriceSection/>
			<ParticlesBg/>
		</Layout>
	)
}

export default Prices

