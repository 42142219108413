// react
import { FC } from 'react'

// component
import FileSelectorModal from '../../../../general/modals/fileSelectorModal/fileSelectorModal'

// type
import { file } from '../../../../../types/general/generalTypes'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setCustomerTablePreviewModalIsOpen, setImportCustomerFileSelectorModalFile, setImportCustomerFileSelectorModalIsOpen } from '../../../../../redux/sales/customerList/modals'


const ImportCustomerFileSelectorModal : FC = () => {
	const { modalIsOpen } = useAppSelector((state) => state.customerListModal.importCustomerFileSelectorModal)
	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setImportCustomerFileSelectorModalIsOpen(false))
	}

	const setFiles = (files: file[]) => {		
		if(files.length === 0) return
		dispatch(setImportCustomerFileSelectorModalFile(files[0]))
		dispatch(setImportCustomerFileSelectorModalIsOpen(false))
		dispatch(setCustomerTablePreviewModalIsOpen(true))
	}

	return (
		<FileSelectorModal 
			isOpen={modalIsOpen} 
			setIsOpen={closeModal}
			setFiles={setFiles}
			supportedFormats={[
				{title: 'csv', mime: 'text/csv'},
				{title: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
			]}	
		/>
	)
}

export default ImportCustomerFileSelectorModal