//react
import { FC, useState } from 'react'

//components
import ConfirmationModal from '../../../../../general/modals/confirmationModal/confirmationModal'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../../customHooks/redux'
import { setMoveEmployeeConfirmationModalIsOpen, setMoveEmployeeId, setMoveEmployeeNewLeaderId, setMoveEmployeeTeam } from '../../../../../../redux/employees/general/modals'
import { setHierarchy } from '../../../../../../redux/employees/hierarchy/general'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import { hierarchyEmployeesUrl, hierarchyMoveEmployeesUrl } from '../../../../../../utils/urls/employees/hierarchy'

//types
import { switchButton } from '../../../../../../types/general/generalTypes'
import { employee, hierarchyColumn } from '../../../../../../types/employees/hierarchyTypes'

//other
import { getEmployeeHierarchyData } from '../../../../../../assets/employees/hierarchy/hierarchyFunctions'

//translation
import { useTranslation } from 'react-i18next'

type moveEmployeeConfirmationProps = {
	getEmployeeActions: (employee: employee) => switchButton[]
}

const MoveEmployeeConfirmation: FC<moveEmployeeConfirmationProps> = ({ getEmployeeActions }) => {
	const {t} = useTranslation('', {keyPrefix:'employees.general.modals.moveEmployee.moveEmployeeConfirmation'})

	const { moveEmployee } = useAppSelector((state) => state.employeesGeneralModal)
	const { hierarchy } = useAppSelector((state) => state.hierarchyGeneral)

	const [loading, setLoading] = useState<boolean>(false)

	const dispatch = useAppDispatch()

	const approveHandler = async () => {
		if(moveEmployee.employeeId){
			setLoading(true)
			await authorizedRequest(hierarchyMoveEmployeesUrl(moveEmployee.employeeId), 'POST', 'accessToken', { new_leader_id: moveEmployee.newLeaderId})

			const updatedHierarchy: hierarchyColumn[] = []

			for(let i=0; i<hierarchy.length; i++ ){
				const column = hierarchy[i]

				if(hierarchy[i-1]){
					const prevActiveEmployee = hierarchy[i-1].employees.find((employee) => {
						return employee.active
					})

					const activeEmployee = column.employees.find((employee) => {
						return employee.active
					})

					if(prevActiveEmployee){
						const updatedLeaderEmplyees = await getEmployeeHierarchyData(hierarchyEmployeesUrl(prevActiveEmployee.id), getEmployeeActions)
						updatedHierarchy.push({
							...column,
							employees: updatedLeaderEmplyees.map((employee) => {
								return {
									...employee,
									active: activeEmployee?.id === employee.id
								}
							})
						})
					}

					dispatch(setHierarchy([...updatedHierarchy]))
				}else{
					updatedHierarchy.push(column)
				}
			}

			setLoading(false)
			closeModal()
		}
	}
    

	const closeModal = () => {
		dispatch(setMoveEmployeeConfirmationModalIsOpen(false))
		dispatch(setMoveEmployeeId(undefined))
		dispatch(setMoveEmployeeNewLeaderId(undefined))
		dispatch(setMoveEmployeeTeam(false))
	}

	const buttons = [{text: t('yes'), onClickHandler: () => {approveHandler()} }, {text: t('no'), onClickHandler: closeModal }]

	return <ConfirmationModal
		loading={loading} 
		title={t('moveTheEmployee')} 
		buttons={buttons} isOpen={moveEmployee.confirmationModalIsOpen} 
		closeModal={closeModal}
	/>
}

export default MoveEmployeeConfirmation