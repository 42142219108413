//styles and icons
import './budgetBillList.scss'
import { listIcon, tableIcon } from '../../../../assets/general/generalIcons'


// react
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import Pagination from '../../../general/pagination/pagination'
import ColumnLayout from '../../../general/columnLayout/columnLayout'
import ListSummaryCard from '../../../general/listSummaryCard/listSummaryCard'
import ExportBillFileModal from '../../../general/modals/bill/exportBillFileModal/exportBillFileModal'
import ImportBillFileSelectorModal from '../../../general/modals/bill/importBillFileSelectorModal/importBillFileSelectorModal'
import BillTablePreviewModal from '../../../general/modals/bill/billTablePreviewModal/billTablePreviewModal'
import CreateBillModal from '../../../general/modals/bill/createBillModal/createBillModal'
import BillListItem from '../../../general/billListItem/billListItem'
import DeleteBillConfirmationModal from '../../../general/modals/bill/deleteBillConfirmationModal/deleteBillConfirmationModal'
import OverviewCardSkeleton from '../../../general/skeletons/overviewCard/overviewCardSkeleton'
import ListCardSkeleton from '../../../general/skeletons/listCard/listCardSkeleton'
import SwitchButtons from '../../../general/switchButtons/switchButtons'
import BillTable from '../../../general/tableViews/billTable/billTable'

// types
import { currency, filterBar, filters, switchButton, } from '../../../../types/general/generalTypes'
import { bill } from '../../../../types/finance/general'

//redux
import { useAppDispatch } from '../../../../customHooks/redux'
import { setCreateBillModalIsOpen } from '../../../../redux/general/modals'

//network
import { authorizedRequest } from '../../../../utils/queries'
import { singleBudgetBillFiltersUrl, singleBudgetBillOverviewwUrl, singleBudgetBillsUrl } from '../../../../utils/urls/finance/budget/budget'

// other
import { formatFilters, createFilteringRequest, currencyToFormattedString, getItemsPerColumn } from '../../../../assets/general/generalFunctions'
import { formatBillResponse } from '../../../../assets/finance/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type budgetBillListProps = {
	setFilterBar: (filterBar: filterBar | undefined) => void
}

const BudgetBillList: FC<budgetBillListProps> = ({ setFilterBar }) => {
	const { t } = useTranslation('', { keyPrefix: 'finance.budget.budgetBillList' })
	const [activeViewId, setActiveViewId] = useState(0)

	const [budgetBillsOverview, setBudgetBillsOverview] = useState<{ title: string, text: string }[]>([])
	const [budgetBills, setBudgetBills] = useState<bill[]>([])
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())
	const [searchRequest, setSearchRequest] = useState('')
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const [isOverviewLoading, setOverviewLoading] = useState<boolean>(false)

	const { budgetId } = useParams()

	const dispatch = useAppDispatch()

	const loadFilterBar = async () => {
		const { result: { filters, create_access } } = await authorizedRequest(singleBudgetBillFiltersUrl(parseInt(budgetId || '-1')), 'GET')

		const filterResult = filters
		const createAccess = create_access

		const formatedFilters: filters[] = formatFilters(filterResult)

		setFilterBar({
			// importButton: () => {
			// 	dispatch(setImportBillFileSelectorModalIsOpen(true))
			// },
			// exportButton: () => {
			// 	dispatch(setExportBillFileModalIsOpen(true))
			// },
			filters: formatedFilters,
			addButton: createAccess && {
				text: t('create'),
				active: true,
				onClick: () => dispatch(setCreateBillModalIsOpen(true))
			},
			onSearch: onSearch
		})
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		const filteringRequest = createFilteringRequest(filters)

		const { result } = await authorizedRequest(singleBudgetBillsUrl(Number(budgetId)) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')
		if (result.length > 0) {
			const formatedBills: bill[] = result.map(formatBillResponse)
			return formatedBills
		}

		return []
	}

	const loadOverview = async () => {
		setOverviewLoading(true)
		const { result } = await authorizedRequest(singleBudgetBillOverviewwUrl(Number(budgetId)), 'GET')

		setBudgetBillsOverview([
			{
				title: t('totalBills'),
				text: result.total_budgets
			},
			...result.amount.map((amount: { value: number, currency: currency }) => {
				return {
					title: t('total'),
					text: currencyToFormattedString(amount.value, amount.currency)
				}
			})
		])
		setOverviewLoading(false)
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		loadOverview()
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		setLoading(true)
		loadData(page, searchValue, filters)
			.then((result) => {
				setBudgetBills([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
				setLoading(false)
			})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			setLoading(true)
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					setBudgetBills([...budgetBills, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
					setLoading(false)
				})
		}
	}

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
			setFilterBar(undefined)
		}
	}, [])
	const renderComponent = () => {
		switch (activeViewId) {
		case 0:
			return (
				<ColumnLayout amount={itemsPerColumn}>
					{isOverviewLoading ? <OverviewCardSkeleton /> : <ListSummaryCard
						title={t('billsOverview')}
						data={budgetBillsOverview}
					/>}
					{budgetBills.map(bill => (<BillListItem key={`budget-bill-item-${bill.id}`} {...bill} />))} 
					{loading ? <ListCardSkeleton /> : null}
				</ColumnLayout>
			)
		case 1:
			return (
				<BillTable
					preloadedBills={{
						bills: budgetBills,
						setBills: setBudgetBills,
					}}
				/>
			)
		}
	}
	const viewButtons: switchButton[] = [
		{
			id: 0,
			icon: listIcon,
		},
		{
			id: 1,
			icon: tableIcon,
		},
	]

	return (
		<div>
			<div className='budget-bill-list'>
				<SwitchButtons
					switchButtons={viewButtons}
					activeSwitchButtonId={activeViewId}
					setActiveSwitchButtonId={setActiveViewId}
					size="medium"
				/>
				<Pagination onLoadMore={handleLoadMore} showLoader={false}>
					{renderComponent()}
				</Pagination>
			</div>
			<ExportBillFileModal />
			<BillTablePreviewModal setBills={setBudgetBills} bills={budgetBills} />
			<ImportBillFileSelectorModal />
			<CreateBillModal setBills={setBudgetBills} bills={budgetBills} />
			<DeleteBillConfirmationModal setBills={setBudgetBills} bills={budgetBills} />
		</div>
	)
}

export default BudgetBillList