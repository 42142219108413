//styles
import { productOffer } from '../../../../../assets/general/generalIcons'

//components
import EditableTable from '../../../editableTable/editableTable'

//react
import { FC, useEffect, useState } from 'react'

//types
import { editableTableItem, offering, order, orderTypes } from '../../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../../utils/queries'

import { companyOfferingsUrl, editOrderUrl } from '../../../../../utils/old_urls/general/orderUrls'
import { singleProviderOfferingsUrl } from '../../../../../utils/urls/purchases/provider'

//redux
import { useAppSelector } from '../../../../../customHooks/redux'

//other
import { formatOfferingResponse, getIdWithCurrency } from '../../../../../assets/general/generalFunctions'

type orderTableOfferingsProps = {
    order: order
}

const OrderTableOfferings: FC<orderTableOfferingsProps> = ({order}) => {
	console.log('order', order)

	const [offerings, setOfferings] = useState<offering[]>([])
	const [selectedOfferings, setSelectedOfferings] = useState<offering[]>([])

	const {userCompanyData, language} = useAppSelector(state => state.general)
	const companyId = userCompanyData?.companyId || -1

	const loadCompanyOfferings = async (searchQuery: string, page: number) => {
		const { result } = await authorizedRequest(companyOfferingsUrl(companyId) + `?needle=${searchQuery}&page=${page}`, 'GET')
		if (result && result.length > 0) {
			const productsData: offering[] = result.map(formatOfferingResponse)


			return productsData.map(e => ({ ...e, placeholder: productOffer }))
		}
		return []
	}

	const loadProviderOfferings = async (searchQuery: string, page: number) => {
		const { result } = await authorizedRequest(singleProviderOfferingsUrl(Number(order.counterparty.relationshipId)) + `?needle=${searchQuery}&page=${page}`, 'GET')
		if (result && result.length > 0) {
			const productsData: offering[] = result.map(formatOfferingResponse)


			return productsData.map(e => ({ ...e, placeholder: productOffer }))
		}
		return []
	}

	const updateSelectedItems = (selectedItems: editableTableItem[]) => {
		authorizedRequest(editOrderUrl(order.id), 'PUT', 'accessToken', {
			language,
			offerings: selectedItems.map((item) => {
				item = item as offering
				return {
					id: item.id,
					name: item.name,
					original_price: item.price.amount,
					price: item.price.amount,
					currency: item.price.currency,
					quantity: item.quantity,
					vat: item.vat
				}
			})
		})
	}

	useEffect(() => {
		setSelectedOfferings(order.items.map((item) => {
			return {
				...item,
				idWithCurrency: getIdWithCurrency(item),
				placeholder: productOffer
			}
		}))
	}, [])

	return (
		<EditableTable
			items={offerings}
			setItems={(value) => {
				setOfferings(value as offering[])
			}}
			loadItems={order.type === orderTypes.sales ? loadCompanyOfferings : loadProviderOfferings}
			selected={{
				items: selectedOfferings,
				setItems: (value) => {
					updateSelectedItems(value)
					setSelectedOfferings(value as offering[])
				},
			}}
			defaultTabId={1}
			disabled={order.isClosed || !order.editAccess}
			updateAmount={!order.isClosed ? () => {} : undefined}
		/>
	)
}

export default OrderTableOfferings