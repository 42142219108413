//styles
import './timer.scss'
import { videoPauseButton, videoPlayButton } from '../../../../assets/employees/chat/chatIcons'

//react
import { useState, useEffect, useRef, ChangeEvent, FC, SetStateAction, Dispatch } from 'react'

//network
import { authorizedRequest } from '../../../../utils/queries'
import { singleTimeTacker } from '../../../../utils/urls/projects/task'

//translation
import { useTranslation } from 'react-i18next'

type timerProps = {
    executorId: number
    isRunning: boolean
    setIsRunning: (value: boolean) => void
    totalSeconds: number
    setTotalSeconds: Dispatch<SetStateAction<number>>
    disabled: boolean
}

const Timer: FC<timerProps> = ({ executorId, isRunning, setIsRunning, totalSeconds, setTotalSeconds, disabled }) => {
	const { t } = useTranslation('', { keyPrefix: 'projects.task.timeTrackerTab' })

	const [hoursInput, setHoursInput] = useState<string>('')
	const [minutesInput, setMinutesInput] = useState<string>('')
	const [secondsInput, setSecondsInput] = useState<string>('')
	const [timeInputed, setTimeInputed] = useState(false)
	const timerRef = useRef<NodeJS.Timeout | null>(null)

	useEffect(() => {
		if (isRunning) {
			timerRef.current = setInterval(() => {
				setTotalSeconds(prevTime => {
					const newTime = prevTime + 1
					localStorage.setItem('timeInSeconds', newTime.toString())
					return newTime
				})
			}, 1000)
		} else {
			clearInterval(timerRef.current as NodeJS.Timeout)
		}

		return () => {
			clearInterval(timerRef.current as NodeJS.Timeout)
		}
	}, [isRunning])

	useEffect(() => {
		const hours = Math.floor(totalSeconds / 3600)
		const minutes = Math.floor((totalSeconds % 3600) / 60)
		const seconds = Math.floor(totalSeconds % 60)
    
		setHoursInput(hours.toString())
		setMinutesInput(minutes.toString())
		setSecondsInput(seconds.toString())

		if(timeInputed){
			authorizedRequest(singleTimeTacker(executorId), 'PUT', 'accessToken', {
				hours_spent: totalSeconds / 3600
			})
				.then(() => {
					setTimeInputed(false) 
				})
		}

	}, [totalSeconds])

	const handleHoursChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		const numericValue = parseInt(value, 10)
		if (!isNaN(numericValue) && numericValue >= 0) {
			setTotalSeconds(Math.floor(Number(numericValue)*60*60 + Number(minutesInput)*60 + Number(secondsInput)))
		} else if (value === '') {
			setTotalSeconds(Math.floor(Number(minutesInput)*60 + Number(secondsInput)))
		}
		setTimeInputed(true)
	}

	const handleMinutesChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		const numericValue = parseInt(value, 10)
		if (!isNaN(numericValue) && numericValue >= 0) {
			setTotalSeconds(Math.floor(Number(hoursInput)*60*60 + Number(numericValue)*60 + Number(secondsInput)))
		} else if (value === '') {
			setTotalSeconds(Math.floor(Number(hoursInput)*60*60 + Number(secondsInput)))
		}
		setTimeInputed(true)
	}

	const handleSecondsChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		const numericValue = parseInt(value, 10)
		if (!isNaN(numericValue) && numericValue >= 0) {
			setTotalSeconds(Math.floor(Number(hoursInput)*60*60 + Number(minutesInput)*60 + Number(numericValue)))
		} else if (value === '') {
			setTotalSeconds(Math.floor(Number(hoursInput)*60*60 + Number(minutesInput)*60))
		}
		setTimeInputed(true)

	}

	return (
		<div className='timer'>
			{!disabled ?
				<div className="timer-button" onClick={() => {
					if(!disabled){
						setIsRunning(!isRunning)
					}
				}}>
					{isRunning ? videoPauseButton : videoPlayButton}
				</div>
				: null}
          
			<div className='timer-time'>
				<input 
					type="text" 
					value={hoursInput} 
					onChange={handleHoursChange} 
					placeholder={t('hours')}
					disabled={disabled}
				/>
				<input 
					type="text" 
					value={minutesInput} 
					onChange={handleMinutesChange} 
					placeholder={t('minutes')}
					disabled={disabled}
				/>
				<input 
					type="text" 
					value={secondsInput} 
					onChange={handleSecondsChange} 
					placeholder={t('seconds')}
					disabled={disabled}
				/>
			</div>
		</div>
	)
}

export default Timer
