// styles and icons
import './counterpartyCard.scss'

// react
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

// components
import Avatar from '../avatar/avatar'
import SwitchButtons from '../switchButtons/switchButtons'
import Label from '../label/label'

// types
import { counterparty, label, switchButton } from '../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

type counterpartyCardProps = counterparty & {
	actions?: switchButton[]
	link?: string
	label?: label
	translate?: boolean
	translateTags?: string
	extraContent?: JSX.Element
}

const CounterpartyCard: FC<counterpartyCardProps> = ({ name, avatar, placeholder, description, link, tags, actions, label, translate=true, translateTags=undefined, extraContent }) => {
	const navigate = useNavigate()

	const { t } = useTranslation('', {keyPrefix: translateTags ? translateTags : 'general.counterpartyCard.tags'})

	const translatedTags = tags?.map(tag => {
		return {
			...tag,
			title: translate ? t(tag.title!) : tag.title!
		}
	})

	return (
		<div className='counterparty'>
			{label ? <Label text={label.text} color={label.color} id={label.id}/> :
				<div className='counterparty-info'onClick={() => {
					if(link){
						navigate(link)
					}
				}}>
					<div className='counterparty-info-content'>
						{avatar !== undefined && 
							<div className='counterparty-avatar'>
								<Avatar blobAvatar={avatar} placeholder={placeholder}/>
							</div>
						}
						<div className='counterparty-details' >
							<h2 className='counterparty-company-name'>
								{name}
							</h2>
							{ description ? 
								<p className='counterparty-company-description'>
									{description}
								</p>
								: null 
							}
						</div>
					</div>
					{translatedTags ? 
						<div className='counterparty-info-tags'>
							{translatedTags.map((tag, index) => <div className='counterparty-tag' key={`tag-${tag.value}-${index}`}>{`${tag.title + (tag.title?.length > 0 && tag.value?.length > 0 ? ':' : '')} ${tag.value}`}</div>)}
						</div>
						: null
					}
					{extraContent}
				</div>
			}
			{
				actions && actions.length > 0 ?
					<div className='counterparty-action'>
						<SwitchButtons 
							switchButtons={actions} 
							orientation='vertical'
						/>
					</div>
					: null	
			}
		</div>
	)
}

export default CounterpartyCard