// styles
import './help.scss'
import { checkMark } from '../../../../assets/general/generalIcons'

//react
import { useEffect, useState } from 'react'

// components
import Layout from '../../components/general/layout/layout'
import ParticlesBg from '../../components/general/particlesBg/particlesBg'
import Title from '../../components/general/title/title'
import Text from '../../components/general/text/text'
import Button from '../../../../components/general/button/button'
import InputField from '../../../../components/general/inputField/inputField'
import TextArea from '../../../../components/general/textArea/textArea'
import Subtitle from '../../components/general/subtitle/subtitle'
import Loading from '../../../../components/general/loading/loading'

//translations
import { useTranslation } from 'react-i18next'

//icons
import { chatPlusIcon, facebook, helpMessageIcon, helpPhoneIcon, helpUserEmailIcon, helpUserIcon, instagramIcon, linkedInIcon, phoneIcon } from '../../assets/icons/icons'

//network
import { unauthorizedRequest } from '../../../../utils/queries'
import { helpRequestUrl } from '../../../../utils/urls/general/internal/internal'

//types
import { infoType } from '../../../../types/general/generalTypes'


type errors = {
    email?: string
    name?: string
    phone?: string
    message?: string
}

const Help = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.other.help' })

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState<errors>({email: '', phone: '', name: '', message: ''})

    const [loading, setLoading] = useState(false)
    const [requestSent, setRequestSent] = useState(false)

    const submitHelpForm = () => {
        let flag = true
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if(name === ''){
			setErrors(prevErrors => ({...prevErrors, name: t('error1')}))
			flag = false
		}else setErrors(prevErrors => ({...prevErrors, name: ''}))
		if(phone === ''){
			setErrors(prevErrors => ({...prevErrors, phone: t('error3')}))
			flag = false
		}else setErrors(prevErrors => ({...prevErrors, phone: ''}))
		if(!emailRegex.test(email)){
			setErrors(prevErrors => ({...prevErrors, email: t('error2')}))
			flag = false
		}else setErrors(prevErrors => ({...prevErrors, email: ''}))
        if(message.length < 1){
            setErrors(prevErrors => ({...prevErrors, message: t('error4')}))
            flag = false
        }else setErrors(prevErrors => ({...prevErrors, message: ''}))

        if(flag){
            setLoading(true)
            unauthorizedRequest(helpRequestUrl, 'POST', {
                name: name,
                email: email,
                phone_number: phone,
                message: message
            })
            .then((response) => {
                setRequestSent(true)
            })
            .catch(() => {
                setRequestSent(false)
            })
            .finally(() => {
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])
	
	return (
		<Layout>
			<div className="help-wrapper">
                <div className="help-container">
                    <div className="help-text-container">
                        <div className="help-icons-container">
                            <div className="help-icon-list">
                                <div className="help-icon-container">
                                    {phoneIcon}
                                </div>
                                <div className="help-icon-white-block block1"></div>
                            </div>
                            <div className="help-icon-list">
                                <div className="help-icon-white-block block2"></div>
                                <div className="help-icon-container">
                                    {chatPlusIcon}
                                </div>
                            </div>
                        </div>

                        <Title>
                            {t('title')}
							<br/>
							<span className='title-black'>
                                {t('titleBlack')}
							</span>
						</Title>
                        <Text>{t('text')}</Text>

                        <div className="help-messangers-list">
                            <div className="help-messanger-container" onClick={()=>window.open('https://www.instagram.com/ri.software')}>
                                {instagramIcon}
                                <Subtitle>Instagram</Subtitle>
                            </div>
                            <div className="help-messanger-container" onClick={()=>window.open('https://www.linkedin.com/company/96241304')}>
                                {linkedInIcon}
                                <Subtitle>LinkedIn</Subtitle>
                            </div>
                            <div className="help-messanger-container" onClick={()=>window.open('https://www.facebook.com/people/RI-Software/61559840792929/')}>
                                {facebook}
                                <Subtitle>Facebook</Subtitle>
                            </div>
                        </div>
                    </div>
                    <div className="help-form-container">
                        <div className="help-form">
                            <Title><span className='title-black'>{t('formTitle')}</span></Title>
                            <Text>{t('formText')}</Text>
                            {
                                loading && <Loading/>
                            }
                            {
                                requestSent &&
                                    <div className={`help-form-message`}>
                                        { checkMark }
                                        <p>{t('requestSent')}</p>
                                    </div>
                            }

                            <div className="help-inputs-container">
                                <InputField
                                    type='text'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder={t('fromPlaceholder1')}
                                    info={errors.name ? {
                                        type: infoType.error,
                                        text: errors.name
                                    } : undefined}
                                    icon={helpUserIcon}
                                />
                                <InputField
                                    type='text'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder={t('fromPlaceholder2')}
                                    info={errors.email ? {
                                        type: infoType.error,
                                        text: errors.email
                                    } : undefined}
                                    icon={helpUserEmailIcon}
                                />
                                <InputField
                                    type='text'
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder={t('fromPlaceholder3')}
                                    info={errors.phone ? {
                                        type: infoType.error,
                                        text: errors.phone
                                    } : undefined}
                                    icon={helpPhoneIcon}
                                />
                                <TextArea
                                    value={message}
                                    setValue={(e) => setMessage(e)}
                                    placeholder={t('fromPlaceholder4')}
                                    icon={helpMessageIcon}
                                    info={errors.message ? {
                                        type: infoType.error,
                                        text: errors.message
                                    } : undefined}
                                />
                            </div>

                            <Button active={true} text={t('button')} onClick={()=>submitHelpForm()} arrow={true}/>
                        </div>
                    </div>
                </div>
            </div>
			<ParticlesBg/>
		</Layout>
	)
}

export default Help

