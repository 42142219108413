//react
import { FC, useEffect, useState } from 'react'

//components
import Pagination from '../../../general/pagination/pagination'
import ColumnLayout from '../../../general/columnLayout/columnLayout'
import CounterpartyCard from '../../../general/counterpartyCard/counterpartyCard'
import CounterpartySkeleton from '../../../general/skeletons/counterParty/counterPartySkeleton'
import TimeUsageData from '../../../general/timeUsageData/timeUsageData'

//network
import { authorizedRequest } from '../../../../utils/queries' 
import { companyIndividualProducivityUrl } from '../../../../utils/urls/employees/productivity'

//redux
import { useAppSelector } from '../../../../customHooks/redux'

//types
import { executor, filterBar } from '../../../../types/general/generalTypes'

//other
import { formatExecutorResponse } from '../../../../assets/projects/projectsFunctions'
import { getItemsPerColumn } from '../../../../assets/general/generalFunctions'

type individualProductivityProps = {
    setFilterBar: (value: filterBar | undefined) => void
}

const IndividualProductivity: FC<individualProductivityProps> = ({ setFilterBar }) => {

	const {userCompanyData} = useAppSelector(state => state.general)
	const companyId = userCompanyData?.companyId || -1

	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn(2))

	const [ page, setPage ] = useState(1)
	const [ searchRequest, setSearchRequest ] = useState('')
	const [ lastPage, setLastPage ] = useState(false)
	const [ loading, setLoading ] = useState(false)

	const [ executors, setExecutors ] = useState<executor[]>([])

	const loadData = async (page: number, request: string) => {
		const { result } = await authorizedRequest(companyIndividualProducivityUrl(companyId) + `?page=${page}&per_page=${10}&needle=${request}`, 'GET')
		if (result.length > 0) {
			const formatedBills: executor[] = result.map(formatExecutorResponse)
			return formatedBills
		}

		return []
	}

	const onSearch = (searchValue: string) => {
		setPage(1)
		setSearchRequest(searchValue)
		setLastPage(false)
		setLoading(true)
		loadData(page, searchValue)
			.then((result) => {
				setExecutors([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
				setLoading(false)
			})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			setLoading(true)
			loadData(page, searchRequest)
				.then((result) => {
					setExecutors([...executors, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
					setLoading(false)
				})
		}
	}


	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {
		onSearch(searchRequest)

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
			setFilterBar(undefined)
		}
	}, [])

	useEffect(() => {

		console.log(executors)
	}, [executors])

	return (
		<>
			<Pagination onLoadMore={handleLoadMore} showLoader={false}>
				<ColumnLayout amount={itemsPerColumn}>
					{executors.map(executor => (<CounterpartyCard 
						name={executor.name}
						description={executor.position.name}
						avatar={executor.avatar}
						extraContent={
							<TimeUsageData
								timeUsage={executor.timeUsage}
								hoursSpent={executor.hoursSpent}
							/>
						}
					/>))} 
					{loading ? <CounterpartySkeleton /> : null}
				</ColumnLayout>
			</Pagination>
		</>
	)
}

export default IndividualProductivity