//import
import './billDetails.scss'

// react
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// components
import InfoDetailsTable from '../../../general/infoDetailsTable/infoDetailsTable'
import Button from '../../../general/button/button'
import DeleteBillConfirmationModal from '../../../general/modals/bill/deleteBillConfirmationModal/deleteBillConfirmationModal'
import PayBillConfirmationModal from '../../../general/modals/bill/payBillConfirmationModal/payBillConfirmationModal'
import Loading from '../../../general/loading/loading'

// types
import {
	infoDetailsTableDataItem,
	currency,
	dropdownOption,
	error,
	employeeResponse,
	tableTypes,
	infoType,
} from '../../../../types/general/generalTypes'
import {
	expenseCategory,
	transactionType,
	billStatus,
} from '../../../../types/finance/general'

// network
import { authorizedRequest } from '../../../../utils/queries'
import { singleBillUrl } from '../../../../utils/urls/finance/bill/bills'
import { companyAccountUrl } from '../../../../utils/urls/finance/account/account'
import { companySearchEmployeesUrl } from '../../../../utils/urls/employees/search'

//redux
import { useAppDispatch, useAppSelector } from '../../../../customHooks/redux'
import {
	setDeleteBillId,
	setDeleteBillModalIsOpen,
	setPayBillId,
	setPayBillModalIsOpen,
} from '../../../../redux/general/modals'

//other
import {
	createDropdownOption,
	translateEnum,
	formatErrorResponse,
	formatStringIntoTwoDigitsFloat,
} from '../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'
import ExtraColumnDetails from '../../../general/extraColumnDetails/extraColumnDetails'

type billDetailsProps = {
	setBillName: (value: string) => void
	setErrors: (value: error[]) => void
}

type details = {
	title: string
	description: string
	endDate: Date | undefined
	selectedCurrencyOption: dropdownOption
	selectedTransactionTypeOption: dropdownOption
	selectedStatusOption: dropdownOption
	selectedCategoryOption: dropdownOption
	selectedEmployeeOption?: dropdownOption
	amount: number
	deleteAccess: boolean
	account?: dropdownOption
}

type errors = {
	title?: string
	amount?: string
}

const BillDetails: FC<billDetailsProps> = ({ setBillName, setErrors }) => {
	const { t } = useTranslation('', { keyPrefix: 'finance.bill.billDetails' })
	const tExpense = useTranslation('', {
		keyPrefix: 'general.expenseCategory',
	}).t
	const tStatus = useTranslation('', { keyPrefix: 'general.billStatus' }).t
	const tCurrency = useTranslation('', { keyPrefix: 'general.currency' }).t
	const tTransaction = useTranslation('', {
		keyPrefix: 'general.transactionType',
	}).t
	const tErrors = useTranslation('', {
		keyPrefix: 'general.modals.bill.createBillModal',
	}).t

	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const [billDetailsEdit, setBillDetalsEdit] = useState<boolean>(false)
	const [editAccess, setEditAccess] = useState(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [details, setDetails] = useState<details>({
		title: '',
		description: '',
		endDate: undefined,
		selectedCurrencyOption: {
			title: '',
			key: '',
		},
		selectedTransactionTypeOption: {
			title: '',
			key: '',
		},
		selectedStatusOption: {
			title: '',
			key: '',
		},
		selectedCategoryOption: {
			title: '',
			key: '',
		},
		selectedEmployeeOption: {
			title: '',
			key: '',
		},
		amount: 0,
		deleteAccess: false,
		account: {
			title: '',
			key: '',
		},
	})
	const [billErrors, setBillErrors] = useState<errors>({})

	const amountInputHandle = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.01'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		details && setDetails({ ...details, amount: numberValue })
		setBillDetalsEdit(details?.amount !== numberValue)
	}

	const { billId } = useParams()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const getAccounts = async (
		search: string,
		page: number
	): Promise<dropdownOption[]> => {
		return authorizedRequest(
			companyAccountUrl(companyId) +
				`?needle=${search}&page=${page}&per_page=10`,
			'GET'
		).then((response) => {
			const { result } = response

			return [
				...result.map(
					(account: { name: string; account_number: string; id: number }) => {
						return {
							title: `${account.name}(${account.account_number})`,
							key: `${account.id}`,
						}
					}
				),
			]
		})
	}

	const checkErrors = () => {
		let hasErrors = false
		if (details?.title.trim() === '') {
			setBillErrors((prevErrors) => ({
				...prevErrors,
				title: tErrors('pleaseEnterTitle'),
			}))
			hasErrors = true
		}

		if (details?.amount === undefined || details?.amount <= 0) {
			setBillErrors((prevErrors) => ({
				...prevErrors,
				amount: tErrors('pleaseEnterBillAmount'),
			}))
		}

		return hasErrors
	}

	const loadData = async () => {
		const response = await authorizedRequest(
			singleBillUrl(Number(billId)),
			'GET'
		)
		if (response === 403) navigate('/ri-business/403')

		const result = response.result

		setBillName(result.title)
		setErrors(result.errors.map(formatErrorResponse))

		setDetails({
			title: result.title,
			selectedCategoryOption: {
				key: result.category,
				title: translateEnum(tExpense, result.category),
			},
			selectedTransactionTypeOption: {
				key: result.bill_type,
				title: translateEnum(tTransaction, result.bill_type),
			},
			selectedStatusOption: {
				key: result.status,
				title: translateEnum(tStatus, result.status),
			},
			selectedEmployeeOption: result.employee
				? {
					key: result.employee.employee_id,
					title: result.employee.name,
					avatar: result.employee.avatar,
				}
				: undefined,
			amount: result.amount,
			description: result.description,
			selectedCurrencyOption: {
				title: tCurrency(result.currency),
				key: result.currency,
			},
			endDate: new Date(result.due_date * 1000),
			deleteAccess: result.delete_access,
			account: result.account
				? {
					title: `${result.account.name}(${result.account.account_number})`,
					key: result.account.id,
				}
				: undefined,
		})

		setEditAccess(result.edit_access)
	}

	const loadEmployees = async (query: string, page: number) => {
		const { result }: { result: employeeResponse[] } = await authorizedRequest(
			companySearchEmployeesUrl(companyId) +
				`?needle=${query}&page=${page}&per_page=${10}`,
			'GET'
		)

		if (result.length > 0) {
			const formatedEmployees: dropdownOption[] = result.map((employee) => {
				return {
					key: `${employee.employee_id}`,
					title: employee.name,
					avatar: employee.avatar,
				}
			})

			return formatedEmployees
		}

		return []
	}

	const editData = async () => {
		const hasErrors = checkErrors()
		if (hasErrors) return
		if (details) {
			const formatedDate = (date: Date | undefined) =>
				date
					? new Date(
						Date.UTC(
							date.getFullYear(),
							date.getMonth(),
							date.getDate(),
							date.getHours(),
							date.getMinutes(),
							date.getSeconds()
						)
					).toISOString()
					: undefined

			await authorizedRequest(
				singleBillUrl(Number(billId)),
				'PUT',
				'accessToken',
				{
					bill_id: billId,
					account_id: details.account ? Number(details.account.key) : null,
					title: details.title,
					currency: details.selectedCurrencyOption.key,
					description: details.description,
					amount: details.amount,
					employee_id: details.selectedEmployeeOption
						? Number(details.selectedEmployeeOption.key)
						: null,
					category: details.selectedCategoryOption.key,
					status: details.selectedStatusOption.key,
					due_date: formatedDate(details.endDate),
					type: details.selectedTransactionTypeOption.key,
				}
			)

			setBillDetalsEdit(false)
		}
	}

	const infoDetailsTableData: infoDetailsTableDataItem[] = [
		{
			title: t('title'),
			data: {
				inputField: {
					value: details.title,
					onChange: (e) => {
						setBillName(e.target.value)
						setDetails({ ...details, title: e.target.value })
						setBillDetalsEdit(true)
					},
					disabled: !editAccess,
					info: billErrors.title ? {
						type: infoType.error,
						text: billErrors.title 
					} : undefined
				},
			},
		},
		{
			title: t('description'),
			data: {
				textArea: {
					value: details.description,
					setValue: (value) => {
						setDetails({ ...details, description: value })
						setBillDetalsEdit(details.description !== value)
					},
				},
			},
		},
		{
			title: t('total'),
			data: {
				floatInput: {
					amountInputHandle: amountInputHandle,
					value: `${details.amount}`,
					disabled: !editAccess,
					info: billErrors.amount ? {
						type: infoType.error,
						text: billErrors.amount 
					} : undefined
				},
			},
		},
		{
			title: t('currency'),
			data: {
				dropdown: {
					placeholder: translateEnum(
						tCurrency,
						details.selectedCurrencyOption.key
					),
					dropdownOptions: createDropdownOption(tCurrency, currency),
					onSelect: ({ key, title }) => {
						setDetails({ ...details, selectedCurrencyOption: { key, title } })
						setBillDetalsEdit(details.selectedCurrencyOption.key !== key)
					},
					selectedOption: details.selectedCurrencyOption,
					disabled: !editAccess,
					clear: false,
				},
			},
		},
		{
			title: t('employee'),
			data: {
				dropdown: {
					placeholder: t('employee'),
					dropdownOptions: [],
					onSelect: ({ key, title }) => {
						setDetails({
							...details,
							selectedEmployeeOption:
								key.length > 0 ? { key, title } : undefined,
						})
						setBillDetalsEdit(details.selectedEmployeeOption?.key !== key)
					},
					loadOptions: loadEmployees,
					selectedOption: details.selectedEmployeeOption,
					disabled: !editAccess,
				},
			},
		},
		{
			title: t('category'),
			data: {
				dropdown: {
					placeholder: translateEnum(
						tExpense,
						details.selectedCategoryOption.key
					),
					dropdownOptions: createDropdownOption(tExpense, expenseCategory),
					onSelect: ({ key, title }) => {
						setDetails({ ...details, selectedCategoryOption: { key, title } })
						setBillDetalsEdit(details.selectedCategoryOption?.key !== key)
					},
					selectedOption: details.selectedCategoryOption,
					disabled: !editAccess,
					clear: false,
				},
			},
		},
		{
			title: t('account'),
			data: {
				dropdown: {
					placeholder: t('account'),
					dropdownOptions: [],
					defaultSelectedOption: details.account,
					onSelect: ({ key, title }) => {
						setDetails({
							...details,
							account: key.length > 0 ? { key, title } : undefined,
						})
						setBillDetalsEdit(details.account?.key !== key)
					},
					selectedOption: details.account,
					loadOptions: getAccounts,
					disabled: !editAccess,
				},
			},
		},
		{
			title: t('status'),
			data: {
				dropdown: {
					placeholder: translateEnum(tStatus, details.selectedStatusOption.key),
					dropdownOptions: createDropdownOption(tStatus, billStatus),
					onSelect: ({ title, key }) => {
						setDetails({ ...details, selectedStatusOption: { title, key } })
						setBillDetalsEdit(details.selectedStatusOption.key !== key)
					},
					selectedOption: details.selectedStatusOption,
					disabled: !editAccess,
					clear: false,
				},
			},
		},
		{
			title: t('transactionType'),
			data: {
				dropdown: {
					placeholder: translateEnum(
						tTransaction,
						details.selectedTransactionTypeOption.key
					),
					dropdownOptions: createDropdownOption(tTransaction, transactionType),
					onSelect: ({ title, key }) => {
						setDetails({
							...details,
							selectedTransactionTypeOption: { title, key },
						})
						setBillDetalsEdit(
							details.selectedTransactionTypeOption?.key !== key
						)
					},
					selectedOption: details.selectedTransactionTypeOption,
					disabled: !editAccess,
					clear: false,
				},
			},
		},
		{
			title: t('dates'),
			data: {
				calendar: {
					dueDate: {
						date: details.endDate,
						setDate: (value) => {
							setDetails({ ...details, endDate: value })
							setBillDetalsEdit(
								details.endDate?.toISOString() !== value?.toISOString()
							)
						},
					},
					disabled: !editAccess,
				},
			},
		},
	]

	useEffect(() => {
		let timeout: NodeJS.Timeout
		if (billDetailsEdit) {
			timeout = setTimeout(() => {
				editData()
			}, 500)
		}
		return () => clearTimeout(timeout)
	}, [details])

	useEffect(() => {
		loadData()
	}, [])

	useEffect(() => {
		setBillErrors({})
	}, [details])

	return (
		<div className="bill-details">
	
			<InfoDetailsTable data={infoDetailsTableData} />
			<ExtraColumnDetails
				type={tableTypes.bill}
				objectId={Number(billId)}
				setLoading={setLoading}
			/>
			<div className="bill-details-actions">
				{details?.deleteAccess ? (
					<Button
						text={t('delete')}
						active={true}
						onClick={() => {
							dispatch(setDeleteBillModalIsOpen(true))
							dispatch(setDeleteBillId(Number(billId)))
						}}
					/>
				) : null}

				<Button
					text={t('pay')}
					active={true}
					onClick={() => {
						dispatch(setPayBillModalIsOpen(true))
						dispatch(setPayBillId(Number(billId)))
					}}
				/>
			</div>
	
			{loading && (
				<Loading style={{ top: '40vh', left: '50vw' }} />
			)}

			<DeleteBillConfirmationModal />
			<PayBillConfirmationModal />
		</div>
	)
}

export default BillDetails
