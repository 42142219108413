// styles
import { vwToPixel } from '../../../../../assets/general/generalFunctions'
import './scrollAnimation.scss'

// react
import { FC, ReactNode, useEffect, useRef, useState } from 'react'

type scrollAnimationProps = {
	children: ReactNode
	direction: 'to-left' | 'to-right' | 'to-bottom' | 'to-top'
	duration?: number
	distance?: number
	id?: string
}


const ScrollAnimation: FC<scrollAnimationProps> = ({children, direction, duration = 1000, distance = 10, id}) => {
	const ref = useRef<HTMLDivElement>(null)

	// const [ scrollY, setScrollY ] = useState(0)
	const [ visible, setVisible ] = useState(false)

	const createInitialStyle = () => {
		switch(direction) {
		case 'to-right':
			return {
				left: `-${distance}vw`
			}
		case 'to-left':
			return {
				left: `${distance}vw`
			}
		case 'to-top':
			return {
				top: `${distance}vw`
			}
		case 'to-bottom':
			return {
				top: `-${distance}vw`
			}
		}
	} 

	const animationFunction = (visible: boolean)=> {
		const container = ref.current
		if(container){
			if(visible) {
				if(direction === 'to-left' || direction === 'to-right') container.style.left = '0vw'
				if(direction === 'to-top' || direction === 'to-bottom') container.style.top = '0vw'
				container.style.opacity = '1'
			} else {
				if(direction === 'to-left') container.style.left = `${distance}vw`
				if(direction === 'to-right') container.style.left = `-${distance}vw`
				if(direction === 'to-top') container.style.top = `${distance}vw`
				if(direction === 'to-bottom') container.style.top = `-${distance}vw`
				container.style.opacity = '0'
			}
		}	
	}

	useEffect(() => {
		animationFunction(visible)
	}, [visible])

	useEffect(() => {
		const updateVisibleStatus = () => {
			const container = ref.current
			if(container){
				vwToPixel(distance)
				const rect = container.getBoundingClientRect()
	
				setVisible(rect.top >= -1*rect.height && rect.bottom <= window.innerHeight+ rect.height)
				
			}
		}
	
		updateVisibleStatus()
		window.addEventListener('scroll', updateVisibleStatus)
	
		return () => {
			window.removeEventListener('scroll', updateVisibleStatus)
		}
	}, [])

	return (
		<div 
			ref={ref} 
			className={`animation-container animate-${direction}`}
			style={{
				transitionDuration: `${duration}ms`,

				...createInitialStyle()
			}}
			id={id}
		>
			{children}
		</div>

	)
}

export default ScrollAnimation