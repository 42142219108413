// styles and icons
import './createCompany.scss'

// react
import { useState, useRef, FormEvent } from 'react'

// components
import Modal from '../modal/modal'
import InputField from '../../inputField/inputField'
import GuideModal from '../guideModal/guideModal'

// network
import { authorizedRequest } from '../../../../utils/queries'
import { createCompanyUrl } from '../../../../utils/old_urls/companyUrls'

// redux
import { useAppSelector, useAppDispatch } from '../../../../customHooks/redux'
import { setCreateCompanyModalIsOpen } from '../../../../redux/general/modals'
import {
	setCompanies,
	setUserCompanyData,
} from '../../../../redux/general/general'

//translaction
import { useTranslation } from 'react-i18next'

//types
import { company, dropdownOption } from '../../../../types/general/generalTypes'
import { setSettingsActiveCompanyId } from '../../../../redux/general/settings/general'
import { whoAmIUrl } from '../../../../utils/old_urls/generalUrls'

const CreateCompany = () => {
	const { t } = useTranslation('', {
		keyPrefix: 'general.modals.createCompany',
	})

	const formRef = useRef<HTMLFormElement | null>(null)

	const [companyName, setCompanyName] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [loading, setLoading] = useState(false)
	const [email, setEmail] = useState('')
	const [selectedTaxationType, setSelectedTaxationType] =
		useState<dropdownOption | null>(null)
	const { modalIsOpen } = useAppSelector(
		(state) => state.generalModals.createCompany
	)
	const { companies, language } = useAppSelector((state) => state.general)

	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setCreateCompanyModalIsOpen(false))
		setCompanyName('')
		setPhoneNumber('')
		setEmail('')
		setSelectedTaxationType(null)
	}

	const createCompany = async () => {
		setLoading(true)
		try {
			const res = await authorizedRequest(
				createCompanyUrl,
				'POST',
				'accessToken',
				{
					name: companyName,
					emails: [email],
					phone_numbers: [phoneNumber],
					language: language,
					private_entrepreneur_status: selectedTaxationType,
				}
			)
			const { result: whoAmIResponse } = await authorizedRequest(
				whoAmIUrl,
				'GET'
			)
			const lastCompany =
				whoAmIResponse.companies[whoAmIResponse.companies.length - 1]
			const employeeId = lastCompany.employee_id

			const pars: company = {
				avatar: res.result.avatar,
				companyId: res.result.company_id,
				employeeId,
				name: res.result.name,
				description: res.result.description,
			}
			console.log(pars, res, whoAmIResponse, employeeId, lastCompany)
			dispatch(setCompanies([...companies, pars]))
			dispatch(
				setUserCompanyData({
					companyId: res.result.company_id,
					companyAvatar: res.result.avatar,
					companyDescription: res.result.description,
					companyName: res.result.name,
					employeeId,
				})
			)
			dispatch(setSettingsActiveCompanyId(res.result.company_id))
		} finally {
			setLoading(false)
			closeModal()
			window.location.reload()
		}
	}
	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		createCompany()
	}

	return (
		<Modal
			closeModal={closeModal}
			open={modalIsOpen}
			isLoading={loading}
			title={t('createACompany')}
			submitButton={{
				text: t('create'),
				onClick: () => {
					if (formRef.current) {
						formRef.current.requestSubmit()
					}
				},
			}}
		>
			<form
				className="create-company-container"
				ref={formRef}
				onSubmit={handleSubmit}
			>
				<InputField
					type="text"
					label={t('companyName')}
					value={companyName}
					onChange={(e) => {
						setCompanyName(e.target.value)
					}}
				/>
				<InputField
					type="text"
					label={t('companyPhoneNumber')}
					value={phoneNumber}
					onChange={(e) => {
						setPhoneNumber(e.target.value)
					}}
				/>
				<InputField
					type="text"
					name="email"
					label={t('companyEmail')}
					value={email}
					onChange={(e) => {
						setEmail(e.target.value)
					}}
				/>
			</form>
		</Modal>
	)
}

export default CreateCompany
