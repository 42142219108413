// WordViewer.tsx
import { FC, useEffect, useRef } from 'react'
import { renderAsync } from 'docx-preview'

//types
import { file } from '../../../../../types/general/generalTypes'

type wordViewerProps =  {
  file: file
}

const WordViewer: FC<wordViewerProps> = ({ file }) => {
	const containerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (file && containerRef.current) {
			const arrayBuffer = file.file
			renderAsync(arrayBuffer, containerRef.current, undefined, {
				inWrapper: false, // Don't wrap the output in an extra div
			}).catch((error) => console.error('Error rendering document:', error))
		}
	}, [file])

	return <div ref={containerRef} style={{ width: '100%', height: '100%' }} />
}

export default WordViewer
