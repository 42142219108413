//styles
import { arrowIcon } from '../../../../assets/general/generalIcons'

//components
import Table from '../../table/table'
import BillTable from '../billTable/billTable'
import Pagination from '../../pagination/pagination'
import DocumentTable from '../documentTable/documentTable'
import TaskTable from '../taskTable/taskTable'
import DeleteProjectConfirmationModal from '../../../projects/pipeline/modals/deleteProjectConfirmationModal/deleteProjectConfirmationModal'

//react
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

//types
import { columnTypes, project, extraColumnsResponse, tableBodyItem, tableBodyItemContent, tableHeader, tableTypes } from '../../../../types/general/generalTypes'

//network
import { singleBudgetBillsUrl } from '../../../../utils/urls/finance/budget/budget'
import { authorizedRequest } from '../../../../utils/queries'
import { companyExtraColumnsUrl } from '../../../../utils/urls/general/company/company'
import { singleProjectDocumentsUrl, singleProjectTasksUrl, singleProjectUrl } from '../../../../utils/urls/projects/project'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector, useAppDispatch } from '../../../../customHooks/redux'
import { setDeleteProjectId, setDeleteProjectModalIsOpen } from '../../../../redux/projects/pipeline/modals'

//other
import { getTableViewData, formatExtraColumnResponse, updateCellValue } from '../../../../assets/general/generalFunctions'
import { formatProjectResponse } from '../../../../assets/projects/projectsFunctions'

type projectTableProps = {
    preloadedProjects?: {
        projects: project[]
        setProjects: (value: project[]) => void
    }
    loadProjects?: {
        projectUrl: string
		showClosed: boolean
    }
}

const ProjectTable: FC<projectTableProps> = ({ preloadedProjects, loadProjects }) => {

	const { t } = useTranslation('', { keyPrefix: 'general.projectTable' })

	const [ columns, setColumns ] = useState<tableHeader[]>([])
	const [ data, setData ] = useState<Record<string, tableBodyItem>[]>([])


	const [ loading, setLoading ] = useState(false)
	const [ lastPage, setLastPage ] = useState(false)
	const [ page, setPage ] = useState(1) 

	const [ projects, setProjects ] = useState<project[]>([])

	const {userCompanyData} = useAppSelector(state => state.general)

	const dispatch = useAppDispatch()

	const companyId = userCompanyData?.companyId || -1

	const formatTableData = (project: project): Record<string, tableBodyItem> => {
		const editAccess = projects.some((project) => project.editAccess)

		return {
			id: {
				content: {
					type: columnTypes.element,
					value: <Link to={`/ri-business/projcets/projcet/${project.id}`}>{project.id}</Link>
				},
				objectId: project.id
			},
			title: {
				content: {
					type: columnTypes.string,
					value: project.title
				},
				objectId: project.id
			},
			description: {
				content: {
					type: columnTypes.string,
					value: project.description
				},
				objectId: project.id
			},
			date: {
				content: {
					type: columnTypes.date,
					value: project.date
				},
				objectId: project.id
			},
			dueDate: {
				content: {
					type: columnTypes.date,
					value: project.dueDate
				},
				objectId: project.id
			},
			tasks: {
				content: {
					type: columnTypes.element,
					value: <div className='nested-table-arrow'>{arrowIcon}</div>
				},
				objectId: project.id,
				extraTable: {
					open: false,
					content: <TaskTable
						loadTasks={{
							taskUrl: singleProjectTasksUrl(project.id),
							showClosed: false
						}}
					/>
				}
			},
			budget:{
				content: {
					type: columnTypes.element,
					value: <Link to={`/ri-business/finance/budget/${project.budget.id}`}>{project.budget.title}</Link>
				},
				objectId: project.id
			},
			bills: {
				content: {
					type: columnTypes.element,
					value: <div className='nested-table-arrow'>{arrowIcon}</div>
				},
				objectId: project.id,
				extraTable: {
					open: false,
					content: <BillTable
						loadBills={{
							billUrl: singleBudgetBillsUrl(project.budget.id)
						}}
					/>
				}
			},
			documents: {
				content: {
					type: columnTypes.element,
					value: <div className='nested-table-arrow'>{arrowIcon}</div>
				},
				objectId: project.id,
				extraTable: {
					open: false,
					content: <DocumentTable
						loadDocuments={{
							documentUrl: singleProjectDocumentsUrl(project.id)
						}}
						parent={{
							type: 'project',
							id: project.id
						}}
						editAccess={editAccess}
						createAccess={editAccess}
						deleteAccess={editAccess}
					/>
				}
			}
		}
	}
    
	const onCellValueChange = async (objectId: number, columnKey: string, newValue: tableBodyItemContent) => {
		updateCellValue(
			objectId,
			companyId,
			columnKey,
			newValue,
			singleProjectUrl,
			[
				{
					frontend: 'title',
					backend: 'title',
					type: columnTypes.string
				},
				{
					frontend: 'description',
					backend: 'description',
					type: columnTypes.string
				}
			],
			projects,
			setProjects,
			preloadedProjects?.setProjects,
			formatProjectResponse,
			tableTypes.project
		)
	}

	const getColumns = async () => {
		const {result}: {result: extraColumnsResponse[]}  = await authorizedRequest(companyExtraColumnsUrl(companyId) + '?table_type=project', 'GET')

		const editAccess = projects.some((project) => project.editAccess)

		setColumns([
			{ key: 'id', title: t('id'), type: columnTypes.number, editAccess: false },
			{ key: 'title', title: t('title'), type: columnTypes.string, editAccess },
			{ key: 'description', title: t('description'), type: columnTypes.string, editAccess },
			{ key: 'date', title: t('date'), type: columnTypes.date, editAccess: false },
			{ key: 'dueDate', title: t('dueDate'), type: columnTypes.date, editAccess: false },
			{ key: 'tasks', title: t('tasks'), editAccess },
			{ key: 'budget', title: t('budget'), editAccess },
			{ key: 'bills', title: t('bills'), editAccess },
			{ key: 'documents', title: t('documents'), editAccess },
			...result.map(formatExtraColumnResponse), 
		])
	}

	useEffect(() => {
		console.log(projects)
		getColumns()

		getTableViewData(
			data,
			tableTypes.project,
			projects,
			formatTableData,
			companyId
		).then((newData) => {
			setData([...data, ...newData])
		})

	}, [projects])


	const loadData = async (page: number) => {
		if(loadProjects){
			try {
				setLoading(true)
    
				const { result } = await authorizedRequest(loadProjects.projectUrl + `?page=${page}&per_page=${10}&show_closed=${loadProjects.showClosed}`, 'GET')
    
				if (result.length > 0) {
					const formatedData: project[] = result.map(formatProjectResponse)
					return formatedData
				}
    
				return []
    
			} finally {
				setLoading(false)
			}
		}
	}

	const handleLoadMore = () => {

		if (!loading && !lastPage) {
			loadData(page)
				.then((result) => {

					if(result){
						setProjects([...projects, ...result])
						if (result.length > 0) {
							setPage(page + 1)
						} else {
							setLastPage(true)
						}
					}
				})
		}
	}

	useEffect(() => {
		if(loadProjects){
			handleLoadMore()
		}
	}, [loadProjects])

	useEffect(() => {
		if(preloadedProjects){
			setProjects(preloadedProjects.projects)
		}
	}, [preloadedProjects])

	return (
		<>
			{loadProjects ? 
				<Pagination onLoadMore={handleLoadMore} onlyLoadOn='bottom' loading={loading} showLoader={true}>
					<Table
						columns={columns}
						setColumns={setColumns}
						data={data}
						setData={setData}
						onCellValueChange={onCellValueChange}
						tableType={tableTypes.project}
						deleteAction={projects.some((project) => project.deleteAccess) ? (projectId) => {
							dispatch(setDeleteProjectModalIsOpen(true))
							dispatch(setDeleteProjectId(projectId))
						} : undefined}
						addColumn={projects.some((project) => project.editAccess)}
					/>
				</Pagination>
				:
				<Table
					columns={columns}
					setColumns={setColumns}
					data={data}
					setData={setData}
					onCellValueChange={onCellValueChange}
					tableType={tableTypes.project}
					deleteAction={projects.some((project) => project.deleteAccess) ? (projectId) => {
						dispatch(setDeleteProjectModalIsOpen(true))
						dispatch(setDeleteProjectId(projectId))
					} : undefined}
					addColumn={projects.some((project) => project.editAccess)}
				/>}
			<DeleteProjectConfirmationModal stagesList={[]} setStagesList={() => {}} projects={projects} setProjects={setProjects} />
		</>
	)
}

export default ProjectTable