// style & icons
import './myTasks.scss'
import { dashboardIcon, listIcon, calendar, companyIcon, tableIcon } from '../../../assets/general/generalIcons'
import { taskListIcon } from '../../../assets/projects/project/projectIcons'

// react
import { FC, useMemo, useState } from 'react'

// components
import Layout from '../../../components/general/layout/layout'
import MyTasksCalendar from '../../../components/projects/myTasks/myTasksCalendar/myTasksCalendar'
import SwitchButtons from '../../../components/general/switchButtons/switchButtons'
import TaskDashboard from '../../../components/general/taskDashboard/taskDashboard'
import TaskList from '../../../components/projects/general/taskList/taskList'
import CheckBox from '../../../components/general/checkBox/checkBox'
import TaskTable from '../../../components/general/tableViews/taskTable/taskTable'

//redux
import { useAppSelector } from '../../../customHooks/redux'

// types
import { filterBar, infoPopupTypes, stageResponse, switchButton, task, taskResponse } from '../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../utils/queries'
import { singleEmployeeTaskCalendarUrl, singleEmployeeTasksUrl } from '../../../utils/urls/employees/employee'
import { getCompanyTaskCalendarUrl, getCompanyTasksUrl } from '../../../utils/urls/general/company/company'

// other
import { formatStageResponse, formatTaskResponse } from '../../../assets/projects/projectsFunctions'

// translation
import { useTranslation } from 'react-i18next'

const MyTasks: FC = () => {

	const { t } = useTranslation('', { keyPrefix: 'projects.myTasks' })

	const { userCompanyData } = useAppSelector((state) => state.general)

	const employeeId: number = userCompanyData?.employeeId || -1

	const [activeToolBarIconId, setActiveToolBarIcon] = useState(0)
	const [activeTypeIcon, setActiveTypeIcon]=useState(3)

	const [filterBar, setFilterBar] = useState<filterBar | undefined>()
	const [showClosed, setShowClosed] = useState(false)

	const titleUrls = [
		{
			url: '/ri-business/projects/my-tasks',
			title: t('myTasks')
		}
	]

	const infoPopup = {
		content: [{
			title: 'my_tasks_info_title',
			description: 'my_tasks_info_description',
			link: '/ri-manual/projects/my-tasks'
		}],
		type: infoPopupTypes.INFO
	}



	const toolBarButtons: switchButton[] = [
		{
			id: 0,
			icon: dashboardIcon
		},
		{
			id: 1,
			icon: listIcon
		},
		{
			id: 2,
			icon: calendar
		},
		{
			id: 3,
			icon: tableIcon
		}
	]

	const rightButtons:switchButton[]=[
		{
			id: 3,
			icon: taskListIcon
		},
		{
			id: 4,
			icon: companyIcon
		}
	]

	const loadCompanyTasks = async (stageId: number, searchRequest: string, page: number, showClosed: boolean) => {
		if (!userCompanyData?.companyId) {
			return
		}
		const { result } = await authorizedRequest(getCompanyTasksUrl(userCompanyData.companyId) +`?&needle=${searchRequest}&status=${stageId}&page=${page}&show_closed=${showClosed}`, 'GET')
		return result.map((task: taskResponse) => formatTaskResponse(task))
	}

	const loadCompanyStages = async (showClosed: boolean) => {
		if (!userCompanyData?.companyId) {
			return
		}

		const response = await authorizedRequest(getCompanyTasksUrl(userCompanyData.companyId) + `?show_closed=${showClosed}`, 'PATCH')
		const { result } = response
		return result.map((stage: stageResponse) => formatStageResponse(stage))
	}

	const loadCompanyTaskCalendar = async () => {
		if (!userCompanyData?.companyId) {
			return
		}

		const response = await authorizedRequest(getCompanyTaskCalendarUrl(userCompanyData.companyId), 'GET')
		const { result } = response
		return result.map((task: taskResponse) => formatTaskResponse(task))
	}


	const loadTasks = async (stageId: number, searchRequest: string, page: number, showClosed: boolean) => {
		const response = await authorizedRequest(singleEmployeeTasksUrl(employeeId) + `?&needle=${searchRequest}&status=${stageId}&page=${page}&show_closed=${showClosed}`, 'GET')
		const { result } = response
		return result.map((task: taskResponse) => formatTaskResponse(task))

	}

	const loadStages = async (showClosed: boolean) => {
		const response = await authorizedRequest(singleEmployeeTasksUrl(employeeId) + `?show_closed=${showClosed}`, 'PATCH')
		const { result } = response
		return result.map((stage: stageResponse) => formatStageResponse(stage))
	}

	const loadTaskCalendar = async () => {
		const response = await authorizedRequest(singleEmployeeTaskCalendarUrl(employeeId), 'GET')
		const { result } = response
		return result.map((task: taskResponse) => formatTaskResponse(task))
	}

	const toolBar = useMemo(() => {
		if (activeTypeIcon === 3) {
			switch (activeToolBarIconId) {
			case 0:
				return  <TaskDashboard loadStages={loadStages} showClosed={showClosed} key={'myTasks'} loadTasks={loadTasks} isMyTasks={true}/>
			case 1:
				return  <TaskList setFilterBar={setFilterBar}  loadTasks={loadTasks} key={showClosed ? 'myTasksList' : 'myTasksListNoClosed'}  showClosed={showClosed} />
			case 2:
				return  <MyTasksCalendar showClosed={showClosed} key={showClosed ? 'myTasksCalendar' : 'myTasksCalendarNoClosed'} loadTasks={loadTaskCalendar} />
			case 3:
				return <TaskTable loadTasks={{
					taskUrl: singleEmployeeTasksUrl(employeeId),
					showClosed: showClosed
				}} />
			}
		}else{
			switch (activeToolBarIconId) {
			case 0:
				return <TaskDashboard showClosed={showClosed} key={'companyTasks'} loadStages={loadCompanyStages} loadTasks={loadCompanyTasks} isMyTasks={true} />
			case 1:
				return  <TaskList setFilterBar={setFilterBar} loadTasks={loadCompanyTasks} key={showClosed ? 'companyTasksList' : 'companyTasksListNoClosed'} showClosed={showClosed} />
			case 2:
				return  <MyTasksCalendar showClosed={showClosed} key={showClosed ? 'companyTasksCalendar' : 'companyCalendarNoClosed'} loadTasks={loadCompanyTaskCalendar} />
			case 3:
				return <TaskTable loadTasks={{
					taskUrl: getCompanyTasksUrl(userCompanyData?.companyId ? userCompanyData?.companyId : -1),
					showClosed: showClosed
				}} />
			}
		}

	}, [activeToolBarIconId, activeTypeIcon, showClosed])
	
	return (
		<Layout
			header={{
				avatar: true,
			}}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>
			<div className='my-tasks'>
				<div className="my-tasks-tool-bar">
					<div className='my-tasks-tool-bar-left'>
						<SwitchButtons switchButtons={toolBarButtons} activeSwitchButtonId={activeToolBarIconId} setActiveSwitchButtonId={setActiveToolBarIcon} size='medium' />
						<CheckBox label={t('showClosed')} isChecked={showClosed} onClick={() => setShowClosed(prev=>!prev)} />
					</div>
					<SwitchButtons  switchButtons={rightButtons} activeSwitchButtonId={activeTypeIcon} setActiveSwitchButtonId={setActiveTypeIcon} size='medium'/>
				</div>
				{toolBar}
			</div>
		</Layout>
	)
}

export default MyTasks