// react
import { useState, FC } from 'react'

//components
import Layout from '../../../components/general/layout/layout'
import CompanyProductivity from '../../../components/employees/productivity/companyProductivity/companyProductivity'
import IndividualProductivity from '../../../components/employees/productivity/individualProductivity/individualProductivity'

// types
import {
	button,
	filterBar,
	infoPopupTypes
} from '../../../types/general/generalTypes'

// translation
import { useTranslation } from 'react-i18next'

const Productivity: FC = () => {
	const [activeProjectSection, setActiveProjectSection] = useState('company')
	const [filterBar, setFilterBar] = useState<filterBar | undefined>()

	const { t } = useTranslation('', { keyPrefix: 'employees.productivity' })


	const projectTabButtons: button[] = [
		{
			active: 'company' === activeProjectSection,
			text: t('company'),
			onClick: () => { setActiveProjectSection('company') }
		},
		{
			active: 'individual' === activeProjectSection,
			text: t('individual'),
			onClick: () => { setActiveProjectSection('individual') }
		}
	]

	const titleUrls = [
		{
			url: '/ri-business/employees/productivity',
			title: t('productivity')
		}
	]

	const infoPopup = {
		content: [{
			title: 'productivity_info_title',
			description: 'productivity_info_description',
			link: '/ri-manual/employees/productivity'
		}],
		type: infoPopupTypes.INFO
	}

	return (
		<Layout
			header={{
				avatar: true
			}}
			tabButtons={projectTabButtons}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>

			<div className='project-wrapper'>
				{
					activeProjectSection === 'company' ?
						<CompanyProductivity />
						: null
				}
				{
					activeProjectSection === 'individual' ?
						<IndividualProductivity setFilterBar={setFilterBar} />
						: null
				}
				
			</div>
		</Layout>
	)

}

export default Productivity