// styles
import './providerOfferingDetails.scss'
import { productOffer } from '../../../../assets/general/generalIcons'

// react
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import InfoDetailsTable from '../../../general/infoDetailsTable/infoDetailsTable'
import AvatarPicker from '../../../general/avatarPicker/avatarPicker'
import DocumentList from '../../../general/documentList/documentList'
import FilterBar from '../../../general/layout/filterBar/filterBar'
import ImportOfferingImageSelectorModal
	from './modals/importOfferingImageFileSelectorModal/importOfferingImageSelectorModal'
import DeleteOfferingImageModal from './modals/deleteOfferingImageModal/deleteOfferingImageModal'
import Loading from '../../../general/loading/loading'

// network
import { authorizedRequest } from '../../../../utils/queries'
import { singleOfferingUrl } from '../../../../utils/urls/sales/offerings'

// types
import {
	currency,
	document,
	infoDetailsTableDataItem,
	moneyValue,
	offeringResponse
} from '../../../../types/general/generalTypes'

// other
import { blobStringToBase64, formatStringIntoTwoDigitsFloat } from '../../../../assets/general/generalFunctions'
import { useTranslation } from 'react-i18next'

// redux
import { useAppDispatch } from '../../../../customHooks/redux'
import {
	setDeleteOfferingImageId,
	setDeleteOfferingImageModalIsOpen,
	setImportOfferingImageSelectorModalIsOpen
} from '../../../../redux/sales/offering/modals'


type providerOfferingDetailsProps = {
	setOfferingName: Dispatch<SetStateAction<string>>
	setProviderName: Dispatch<SetStateAction<string>>
	setProviderId: Dispatch<SetStateAction<number | undefined>>

}

type details = {
	name: string
	description: string
	avatar: string | null
	price: moneyValue
	vat: number
	editAccess: boolean
}

const ProviderOfferingDetails: FC<providerOfferingDetailsProps> = ({ setOfferingName, setProviderName, setProviderId }) => {

	const [editDetails, setEditDetails] = useState<boolean>(false)
	const [details, setDetails] = useState<details>({
		name: '',
		description: '',
		avatar: null,
		price: {
			amount: 0,
			currency: currency.USD
		},
		vat: 0,
		editAccess: false,
	})

	const [images, setImages] = useState<document[]>([])
	const { offeringId } = useParams()
	const dispatch = useAppDispatch()

	const { t } = useTranslation('', { keyPrefix: 'sales.offering.offeringDetails' })
	const tCurrency = useTranslation('', { keyPrefix: 'general.currency' }).t

	const infoDetailsTableData: infoDetailsTableDataItem[] = [
		{
			title: t('offeringName'),
			data: {
				inputField: {
					type: 'text',
					value: details.name,
					onChange: (e) => {
						setOfferingName(e.target.value)
						setDetails({ ...details, name: e.target.value })
						setEditDetails(true)
					},
					disabled: !details.editAccess
				}
			}
		},
		{
			title: t('description'),
			data: {
				textArea: {
					value: details.description,
					setValue: (value) => {
						setDetails({ ...details, description: value })
						setEditDetails(details.description !== value)
					},
					disabled: !details.editAccess
				}
			}
		},
		{
			title: t('priceWithoutVat'),
			data: {
				floatInput: {
					type: 'text',
					value: `${formatStringIntoTwoDigitsFloat(`${details.price.amount / (1 + details.vat / 100)}`)}`,
					amountInputHandle: (e) => {
						if (e.length < 1) {
							e = '00.00'
						}
						const numberValue = formatStringIntoTwoDigitsFloat(e)
						setDetails({
							...details,
							price: {
								...details.price,
								amount: formatStringIntoTwoDigitsFloat(`${numberValue * (1 + details.vat / 100)}`)
							}
						})
						setEditDetails(details.price.amount!==formatStringIntoTwoDigitsFloat(`${numberValue * (1 + details.vat / 100)}`))
					},
					disabled: !details.editAccess
				}
			}
		},
		{
			title: t('priceWithVat'),
			data: {
				floatInput: {
					type: 'text',
					value: `${details.price.amount}`,
					amountInputHandle: (e) => {
						if (e.length < 1) {
							e = '00.00'
						}
						const numberValue = formatStringIntoTwoDigitsFloat(e)
						setDetails({
							...details,
							price: {
								...details.price,
								amount: numberValue
							}
						})
						setEditDetails(details.price.amount!==numberValue)
					},
					disabled: !details.editAccess
				}
			}
		},
		{
			title: t('vat'),
			data: {
				floatInput: {
					type: 'text',
					value: `${details.vat}%`,
					amountInputHandle: (e) => {
						if (e.length < 1) {
							e = '00.00'
						}
						const numberValue = formatStringIntoTwoDigitsFloat(e)
						setDetails({
							...details,
							vat: numberValue
						})
						setEditDetails(details.vat!==numberValue)
					},
					disabled: !details.editAccess
				}
			}
		},
		{
			title: t('currency'),
			data: {
				dropdown: {
					placeholder: '',
					dropdownOptions: [{ title: tCurrency(currency.UAH), key: currency.UAH }, { title: tCurrency(currency.USD), key: currency.USD }, { title: tCurrency(currency.EUR), key: currency.EUR }],
					defaultSelectedOption: {
						key: details.price.currency,
						title: tCurrency(details.price.currency)
					},
					onSelect: (option) => {
						setDetails({ ...details, price: { ...details.price, currency: option.key as currency } })
						setEditDetails(details.price.currency!==option.key)
					},
					selectedOption: { key: details.price.currency, title: tCurrency(details.price.currency) },
					disabled: !details.editAccess
				}
			}
		}
	]
	

	const loadData = async () => {
		const { result }: { result: offeringResponse } = await authorizedRequest(singleOfferingUrl(Number(offeringId)), 'GET')

		const avatar = result.avatar ? await blobStringToBase64(result.avatar!, 'image/png') : null

		setDetails({
			name: result.name,
			description: result.description,
			avatar: avatar,
			price: result.price,
			vat: result.vat,
			editAccess: result.edit_access
		})

		setOfferingName(result.name)
	}

	const editData = async () => {

		if (details) {
			await authorizedRequest(singleOfferingUrl(Number(offeringId)), 'PUT', 'accessToken', {
				name: details.name,
				price: details.price.amount,
				vat: details.vat,
				currency: details.price.currency,
				description: details.description,
				avatar: details.avatar,
			})
			setEditDetails(true)
		}
	}

	useEffect(() => {
		let timeout: NodeJS.Timeout
		if (editDetails) {
			timeout = setTimeout(() => {
				editData()
			}, 500)
		}
		return () => clearTimeout(timeout)
	}, [details])

	useEffect(() => {
		loadData()
	}, [])


	return (
		<>
			<AvatarPicker currenctAvatar={details ? details.avatar : null} defaultAvatar={productOffer} submitAvatar={(avatar) => {
				if (details) {
					setDetails({ ...details, avatar: avatar })
					setEditDetails(true)
				}
			}} />
			<InfoDetailsTable data={infoDetailsTableData} />
			<div className='product-images'>
				<div className='product-images-header'>
					<h3 className='product-images-header-heading'>{t('offeringImages')}</h3>
					<FilterBar addButton={{
						text: t('add'),
						active: true,
						onClick: () => dispatch(setImportOfferingImageSelectorModalIsOpen(true))
					}} />
				</div>

				<DocumentList
					fileList={images}
					handleDelete={(id) => {
						dispatch(setDeleteOfferingImageId(id))
						dispatch(setDeleteOfferingImageModalIsOpen(true))
					}}
				/>
				<ImportOfferingImageSelectorModal
					images={images}
					setImages={setImages}
					setEditDetails={setEditDetails}
				/>
				<DeleteOfferingImageModal
					handleDelete={(id) => {
						const updatedImages = images.filter((_, idx) => idx !== id)
						setImages(updatedImages)
						setEditDetails(true)
					}}
				/>
			</div>
			{!details && <Loading style={{ top: '40vh', left: '50vw' }} />}
		</>
	)
}

export default ProviderOfferingDetails