//styles and icons
import './resetPasswordEmail.scss'

//react
import { useEffect, useState } from 'react'

//components
import InputField from '../../../components/general/inputField/inputField'
import Button from '../../../components/general/button/button'
import AuthenticationHeader from '../../../components/authentication/general/authenticationHeader/authenticationHeader'
import Loading from '../../../components/general/loading/loading'
import LanguageSelector from '../../../components/general/languageSelector/languageSelector'

//network
import { resetPasswordUrl } from '../../../utils/old_urls/resetPassword'
import { unauthorizedRequest } from '../../../utils/queries'

//redux
import { useAppSelector } from '../../../customHooks/redux'

//translation
import { useTranslation } from 'react-i18next'

//hooks
import { useNavigate } from 'react-router-dom'
import { infoType } from '../../../types/general/generalTypes'

type formInfo = {
	email?: string
	type?: infoType
}

const ResetPasswordEmail = () => {
	const [loading, setLoading] = useState(false)

	const { t } = useTranslation('', { keyPrefix: 'authentication.resetPasswordEmail' })

	const { language } = useAppSelector((state) => state.general)

	const [info, setInfo] = useState<formInfo>({})
	const [email, setEmail] = useState<string>('')
	const navigate = useNavigate()

	const checkErrors = () => {
		let result = true
		const invalidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
		if (email === '' || !invalidEmail) {
			setInfo(prevErrors => ({ ...prevErrors, email: t('pleaseEnterYourEmail'), type: infoType.error }))
			result = false
		}

		return result
	}

	// resetPasswordEmail method
	const onSubmit = async () => {
		if (checkErrors()) {
			setLoading(true)
			try {
				const emailValidate = await unauthorizedRequest(resetPasswordUrl, 'POST', { email: email, message: t('message'), language: language })

				if (emailValidate === 404) {
					setInfo({
						...info,
						type: infoType.error,
						email: t('thereIsNoAccountRegisteredWithThisEmail')
					})
				}else {
					setInfo({
						type: infoType.success,
						email: t('verificationEmailSentSuccessfully') 
					})
				}
			} finally {
				setLoading(false)
			}



			// // Api call to check email valide will go here

			// navigate('/reset-password')
		}
	}

	useEffect(() => {
		setInfo({})
	}, [email])

	return (
		<div className="reset-password-email">
			<div className="reset-password-email-container">
				<AuthenticationHeader
					title={t('recoveryPassword')}
					subtitle={{
						text: t('haveAnAccount'),
						link: {
							text: t('signIn'),
							url: '/login'
						}
					}}
				/>
				<form onSubmit={(e) => {
					e.preventDefault()
					onSubmit()
				}} className="reset-password-email-form">
					<div className="form-controls">
						<InputField
							type="text"
							onChange={(e) => setEmail(e.target.value)}
							value={email}
							placeholder={t('email')}
							info={info.email && info.type ? {
								type: info.type,
								text: info.email 
							} : undefined}
						/>
					</div>
					<Button active={true} text={t('sendTheLink')} onClick={onSubmit} />
				</form>
				{loading ? <Loading /> : null}
			</div>
			<div className='reset-password-email-langueage'>
				<LanguageSelector />
			</div>
		</div>
	)
}

export default ResetPasswordEmail
