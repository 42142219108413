// styles and icons
import './table.scss'
import { arrowIcon, plusIcon } from '../../../assets/general/generalIcons' 

// react
import  { FC, useEffect } from 'react'

//types
import { columnTypes, tableBodyItem, tableBodyItemContent, tableHeader, tableTypes } from '../../../types/general/generalTypes'
import { TFunction } from 'i18next'

//redux
import { useAppDispatch } from '../../../customHooks/redux'
import { setCreateColumnModalIsOpen, setDeleteColumnModalIsOpen, setDeleteColumnId } from '../../../redux/general/modals' 

//components
import EditableCell from './editableCell/editableCell'
import CreateColumnModal from './modals/createColumnModal/createColumnModal'
import DeleteColumnConfirmationModal from './modals/deleteColumnConfirmationModal/deleteColumnConfirmationModal'

//network
import { authorizedRequest } from '../../../utils/queries'
import { singleExtraColumnsUrl } from '../../../utils/urls/general/company/company'

//other
import { formatExtraColumnResponse } from '../../../assets/general/generalFunctions'

type tableProps = {
  columns: tableHeader[]
  setColumns?: (value: tableHeader[]) => void
  data: Record<string, tableBodyItem>[]
  setData?: (value: Record<string, tableBodyItem>[]) => void
  showHeader?: boolean
  t?: TFunction
  onCellValueChange?: (objectId: number, columnKey: string, newValue: tableBodyItemContent) => void
  tableType?: tableTypes
  deleteAction?: (objectId: number) => void
  createAction?: () => void
  addColumn?: boolean
}

const Table: FC<tableProps> = ({ columns, setColumns, data, setData, showHeader = true, t, onCellValueChange, tableType, deleteAction, createAction, addColumn }) => {

	const handleCellValueChange = (objectId: number, columnKey: string, newValue: tableBodyItemContent) => {
		onCellValueChange && onCellValueChange(objectId, columnKey, newValue)
	}

	const dispatch = useAppDispatch()

	return (
		<>
			<table className='table'>
				{
					showHeader && 
						<thead className='table-head'>
							<tr className='table-head-row'>
								{
									deleteAction || createAction ? 
										<th key='add-column-button' onClick={() => {
											createAction && createAction()
										}}>
											{createAction && plusIcon}
										</th>
										: null
								}

								{columns.map((column) => (
									<th key={column.key}>
										<div className="table-head-row-content">
											{
												!!column?.extraColumn?.id && !!column.editAccess ? 
													<>	
														<div className="table-head-row-delete" onClick={() => {
															dispatch(setDeleteColumnModalIsOpen(true))
															dispatch(setDeleteColumnId(column?.extraColumn?.id || null))
														}}>
															{plusIcon}
														</div>
														<EditableCell
															key={column.key}
															content={{
																type: columnTypes.string,
																value: t ? t(column.title.toString()).toLowerCase() : column.title.toString().toLowerCase()
															}}
															isEditable={true}
															onChange={(newValue) => {
																console.log(newValue,'new value')
																const existingKey = columns.find((c) => c.key === `${newValue}`.toLowerCase().replace(' ', '_'))

																if(existingKey){
																	return 
																}

																authorizedRequest(singleExtraColumnsUrl(column?.extraColumn?.id || -1), 'PUT', 'accessToken', {
																	name: newValue
																})
																	.then(({result}) => {
																		const newColumns: tableHeader[] = []
																		const newData: Record<string, tableBodyItem>[] = []
																	
																		columns.forEach((c) => {
																			newColumns.push(c.extraColumn?.id && c.extraColumn?.id === column.extraColumn?.id ? formatExtraColumnResponse(result): c)
																		})
																	
																		data.forEach((d) => {
																			let dataRow: Record<string, tableBodyItem> = {}

																			for (const property in d) {
																				if (property === column.key) {
																					dataRow = {
																						...dataRow,
																						[`${newValue}`.toLowerCase().replace(' ', '_')]: d[property]
																					}
																				}else{
																					dataRow = {
																						...dataRow,
																						[property]: d[property]
																					}
																				}
																			}

																			newData.push(dataRow)
																		})

																		console.warn(newData)
																	
																		setData && setData([...newData])
																		setColumns && setColumns([...newColumns])
																	})
															}}
														/>
													</>
													: t ? t(column.title.toString()).toLowerCase() : column.title.toString().toLowerCase()
											}
											
											
										</div>
									</th>
								))}

								{
									addColumn &&
										<th className='add-column-button' onClick={() => {
											dispatch(setCreateColumnModalIsOpen(true))
										}}>
											{plusIcon}
										</th>
								}
							</tr>

						</thead>
				}
				
				<tbody className='table-body'>
					{data.map((item, rowIndex) => {
						let objectId: number | undefined

						for(let i=0; i<columns.length; i++) {
							objectId = item[columns[i].key]?.objectId
						}

						return (
							<>
								<tr key={`table-body-row-${rowIndex}`} className='table-body-row'>

									{
										deleteAction || createAction ?
											<td className='table-body-row-delete' onClick={() => {
												if(objectId && deleteAction){
													deleteAction(objectId)
												}
											}}>
												{plusIcon}
											</td>
											: null
									}
									{columns.map((column) => 
									{
										if(objectId === undefined){
											objectId = item[column.key]?.objectId
										}
											
										const itemContent = item[column.key]?.content || {
											type: column.type,
											value: null
										}

										const itemExtraTable = item[column.key]?.extraTable || undefined
												
										return (
											<EditableCell
												key={column.key + rowIndex}
												content={
													column.translate ? 
														{
															type: columnTypes.string,
															value: t ? t(`${itemContent.value || ''}`) : `${itemContent.value}`
														}
														: {...itemContent}
												}
												dropdown={column.dropdown}
												loadSelectionOptions={column.loadSelectionOptions}
												isEditable={!!onCellValueChange && !!column.editAccess && itemExtraTable === undefined}
												onChange={(newValue) => handleCellValueChange(objectId || -1, column.key , newValue)}
												extraTable={itemExtraTable ? {
													open: itemExtraTable.open,
													setOpen: (value) => {
														const newData = [...data]

														newData[rowIndex] = {
															...item,
															[column.key]: {
																...item[column.key],
																content: {
																	type: columnTypes.element,
																	value: <div className={`nested-table-arrow ${value ? 'nested-table-arrow-hide' : ''}`}>{arrowIcon}</div>
																},
																extraTable: {
																	...(item[column.key].extraTable as {content: JSX.Element, open: boolean}),
																	open: value
																}
															}
														}
														setData && setData([...newData])
													}
												} : undefined}
											/>
										)
									}
									)}
									{
										addColumn &&
											<td className='table-body-row-delete'></td>
									}

								</tr>
								{columns.map((column) => 
								{
									if(objectId === undefined){
										objectId = item[column.key]?.objectId
									}
										
									const itemData = item[column.key]

									let colSpan = columns.length

									if(deleteAction || createAction){
										colSpan++
									}

									if(columns.some((column) => column.editAccess)){
										colSpan++
									}

									return ( itemData?.extraTable &&
											<tr className={`table-body-row ${itemData?.extraTable?.open ? '' : 'table-row-hidden'}`}>
												<td colSpan={colSpan}>{itemData.extraTable.content}</td>
											</tr>
									)
								}
								)}
							</>
						)
					})}
				</tbody>
			</table>
			{setColumns && tableType && <CreateColumnModal columns={columns} setColumns={setColumns} tableType={tableType}/>}
			{setColumns && <DeleteColumnConfirmationModal columns={columns} setColumns={setColumns} /> }
		</>
	)
}

export default Table
