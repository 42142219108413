//styles
import { arrowIcon, productOffer } from '../../../../assets/general/generalIcons'

//components
import Table from '../../table/table'
import Pagination from '../../pagination/pagination'
import CreateCustomerOrderModal from '../../../sales/customer/customerOrderList/modals/createCustomerOrderModal/createCustomerOrderModal'
import CreateProviderOrderModal from '../../../purchase/provider/providerOrderList/modals/createProviderOrderModal/createProviderOrderModal'
import CreateSalesOrderModal from '../../../sales/salesOrderList/modals/createSalesOrderModal/createSalesOrderModal'
import CreatePurchaseOrderModal from '../../../purchase/purchaseOrderList/modals/createPurchaseOrderModal/createPurchaseOrderModal'
import DeleteOrderModal from '../../order/orderDetails/modals/deleteOrderConfirmationModal/deleteOrderModal'
import OrderTableOfferings from './orderTableOfferings/orderTableOfferings'

//react
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

//types
import { columnTypes, currency, extraColumnsResponse, offering, order, tableBodyItem, tableHeader, tableTypes } from '../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../utils/queries'
import { companyExtraColumnsUrl } from '../../../../utils/urls/general/company/company'
//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector, useAppDispatch } from '../../../../customHooks/redux'
import { setDeleteOrderId, setDeleteOrdernModalIsOpen } from '../../../../redux/general/order/modals'
import { setCreateCustomerOrderModalIsOpen } from '../../../../redux/sales/customer/modals'
import { setCreateProviderOrderModalIsOpen } from '../../../../redux/purchase/provider/modals'
import { setCreateSalesOrderModalIsOpen } from '../../../../redux/sales/salesOrderList/modals'
import { setCreatePurchaseOrderModalIsOpen } from '../../../../redux/purchase/purchaseOrderList/modals'

//other
import { formatDate, getTableViewData, formatExtraColumnResponse, formatOrderResponse } from '../../../../assets/general/generalFunctions'



type orderTableProps = {
    loadOrders?: {
        orderUrl: string
    }
    preloadedOrders?: {
        orders: order[]
        setOrders: (value: order[]) => void
    }
    parent: {
        type: 'provider' | 'customer' | 'sales' | 'purchase' 
    }
}

const OrderTable: FC<orderTableProps> = ({ loadOrders, preloadedOrders, parent }) => {

	const { t } = useTranslation('', { keyPrefix: 'general.orderTable' })

	const [ columns, setColumns ] = useState<tableHeader[]>([])
	const [ data, setData ] = useState<Record<string, tableBodyItem>[]>([])

	const [ loading, setLoading ] = useState(false)
	const [ lastPage, setLastPage ] = useState(false)
	const [ page, setPage ] = useState(1) 

	const [ orders, setOrders ] = useState<order[]>([])

	const [ deleteModalIsOpen, setDeleteModalIsOpen ] = useState(false) 
	const [ createModalIsOpen, setCreateModalIsOpen ] = useState(false) 
    
	const {userCompanyData} = useAppSelector(state => state.general)

	const { deleteOrderModal } = useAppSelector(state => state.orderModals)

	const { createCustomerOrderModal } = useAppSelector(state => state.customerModal.customerOrders)
	const { createProviderOrderModal } = useAppSelector(state => state.providerModal.providerOrders)
	const { createSalesOrderModal } = useAppSelector(state => state.salesOrderListModal)
	const { createPurchaseOrderModal } = useAppSelector(state => state.purchaseOrderListModal)


	const dispatch = useAppDispatch()

	const companyId = userCompanyData?.companyId || -1

	const getTotal = (items: offering[]) => {
		const total = [
			{ amount: 0, currency: currency.USD },
			{ amount: 0, currency: currency.EUR },
			{ amount: 0, currency: currency.UAH }
		]

		items.forEach((item) => {
			if (item.price.currency == currency.USD) total[0].amount += item.price.amount * item.quantity
			if (item.price.currency == currency.EUR) total[1].amount += item.price.amount * item.quantity
			if (item.price.currency == currency.UAH) total[2].amount += item.price.amount * item.quantity
		})
		return total

	}

    

	const formatTableData = (order: order): Record<string, tableBodyItem> => {

		const totals = getTotal(order.items)
    
		return {
			id: {
				content: {
					type: columnTypes.element,
					value: <Link to={`/ri-business/order/${order.id}`}>{order.id}</Link>
				},
				objectId: order.id
			},
			totalUAH: {
				content: {
					type: columnTypes.number,
					value: totals[2].amount
				},
				objectId: order.id
			},
			totalUSD: {
				content: {
					type: columnTypes.number,
					value: totals[1].amount
				},
				objectId: order.id
			},
			totalEUR: {
				content: {
					type: columnTypes.number,
					value: totals[0].amount
				},
				objectId: order.id
			},
			startDate: {
				content: {
					type: columnTypes.date,
					value: order.startDate ? new Date(order.startDate) : undefined
				},
				objectId: order.id
			},
			endDate: {
				content: {
					type: columnTypes.date,
					value: order.endDate ? new Date(order.endDate) : undefined
				},
				objectId: order.id
			},
			address: {
				content: {
					type: columnTypes.string,
					value: order.destination ? order.destination.address : ''
				},
				objectId: order.id
			},
			offerings: {
				content: {
					type: columnTypes.element,
					value: <div className='nested-table-arrow'>{arrowIcon}</div>
				},
				extraTable: {
					open: false,
					content: <OrderTableOfferings order={order} />
				},
				objectId: order.id
			}
		}
	}



	const getColumns = async () => {
		const {result}: {result: extraColumnsResponse[]}  = await authorizedRequest(companyExtraColumnsUrl(companyId) + '?table_type=bill', 'GET')

		const editAccess = orders.some((order) => order.editAccess)

		setColumns([
			{ key: 'id', title: t('id'), type: columnTypes.number, editAccess: false },
			{ key: 'totalUAH', title: t('totalUAH'), type: columnTypes.string, editAccess: false },
			{ key: 'totalUSD', title: t('totalUSD'), type: columnTypes.string, editAccess: false },
			{ key: 'totalEUR', title: t('totalEUR'), type: columnTypes.string, editAccess: false },
			{ key: 'startDate', title: t('startDate'), type: columnTypes.date, editAccess: false  },
			{ key: 'endDate', title: t('endDate'), type: columnTypes.date, editAccess: false  },
			{ key: 'address', title: t('address'), type: columnTypes.string, editAccess: false },
			{ key: 'offerings', title: t('offerings'), editAccess },
			...result.map(formatExtraColumnResponse), 
		])
	}

	useEffect(() => {
		getColumns()
		getTableViewData(
			data,
			tableTypes.order,
			orders,
			formatTableData,
			companyId
		)
			.then((newData) => {
				setData([...data, ...newData])
			})
	}, [orders])

	const loadData = async (page: number) => {
		if(loadOrders){
			try {
				setLoading(true)
    
				const { result } = await authorizedRequest(loadOrders.orderUrl + `?page=${page}&per_page=${10}`, 'GET')
    
				if (result.length > 0) {
					const formatedBills: order[] = result.map(formatOrderResponse)
					return formatedBills
				}
    
				return []
    
			} finally {
				setLoading(false)
			}
		}
	}

	const handleLoadMore = () => {

		if (!loading && !lastPage) {
			loadData(page)
				.then((result) => {

					if(result){
						setOrders([...orders, ...result])
						if (result.length > 0) {
							setPage(page + 1)
						} else {
							setLastPage(true)
						}
					}
				})
		}
	}

	useEffect(() => {
		if(loadOrders){
			handleLoadMore()
		}
	}, [loadOrders])

	useEffect(() => {
		if(preloadedOrders){
			setOrders(preloadedOrders.orders)
		}
	}, [preloadedOrders])

	useEffect(() => {
		if(!deleteOrderModal.modalIsOpen){
			setTimeout(() => {
				setDeleteModalIsOpen(false)
			}, 400)
		}
	}, [deleteOrderModal])

	useEffect(() => {
		if(!createCustomerOrderModal.modalIsOpen){
			setTimeout(() => {
				setCreateModalIsOpen(false)
			}, 400)
		} else if(!createProviderOrderModal.modalIsOpen){
			setTimeout(() => {
				setCreateModalIsOpen(false)
			}, 400)
		} else if(!createSalesOrderModal.modalIsOpen){
			setTimeout(() => {
				setCreateModalIsOpen(false)
			}, 400)
		}else if(!createPurchaseOrderModal.modalIsOpen){
			setTimeout(() => {
				setCreateModalIsOpen(false)
			}, 400)
		}
	}, [createCustomerOrderModal, createProviderOrderModal, createSalesOrderModal, createPurchaseOrderModal])

	return (
		loadOrders ? 
			<>
				<Pagination onLoadMore={handleLoadMore} onlyLoadOn='bottom' loading={loading} showLoader={true}>
					<Table
						columns={columns}
						data={data}
						setData={setData}
						// onCellValueChange={onCellValueChange}
						tableType={tableTypes.bill}
						deleteAction={orders.some((order) => order.deleteAccess) ? (orderId) => {
							dispatch(setDeleteOrdernModalIsOpen(true))
							dispatch(setDeleteOrderId(orderId))

							setDeleteModalIsOpen(true)
						} : undefined}
						createAction={orders.some((order) => order.createAccess) ? () => {
							if(parent.type === 'customer'){
								dispatch(setCreateCustomerOrderModalIsOpen(true))
							}else if(parent.type === 'provider'){
								dispatch(setCreateProviderOrderModalIsOpen(true))
							}else if(parent.type === 'sales'){
								dispatch(setCreateSalesOrderModalIsOpen(true))
							}else if(parent.type === 'purchase'){
								dispatch(setCreatePurchaseOrderModalIsOpen(true))
							}

							setCreateModalIsOpen(true)
						} : undefined}
						addColumn={orders.some((order) => order.editAccess)}
					/>
				</Pagination>
				{ parent.type === 'customer' && createModalIsOpen && <CreateCustomerOrderModal orders={orders} setOrders={setOrders} /> }
				{ parent.type === 'provider' && createModalIsOpen && <CreateProviderOrderModal orders={orders} setOrders={setOrders} /> }
				{ parent.type === 'sales' && createModalIsOpen && <CreateSalesOrderModal orders={orders} setOrders={setOrders} /> }
				{ parent.type === 'purchase' && createModalIsOpen && <CreatePurchaseOrderModal orders={orders} setOrders={setOrders} /> }


				{ deleteModalIsOpen && <DeleteOrderModal orders={orders} setOrders={setOrders} /> }
			</>
			:
			<Table
				columns={columns}
				data={data}
				setData={setData}
				// onCellValueChange={onCellValueChange}
				tableType={tableTypes.bill}
				deleteAction={orders.some((order) => order.deleteAccess) ? (orderId) => {
					dispatch(setDeleteOrdernModalIsOpen(true))
					dispatch(setDeleteOrderId(orderId))
				} : undefined}
				addColumn={orders.some((order) => order.editAccess)}
			/>
	)
}

export default OrderTable