// styles
import './priceSection.scss'

// components
import Button from '../../../../../components/general/button/button'
import Title from '../../general/title/title'
import Text from '../../general/text/text'
import Subtitle from '../../general/subtitle/subtitle'

// icons
import { checkMark } from '../../../../../assets/general/generalIcons'

//other
import { useTranslation } from 'react-i18next'

const PriceSection = () => {
    const { t } = useTranslation('', { keyPrefix: 'apps.landing.prices.maximumVersion' })

	return (
		<div className="price-section-wrapper">
			<div className="price-section-container">
				<div className="price-section-item">
                    <Title>{t('title')}</Title>
                    <Subtitle>{t('price')}</Subtitle>
                    <div className="price-section-item-content">
                        <Text>{checkMark} {t('text1')}</Text>
                        <Text>{checkMark} {t('text2')}</Text>
                        <Text>{checkMark} {t('text3')}</Text>
                        <Text>{checkMark} {t('text4')}</Text>
                        <Text>{checkMark} {t('text5')}</Text>
                        <Text>{checkMark} {t('text6')}</Text>
                        <Text>{checkMark} {t('text7')}</Text>
                        <Text>{checkMark} {t('text8')}</Text>
                    </div>
                    <Button active={true} text={t('button')} onClick={()=>console.log()}/>
                </div>
			</div>
		</div>
	)
}

export default PriceSection