//styles and icons
import './button.scss'

//react
import { FC } from 'react'

//types 
import { button } from '../../../types/general/generalTypes'
import { arrowIcon } from '../../../assets/general/generalIcons'

type buttonProps = button

const Button: FC<buttonProps> = ({ text, type='button', icon, active, inverted, outlined, onClick, disabled, arrow }) => {
	return (
		<button disabled={disabled} type={type} className={`button ${inverted ? 'button-inverted' : ''} ${outlined ? 'button-outlined' : ''} ${active ? 'button-active' : ''}`} onClick={onClick}>
			{icon ? <div className="button-icon">{icon}</div> : null}
			{text ? <p>{text}</p> : null}
			{arrow ? <div className="arrow-icon-container">{arrowIcon}</div>: null}
		</button>
	)
}

export default Button