//react
import { FC, useEffect, useState } from 'react'

//components
import Table from '../../table/table'
import Pagination from '../../pagination/pagination'

//types
import { transaction } from '../../../../types/finance/general'

//translation
import { useTranslation } from 'react-i18next'

//network
import { authorizedRequest } from '../../../../utils/queries'


//redux
import { useAppDispatch } from '../../../../customHooks/redux'
import { setDeleteTransactionId, setDeleteTransactionModalIsOpen } from '../../../../redux/finance/general/modals'


//others
import { formatTransactionTableData, formatTransactionsResponse } from '../../../../assets/finance/general/generalFunctions'

type transactionTableProps = {
    transactionUrl: string
}
const TransactionTable: FC<transactionTableProps> = ({
	transactionUrl,
}) => {
	const { t } = useTranslation('', {
		keyPrefix: 'finance.account.accountTransactionList',
	})
	const dispatch = useAppDispatch()


	const [accountTransactionsData, setAccountTransactions] = useState<transaction[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const loadData = async (page: number) => {

		const { result } = await authorizedRequest(transactionUrl + `?page=${page}&per_page=${10}`, 'GET')
		if (result.length > 0) {
			const formatedTransactions: transaction[] = result.map(formatTransactionsResponse)

			return formatedTransactions
		}

		return []
	}


	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			setLoading(true)

			loadData(page)
				.then((result) => {
					if (result.length > 0) {
						setAccountTransactions((currentTransactions) => [...currentTransactions, ...result])
						setPage((currentPage) => currentPage + 1)
					} else {
						setLastPage(true)
					}
					setLoading(false)
				})
		}
	}
	const handleDelete = (transactionId: number) => {
		dispatch(setDeleteTransactionId(transactionId))
		dispatch(setDeleteTransactionModalIsOpen(true))
	}

	useEffect(() => {
		if(transactionUrl){
			handleLoadMore()
		}
	}, [transactionUrl])

	return (
		<Pagination onLoadMore={handleLoadMore} loading={loading}>
			<Table
				columns={
					accountTransactionsData[0]?.deleteAccess
						? [
							{ key: 'icon', title: '' },
							{ key: 'description', title: t('description') },
							{ key: 'amount', title: t('total') },
							{ key: 'date', title: t('date') },
							{ key: 'transaction_type', title: t('type') },
							{ key: 'delete', title: '' },
						]
						: [
							{ key: 'icon', title: '' },
							{ key: 'description', title: t('description') },
							{ key: 'amount', title: t('total') },
							{ key: 'date', title: t('date') },
							{ key: 'transaction_type', title: t('type') },
						]
				}
				data={formatTransactionTableData(accountTransactionsData, handleDelete)}
			/>
		</Pagination>
	)
}
export default TransactionTable