//react
import { FC, useState, useEffect, ChangeEvent } from 'react'

//translation

//types
import {
	columnTypes,
	dropdownOption,
	extraColumnDataResponse,
	extraColumnsResponse,
	infoDetailsTableDataItem,
	tableBodyItem,
	tableBodyItemContent,
	tableHeader,
	tableTypes,
} from '../../../types/general/generalTypes'

//redux
import { useAppSelector } from '../../../customHooks/redux'

//others
import {
	formatDate,
	formatExtraColumnDataResponse,
	formatExtraColumnResponse,
	formatTableBodyItemContentResponse,
} from '../../../assets/general/generalFunctions'

//network
import {
	companyExtraColumnDataUrl,
	companyExtraColumnsUrl,
} from '../../../utils/urls/general/company/company'
import { authorizedRequest } from '../../../utils/queries'

//components
import InfoDetailsTable from '../infoDetailsTable/infoDetailsTable'

type extraColumnDetailsProps = {
	objectId: number
	type: tableTypes
	setLoading: (value: boolean) => void
}

const ExtraColumnDetails: FC<extraColumnDetailsProps> = ({
	objectId,
	type,
	setLoading,
}) => {
	const [details, setDetails] = useState<Record<string, tableBodyItem>>({})
	const [editDetails, setEditDetails] = useState<boolean>(false)
	const [extraColumn, setExtraColumn] = useState<tableHeader[]>([])
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1
	const generateExtraColumnData = ():infoDetailsTableDataItem[] => {
		return extraColumn.map((column) => {
			// console.log(c)
			const value = details[column.key].content.value
			console.log(value, 'value')
			switch (column.type) {
			case columnTypes.dropdown:
				return {
					title: column.title as string,
					data: {
						dropdown: {
							dropdownOptions: column.dropdown?.dropdownOptions || [],
							placeholder: column.dropdown?.placeholder || '',
							onSelect: (selectedOption: dropdownOption) => {
								setDetails((prevDetails) => ({
									...prevDetails,
									[column.key]: {
										...prevDetails[column.key],
										content: {
											type: columnTypes.dropdown,
											value: selectedOption,
										},
									},
								}))
								setEditDetails(true)
							},
							selectedOption: column.dropdown?.dropdownOptions.find(
								(option) => (details[column.key].content.value as dropdownOption).key === option.key
							),
							clear: false,
						},
					},
				}

			case columnTypes.number:
				return {
					title: column.title as string,
					data: {
						floatInput: {
							value: String(details[column.key]?.content.value || ''),
							amountInputHandle: (value: string) => {
								setEditDetails(true)
								setDetails(
									(prevDetails) =>
										({
											...prevDetails,
											[column.key]: {
												...prevDetails[column.key],
												content: {
													type: columnTypes.number,
													value: Number(value),
												},
											},
										} )
								)
							},
						},
					},
				}

			case columnTypes.date:
				return {
					title: column.title as string,
					data: {
						calendar: {
							startDate: {
								date: details[column.key]
									? details[column.key].content.value as Date
									: undefined,
								setDate: (date: Date | undefined) => {
									setDetails((prevDetails) => ({
										...prevDetails,
										[column.key]: {
											...prevDetails[column.key],
											content: {
												type: columnTypes.date,
												value: date,
											},
										},
									}))
									setEditDetails(true)
								}
							},
						
							size: 'large' as const,
							popup: true,
							hasTime: true,
						},
					},
				}

			default:
				return {
					title: column.title as string,
					data: {
						inputField: {
							type: column.type,
							value: String(details[column.key]?.content.value || ''),
							onChange: (e: ChangeEvent<HTMLInputElement>) => {
								setEditDetails(true)
								setDetails(
									(prevDetails) =>
										({
											...prevDetails,
											[column.key]: {
												...prevDetails[column.key],
												content: {
													type: columnTypes.string,
													value: e.target.value,
												},
											},
										})
								)
							},
						},
					},
				}
			}
		})
	}

	useEffect(() => {
		const loadExtraColumns = async () => {
			try {
				setLoading(true)
				const {
					result: extraColumnsResult,
				}: { result: extraColumnsResponse[] } = await authorizedRequest(
					companyExtraColumnsUrl(companyId) + `?table_type=${type}`,
					'GET'
				)
				const {
					result: extraColumnsDataResult,
				}: { result: extraColumnDataResponse[] } = await authorizedRequest(
					companyExtraColumnDataUrl(companyId) +
						`?table_type=${type}&object_id=${objectId}`,
					'GET'
				)
				
				const formatedExtraColumns = extraColumnsResult.map(formatExtraColumnResponse)
				const extraColumnData = extraColumnsDataResult.map(formatExtraColumnDataResponse)

				const extraColumnValue = extraColumnData?.reduce(
					(acc, item) => ({
						...acc,
						...item,
					}),
					{}
				)

				setDetails(extraColumnValue)
				setExtraColumn(formatedExtraColumns)
			} finally {
				setLoading(false)
			}
		}

		loadExtraColumns()
	}, [companyId, objectId])

	const editData = async () => {
		if (Number(objectId)) {
			try {
				const formatedDate = (date: Date | undefined) =>{
					console.warn(date, date ? new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())).toISOString() : undefined)
					return date ? new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())).toISOString() : undefined
				}
				
				const extraColumnUpdates = extraColumn.map(async (column) => {
				
					const newValue = details[column.key]?.content
					let value: string | number | number[] | undefined

					if(newValue.value){
						if(newValue.type === columnTypes.date){
							value = formatedDate(newValue.value)
						}else if(newValue.type === columnTypes.dropdown){
							value = newValue.value.key
						}else if(newValue.type === columnTypes.selection){
							value = newValue.value.map((option) => option.id)
						}else if (newValue.type === columnTypes.string || newValue.type === columnTypes.number){
							value = newValue.value
						}
					}

					if (value) {
						await authorizedRequest(
							companyExtraColumnDataUrl(companyId),
							'PUT',
							'accessToken',
							{
								table_type: type,
								key: column.key,
								object_id: Number(objectId),
								value: value,
							}
						)
					}
				})

				await Promise.all(extraColumnUpdates)
			} catch (error) {
				console.error('Error updating data:', error)
			}
		}
	}

	useEffect(() => {
		if (editDetails) {
			editData()
			setTimeout(() => {
				setEditDetails(false)
			}, 500)
		}
	}, [details])

	return (<InfoDetailsTable data={generateExtraColumnData()} />)
}

export default ExtraColumnDetails
