// styles and icons
import './customerList.scss'
import { listIcon, tableIcon } from '../../../assets/general/generalIcons'

// react
import { FC, useEffect, useState } from 'react'

// components
import Layout from '../../../components/general/layout/layout'
import DeleteCustomerModal from '../../../components/sales/customerList/modals/deleteCustomerModal/deleteCustomerModal'
import CreateCustomerModal from '../../../components/sales/customerList/modals/createCustomerModal/createCustomerModal'
import Pagination from '../../../components/general/pagination/pagination'
import ImportCustomerFileSelectorModal from '../../../components/sales/customerList/modals/importCustomerFileSelectorModal/importCustomerFileSelectorModal'
import CustomerTablePreviewModal from '../../../components/sales/customerList/modals/customerTablePreviewModal/customerTablePreviewModal'
import ExportCustomerFileModal from '../../../components/sales/customerList/modals/exportCustomerFileModal/exportCustomerFileModal'
import CustomerCardList from '../../../components/sales/customerList/customerCardList/customerCardList'
import CustomerTable from '../../../components/general/tableViews/customerTable/customerTable'
import SwitchButtons from '../../../components/general/switchButtons/switchButtons'

// redux
import { useAppDispatch, useAppSelector } from '../../../customHooks/redux'
import { setCreateCustomerModalIsOpen } from '../../../redux/sales/customerList/modals'

// network
import { authorizedRequest } from '../../../utils/queries'
import { companyCustomersFiltersUrl, companyCustomersUrl } from '../../../utils/urls/sales/customer'

// types
import { filterBar, filters, customerResponse, infoPopupTypes, switchButton } from '../../../types/general/generalTypes'
import { customer } from '../../../types/sales/customerTypes'

// translation
import { useTranslation } from 'react-i18next'

// other
import { createFilteringRequest, formatCustomerResponse, formatFilters } from '../../../assets/general/generalFunctions'

const CustomerList: FC = () => {
	const { userCompanyData } = useAppSelector((state) => state.general)
	const { t } = useTranslation('', { keyPrefix: 'sales.customerList' })

	const companyId: number = userCompanyData?.companyId || -1
	const dispatch = useAppDispatch()

	const [customers, setCustomers] = useState<customer[]>([])

	const [searchRequest, setSearchRequest] = useState('')
	const [filterBar, setFilterBar] = useState<filterBar>()
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [loading, setLoading] = useState(false)
	const [lastPage, setLastPage] = useState(false)
	const [isFilterBarLoading, setFilterBarLoading] = useState<boolean>(false)

	const [activeViewId, setActiveViewId] = useState(0)

	const viewButtons: switchButton[] = [
		{
			id: 0,
			icon: listIcon
		},
		{
			id: 1,
			icon: tableIcon
		}
	]


	const titleUrls = [
		{
			url: '/ri-business/sales/customer-list',
			title: t('customers')
		}
	]

	const infoPopup = {
		content: [{
			title: 'customer_list_info_title',
			description: 'customer_list_info_description',
			link: '/ri-manual/sales/customers'
		}],
		type: infoPopupTypes.INFO
	}

	const loadFilterBar = async () => {
		try {
			setFilterBarLoading(true)
			const { result } = await authorizedRequest(companyCustomersFiltersUrl(companyId), 'GET')
			const filterResult = result.filters
			const createAccess = result.create_access

			const formatedFilters: filters[] = formatFilters(filterResult)

			setFilterBar({
				// importButton: () => {
				// 	dispatch(setImportCustomerFileSelectorModalIsOpen(true))
				// },
				// exportButton: () => {
				// 	dispatch(setExportCustomerFileModalIsOpen(true))
				// },
				filters: formatedFilters,
				addButton: createAccess && {
					text: t('create'),
					active: true,
					onClick: () => {
						dispatch(setCreateCustomerModalIsOpen(true))
					}
				},
				onSearch: onSearch
			})
		} finally {
			setFilterBarLoading(false)
		}
	}


	const loadData = async (page: number, request: string, filters: filters[]) => {
		try {
			setLoading(true)
			const filteringRequest = createFilteringRequest(filters)
			const { result } = await authorizedRequest(companyCustomersUrl(companyId) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')
			if (result.length > 0) {
				const formatedCustomers: customer[] = result.map(formatCustomerResponse)
				return formatedCustomers
			}

			return []
		} finally {
			setLoading(false)
		}
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		loadData(1, searchValue, filters)
			.then((result) => {
				setCustomers([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
			})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					setCustomers([...customers, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				})
		}
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)
		loadFilterBar()
	}, [])


	const renderComponent = () => {
		switch(activeViewId) {
		case 0: 
			return <CustomerCardList customers={customers} loading={loading} />
		case 1: 
			return <CustomerTable preloadedCustomers={{
				customers: customers,
				setCustomers: setCustomers
			}}/>
		}
	}

	return (
		<Layout
			header={{
				avatar: true,
			}}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
			isFilterBarLoading={isFilterBarLoading}
		>
			<div className='customers' >
				<SwitchButtons switchButtons={viewButtons} activeSwitchButtonId={activeViewId} setActiveSwitchButtonId={setActiveViewId} size='medium' />
				<Pagination onLoadMore={handleLoadMore} onlyLoadOn='bottom' loading={loading} showLoader={activeViewId !== 0}>
					{renderComponent()}
				</Pagination>
			</div>
			<CreateCustomerModal customers={customers} setCustomers={setCustomers} />
			<DeleteCustomerModal customers={customers} setCustomers={setCustomers} />
			<ImportCustomerFileSelectorModal />
			<CustomerTablePreviewModal customers={customers} setCustomers={setCustomers} />
			<ExportCustomerFileModal />
		</Layout>
	)
}

export default CustomerList