// styles and icons
import './createCustomerModal.scss'

// react
import { Dispatch, FC, FormEvent, SetStateAction, useRef, useState } from 'react'

// components
import Modal from '../../../../general/modals/modal/modal'
import InputField from '../../../../general/inputField/inputField'
import TextArea from '../../../../general/textArea/textArea'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setCreateCustomerModalIsOpen } from '../../../../../redux/sales/customerList/modals'

// network
import { authorizedRequest } from '../../../../../utils/queries'
import { companyCustomersUrl } from '../../../../../utils/urls/sales/customer'

// translation
import { useTranslation } from 'react-i18next'

// types
import { infoType, relationshipTypes } from '../../../../../types/general/generalTypes'
import { customer } from '../../../../../types/sales/customerTypes'

//other
import { formatCustomerResponse } from '../../../../../assets/general/generalFunctions'

type formErrors =  {
	name?: string
	phoneNumber?: string
    email?: string
}

type createCustomerModalProps = {
	customers: customer[]
	setCustomers: Dispatch<SetStateAction<customer[]>>
}

const CreateCustomerModal: FC<createCustomerModalProps> = ({customers, setCustomers}) => {
	const { modalIsOpen } = useAppSelector((state) => state.customerListModal.createCustomerModal)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1
	
	const dispatch = useAppDispatch()

	const formRef = useRef<HTMLFormElement | null>(null)

	const [ name, setName ] = useState('')
	const [ email, setEmail ] = useState('')
	const [ phoneNumber, setPhoneNumber ] = useState('')
	const [ description, setDescription ] = useState<string>('')

	const [ errors, setErrors ] = useState<formErrors>({})

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const {t} = useTranslation('', {keyPrefix: 'sales.customerList.modals.createCustomerModal'})
    
	const closeModal = () => {
		setName('')
		setEmail('')
		setPhoneNumber('')
		dispatch(setCreateCustomerModalIsOpen(false))
	}

	const checkErrors = ()=> {
		let result = true

		if(name === ''){
			setErrors(prevErrors => ({...prevErrors, name: t('pleaseEnterTheFirstName')}))
			result = false
		}
		if(phoneNumber === ''){
			setErrors(prevErrors => ({...prevErrors, phoneNumber: t('pleaseEnterThePhoneNumber')}))
			result = false
		}

		if(email === ''){
			setErrors(prevErrors => ({...prevErrors, email: t('pleaseEnterTheEmail')}))
			result = false
		}

		return result
	}

	const handleSubmit = async (e:FormEvent<HTMLFormElement>)=> {
		e.preventDefault()
		setErrors({})
		
		setIsLoading(true)
		
		if(checkErrors()) {
			const {result} = await authorizedRequest(companyCustomersUrl(companyId), 'POST', 'accessToken', {
				data: [{
					name: name,
					phone: phoneNumber,
					email:email,
					company_id: companyId,
					description, 
					relationship_type: relationshipTypes.CUSTOMER
				}]
			})
			if(result.length > 0){
				const formatedCustomers: customer[] = result.map(formatCustomerResponse)
				setCustomers([...formatedCustomers,...customers])
			}
			closeModal()
		}
		
		setIsLoading(false)
	}

	return (
		<Modal
			closeModal={closeModal}
			open={modalIsOpen}
			title={t('createACustomer')}
			submitButton={{
				text: t('create'),
				onClick: () => {
					if(formRef.current){
						
						formRef.current.requestSubmit()
						
					}
				}
			}}
			isLoading={isLoading}
		>
			<form
				className='create-customer-container'
				ref={formRef}
				onSubmit={handleSubmit}
			>
				<div className='form-elements-wrapper'>
					<InputField
						type='text'
						label={t('name')}
						value={name} 
						onChange={(e) => {
							setName(e.target.value)
						}}
						info={errors.name ? {
							type: infoType.error,
							text: errors.name
						} : undefined}
					/>
					<InputField
						type='text'
						label={t('email')}
						value={email} 
						onChange={(e) => {
							setEmail(e.target.value)
						}}
						info={errors.email ? {
							type: infoType.error,
							text: errors.email
						} : undefined}
					/>
					
					<InputField
						type='text'
						name='phoneNumber'
						label={t('phoneNumber')}
						value={phoneNumber} 
						onChange={(e) => {
							if(isNaN(Number(e.target.value))) return
							setPhoneNumber(e.target.value)
						}}
						info={errors.phoneNumber ? {
							type: infoType.error,
							text: errors.phoneNumber
						} : undefined}
					/>

					<TextArea
						label={t('description')}
						value={description}
						setValue={ val => setDescription(val) }
					/>
				</div>
			</form>
			
		</Modal>
	)
}

export default CreateCustomerModal