// CustomerDetails.tsx
//react
import { FC, useEffect, useState } from 'react'

//hooks
import { useParams, useNavigate } from 'react-router'

//components
import InfoDetailsTable from '../../../general/infoDetailsTable/infoDetailsTable'
import Loading from '../../../general/loading/loading'
import ExtraColumnDetails from '../../../general/extraColumnDetails/extraColumnDetails'


//network
import { authorizedRequest } from '../../../../utils/queries'
import { singleCustomerUrl } from '../../../../utils/urls/sales/customer'

//translation
import { useTranslation } from 'react-i18next'

//redux

//type
import {
	infoDetailsTableDataItem,
	customerResponse,
	tableTypes,
	infoType,
} from '../../../../types/general/generalTypes'


type Details = {
  name: string;
  email: string;
  phoneNumber: string;
  description: string;
  editAccess: boolean;
};
type Errors = {
  name?: string
  email?: string
  phone?: string
}

const CustomerDetails: FC = () => {
	const { t } = useTranslation('', {
		keyPrefix: 'sales.customer.customerDetails',
	})
	const tErrors = useTranslation('', {
		keyPrefix: 'sales.customerList.modals.createCustomerModal',
	}).t
	const navigate = useNavigate()
	const { relationshipId } = useParams()
	const [details, setDetails] = useState<Details>({
		name: '',
		email: '',
		phoneNumber: '',
		description: '',
		editAccess: false,
	})
	const [editDetails, setEditDetails] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [errors, setErrors] = useState<Errors>({})

	const infoDetailsTableData: infoDetailsTableDataItem[] = [
		{
			title: t('name'),
			data: {
				inputField: {
					type: 'text',
					value: details.name,
					onChange: (e) => {
						setDetails({ ...details, name: e.target.value })
						setEditDetails(true)
						setErrors((prevErrors) => ({ ...prevErrors, name: undefined }))
					},
					disabled: !details.editAccess,
					info: errors.name ? {
						type: infoType.error,
						text: errors.name
					} : undefined
				},
			},
		},
		{
			title: t('email'),
			data: {
				inputField: {
					type: 'text',
					value: details.email,
					onChange: (e) => {
						setDetails({ ...details, email: e.target.value })
						setEditDetails(true)
						setErrors((prevErrors) => ({ ...prevErrors, email: undefined }))
					},
					disabled: !details.editAccess,
					info: errors.email ? {
						type: infoType.error,
						text: errors.email
					} : undefined
				},
			},
		},
		{
			title: t('phoneNumber'),
			data: {
				inputField: {
					type: 'text',
					value: details?.phoneNumber || '',
					onChange: (e) => {
						setDetails({ ...details, phoneNumber: e.target.value })
						setEditDetails(true)
						setErrors((prevErrors) => ({ ...prevErrors, phone: undefined }))
					},
					disabled: !details.editAccess,
					info: errors.phone ? {
						type: infoType.error,
						text: errors.phone
					} : undefined
				},
			},
		},
		{
			title: t('description'),
			data: {
				textArea: {
					value: details.description,
					setValue: (value) => {
						setDetails({ ...details, description: value })
						setEditDetails(true)
					},
					disabled: !details.editAccess,
				},
			},
		},
	]

	const checkErrors = () => {
		let hasError = true
		if (details.name?.trim() === '') {
			setErrors((prevErrors) => ({
				...prevErrors,
				name: tErrors('pleaseEnterTheFirstName'),
			}))
			hasError = false
		}
		if (details.email?.trim() === '') {
			setErrors((prevErrors) => ({
				...prevErrors,
				email: tErrors('pleaseEnterTheEmail'),
			}))
			hasError = false
		}
		if (details.phoneNumber?.trim() === '') {
			setErrors((prevErrors) => ({
				...prevErrors,
				phone: tErrors('pleaseEnterThePhoneNumber'),
			}))
			hasError = false
		}
		return hasError
	}

	const loadData = async () => {
		if (isNaN(Number(relationshipId))) {
			navigate('/ri-business/404')
		}
		try {
			setLoading(true)
			const { result }: { result: customerResponse } = await authorizedRequest(
				singleCustomerUrl(Number(relationshipId)),
				'GET'
			)
			if (result === undefined) {
				navigate('/ri-business/404')
			}

			setDetails({
				name: result.name,
				email: result.email,
				phoneNumber: result.phone,
				description: result.description,
				editAccess: result.edit_access,
			})
		} finally {
			setLoading(false)
		}
	}

	const editData = async () => {
		if (Number(relationshipId) && checkErrors()) {
			// Create a payload object for the main customer data
			const payload: { [key: string]: any } = {
				name: details.name,
				email: details.email,
				description: details.description,
				phone: details.phoneNumber,
			}

			// Update main customer data
			await authorizedRequest(
				singleCustomerUrl(Number(relationshipId)),
				'PUT',
				'accessToken',
				payload
			)

			console.log('Updated payload:', payload)
		}
	}

	useEffect(() => {
		if (editDetails) {
			editData()
			setTimeout(() => {
				setEditDetails(false)
			}, 500)
		}
	}, [details])

	useEffect(() => {
		loadData()
	}, [])

	return (
		<>
			<InfoDetailsTable data={infoDetailsTableData} />
			<ExtraColumnDetails type={tableTypes.customer}  objectId={Number(relationshipId)} setLoading={setLoading} />
			{loading && <Loading style={{ top: '40vh', left: '50vw' }} />}
		</>
	)
}

export default CustomerDetails
