//styles
import './timeTrackerItem.scss'
import {
	arrowIcon,
	deleteIcon,
	windows,
	apple
} from '../../../assets/general/generalIcons'

//react
import { FC, useEffect, useState, useRef } from 'react'

//components
import Avatar from '../avatar/avatar'
import Dropdown from '../dropdown/dropdown'
import Timer from './timer/timer'
import CurrencyInput from 'react-currency-input-field'
import SwitchButtons from '../switchButtons/switchButtons'
import StatusCircle from '../statusCircle/statusCircle'
import TimeUsageData from '../timeUsageData/timeUsageData'
import Button from '../button/button'

//redux
import { useAppDispatch } from '../../../customHooks/redux'
import {
	setDeleteTimeTrackerModalExecutorId,
	setDeleteTimeTrackerModalIsOpen,
} from '../../../redux/projects/task/modals'

//types
import {
	currency,
	dropdownOption,
	timeUsage,
	userStatus,
} from '../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

//network
import { singleTimeTacker } from '../../../utils/urls/projects/task'
import { authorizedRequest, unauthorizedRequest } from '../../../utils/queries'
import { baseUrl } from '../../../utils/network'

//other
import {
	createDropdownOption
} from '../../../assets/general/generalFunctions'
  
type timeTrackerItemProps = {
  taskTitle?: string;
  executorId: number;
  avatar?: string | null;
  timerStartDate: Date | null;
  name?: string;
  position?: string;
  editAccess: boolean;
  deleteAccess: boolean;
  wage: number;
  hoursSpent: number;
  relevantCurrency: currency;
  isProductivityTrackingActive: boolean
  timeUsage: timeUsage[]
};

const TimeTrackerItem: FC<timeTrackerItemProps> = ({
	taskTitle,
	executorId,
	avatar,
	timerStartDate,
	name,
	position,
	wage,
	hoursSpent,
	relevantCurrency,
	editAccess,
	deleteAccess,
	isProductivityTrackingActive,
	timeUsage
}) => {
	const tCurrency = useTranslation('', { keyPrefix: 'general.currency' }).t
	const {t} = useTranslation('', { keyPrefix: 'projects.task.appsUsed' })

	const [totalSeconds, setTotalSeconds] = useState(hoursSpent * 60 * 60)

	const [isRunning, setIsRunning] = useState(!!timerStartDate)
	const [activeWage, setActiveWage] = useState<number>(wage)
	const [toggle, setToggle] = useState<boolean>(false)
	const [selectedCurrency, setSelectedCurrency] = useState<dropdownOption>({
		title: tCurrency(relevantCurrency),
		key: relevantCurrency,
	})

	const dispatch = useAppDispatch()

	const isFirstWageRender = useRef(true)
	const isFirstCurrencyRender = useRef(true)
	const isFirstRunningRender = useRef(true)


	const handleDownload = async (type: 'dmg' | 'exe') => {
		// URL of the RESTful endpoint in your Flask app
		const { result } = await unauthorizedRequest(baseUrl + `/api/download-time-trackers?type=${type}`, 'GET')
		console.log(result)


		const link = document.createElement('a')
       
		const binaryString = window.atob(result)
		const len = binaryString.length
		const bytes = new Uint8Array(len)
	
		for (let i = 0; i < len; i++) {
		  bytes[i] = binaryString.charCodeAt(i)
		}
	
		// Create a blob from the Uint8Array
		const blob = new Blob([bytes], { type: 'application/octet-stream' })
	
		// Create a URL for the blob
		const url = window.URL.createObjectURL(blob)
	
		link.href = url
		link.download = 'time-tracker.' + type // Desired file name

		// Append the link to the body and trigger the download
		document.body.appendChild(link)
		link.click()
        
		// Clean up
		link.remove()
		// window.URL.revokeObjectURL(result);
	}

	useEffect(() => {
		if (isFirstWageRender.current) {
			isFirstWageRender.current = false
			return
		}

		const timeout = setTimeout(() => {
			authorizedRequest(singleTimeTacker(executorId), 'PUT', 'accessToken', {
				wage: activeWage,
			})
		}, 500)

		return () => {
			clearTimeout(timeout)
		}
	}, [activeWage])

	useEffect(() => {
		if (isFirstCurrencyRender.current) {
			isFirstCurrencyRender.current = false
			return
		}

		const timeout = setTimeout(() => {
			authorizedRequest(singleTimeTacker(executorId), 'PUT', 'accessToken', {
				currency: selectedCurrency.key,
			})
		}, 500)

		return () => {
			clearTimeout(timeout)
		}
	}, [selectedCurrency])

	useEffect(() => {
		if (isFirstRunningRender.current) {
			isFirstRunningRender.current = false
			return
		}

		const timeout = setTimeout(() => {
			authorizedRequest(
				singleTimeTacker(executorId),
				'PUT',
				'accessToken',
				isRunning
					? {
						timer_start_date: true,
					}
					: {
						timer_stop: true,
					}
			)
		}, 500)

		return () => {
			clearTimeout(timeout)
		}
	}, [isRunning])

	return (
		<div className="time-tracker-item">
			<div className="time-tracker-item-header">
				{taskTitle && <h2 className="time-tracker-item-title">{taskTitle}</h2>}
				<div className="time-tracker-item-status">
					<div className="time-tracker-item-status-text-container">
						<div className="time-tracker-item-status-circle">
							<StatusCircle status={isProductivityTrackingActive ? userStatus.ONLINE : userStatus.OFFLINE} />
						</div>
						<p className="time-tracker-item-status-text">{isProductivityTrackingActive ? t('productivityOn') : t('productivityOff')}</p>	
					</div>
					{ !isProductivityTrackingActive && (
						<div className="time-tracker-item-status-download">
							<Button 
								text={t('download')}
								active={true}
								onClick={() => {
									handleDownload('exe')
								}}
								icon={windows}
							/>
							<Button 
								text={t('download')}
								active={true}
								onClick={() => {
									handleDownload('dmg')
								}}
								icon={apple}
							/>
						</div>
					)}
				</div>
			</div>
			<div className="time-tracker-item-content">
				{deleteAccess && (
					<SwitchButtons
						switchButtons={[
							{
								id: 1,
								icon: deleteIcon,
								onClick: () => {
									dispatch(setDeleteTimeTrackerModalIsOpen(true))
									dispatch(setDeleteTimeTrackerModalExecutorId(executorId))
								},
							},
						]}
					/>
				)}
				{name || position || avatar ? (
					<div className="time-tracker-executor">
						{avatar !== undefined && <Avatar blobAvatar={avatar} />}
						<div className="time-tracker-executor-details">
							<h2 className="time-tracker-executor-name">{name}</h2>
							<p className="time-tracker-executor-position">{position}</p>
						</div>
					</div>
				) : null}
				<div className="time-tracker-details">
					<Timer
						executorId={executorId}
						isRunning={isRunning}
						setIsRunning={setIsRunning}
						totalSeconds={totalSeconds}
						setTotalSeconds={setTotalSeconds}
						disabled={!editAccess}
					/>
					<span className="time-tracker-separation">*</span>
					<div className="time-tracker-billing">
						<CurrencyInput
							value={activeWage}
							decimalsLimit={2}
							onValueChange={(value: any) =>
								setActiveWage(parseFloat(value || '0'))
							}
							disabled={!editAccess}
						/>
						<Dropdown
							placeholder={tCurrency(selectedCurrency?.key)}
							dropdownOptions={createDropdownOption(tCurrency, currency)}
							onSelect={(option) => {
								setSelectedCurrency(option)
							}}
							selectedOption={selectedCurrency}
							clear={false}
							disabled={!editAccess}
						/>
					</div>
					<span className="time-tracker-separation">=</span>
					<div className="time-tracker-billing">
						<p>
							{Math.floor((totalSeconds / 60 / 60) * activeWage * 100) / 100}
						</p>
						<p>{selectedCurrency.title}</p>
					</div>
				</div>
			</div>

			<div onClick={() => setToggle(!toggle)} className={`time-tracker-toggle-bar-button ${toggle ? 'open' : ''}`}>        
				{arrowIcon}
			</div>

			<div className={`time-tracker-chart-container ${toggle ? 'open' : ''}`}>
				<TimeUsageData hoursSpent={hoursSpent} timeUsage={timeUsage} />
			</div>
		</div>
	)
}

export default TimeTrackerItem
