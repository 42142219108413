//styles
import './addInventoryItemProviderOfferingModal.scss'

//components
import Modal from '../../../../../general/modals/modal/modal'
import InputField from '../../../../../general/inputField/inputField'
import Pagination from '../../../../../general/pagination/pagination'
import ColumnLayout from '../../../../../general/columnLayout/columnLayout'
import OfferingCard from '../../../../../general/offeringCard/offeringCard'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../../customHooks/redux'
import { setAddInventoryItemProviderOfferingModalIsOpen } from '../../../../../../redux/warehouse/inventoryItem/modals'

//react
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import { companyProviderOfferingsUrl } from '../../../../../../utils/urls/purchases/provider'
import { inventoryItemProviderOfferingsUrl } from '../../../../../../utils/urls/warehouses/warehouse'

//translation
import { useTranslation } from 'react-i18next'

//types
import { offering, offeringResponse, dropdownOption } from '../../../../../../types/general/generalTypes'

//other
import { formatOfferingResponse } from '../../../../../../assets/general/generalFunctions'

type addInventoryItemProviderOfferingModalProps = {
	inventroyItemOfferings: offering[]
	setInventroyItemOfferings: (value: offering[]) => void
}

const AddInventoryItemProviderOfferingModal: FC<addInventoryItemProviderOfferingModalProps> = ({ inventroyItemOfferings, setInventroyItemOfferings }) => {
	const dispatch = useAppDispatch()
	const { t } = useTranslation('', { keyPrefix: 'warehouse.inventoryItem.inventoryItemProviderOfferingList.modals' })

	const { modalIsOpen } = useAppSelector((state) => state.inventoryItemModal.addInventoryItemProviderOfferingModal)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const { inventoryItemId } = useParams()

	const companyId: number = userCompanyData?.companyId || -1

	const [selectedOfferingOption, setSelectedOfferingOption] = useState<dropdownOption>()

	const [searchRequest, setSearchRequest] = useState('')
	const [offerings, setOfferings] = useState<offering[]>([])
	const [loading, setLoading] = useState(false)
	const [page, setPage] = useState<number>(1)
	const [lastPage, setLastPage] = useState<boolean>(false)

	const closeModal = () => {
		setSelectedOfferingOption(undefined)
		dispatch(setAddInventoryItemProviderOfferingModalIsOpen(false))
	}

	const loadData = async (companyId: number, query: string, page: number) => {
		if (!loading && !lastPage) {
			setLoading(true)
			const { result }: { result: offeringResponse[] } = await authorizedRequest(companyProviderOfferingsUrl(companyId) + `?needle=${query}&page=${page}&per_page=${10}&inventory_item=true`, 'GET')

			if (result.length > 0) {
				const formatedOfferings = result.map(formatOfferingResponse)

				setOfferings([...formatedOfferings])
				setPage(page)
			} else {
				setLastPage(true)
			}
			setLoading(false)
		}
	}

	const onSearch = (query: string) => {
		setPage(1)
		setLastPage(false)
		setSearchRequest(query)
		setLoading(false)

		loadData(companyId, query, 1)
	}


	const handleLoadMore = () => {
		loadData(companyId, searchRequest, page + 1)
	}

	useEffect(() => {
		if (modalIsOpen) {
			onSearch('')
		}
	}, [modalIsOpen])

	return (
		<Modal
			closeModal={closeModal}
			open={modalIsOpen}
		>
			<div className="move-employee-container">
				<div className="move-employee-search-container">
					<InputField
						placeholder={t('search')}
						type="text"
						value={searchRequest}
						onChange={(e) => setSearchRequest(e.target.value)}
						onSearch={() => { onSearch(searchRequest) }}
					/>
				</div>
				<div className="move-employee-new-leaders-list">
					<Pagination onLoadMore={handleLoadMore} loadAxis='vertical'>
						<ColumnLayout>
							{
								offerings.map((item: offering) => (

									<div
										key={`inventoryOffering-${item.id}`}
										onClick={() => {
											authorizedRequest(inventoryItemProviderOfferingsUrl(Number(inventoryItemId)), 'POST', 'accessToken', {
												offering_id: Number(item.id)
											})
												.then((response) => {
													setInventroyItemOfferings([...inventroyItemOfferings, formatOfferingResponse(response.result)])
													closeModal()
												})

										}
										}
									>
										<OfferingCard
											link=''
											items={item.items}
											id={item.id}
											type={item.type}
											offering_type={item.offering_type}
											description={item.description}
											price={item.price}
											vat={item.vat}
											quantity={item.quantity}
											editAccess={false}
											deleteAccess={false}
											createAccess={false}
											name={item.name}
											avatar={item.avatar}
										/>
									</div>
								)
								)
							}
						</ColumnLayout>
					</Pagination>
				</div>
			</div>
		</Modal>
	)
}

export default AddInventoryItemProviderOfferingModal