//icons
import { deleteIcon } from '../../general/generalIcons'
import { purchase, sale } from '../../general/generalIcons'

//components
import StatusLabel from '../../../components/general/statusLabel/statusLabel'
import SwitchButtons from '../../../components/general/switchButtons/switchButtons'

//types
import { transaction, transactionType, transactionStatusColor, expenseCategory, billResponse, bill, transactionResponse} from '../../../types/finance/general'
import { columnTypes, tableBodyItem } from '../../../types/general/generalTypes'

//other
import { currencyToFormattedString, formatAccountResponse, formatDate, formatEmployeeResponse, formatErrorResponse } from '../../general/generalFunctions'

export const formatTransactionTableData = (transactions: transaction[], handleDelete: (transactionId: number) => void): Record<string, tableBodyItem>[] => {
	return transactions.map(transaction => {
		const baseData: Record<string, tableBodyItem> = {
			icon: {
				content: {
					type: columnTypes.element,
					value: <div className='account-transaction-icon'>{transaction.transactionType === transactionType.expense ? purchase : sale}</div>
				}
			},
			description: {
				content: {
					type: columnTypes.string,
					value: transaction.description
				}
			},
			amount: {
				content: {
					type: columnTypes.string,
					value: currencyToFormattedString(transaction.amount, transaction.currency)
				}
			},
			date: {
				content: {
					type: columnTypes.date,
					value: transaction.date
				}
			},
			transaction_type: {
				content: {
					type: columnTypes.element,
					value: <StatusLabel
						color={transactionStatusColor[transaction.transactionType].color}
						bg={transactionStatusColor[transaction.transactionType].bg}
						text={transaction.transactionType} />
				}
			}
		}

		if (transactions[0]?.deleteAccess) {
			return {
				...baseData,
				delete: {
					content: {
						type: columnTypes.element,
						value: <SwitchButtons
							switchButtons={[
								{
									id: 1,
									onClick: () => {
										handleDelete(transaction.id)
									},
									icon: deleteIcon
								}
							]}
							orientation='vertical'
						/>
					}
				}
			}
		}

		return baseData
	})
}


export const formatBillResponse = (bill: billResponse): bill => {
	return {
		id: bill.id,
		amount: bill.amount,
		currency: bill.currency,
		endDate: bill.due_date ? new Date(bill.due_date * 1000) : null,
		title: bill.title,
		description: bill.description,
		category: expenseCategory[bill.category as keyof typeof expenseCategory],
		status: bill.status,
		deleteAccess: bill.delete_access,
		editAccess: bill.edit_access,
		createAccess: bill.create_access,
		transactionType: transactionType[bill.bill_type as keyof typeof transactionType],
		account: bill.account ? formatAccountResponse(bill.account) : null,
		employee: bill.employee ? formatEmployeeResponse(bill.employee) : null,
		errors: bill.errors.map(formatErrorResponse)
	}
}
export const formatTransactionsResponse = (transaction: transactionResponse) => {
	return {
		id: transaction.id,
		accountId: transaction.account_id,
		amount: transaction.amount,
		currency: transaction.currency,
		transactionType: transaction.transaction_type,
		description: transaction.transaction_type,
		date: new Date(transaction.date * 1000),
		deleteAccess: false
	}
}