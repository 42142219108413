//styles
import './navbarBurgerItem.scss'
import { arrowIcon } from '../../../../../../../../assets/general/generalIcons'

//react
import { useState, FC } from 'react'
import { Link } from 'react-router-dom'

//translation
import { useTranslation } from 'react-i18next'

type navbarBurgerItemProps = {
	open: boolean
	setOpen: (value: boolean) => void
    title: string
    href: string
    dropdownElements?: {
        title: string
		icon: JSX.Element
		link?: string
        links: {
            text: string
            href: string
			icon?: JSX.Element
			description: string
			title: string
        }[]
    }[]
}

const NavbarBurgerItem: FC<navbarBurgerItemProps> = ({ open, setOpen, title, href, dropdownElements }) => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.general.layout.navbar' })

	const [openDropdownItem, setOpenDropdownItem] = useState<number | undefined>(undefined)

	return (
		<div className="navbar-burger-content-item-wrapper">
			<div className={`navbar-burger-content-item-header ${open ? 'open' : ''}`} onClick={(e) => {
				e.stopPropagation()

				setOpen(!open)
			}}>
				{dropdownElements ? <p className="navbar-burger-content-item-title">{title}</p> : <Link to={href} className="navbar-burger-content-item-title">{title}</Link>}
				{dropdownElements && <div className={`navbar-link-arrow ${open ? 'open' : ''}`}>{arrowIcon}</div>}
			</div>

			{ dropdownElements && 
                <div className={`navbar-burger-content-item-container ${open ? 'open' : ''}`}>
                	{dropdownElements?.map((elem, index) => {
                		return (
                			<div className={`navbar-burger-content-item-section ${openDropdownItem === index ? 'open' : ''}`} onClick={() => {
								setOpenDropdownItem(openDropdownItem === index ? undefined : index)
							}}>
                				<div className="navbar-burger-content-item-section-header">
									<div className="navbar-burger-content-item-section-icon">
										{elem.icon}
									</div>
									<div className="navbar-burger-content-item-section-text">
										<p className="navbar-burger-content-item-section-title">{t(elem.title)}</p>
										<p className="navbar-burger-content-item-section-subtitle">{t(`${elem.title}Subtitle`)}</p>
									</div>
                				</div>
                				<div className={`navbar-burger-content-item-section-content ${openDropdownItem === index ? 'open' : ''}`}>
                					{elem.links.map((link) => {
                						return (
                							<Link to={link.href}>{t(link.text)}</Link>
                						)
                					})}
                				</div>
                			</div>
                		)
                	})}
                </div>
			}
		</div>
	)
}

export default NavbarBurgerItem