//components
import HierarchyColumn from './hierarchyColumn/hierarchyColumn'

//react
import { useRef, useState, useEffect, FC } from 'react'

//redux
import { useAppSelector, useAppDispatch } from '../../../../customHooks/redux'
import { setHierarchy } from '../../../../redux/employees/hierarchy/general'

//network
import { authorizedRequest } from '../../../../utils/queries'
import {
	hierarchyEmployeesLeadersUrl,
	hierarchyEmployeesOwnersUrl,
	hierarchyEmployeesUrl,
	hierarchyGetTheLeaderUrl
} from '../../../../utils/urls/employees/hierarchy'

//types
import { employee, hierarchyColumn } from '../../../../types/employees/hierarchyTypes'
import { switchButton, employeeResponse } from '../../../../types/general/generalTypes'

//other
import { getEmployeeHierarchyData } from '../../../../assets/employees/hierarchy/hierarchyFunctions'
import { formatEmployeeResponse } from '../../../../assets/general/generalFunctions'

type hierarchyTableProps = {
	getEmployeeActions: (employee: employee) => switchButton[]
}

const HierarchyTable: FC<hierarchyTableProps> = ({ getEmployeeActions }) => { 
	const dispatch = useAppDispatch()
	const { hierarchy } = useAppSelector((state) => state.hierarchyGeneral)

	const { userCompanyData } = useAppSelector((state) => state.general)

	const horizontalContainerRef = useRef<null | HTMLDivElement>(null) // horizontal scrolling element
	const [reachedTheOwners, setReachedTheOwners] = useState(false)

	//global variables
	const companyId: number = userCompanyData?.companyId || -1 // id of chosen company
	const employeeId: number = userCompanyData?.employeeId || -1 // employee id of the user in the chosen company

	const generateHierarchy = async () => {
		const { result }: { result: employeeResponse } = await authorizedRequest(hierarchyGetTheLeaderUrl(employeeId), 'GET')


		const directLeader = result ? formatEmployeeResponse(result) : null

		console.log(directLeader, employeeId)

		dispatch(setHierarchy([
			{
				employees: [],
				isLoading: true,
				hire: false
			},
			{
				employees: [],
				isLoading: true,
				hire: false
			},
			{
				employees: [],
				isLoading: true,
				hire: false
			}
		]))

		if(directLeader?.id){
			const leaders = await getEmployeeHierarchyData(hierarchyEmployeesLeadersUrl(employeeId), getEmployeeActions)
			const peers = await getEmployeeHierarchyData(hierarchyEmployeesUrl(directLeader.id), getEmployeeActions)
			const employees = await getEmployeeHierarchyData(hierarchyEmployeesUrl(employeeId), getEmployeeActions)
			dispatch(setHierarchy([
				{
					employees: [...leaders.map((employee) => {
						return {
							...employee,
							active: employee.id === directLeader.id
						}
					})],
					isLoading: false,
					hire: leaders.every((employee) => employee.replaceAccess)
				},
				{
					employees: [...peers.map((employee) => {
						return {
							...employee,
							active: employee.id === employeeId
						}
					})],
					isLoading: false,
					hire: peers.every((employee) => employee.replaceAccess)
				},
				{
					employees: [...employees],
					isLoading: false,
					hire: employees.every((employee) => employee.replaceAccess)
				}
			]))			
		}else{
			const owners = await getEmployeeHierarchyData(hierarchyEmployeesOwnersUrl(companyId), getEmployeeActions)
			const employees = await getEmployeeHierarchyData(hierarchyEmployeesUrl(employeeId), getEmployeeActions)

			dispatch(setHierarchy([
				{
					employees: [...owners.map((employee) => {
						return {
							...employee,
							active: employee.id === employeeId
						}
					})],
					isLoading: false,
					hire: owners.every((employee) => employee.replaceAccess)
				},
				{
					employees: [...employees],
					isLoading: false,
					hire: employees.every((employee) => employee.replaceAccess)
				}
			]))			
		}

	}

	// loading deeper structure if the user requested it by scrolling to the beginning
	const scrollHandler = () => {
		// if the user scrolled to the beginning
		if (horizontalContainerRef.current && horizontalContainerRef.current.scrollLeft <= 50 && !reachedTheOwners) {
			const notDirectLeader = hierarchy[0].employees.find((employee) => employee.active) // the most remoted leader of the user
			if (notDirectLeader) {
				// getting leader of the not direct leader
				getEmployeeHierarchyData(hierarchyEmployeesLeadersUrl(notDirectLeader.id), getEmployeeActions)
					.then((leaders) => {
						if(leaders.length > 0){

							const newColumn: hierarchyColumn = {
								employees: [],
								isLoading: true,
								hire: false
							}


							dispatch(setHierarchy([newColumn, ...hierarchy]))

							authorizedRequest(hierarchyGetTheLeaderUrl(notDirectLeader.id), 'GET')
								.then((response) => {
									const { result } = response

									// Create the new column based on the loaded leader data
									const updatedNewColumn = {
										employees: leaders.map((employee) => ({
											...employee,
											active: employee.id === result.employee_id, // Set active state for the current leader
										})),
										isLoading: false,
										hire: leaders.every((employee) => employee.replaceAccess),
									}

									// Prepend the new column and leave the rest of the hierarchy unchanged
									const updatedHierarchy = [updatedNewColumn, ...hierarchy]

									// Dispatch the updated hierarchy with the new leader column at the front
									dispatch(setHierarchy(updatedHierarchy))
								})
						}else{
							setReachedTheOwners(true)
						}
					})
			}

		}
	}

	useEffect(() => {
		
		generateHierarchy()
			.then(() => {
				const horizontalContainer = horizontalContainerRef.current

				if(horizontalContainer){
					horizontalContainer.scrollLeft = horizontalContainer.scrollWidth
				}
			})

	}, [])

	return (
		<div className="hierarchy">
			<div className="hierarchy-row" ref={horizontalContainerRef} onScroll={scrollHandler}>
				{hierarchy.map((column, index) => {
					return <HierarchyColumn {...column} getEmployeeActions={getEmployeeActions} level={index}/>
				})}
			</div>
		</div>
	)
}

export default HierarchyTable