// styles
import './avatar.scss'

// icons
import { basicUserAvatar } from '../../../assets/general/generalIcons'

//react
import { FC, useEffect, useState } from 'react'

//components
import StatusCircle from '../statusCircle/statusCircle'

//types
import { userStatus } from '../../../types/general/generalTypes'

//other
import { blobStringToBase64 } from '../../../assets/general/generalFunctions'

type avatarProps = {
    status?: userStatus
    blobAvatar: string | null
	placeholder?: JSX.Element
}

const Avatar: FC<avatarProps> = ({ status, blobAvatar, placeholder }) => {
	const [avatar, setAvatar] = useState<string | null>(null)

	useEffect(() => {
		if(blobAvatar){
			blobStringToBase64(blobAvatar, 'image/png')
				.then((avatar) => {
					setAvatar(avatar)
				})
		}else{
			setAvatar(null)
		}
	}, [blobAvatar])
	
	return (
		<div className="avatar-container">
			{
				avatar ? <img src={avatar} alt="" /> : (placeholder || basicUserAvatar)
			}
			<div className="avatar-status">
				{
					status ? <StatusCircle status={status} /> : null
				}
			</div>
		</div>
	)
}

export default Avatar
