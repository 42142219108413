//react
import { FC, useEffect, useState } from 'react'
import { deleteIcon } from '../../../../assets/general/generalIcons'

//components
import CounterpartyCard from '../../../general/counterpartyCard/counterpartyCard'
import ColumnLayout from '../../../general/columnLayout/columnLayout'

// redux
import { useAppDispatch } from '../../../../customHooks/redux'
import { setDeleteCustomerRelationshipId, setDeleteCustomerModalIsOpen } from '../../../../redux/sales/customerList/modals'
import CounterpartySkeleton from '../../../general/skeletons/counterParty/counterPartySkeleton'

//other
import { getItemsPerColumn, formatTags } from '../../../../assets/general/generalFunctions'

// translation
import { useTranslation } from 'react-i18next'

//types
import { customer } from '../../../../types/sales/customerTypes'

type customerCardListProps = {
    customers: customer[]
    loading: boolean
}

const CustomerCardList: FC<customerCardListProps> = ({ customers, loading }) => {
	const { t: tCurrency } = useTranslation('', { keyPrefix: 'general.currency' })

	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())

	const dispatch = useAppDispatch()

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (

		<ColumnLayout amount={itemsPerColumn}>
			{customers.map(customer =>
				(<CounterpartyCard
					key={`customer-${customer.id}`}
					link={`/ri-business/sales/customer/${customer.id}`}
					tags={customer.labels.map(e => ({ ...e, value: formatTags(e.value, tCurrency) }))}
					actions={customer.deleteAccess ? [
						{
							id: 2, icon: deleteIcon, onClick: () => {
								dispatch(setDeleteCustomerRelationshipId(customer.id))
								dispatch(setDeleteCustomerModalIsOpen(true))
							}
						}] : []}
					{...customer}
				/>)
			)}
			{loading ? <CounterpartySkeleton /> : null}

		</ColumnLayout>
	)

}

export default CustomerCardList