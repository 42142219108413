// react
import { FC } from 'react'

// component
import FileTableParserModal from '../../../../../general/modals/fileTableParserModal/fileTableParserModal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setImportTransactionFileSelectorModalFile, setExportTransactionFileModalIsOpen, setTransactionTablePreviewModalIsOpen } from '../../../../../../redux/finance/general/modals'

//types
import { currency } from '../../../../../../types/general/generalTypes'
import { transactionType } from '../../../../../../types/finance/general'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import { singleAccountTransactionsUrl } from '../../../../../../utils/urls/finance/account/account'

//other
import { formatDate, translateEnum } from '../../../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

const TransactionTablePreviewModal: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'finance.general.modals.transcation.transactionTablePreviewModal' })
	const tTransaction = useTranslation('', { keyPrefix: 'general.transactionType' }).t
	const tCurrency = useTranslation('', { keyPrefix: 'general.currency' }).t

	const { file } = useAppSelector((state) => state.financeGeneralModal.importTransactionFileSelectorModal)
	const { modalIsOpen } = useAppSelector((state) => state.financeGeneralModal.transactionTablePreviewModal)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setTransactionTablePreviewModalIsOpen(false))
		dispatch(setExportTransactionFileModalIsOpen(false))
		dispatch(setImportTransactionFileSelectorModalFile(null))
	}

	return (
		<FileTableParserModal
			file={file}
			open={modalIsOpen}
			closeModal={closeModal}
			onTableProcessed={(tableData) => {
				authorizedRequest(singleAccountTransactionsUrl(companyId), 'POST', 'accessToken', {
					data: tableData.data.map((item) => {
						const dateArray = formatDate(item.date.content.value as Date, false, false).split('.')
						return {
							amount: item.amount.content.value,
							currency: item.currency,
							description: item.description,
							transaction_type: item.transaction_type,
							date: dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0]
						}
					})
				})
			}}
			requiredColumns={[
				{
					title: t('total'),
					key: 'amount',
					default: '0',
					type: Number
				},
				{
					title: t('currency'),
					key: 'currency',
					default: translateEnum(tCurrency, currency.UAH),
					type: currency
				},
				{
					title: t('description'),
					key: 'description',
					default: '',
					type: String
				},
				{
					title: t('transactionType'),
					key: 'transaction_type',
					default: translateEnum(tTransaction, transactionType.income),
					type: transactionType
				},
				{
					title: t('date'),
					key: 'date',
					default: formatDate(new Date, false, true),
					type: Date
				}
			]}
		/>
	)
}

export default TransactionTablePreviewModal