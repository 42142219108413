//styles
import './updating.scss'

//react
import { useParams } from 'react-router-dom'

//components
import Loading from '../../../components/general/loading/loading'

//translation
import { useTranslation } from 'react-i18next'

const Updating = () => {
	const { t } = useTranslation('', { keyPrefix: 'general' })

	const { text } = useParams() 

	return (
		<div className="updating">
			<Loading/>
			<p>{t('updating')}</p>
			<p>{t('newVersion')}: {text}</p>
		</div>
	)
}

export default Updating