//styles
import './dropdownOptionEditor.scss'
import { close } from '../../../../../../assets/general/generalIcons'

//react 
import { Dispatch, FC, KeyboardEvent, SetStateAction } from 'react'

//components
import DropStageArea from '../../../../../general/dragAndDropHandler/dropStageArea/dropStage'
import Draggable from '../../../../../general/dragAndDropHandler/draggable/draggable'
import InputField from '../../../../../general/inputField/inputField'
import Button from '../../../../../general/button/button'

//context
import { DragAndDropHandlerProvider } from '../../../../../general/dragAndDropHandler/context/dragAndDropHandlerContext'

//types
import { dragLocation } from '../../../../../../types/general/generalTypes'

// translation
import { useTranslation } from 'react-i18next'

type dropdownOptionEditorProps = {
    options: string[],
    setOptions: Dispatch<SetStateAction<string[]>>
}

const DropdownOptionEditor: FC<dropdownOptionEditorProps> = ({ options, setOptions }) => {
	const { t } = useTranslation('', {keyPrefix: 'general.table.modals.createColumnModal.dropdownOptionEditor'}) 
	const moveStage = (dragInfo: dragLocation, dropInfo: dragLocation) => {
		
		setOptions((prevStages)=> {
			let updatedStages = [...prevStages]
			const dragItem = prevStages[dragInfo.index]

			if(dragInfo.index === dropInfo.index) {
				return prevStages
			} else if(dragInfo.index > dropInfo.index) {
				updatedStages = updatedStages.filter((stage,index)=> dragInfo.index !== index)
				updatedStages.splice(dropInfo.index +1 , 0, dragItem)
			} else {
				updatedStages = updatedStages.filter((stage,index)=> dragInfo.index !== index)
				updatedStages.splice(dropInfo.index , 0, dragItem)
			}

			return updatedStages
		})

	}

	const handleStagesInputKeyPress = (e: KeyboardEvent<HTMLInputElement>, idx: number)=> {
		if(e.key === 'Enter' && options[idx+1] === undefined) setOptions([...options, ''])
		if(e.key === 'Backspace' && options[idx] === '') {
			const updatedStages = options.filter((stage, index)=> index !== idx)
			setOptions(updatedStages)
		}
		if(e.key === 'Delete') {
			const updatedStages = options.filter((stage, index)=> index !== idx)
			setOptions(updatedStages)
		}
	}

	return (
		<div className="drowdown-options-editor-container">
			<DragAndDropHandlerProvider>
				<div>
					<p className='drowdown-options-editor-title'>{t('options')}</p>
					<DropStageArea dropAreaSize='4.44vw' stageId={-1} stage={String(1)} onDrop={moveStage} className='drowdown-options-container'>
						{options.map((option, idx)=> (

							<Draggable key={`stage-${idx+1}`} dropAreaSize='4.44vw' index={idx} stageId={-1} stage={String(1)} dragData={option === '' ? null : option}>
								<div className="drowdown-options">
									<InputField 
										id={`stage-input-${idx}`}
										name={`stage-${idx+1}`} 
										autoFocus
										placeholder={`${t('option')} ${idx+1}`}
										type='text' 
										value={option} 
										onChange={(e)=> {
											options[idx] = e.target.value
											setOptions([...options])
										}}
										onKeyDown={(e)=> {
											handleStagesInputKeyPress(e, idx)
										}}
									/>
									<div className="drowdown-options-remove" onClick={()=> {
										const updatedStages = options.filter((stage, index)=> index !== idx)
										setOptions(updatedStages)
									}}>{close}</div>
								</div>
							</Draggable>

						))} 
					</DropStageArea>
				</div>
			</DragAndDropHandlerProvider>

			<Button text={t('addAnOption')} active={false} onClick={()=> {setOptions([...options, ''])}}/>
		</div>
	)
}

export default DropdownOptionEditor