//styles
import './floatInput.scss'

//react
import {FC, useEffect, useState} from 'react'
import { errorAlert } from '../../../assets/general/generalIcons'

//components
import CurrencyInput from 'react-currency-input-field'

//hooks
import useDebounce from '../../../customHooks/useDebounce'

//types
import {floatInput} from '../../../types/general/generalTypes'

type floatInputProps = floatInput

const FloatInput:FC<floatInputProps> = ({value, amountInputHandle, disabled, name, label, info, onBlur, onKeyPress, maxLength})=>{
	const [inputValue, setInputValue]=useState<string>(value)
	
	const debounceValue = useDebounce<string>(inputValue, 200)

	useEffect(() => {
		amountInputHandle(debounceValue)
	}, [debounceValue])

	useEffect(() => {
		setInputValue(value)
	}, [value])

	return (
		<div className="float-input">
			<label htmlFor={name}>{label}</label>
			<div className="float-input-wrapper">
				<div className="float-input-container">
					<CurrencyInput
						decimalsLimit={2}
						onValueChange={(value) => {
							// if(value !== inputValue){
							setInputValue(value || '')
							// }
						}}
						onBlur={onBlur}
						name={name}
						disabled={disabled}
						value={inputValue}
						maxLength={maxLength}
						onKeyPress={onKeyPress}
					/>
				</div>
			</div>
			{info && 
				<div className='error-wrapper'>
					<div className='error-text'>
						{errorAlert}
						<p>{info.text}</p>
					</div>
				</div>
			}
		</div>
	)
}

export default FloatInput