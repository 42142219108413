//styles and icons
import './moveEmployee.scss'

//react
import { useEffect, useState, FC } from 'react'

//components
import InputField from '../../../../general/inputField/inputField'
import Modal from '../../../../general/modals/modal/modal'
import CounterpartyCard from '../../../../general/counterpartyCard/counterpartyCard'
import Pagination from '../../../../general/pagination/pagination'
import ColumnLayout from '../../../../general/columnLayout/columnLayout'
import CounterpartySkeleton from '../../../../general/skeletons/counterParty/counterPartySkeleton'

//redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setMoveEmployeeModalIsOpen, setMoveEmployeeConfirmationModalIsOpen, setMoveEmployeeId, setMoveEmployeeNewLeaderId, setMoveEmployeeTeam } from '../../../../../redux/employees/general/modals'

//types
import { employeeResponse } from '../../../../../types/general/generalTypes'
import { employee } from '../../../../../types/employees/hierarchyTypes'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import {hierarchyMoveEmployeesUrl} from '../../../../../utils/urls/employees/hierarchy'

//translation
import { useTranslation } from 'react-i18next'

//other
import { formatEmployeeResponse } from '../../../../../assets/general/generalFunctions'

const MoveEmployee: FC = () => {
	const { t } = useTranslation('', {keyPrefix:'employees.general.modals.moveEmployee'})

	const { moveEmployee } = useAppSelector((state) => state.employeesGeneralModal)
	const dispatch = useAppDispatch()

	const [searchRequest, setSearchRequest] = useState('')
	const [newLeaders, setNewLeaders] = useState<employee[]>([])
	const [loading, setLoading] = useState(false)
	const [page, setPage] = useState<number>(1)
	const [lastPage, setLastPage] = useState<boolean>(false)

	const closeModal = () => {
		setSearchRequest('')
		setNewLeaders([])
		dispatch(setMoveEmployeeId(undefined))
		dispatch(setMoveEmployeeNewLeaderId(undefined))
		dispatch(setMoveEmployeeTeam(false))
		dispatch(setMoveEmployeeModalIsOpen(false))
	}

	const handleClick = async (newLeaderId: number) => {
		dispatch(setMoveEmployeeId(moveEmployee.employeeId))
		dispatch(setMoveEmployeeNewLeaderId(newLeaderId))
		dispatch(setMoveEmployeeTeam(moveEmployee.team))
		dispatch(setMoveEmployeeConfirmationModalIsOpen(true))
		dispatch(setMoveEmployeeModalIsOpen(false))
	}

	const getNewLeaders = async (employeeId: number, query: string, page: number) => {
		if(!loading && !lastPage){
			setLoading(true)
			const { result }: { result: employeeResponse[] } = await authorizedRequest(hierarchyMoveEmployeesUrl(employeeId) + `?needle=${query}&page=${page}&per_page=${10}`, 'GET')

			if(result.length>0){
				const leaders = result.map(employee => {
					return formatEmployeeResponse(employee)
				})

				setNewLeaders([...newLeaders, ...leaders])
				setPage(page)
			}else{
				setLastPage(true)
			}
			setLoading(false)
		}
	}

	const onSearch = (query: string) => {
		if(moveEmployee.employeeId){
			setPage(1)
			setLastPage(false)
			setSearchRequest(query)
			setLoading(false)
	
			getNewLeaders(moveEmployee.employeeId, query, 1)
		}
	}


	const handleLoadMore = () => {
		if(moveEmployee.employeeId){
			getNewLeaders(moveEmployee.employeeId, searchRequest, page+1)
		}
	}

	useEffect(() => {
		if(moveEmployee.modalIsOpen){
			onSearch('')
		}
	}, [moveEmployee.modalIsOpen])

	return (
		<Modal
			closeModal={closeModal}
			open={moveEmployee.modalIsOpen}
		>
			<div className="move-employee-container">
				<div className="move-employee-search-container">
					<InputField
						placeholder={t('search')}
						type="text"
						value={searchRequest}
						onChange={(e) => setSearchRequest(e.target.value)}
						onSearch={() => { onSearch(searchRequest) }}
					/>
				</div>
				<div className="move-employee-new-leaders-list">
					<Pagination onLoadMore={handleLoadMore} loadAxis='vertical'>
						<ColumnLayout>
							{
								newLeaders.map((leader: employee) => (
									<div
										key={`leader-${leader.id}`}
										onClick={() => handleClick(leader.id)}
									>
										<CounterpartyCard
											name={leader.name}
											avatar={leader.avatar}
											tags={leader.tags}
										/>
									</div>
								))
							}
							{
								loading ?
									<CounterpartySkeleton/>
									: null
							}
						</ColumnLayout>
					</Pagination>

				</div>
				
			</div>
		</Modal>
	)
}
export default MoveEmployee