// react
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import InfoDetailsTable from '../../../../components/general/infoDetailsTable/infoDetailsTable'
import Loading from '../../../general/loading/loading'

// types
import { currency, infoDetailsTableDataItem, infoType, warehouseResponse } from '../../../../types/general/generalTypes'

// network
import { authorizedRequest } from '../../../../utils/queries'
import { singleWarehouseUrl } from '../../../../utils/urls/warehouses/warehouses/warehouses'

//other
import { currencyToFormattedString } from '../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type warehouseDetailsProps = {
	setWarehouseName: (value: string) => void
	editAccess: boolean,
	setEditAccess: (value: boolean) => void
}

type error = {
	name?: string;
}

const WarehouseDetails: FC<warehouseDetailsProps> = ({ setWarehouseName, editAccess, setEditAccess }) => {
	const { t } = useTranslation('', { keyPrefix: 'warehouse.warehouse.warehouseDetails' })
	const tErrors = useTranslation('', { keyPrefix: 'warehouse.warehouseList.modals.createWarehouseModal' }).t

	const [editDetails, setEditDetails] = useState<boolean>(false)
	const [name, setName] = useState<string>('')
	const [errors, setErrors] = useState<error>({})

	const [address, setAddress] = useState<string>('')
	const [addressCoordinates, setAddressCoordinates] = useState<{ lat: number, lng: number }>()
	const [worth, setWorth] = useState<{ amount: number, currency: currency }[]>([])
	const [activeShipments, setActiveShipments] = useState(0)
	const [activeMovings, setActiveMovings] = useState(0)
	const { warehouseId } = useParams()
	const [loading, setLoading] = useState<boolean>(false)

	const infoDetailsTable: infoDetailsTableDataItem[] = [
		{
			title: t('name'),
			data: {
				inputField: {
					type: 'text',
					value: name,
					onChange: (e) => {
						setName(e.target.value)
						setWarehouseName(e.target.value)
						setEditDetails(true)
						setErrors({})
					},
					disabled: !editAccess,
					info: errors.name ? {
						type: infoType.error,
						text: errors.name
					} : undefined
				}
			}
		},
		{
			title: t('address'),
			data: {
				locationSearchInput: {
					setAddress: (value) => {
						setAddress(value)
						setEditDetails(true)
					},
					setLocation: (value) => {
						setAddressCoordinates(value)
						setEditDetails(true)
					},
					disabled: !editAccess
				}
			}
		},
		...worth.map((worth) => ({
			title: t('worth'),
			data: {
				inputField: {
					type: 'text',
					value: currencyToFormattedString(worth.amount, worth.currency),
					disabled: true
				}
			}
		})),
		{
			title: t('activeShipments'),
			data: {
				inputField: {
					type: 'text',
					value: `${activeShipments}`,
					disabled: true
				}
			}
		},
		{
			title: t('activeMovings'),
			data: {
				inputField: {
					type: 'text',
					value: `${activeMovings}`,
					disabled: true
				}
			}
		}
	]

	const checkErrors = () => {
		let hasErrors = true
		if (!name.length) {
			setErrors(prevErrors => ({ ...prevErrors, name: tErrors('pleaseEnterTheName') }))
			hasErrors = false
		}
		return hasErrors
	}

	const loadData = async () => {
		try {
			setLoading(true)
			const { result }: { result: warehouseResponse } = await authorizedRequest(singleWarehouseUrl(Number(warehouseId)), 'GET')
			setWarehouseName(result.name)
			setName(result.name)
			setAddress(result.address)
			setAddressCoordinates({
				lat: result.coords.lat,
				lng: result.coords.lng
			})
			setWorth(result.worth)
			setActiveShipments(result.shipment_amount)
			setActiveMovings(result.moving_amount)
			setEditAccess(result.edit_access)
		} finally {
			setLoading(false)
		}
	}


	const editData = async () => {
		if (Number(warehouseId) && checkErrors()) {
			const { result } = await authorizedRequest(singleWarehouseUrl(Number(warehouseId)), 'PUT', 'accessToken', {
				name: name,
				address: address,
				coordinates: addressCoordinates,
			})
		}
	}

	useEffect(() => {

		if (editDetails) {
			editData()
			setTimeout(() => {
				setEditDetails(false)
			}, 500)
		}

	}, [editDetails])

	useEffect(() => {
		loadData()
	}, [])

	return (
		<>
			<InfoDetailsTable data={infoDetailsTable} />{loading && <Loading style={{ top: '40vh', left: '50vw' }} />}
		</>
	)
}

export default WarehouseDetails