// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'

const SetUpManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.general' })

	const data: manualData[] = [
		{
			title: t('registerTitle'),
			text: t('registerText'),
			link: '/register'
		},
		{
			title: t('createACompanyTitle'),
			text: t('createACompanyText'),
		},
		{
			title: t('inviteEmployeesTitle'),
			text: t('inviteEmployeesText'),
			link: '/ri-manual/employees'
		},
		{
			title: t('setUpPipelinesTitle'),
			text: t('setUpPipelinesText'),
			link: '/ri-manual/projects'
		},
		{
			title: t('setUpPurchasesTitle'),
			text: t('setUpPurchasesText1')
		},
		{
			title: '',
			text: t('setUpPurchasesText2'),
			link: '/ri-manual/purchases'
		},
		{
			title: '',
			text: t('setUpPurchasesText3'),
			link: '/ri-manual/purchase/provider'
		},
		{
			title: '',
			text: t('setUpPurchasesText4'),
			link: '/ri-manual/purchase/provider'
		},
		{
			title: '',
			text: t('setUpPurchasesText5')
		},
		{
			title: '',
			text: t('setUpPurchasesText6')
		},
		{
			title: t('setUpSalesTitle'),
			text: t('setUpSalesText1'),
			link: '/ri-manual/sales/customer'
		},
		{
			title: '',
			text: t('setUpSalesText2'),
			link: '/ri-manual/sales/offering'
		},
		{
			title: '',
			text: t('setUpSalesText3'),
			link: '/ri-manual/sales/offering'
		},
		{
			title: t('setUpFinanceTitle'),
			text: t('setUpFinanceText'),
			link: '/ri-manual/finance'
		},
		{
			title: t('setUpWarehouseTitle'),
			text: t('setUpWarehouseText'),
			link: '/ri-manual/warehouses'
		}, 
		{
			title: t('thankYouTitle'),
			text: t('thankYouText')
		}
	]
	
	return (
		<ManualLayout 
			heading={t('title')}
			data={data}
		/>
	)
}

export default SetUpManual
