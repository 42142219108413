// react
import { FC } from 'react'
import { useParams } from 'react-router-dom'

// component
import FileSelectorModal from '../../../../../general/modals/fileSelectorModal/fileSelectorModal'

// type
import { file, document, templateTypes } from '../../../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../../../utils/queries'
import { singleCustomerDocumentsUrl } from '../../../../../../utils/urls/sales/customer'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setImportCustomerDocumentFileSelectorFile, setImportCustomerDocumentFileSelectorModalIsOpen } from '../../../../../../redux/sales/customer/modals'

//other
import { blobStringToBlob, blobToBase64 } from '../../../../../../assets/general/generalFunctions'

type importCustomerDocumentFileSelectorModalProps = {
	setDocumentsData: (value: document[]) => void
	documentsData: document[]
	customerId?: number
}

type customerDocumentUploadResponse = {
	id: number
	file: string
	file_type: string
	file_name: string
}

const ImportCustomerDocumentFileSelectorModal : FC<importCustomerDocumentFileSelectorModalProps> = ({ documentsData, setDocumentsData, customerId }) => {
	const { modalIsOpen } = useAppSelector((state) => state.customerModal.customerDocuments.importCustomerDocumentFileSelectorModal)
	const { relationshipId } = useParams()
	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setImportCustomerDocumentFileSelectorModalIsOpen(false))
	}

	const setFiles = async (files: file[]) => {		
		if (files.length === 0) return

		const filesData = await Promise.all(files.map(async (file) => {
			const base64File = await blobToBase64(file.file)
			return {
				'name': file.fileName,
				'file_type': file.fileType,
				'file': base64File,
			}
		}))

		const data = {
			files: filesData
		}

		authorizedRequest(singleCustomerDocumentsUrl(Number(relationshipId ? relationshipId : customerId)), 'POST', 'accessToken', data)
			.then((response) => {
				console.log(response)
				const responseData: document[] = response.result.map((item: customerDocumentUploadResponse) => {
					return {
						file: blobStringToBlob(item.file, item.file_type),
						fileName: item.file_name,
						fileType: item.file_type,
						id: item.id
					}
				})
				console.log(responseData)
				setDocumentsData([...documentsData, ...responseData])
				dispatch(setImportCustomerDocumentFileSelectorModalIsOpen(false))
			})
		dispatch(setImportCustomerDocumentFileSelectorFile(files[0]))
	}

	return (
		<FileSelectorModal 
			isOpen={modalIsOpen} 
			setIsOpen={closeModal}
			setFiles={(value) => { setFiles(value) }}
			supportedFormats={[
				{title: 'csv', mime: 'text/csv'},
				{title: 'xls', mime: 'application/vnd.ms-excel'},
				{title: '.docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'},
				{title: '.pdf', mime: 'application/pdf'}, // jpg png .mp4 .mp3 All
				{title: '.jpeg', mime: 'image/jpeg'},
				{title: '.jpg', mime: 'image/jpg'},
				{title: '.png', mime: 'image/png'},
				{title: '.mp4', mime: 'video/mp4'},
				{title: '.webm', mime: 'video/webm'},
				{title: '.mp3', mime: 'audio/mpeg'},
				{title: '.weba', mime: 'audio/webm'},
				{title: '.ppt', mime: 'application/vnd.ms-powerpoint'},
				{title: '.zip', mime: 'application/zip'}
			]}			
			template={{
				type: templateTypes.customer,
				objectId: customerId || -1
			}}
		/>
	)
}

export default ImportCustomerDocumentFileSelectorModal