// react
import { FC, useEffect, useState } from 'react'

// components
import ConfirmationModal from '../../../../../general/modals/confirmationModal/confirmationModal'

// types
import { executor } from '../../../../../../types/general/generalTypes'

// network
import { authorizedRequest } from '../../../../../../utils/queries'
import { singleTimeTacker } from '../../../../../../utils/urls/projects/task'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setDeleteTimeTrackerModalExecutorId, setDeleteTimeTrackerModalIsOpen } from '../../../../../../redux/projects/task/modals'

//translate
import { useTranslation } from 'react-i18next'

type deleteTimeTrackerModalProps = {
	timeTrackers: executor[]
	setTimeTrackers: (value: executor[]) => void
}

const DeleteTimeTrackerModal: FC<deleteTimeTrackerModalProps> = ({timeTrackers, setTimeTrackers}) => {
	const { t } = useTranslation('', {keyPrefix: 'projects.task.timeTrackerTab.modals.deleteTimeTrackerModal'})  
	
	const { modalIsOpen, executorId } = useAppSelector(state=> state.taskModals.deleteTimeTrackerModal)
	const dispatch = useAppDispatch()
	
	const [loading, setLoading] = useState(false)


	const closeModal = ()=>{
		dispatch(setDeleteTimeTrackerModalIsOpen(false))
		dispatch(setDeleteTimeTrackerModalExecutorId(undefined))
	}

	const handleDelete = async ()=> {
		try {
			setLoading(true)
			await authorizedRequest(singleTimeTacker(Number(executorId)), 'DELETE')
			
			const updatedTrackers = timeTrackers.filter(tracker => tracker.id !== executorId)
			setTimeTrackers(updatedTrackers)
			closeModal()
			
		} catch(err) {
			console.log(err)
		} finally{
			setLoading(false)
		}
	}

	return (
		<ConfirmationModal 
			title={t('deleteThisTimeTracker')}
			closeModal={closeModal} 
			isOpen={modalIsOpen}
			buttons={[
				{
					text: t('yes'),
					onClickHandler: handleDelete
				},
				{
					text: t('no'),
					onClickHandler: closeModal
				}
			]}
			loading={loading}
		/>
	)
}

export default DeleteTimeTrackerModal