//components
import DocumentList from '../../../general/documentList/documentList'
import Pagination from '../../../general/pagination/pagination'
import DeleteProjectDocumentConfirmationModal from './modals/deleteProviderDocumentConfirmationModal/deleteProjectDocumentConfirmationModal'
import ImportProjectDocumentFileSelectorModal from './modals/ImportProviderDocumentFileSelectorModal/ImportProjectDocumentFileSelectorModal'

//react
import { FC, useState, useEffect } from 'react'
import { useParams } from 'react-router'

//types
import { filterBar, filters, document } from '../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../utils/queries'
import { singleProjectDocumentFiltersUrl, singleProjectDocumentsUrl } from '../../../../utils/urls/projects/project'

//redux
import { useAppDispatch } from '../../../../customHooks/redux'
import { setDeleteProjectDocumentId, setDeleteProjectDocumentModalIsOpen, setImportProjectDocumentFileSelectorModalIsOpen } from '../../../../redux/projects/pipeline/modals'

//other
import { createFilteringRequest, formatDocumentResponse, formatFilters } from '../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type providerDocumentsProps = {
	setFilterBar: (filterBar: filterBar | undefined) => void
}

const ProjectDocuments: FC<providerDocumentsProps> = ({ setFilterBar }) => {

	const { t } = useTranslation('', {keyPrefix:'projects.project.projectDocuments'})

	const dispatch = useAppDispatch()

	const [documents, setDocuments] = useState<document[]>([])

	const [searchRequest, setSearchRequest] = useState('')
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)

	const {projectId} = useParams()

	const loadFilterBar = async () => {
		const { result } = await authorizedRequest(singleProjectDocumentFiltersUrl(Number(projectId)), 'GET')

		const filterResult = result.filters

		const formatedFilters: filters[] = formatFilters(filterResult)

		setFilterBar({
			filters: formatedFilters,
			addButton: {
				text: t('add'),
				active: true,
				onClick: () => {
					dispatch(setImportProjectDocumentFileSelectorModalIsOpen(true))
				}
			},
			onSearch: onSearch
		})
	}

	const loadData = async (page: number, query: string, filters: filters[]) => {
		const filteringRequest = createFilteringRequest(filters)
		const { result } = await authorizedRequest(singleProjectDocumentsUrl(Number(projectId)) + `?needle=${query}&page=${page}` + filteringRequest, 'GET')
		
		if (result.length > 0) {
			const formatedDocumentsData: document[] = result.map(formatDocumentResponse)
			return formatedDocumentsData
		}
		return []
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		setLoading(true)
		loadData(1, searchValue, filters)
			.then((result) => {
				if (result.length > 0) {
					setDocuments([...documents, ...result])
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
				setLoading(false)
			})
	}

	const handleLoadMore = () => {
		console.log(loading, lastPage)
		if (!loading && !lastPage) {
			setLoading(true)
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					if (result.length > 0) {
						setDocuments([...documents, ...result])
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
					setLoading(false)
				})
		}
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()

		return () => {
			setFilterBar(undefined)
		}
	}, [])

	const handleDeleteProviderDocument = (id: number) => {
		console.log(id)
		dispatch(setDeleteProjectDocumentId(id))
		dispatch(setDeleteProjectDocumentModalIsOpen(true))
	}

	return (
		<Pagination onLoadMore={handleLoadMore}>
			<DocumentList
				fileList={documents.map((doc) => ({ ...doc }))}
				handleDelete={handleDeleteProviderDocument}
			/>
			<ImportProjectDocumentFileSelectorModal documentsData={documents} setDocumentsData={setDocuments} />
			<DeleteProjectDocumentConfirmationModal documentsData={documents} setDocumentsData={setDocuments}/>
		</Pagination>
	)
}

export default ProjectDocuments