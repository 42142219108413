//translation
import { TFunction } from 'i18next'

//types
import {
	chartItem,
	chartDurationOptions,
	currency,
	dragStage,
	dropdownOption,
	file,
	filterTypes,
	filters,
	inventoryItem,
	inventoryItemResponse,
	itemTypes,
	location,
	locationResponse,
	notificationType,
	order,
	orderResponse,
	task,
	offering,
	offeringResponse,
	weekDays,
	shifts,
	shiftResponse,
	employeeResponse,
	phoneNumber,
	phoneNumberResponse,
	moneyValue,
	shipment,
	shipmentResponse,
	expandableCheckbox,
	errorResponse,
	error,
	detailedErrorObjectResponse,
	detailedErrorObject,
	detailedErrorObjectTypes,
	switchButton,
	chartItemResponse,
	charItemDataResponse,
	customerResponse,
	extraColumnsResponse,
	tableHeader,
	extraColumnDataResponse,
	tableBodyItem,
	document,
	documentResponse,
	tableTypes,
	project,
	columnTypes,
	tableBodyItemContentResponse,
	tableBodyItemContent,
	selectionOption,
	templateResponse,
	template,
	fileResponse
} from '../../types/general/generalTypes'
import { customer } from '../../types/sales/customerTypes'
import { bill } from '../../types/finance/general'
import { account, accountResponse } from '../../types/finance/accountTypes'
import { provider, providerResponse } from '../../types/purchase/providerTypes'
import { employee } from '../../types/employees/hierarchyTypes'
import { budget } from '../../types/finance/budgetTypes'

//network
import { authorizedRequest } from '../../utils/queries'
import { baseUrl } from '../../utils/network'
import { companyExtraColumnDataUrl } from '../../utils/urls/general/company/company'
import { whoAmIUrl } from '../../utils/old_urls/generalUrls' 

//others
import { formatBillResponse } from '../finance/general/generalFunctions'
import { formatBudgetResponse, formatProjectResponse, formatTaskResponse } from '../projects/projectsFunctions'


export const translateDate = (date: Date): Date => {
	const offsetMinutes = new Date().getTimezoneOffset()
	const offsetMilliseconds = 0

	return new Date(date.getTime() - offsetMilliseconds)
}

export const formatDate = (date: Date, translate: boolean, full: boolean): string => {
	const localDate = translate ? translateDate(date) : date


	const day = localDate.getDate() < 10 ? '0' + localDate.getDate() : localDate.getDate()
	const month = localDate.getMonth() + 1 < 10 ? '0' + (localDate.getMonth() + 1) : localDate.getMonth() + 1
	const year = localDate.getFullYear()

	const hours = localDate.getHours() < 10 ? '0' + localDate.getHours() : localDate.getHours()
	const minutes = localDate.getMinutes() < 10 ? '0' + localDate.getMinutes() : localDate.getMinutes()

	return full ? `${day}.${month}.${year} ${hours}:${minutes}` : `${day}.${month}.${year}`
}

export const formatDateForBackend = (date: Date): string => `${date.getDate() }-${date.getMonth() + 1}-${date.getFullYear()}`

export const formatDateToHour = (date: Date):string => date.toLocaleTimeString(undefined,{hour:'numeric',minute:'numeric',hour12:true}) 

export const getDaysInMonth = (date: Date) => new Date(date.getFullYear(), date.getMonth(), 0).getDate()

export const getDeadlineData = (dueDate?: Date | null) => {
	if(dueDate){
		const currentDate = new Date()
		const timeDiff = translateDate(dueDate).getTime() - currentDate.getTime()
		const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24))
		return { daysLeft: daysDiff, isDue: daysDiff < 0 || timeDiff < currentDate.getTime() }
	}else{
		return { daysLeft: null, isDue: null }
	}
} 

export const formatStringAmountIntoNumber = (amount: string): string => {
	const cleanedString = amount.replace(/[^\d.]+/g, '') // Remove anything that is not a digit or dot
	return cleanedString
}

export const formatStringIntoTwoDigitsFloat = (string: string)=>{
	const value = formatStringAmountIntoNumber(string)
	
	if(value === ''){
		return 0
	}

	const numberValue = Number(parseFloat(value).toFixed(2))
	return numberValue
}

const stringToBlob = (data: string, mimeType: string): Blob => {
	const bytes = new Uint8Array(data.length)
	for (let i = 0; i < data.length; i++) {
		bytes[i] = data.charCodeAt(i)
	}
	return new Blob([bytes], { type: mimeType })
}

export const blobStringToBase64 = (data: string, mimeType: string) => {
	return new Promise<string>((resolve) => {
		const reader = new FileReader()

		reader.onloadend = () => {
			resolve(reader.result as string)
		}
    

		reader.readAsDataURL(stringToBlob(data, mimeType))
	})
}

export const currencyToFormattedString = ( amount: number, currencyType: currency ) =>{
	
	let formatter: {
		format: (amount:number) => string
	} 

	switch(currencyType){
	case currency.EUR:
		// EUR formatting
		formatter = new Intl.NumberFormat('en-GB', {
			style: 'currency',
			currency: 'EUR',
		})
		break
	case currency.UAH:
		// UAH Formatting
		formatter = new Intl.NumberFormat('en-UA', {
			style: 'currency',
			currencyDisplay:'narrowSymbol',
			currency: 'UAH',
		})
		break
	case currency.USD:
		// USD Formatting
		formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
		})
		break
	default:
		formatter = new Intl.NumberFormat('en-UA', {
			style: 'currency',
			currencyDisplay:'narrowSymbol',
			currency: 'UAH',
		})
	}
	return formatter.format(amount)
}

export const blobStringToBlob  = (data: string, mimeType: string): Blob => {
	const bytes = new Uint8Array(data.length)
	for (let i = 0; i < data.length; i++) {
		bytes[i] = data.charCodeAt(i)
	}
	return new Blob([bytes], { type: mimeType })
}

export const base64ToBlob = (base64Data: string, contentType: string): Blob => {
	const binaryString = window.atob(base64Data.split('base64,')[1])

	const length = binaryString.length
	const bytes = new Uint8Array(length)

	for (let i = 0; i < length; i++) {
		bytes[i] = binaryString.charCodeAt(i)
	}
	
	return new Blob([bytes], { type: contentType })
}

export const formatTags = (value: string,tFunction:TFunction): string => {
	const regex = /(UAH|EU|USD)/
	if (!regex.test(value.toString().toUpperCase())) {
		return value
	}
	
	let result = ''
	value.split(' ').forEach(e => {
		const amountArr: string[] = []
		const currenyArr: string[] = []
		for (const char of e) {
			if (!isNaN(Number(char)) || char === '.') {
				amountArr.push(char)
			} else if (char !== ' ') {
				currenyArr.push(char)
			}
		}
		const arrayRes = `${Number(amountArr.join('')).toFixed(2)}${currenyArr.length > 0 ? tFunction(currenyArr.join('').toUpperCase()) : ''} `
		result += arrayRes
	})
	return result
}

export const blobToBase64 = (blob: Blob): Promise<string> => {
	return new Promise((resolve) => {
		const reader = new FileReader()

		reader.onloadend = () => {
			resolve(reader.result as string)
		}
	
		reader.readAsDataURL(blob)
	})
}

export const formatFiles = (files: File[]): Promise<file[]> =>{
	const fileList: file[] = []
	return new Promise((resolve) => {
				
		for (let i = 0; i < files.length; i++) {
			const reader = new FileReader()
			reader.onloadend = () => {
				
				fileList.push({
					fileName: files[i].name,
					file: base64ToBlob(reader.result as string, files[i].type),
					fileType: files[i].type
				})	
				
				
				if (i === files.length-1) {
					resolve(fileList)
				}
			}
			reader.readAsDataURL(files[i])
		}
		
	})
}

export const vwToPixel = (vw:number)=>{
	return window.innerWidth / 100 * vw
}


export const hexToRgb = (hex: string): { r: number; g: number; b: number } | null  => {
	// Remove the hash character, if any
	hex = hex.replace(/^#/, '')

	// Ensure the hex code is valid
	const validHexRegex = /^[0-9A-Fa-f]{6}$/
	if (!validHexRegex.test(hex)) {
		return null // Invalid hex code
	}

	// Parse the hex values for red, green, and blue components
	const bigint = parseInt(hex, 16)
	const r = (bigint >> 16) & 255
	const g = (bigint >> 8) & 255
	const b = bigint & 255

	return { r, g, b }
}

type filterResponse = {
	type: 'RANGE'
	title: string
	data: {
		min: number
		max: number
	}
	key: string
} | {
	type: 'CALENDAR'
	title: string
	data: null
	key: string
} | {
	type: 'DROPDOWN'
	title: string
	data: {
		title: string
		key: string
	}[]
	selectedOption: undefined
	key: string
}

export const formatFilters = (filters: filterResponse[]): filters[] => {
	return filters.map((filter) => {
		switch(filter.type){
		case 'DROPDOWN':
			return {
				filter: {
					type: filterTypes.DROPDOWN,
					title: filter.key,
					data: {
						selectedOption:undefined,
						title: filter.key,
						dropdownOptions: filter.data.map((data)=>{
							return {
								...data,
								title: data.key,
							}
						}),
						placeholder:''
					},
					placeholder:'',
					selectedOption: undefined
				},
				key: filter.key
			}
		case 'RANGE':
			return {
				filter: {
					type: filterTypes.RANGE,
					title: filter.key,
					data: {
						min: Math.floor(filter.data.min),
						max: Math.ceil(filter.data.max)
					},
					selectedOption: {
						min: Math.floor(filter.data.min),
						max: Math.ceil(filter.data.max)
					}
				},
				key: filter.key
			}
		case 'CALENDAR':
			return {
				filter: {
					type: filterTypes.CALENDAR,
					title: filter.key,
					data: undefined,
					selectedOption: {startDate: undefined, endDate: undefined}
				},
				key: filter.key
			}
		}

		
	})
}

export const createFilteringRequest = (filters: filters[]): string => {
	let result = ''
	filters.forEach((filter) => {

		let endDate = undefined
		let startDate = undefined
		if(filter.filter.type === filterTypes.CALENDAR){
			endDate = filter.filter.selectedOption?.endDate
			startDate = filter.filter.selectedOption?.startDate
		}
		switch(filter.filter.type){
			
		case filterTypes.DROPDOWN:
			result += `&${filter.key}=${filter.filter.selectedOption?.key || ''}`
			break
		case filterTypes.RANGE:
			result += `&max_${filter.key}=${filter.filter.selectedOption.max}&min_${filter.key}=${filter.filter.selectedOption.min}`
			break

		case filterTypes.CALENDAR:
			if(startDate) result += `&min_${filter.key}=${startDate ? `${startDate.getFullYear()}-${startDate.getMonth()+1}-${startDate.getDate()}` : ''}`
			if(endDate)result += `&max_${filter.key}=${endDate ? `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}` : ''}`
			break
		}
	})
	
	return result
}

export const formatEmployeeResponse = (response: employeeResponse, getActions?: (employee: employee) => switchButton[]): employee => {
	const formattedEmployee: employee = {
		id: response.employee_id,
		userId: response.user_id,
		chatId: response.chat_id,
		position: response.position.name,
		isTeamLeader: response.is_team_leader,
		inTeam: response.in_team, 
		name: response.name,
		avatar: response.avatar,
		tags: response.labels,
		isOwner: response.is_owner,
		active: false,
		editAccess: response.edit_access,
		fireAccess: response.fire_access,
		moveAccess: response.move_access,
		replaceAccess: response.replace_access
	}

	return ({
		...formattedEmployee,
		actions: getActions ? getActions(formattedEmployee) : undefined
	})

}

export const getGraphXAxis = (date1: Date, date2: Date, duration: chartDurationOptions) => {
	const result = []
	const currentDate = new Date(date1)
  
	while (currentDate <= date2) {
		result.push(formatDate(currentDate, true, false)) // Get the date part only
  
		switch (duration) {
		case chartDurationOptions.DAILY:
			currentDate.setDate(currentDate.getDate() + 1)
			break
		case chartDurationOptions.WEEKLY:
			currentDate.setDate(currentDate.getDate() + 7)
			break
		case chartDurationOptions.MONTHLY:
			currentDate.setMonth(currentDate.getMonth() + 1)
			break
		case chartDurationOptions.YEARLY:
			currentDate.setFullYear(currentDate.getFullYear() + 1)
			break
		default:
			throw new Error('Invalid duration option')
		}
	}
  
	return result
}

export const getItemsPerColumn = (max = 3): number => {

	switch(max){
	case 3:
		return screen.width > 768 ? 3 : screen.width > 425 ? 2 : 1
	case 2: 
		return screen.width > 768 ? 2 : 1
	default:
		return screen.width > 768 ? 3 : screen.width > 425 ? 2 : 1
	}

}

export const getNotificationType = (type: string) => {
	switch (type) {
	case 'positive':
		return notificationType.POSITIVE
	case 'negative':
		return notificationType.NEGATIVE
	case 'neutral':
		return notificationType.NEUTRAL
	case 'info':
		return notificationType.INFO
	default:
		return notificationType.INFO
	}

}


export const createDropdownOption = (t: TFunction, valuesObj: Record<string, string>): dropdownOption[] => {
	return Object.entries(valuesObj).map(([key, value])=>({ key, title: t(`${key}`) }))
}

export const getOfferingCost = (items: inventoryItemResponse[] | inventoryItem[])=> {
	const costResult: {
		minAmount?: number
		maxAmount?: number
		currency: currency
	}[] = [
		{minAmount: undefined, maxAmount: undefined, currency: currency.UAH},
		{minAmount: undefined, maxAmount: undefined, currency: currency.USD},
		{minAmount: undefined, maxAmount: undefined, currency: currency.EUR}
	]

	items.forEach((item)=> {
		item.costs.forEach((cost) => {
			if(cost.currency == currency.UAH){
				costResult[0].minAmount = Math.min(cost.amount, (costResult[0].minAmount || cost.amount))
				costResult[0].maxAmount = Math.max(cost.amount, (costResult[0].maxAmount || cost.amount))
			}
			if(cost.currency == currency.USD){
				costResult[1].minAmount = Math.min(cost.amount, (costResult[1].minAmount || cost.amount))
				costResult[1].maxAmount = Math.max(cost.amount, (costResult[1].maxAmount || cost.amount))
			}
			if(cost.currency == currency.EUR){
				costResult[2].minAmount = Math.min(cost.amount, (costResult[2].minAmount || cost.amount))
				costResult[2].maxAmount = Math.max(cost.amount, (costResult[2].maxAmount || cost.amount))
			}
		})
		
	})

	const filteredCostResult = costResult.map((costResultItem) => {
		return {
			minAmount: costResultItem.minAmount,
			maxAmount: costResultItem.maxAmount == costResultItem.minAmount ? undefined : costResultItem.maxAmount,
			currency: costResultItem.currency
		}
	})

	return filteredCostResult
}

export const getCost = (costs: moneyValue[])=> {
	const costResult: {
		minAmount?: number
		maxAmount?: number
		currency: currency
	}[] = [
		{minAmount: undefined, maxAmount: undefined, currency: currency.UAH},
		{minAmount: undefined, maxAmount: undefined, currency: currency.USD},
		{minAmount: undefined, maxAmount: undefined, currency: currency.EUR}
	]
	costs.forEach((cost) => {
		if(cost.currency == currency.UAH){
			costResult[0].minAmount = Math.min(cost.amount, (costResult[0].minAmount || cost.amount))
			costResult[0].maxAmount = Math.max(cost.amount, (costResult[0].maxAmount || cost.amount))
		}
		if(cost.currency == currency.USD){
			costResult[1].minAmount = Math.min(cost.amount, (costResult[1].minAmount || cost.amount))
			costResult[1].maxAmount = Math.max(cost.amount, (costResult[1].maxAmount || cost.amount))
		}
		if(cost.currency == currency.EUR){
			costResult[2].minAmount = Math.min(cost.amount, (costResult[2].minAmount || cost.amount))
			costResult[2].maxAmount = Math.max(cost.amount, (costResult[2].maxAmount || cost.amount))
		}
	})

	const filteredCostResult = costResult.map((costResultItem) => {
		return {
			minAmount: costResultItem.minAmount,
			maxAmount: costResultItem.maxAmount == costResultItem.minAmount ? undefined : costResultItem.maxAmount,
			currency: costResultItem.currency
		}
	})

	return filteredCostResult
}

export const getOrderItemsTotal = (selectedItems: offering[])=> {
	const total = [
		{amount: 0, currency: currency.UAH},
		{amount: 0, currency: currency.USD},
		{amount: 0, currency: currency.EUR}
	]
	selectedItems.forEach(selectedItem => {
		if(selectedItem.price.currency === currency.UAH) total[0].amount += selectedItem.price.amount * selectedItem.quantity
		if(selectedItem.price.currency === currency.USD) total[1].amount += selectedItem.price.amount * selectedItem.quantity
		if(selectedItem.price.currency === currency.EUR) total[2].amount += selectedItem.price.amount * selectedItem.quantity
	})
	return total
}


export const formatOfferingResponse = (item: offeringResponse): offering => {
	return {
		items: item.items.map(formatInventoryItemResponse),
		quantity: item.quantity ? item.quantity : 0,
		description: item.description,
		name: item.name,
		avatar: item.avatar,
		id: item.offering_id,
		price: item.price,
		vat: item.vat,
		editAccess: item.edit_access,
		deleteAccess: item.delete_access,
		createAccess: item.create_access,
		offering_type: item.offering_type,
		type: itemTypes.offering,
		orderItemId: item.orderitem_id
	}
}

// export const formatOrderItemResponse = (item: orderItemResponse): orderItem => {
// 	return {
// 		id:item.offering_id,
// 		orderItemId:item.orderitem_id,
// 		avatar: item.avatar,
// 		name: item.name,
// 		price: {
// 			amount: item.price.amount,
// 			currency: item.price.currency
// 		},
// 		quantity: item.quantity,
// 		type: itemTypes.order_item
// 	}
// }

export const formatInventoryItemResponse = (item: inventoryItemResponse): inventoryItem => {
	return {
		id: item.inventory_item_id,
		avatar: item.avatar,
		name: item.name,
		description: item.description,
		costs: item.costs,
		cost: item.cost,
		deleteAccess: item.delete_access,
		editAccess: item.edit_access,
		createAccess: item.create_access,
		quantity: item.quantity ? item.quantity : 0,
		type: itemTypes.inventory_item
	}
}

export const formatLocationResponse = (location: locationResponse) : location => {
	return {
		id: location.id,
		address: location.address,
		coords: location.coords,
		type: location.type
	}
}

export const formatShipmentResponse = (response: shipmentResponse): shipment => {
	return {
		id: response.id,
		startDate: response.start_date ? new Date(response.start_date * 1000) : null,
		endDate: response.end_date ? new Date(response.end_date * 1000) : null,
		status: response.status,
		origin: response.origin && formatLocationResponse(response.origin),
		destination: response.destination && formatLocationResponse(response.destination),
		items: response.items.map(formatInventoryItemResponse),
		taskId: response.task_id,
		isClosed: response.is_closed
	}
}

export const formatOrderResponse = (order: orderResponse): order => {
	return {
		counterparty: {
			avatar: order.counterparty.avatar,
			name: order.counterparty.name,
			relationshipId: order.counterparty.relationship_id
		},
		id: order.id,
		startDate: order.start_date ? new Date(order.start_date * 1000) : null,
		endDate: order.end_date ? new Date(order.end_date * 1000) : null,
		destination: order.destination ? formatLocationResponse(order.destination) : null,
		items: order.items.map(formatOfferingResponse),
		type: order.type,
		isClosed: order.is_closed,
		errors: order.errors.map(formatErrorResponse),
		deleteAccess: order.delete_access,
		editAccess: order.edit_access,
		closeAccess: order.edit_access,
		createAccess: order.create_access
	}
}

export const timeStringToDecimalFraction = (timeString: string) => {
	const hours = Number(timeString.split(':')[0])
	const minutes = Number(timeString.split(':')[1])
	return hours + minutes/60
}

export const decimalFractionToTimeString = (time: number) => {
	// Calculate the hours, minutes, and seconds
	const hours = Math.floor(time)
	const minutes = Math.floor((time - hours) * 60)
	const seconds = Math.floor(((time - hours) * 60 - minutes) * 60)
   
	// Ensure double-digit formatting
	const hoursStr = hours < 10 ? '0' + hours : hours.toString()
	const minutesStr = minutes < 10 ? '0' + minutes : minutes.toString()
	const secondsStr = seconds < 10 ? '0' + seconds : seconds.toString()
   
	return hoursStr + ':' + minutesStr + ':' + secondsStr
}

export const formatShiftResponse = (shiftsData: shiftResponse): shifts => {
	const shifts: shifts = [
		{
			day: weekDays.mon,
			shift: [],
			active: true
		},
		{
			day: weekDays.tue,
			shift: [],
			active: false
		},
		{
			day: weekDays.wed,
			shift: [],
			active: false
		},
		{
			day: weekDays.thu,
			shift: [],
			active: false
		},
		{
			day: weekDays.fri,
			shift: [],
			active: false
		},
		{
			day: weekDays.sat,
			shift: [],
			active: false
		},
		{
			day: weekDays.sun,
			shift: [],
			active: false
		}
	]
	shiftsData.map(shift => {
		const shiftStart = timeStringToDecimalFraction(shift.shift.start)
		const shiftEnd = timeStringToDecimalFraction(shift.shift.end)
		const shiftHours = {start: shiftStart, end: shiftEnd < shiftStart ? 23.99 : shiftEnd}
		switch(shift.day) {
		case weekDays.mon:
			return shifts[0].shift.push(shiftHours)
		case weekDays.tue:
			return shifts[1].shift.push(shiftHours)
		case weekDays.wed:
			return shifts[2].shift.push(shiftHours)
		case weekDays.thu:
			return shifts[3].shift.push(shiftHours)
		case weekDays.fri:
			return shifts[4].shift.push(shiftHours)
		case weekDays.sat:
			return shifts[5].shift.push(shiftHours)
		case weekDays.sun:
			return shifts[6].shift.push(shiftHours)
		}
	})
	return shifts
}

export const formatShiftForBackend = (shifts:shifts) => {
	const shiftsForBackend : {shift_day: weekDays, shift_start: number, shift_end: number}[] = []
	shifts.forEach(shift => {
		shift.shift.forEach(duration => {
			shiftsForBackend.push({shift_day: shift.day, shift_start: duration.start, shift_end: duration.end})
		})
	})
	
	return shiftsForBackend
}

export const getEnumKeyFromValue = (obj: Record<string, string>, value: string) => {
	return Object.keys(obj)[Object.values(obj).indexOf(value)]
}
export const translateEnum = (t: TFunction, key: string): string => {
	return t(`${key}`)
}

export const translateDragStages = (t: TFunction, stages: dragStage<task<Date | undefined>>[]) => { 
	const stagesCopy = [...stages]

	const stagesList = ['open', 'in_progress', 'completed', 'accepted', 'rejected']

	stagesCopy.forEach((stage, index) => {
		stage.stageName = t(`${stagesList[index]}`)
	})

	return stagesCopy
}

export const formatGraphResponse = async (url: string, startDate: Date, endDate: Date, duration: chartDurationOptions, t?: TFunction) => {
	const { result } = await authorizedRequest(url + `?min_date=${formatDateForBackend(startDate)}&max_date=${formatDateForBackend(endDate)}&data_resolution=${duration}`, 'GET')
	const chartData: chartItem[] =[ ...result.map((data: chartItemResponse) => {
		
		return {
			label: t ? t(data.label) : data.label,
			color: `#${Math.floor(Math.random()*16777215).toString(16).padStart(6, '0')}`,
			data: data.data.map((dataItem: charItemDataResponse) => {
				return {
					value: dataItem.value,
					date: new Date(dataItem.date*1000)
				}
			})
		}
	})]
	
	
	return {
		xAxisLabels: getGraphXAxis(startDate, endDate, duration),
		chartItems: chartData
	}
}

export const formatPhoneNumberResponse = (response: phoneNumberResponse): phoneNumber => {
	return {
		id: response.id,
		phoneNumber: response.number
	}
}

export const formatPermissionForBackend = (permissions: expandableCheckbox[]) => {
	return permissions.reduce((acc: string[], option) => {
		const selectedNestedOptions = option.nestedOptions
			.filter(subCategory => subCategory.isChecked)
			.map(subCategory => subCategory.value)
			.flat() as string[]
		return [...acc, ...selectedNestedOptions]
	}, [])
}

export const formatAccountResponse = (response: accountResponse): account => {
	return {
		id: response.id,
		name: response.name,
		bank: response.bank,
		balance: response.balance,
		accountNumber: response.account_number,
		description: response.description,
		lastAction: new Date(response.last_action_date * 1000),
		deleteAccess: response.delete_access,
		editAccess: response.edit_access,
		createdDate: new Date(response.date_created * 1000)
	}
}

export const formatErrorResponse = (response: errorResponse): error => {
	return {
		title: response.title,
		description: response.description,
		resolve: response.resolve_url ? async (method='POST', body?: object) => {
			if(response.resolve_url){
				return await authorizedRequest(baseUrl + response.resolve_url, method.toUpperCase(), 'accessToken', body)
			}
		} : undefined,
		labels: response.labels,
		errorRootLink: response.error_root_link,
		translateKey: response.translate_key,
		translate: response.translate
	}	
}

export const formatDetailedErrorObjectResponse = (response: detailedErrorObjectResponse): detailedErrorObject => {
	switch(response.type){
	case detailedErrorObjectTypes.EMPLOYEE:
		return {
			object: formatEmployeeResponse(response.object),
			type: response.type
		}
	case detailedErrorObjectTypes.SHIPMENT:
		return {
			object: formatShipmentResponse(response.object),
			type: response.type
		}
	case detailedErrorObjectTypes.BUDGET:
		return {
			object: formatBudgetResponse(response.object),
			type: response.type
		}

	case detailedErrorObjectTypes.ORDER:
		return {
			object: formatOrderResponse(response.object),
			type: response.type
		}

	case detailedErrorObjectTypes.ACCOUNT:
		return {
			object: formatAccountResponse(response.object),
			type: response.type
		}
	case detailedErrorObjectTypes.PROJECT:
		return {
			object: formatProjectResponse(response.object),
			type: response.type
		}
	case detailedErrorObjectTypes.BILL:
		return {
			object: formatBillResponse(response.object),
			type: response.type
		}
	case detailedErrorObjectTypes.TASK:
		return {
			object: formatTaskResponse(response.object),
			type: response.type
		}
	case detailedErrorObjectTypes.INVENTORY:
		return {
			object: formatInventoryItemResponse(response.object),
			type: response.type
		}
	}
}

export const compileInventoryItemId = (inventoryItem: inventoryItem) =>	{
	if(inventoryItem.cost){
		const newId = `${inventoryItem.id}&${inventoryItem.cost.amount}&${inventoryItem.cost.currency}`
		return newId
	} else {
		return `${inventoryItem.id}`
	}
}
export const compileOfferingId = (offering: offering) =>	{
	const newId = `${offering.id}&${offering.price.amount}&${offering.price.currency}`
	return newId
}

export const getIdWithCurrency = (item: offering | inventoryItem) => {
	return item.type === itemTypes.offering ? 
		compileOfferingId(item) : 
		item.type === itemTypes.inventory_item 	?
			compileInventoryItemId(item) :
			'-1'
}

export const deepEqual = (obj1: any, obj2: any) => {
	// Check if they are the same instance
	if (obj1 === obj2) {
		return true
	}
  
	// Check if both are objects
	if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
		return false
	}
  
	const keys1 = Object.keys(obj1)
	const keys2 = Object.keys(obj2)
  
	// Check if they have the same number of keys
	if (keys1.length !== keys2.length) {
		return false
	}
  
	for (const key of keys1) {
		// Check if the current key exists in both objects
		// eslint-disable-next-line no-prototype-builtins
		if (!obj2.hasOwnProperty(key)) {
			return false
		}
  
		// Recursively compare nested objects or arrays
		if (!deepEqual(obj1[key], obj2[key])) {
			return false
		}
	}
  
	// If all checks pass, objects are deeply equal
	return true
}
  
export const formatCustomerResponse = (response: customerResponse): customer => {
	return {
		id: response.relationship_id,
		name: response.name,
		email: response.email,
		phone: response.phone,
		avatar: response.avatar,
		editAccess: response.edit_access,
		deleteAccess: response.delete_access,
		description: response.description,
		labels: response.labels
	}
}

export const formatProviderResponse = (response: providerResponse): provider => {
	return {
		id: response.relationship_id,
		name: response.name,
		email: response.email,
		phone: response.phone,
		avatar: response.avatar,
		editAccess: response.edit_access,
		deleteAccess: response.delete_access,
		description: response.description,
		labels: response.labels
	}
}

export const formatExtraColumnResponse = (response: extraColumnsResponse): tableHeader => {
	return {
		title: response.name,
		key: response.key,
		extraColumn: {
			id: response.id
		},
		dropdown: response.dropdown_options ? {
			placeholder: '',
			selectedOption: null,
			dropdownOptions: response.dropdown_options
		} : undefined, 
		editAccess: response.edit_access,
		type: response.data_type
	}
}

export const formatExtraColumnDataResponse = (response: extraColumnDataResponse): Record<string, tableBodyItem> => {
	return {
		[response.key]: {
			objectId: response.object_id,
			content: formatTableBodyItemContentResponse(response.content)
		} 
	}
	
}

export const toSnakeCase = (str: string): string => {
	return str.replace(/([A-Z])/g, '_$1').toLowerCase()
}

export const formatDocumentResponse = (response: documentResponse): document => {
	return {
		file: blobStringToBlob(response.file, response.file_type),
		fileName: response.file_name,
		fileType: response.file_type,
		id: response.id
	}
}

export const getTableViewData = async (
	oldData: Record<string, tableBodyItem>[], 
	tableType: tableTypes, 
	objects: (customer | bill | document | order | budget | offering | inventoryItem | task<Date | undefined> | project | account | employee)[], 
	formatTableData: (object: any) => Record<string, tableBodyItem>,
	companyId: number
) => {
	const data: Record<string, tableBodyItem>[] = []

	const missingIds: number[] = objects.filter((object) => {
		let isMissing = true
		oldData.forEach((dataItem) => {

			Object.values(dataItem).forEach((dataSubItem) => {
				if(object.id === dataSubItem.objectId){
					isMissing = false
				}
			})

		}) 

		return isMissing
	}).map((object) => object.id)

	
	for(let i=0; i<missingIds.length; i++){
		const id = missingIds[i]

		const { result } = await authorizedRequest(companyExtraColumnDataUrl(companyId) + `?table_type=${tableType}&object_id=${id}`, 'GET')

		const formattedResult: Record<string, tableBodyItem>[] = result.map(formatExtraColumnDataResponse)

		let matchingResult = {}

		formattedResult.forEach(record => {
			Object.values(record).forEach(resultItem => {
				matchingResult = {
					...matchingResult,
					...record
				}
			})
		})

		data.push({
			...formatTableData(objects.find((object) => object.id === id)),
			...matchingResult,
		})

	}
	
	return data
}

export const updateCellValue = async (
	objectId: number,
	companyId: number,
	columnKey: string,
	newValue: tableBodyItemContent,
	objecttUpdateUrl: (id: number) => string,
	defaultKeyData: {backend: string, frontend: string, type: columnTypes}[],
	objects: any[],
	setObjects: any,
	setPreloadedObjects: any,
	formatObjectResponse: any,
	tableType: tableTypes
) => {
	const objIndex = objects.findIndex((object) => object.id === objectId)

	let response

	const formatedDate = (date: Date | undefined) =>{
		console.warn(date, date ? new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())).toISOString() : undefined)
		return date ? new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())).toISOString() : undefined
	}

	let value: string | number | number[] | undefined

	if(newValue.value !== null && newValue !== undefined){
		if(newValue.type === columnTypes.date){
			value = formatedDate(newValue.value)
		}else if(newValue.type === columnTypes.dropdown){
			value = newValue.value.key
		}else if(newValue.type === columnTypes.selection){
			value = newValue.value.map((option) => option.id)
		}else if (newValue.type === columnTypes.string || newValue.type === columnTypes.number){
			value = newValue.value
		}
	}

	if(objIndex !== -1){
		const defaultKey = defaultKeyData.find((key) => key.frontend === columnKey)
		if(defaultKey && defaultKey.backend){
			const obj = objects[objIndex]

			const body: any = {}

			for(const objKey in obj){
				const key = defaultKeyData.find((key) => key.frontend === objKey)
				if(key){
					if(key.type === columnTypes.date){
						body[key.backend] = formatedDate(obj[objKey] as Date)
					}else if(key.type === columnTypes.dropdown){
						body[key.backend] = obj[objKey] ? obj[objKey] ['id'] : null // for dropdowns with backend loaded objects only. For other dropdowns use string type
					}else if(key.type === columnTypes.selection){
						body[key.backend] = obj[objKey].map((option: selectionOption) => option.id)
					}else if(key.type === columnTypes.string || key.type === columnTypes.number){
						body[key.backend] = obj[objKey]
					}
				}
			}
			console.log(obj, body)

			response = await authorizedRequest(objecttUpdateUrl(objectId), 'PUT', 'accessToken', {
				...body,
				[defaultKey.backend]: value
			})


			const { result } = response


			objects[objIndex] = formatObjectResponse(result)

			setObjects([...objects])
			setPreloadedObjects && setPreloadedObjects([...objects])

		}else{


			response = await authorizedRequest(companyExtraColumnDataUrl(companyId), 'PUT', 'accessToken', {
				table_type: tableType,
				key: columnKey,
				object_id: objectId,
				value: value
			})

			console.log({table_type: tableType,
				key: columnKey,
				object_id: objectId,
				value: value})

			const { result }: {result: extraColumnDataResponse} = response

			const newObj = {
				...objects[objIndex],
				[result.key]: formatExtraColumnDataResponse(result)
			}

			objects[objIndex] = newObj

			setObjects([...objects])
			setPreloadedObjects && setPreloadedObjects([...objects])

		}
	}

}

export const formatBytes = (bytes: number): string  => {
	if (bytes === 0) return '0 Bytes'
	const k = 1024
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
	const i = Math.floor(Math.log(bytes) / Math.log(k))
	const size = parseFloat((bytes / Math.pow(k, i)).toFixed(2))
	return `${size} ${sizes[i]}`
}


export const formatTableBodyItemContentResponse = (response: tableBodyItemContentResponse): tableBodyItemContent => {
	switch(response.type){
	case columnTypes.date:
		console.log('warrrn', response.value)
		return {
			type: response.type,
			value: typeof response.value === 'number' ? new Date(response.value * 1000) : 
				typeof response.value === 'string' ? new Date(response.value)
					: undefined
		}
	case columnTypes.number:
		return {
			type: response.type,
			value: response.value
		}
	case columnTypes.string:
		return {
			type: response.type,
			value: response.value
		}
	case columnTypes.dropdown:
		return {
			type: response.type,
			value: response.value ? {
				key: response.value,
				title: response.value
			} : null
		}
	}
}

export const formatFileResponse = (response: fileResponse): file => {
	return {
		file: blobStringToBlob(response.file, response.file_type),
		fileName: response.file_name,
		fileType: response.file_type
	}
}

export const formatTemplateResponse = (response: templateResponse): template => {
	return {
		id: response.id,
		preview: formatFileResponse(response.preview)
	}
}