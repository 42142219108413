import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type taskModalsState = {
    deleteTaskModal: {
        modalIsOpen: boolean
        taskId: number | null
    }
    closeTaskModal: {
        modalIsOpen: boolean
        taskId: number | null
		action: 'close' | 'activate'
    }
	createExecutorModal: {
		modalIsOpen: boolean
	}
	deleteExecutorModal: {
		modalIsOpen: boolean
		executorId: number | null
	}
	createWarehouseActionModal: {
		modalIsOpen: boolean
		taskId: number | null
	}
	createTimeTrackerModal: {
		modalIsOpen: boolean
	}
	deleteTimeTrackerModal: {
		modalIsOpen: boolean
		executorId: number | undefined
	}
}

const initialState: taskModalsState = {
	deleteTaskModal: {
		modalIsOpen: false,
		taskId: null
	},
	closeTaskModal: {
		modalIsOpen: false,
		taskId: null,
		action: 'close'
	},
	createExecutorModal: {
		modalIsOpen: false
	},
	deleteExecutorModal: {
		modalIsOpen: false,
		executorId: null
	},
	createWarehouseActionModal: {
		modalIsOpen: false,
		taskId: null
	},
	createTimeTrackerModal: {
		modalIsOpen: false
	},
	deleteTimeTrackerModal: {
		modalIsOpen: false,
		executorId: undefined
	}
}

export const taskModalsSlice = createSlice({
	name: 'taskModalsReducer',
	initialState,
	reducers: {
		setDeleteTaskModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.deleteTaskModal.modalIsOpen = action.payload
		},
		setDeleteTaskId: (state, action: PayloadAction<number | null>)=> {
			state.deleteTaskModal.taskId = action.payload
		},
		setCloseTaskModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.closeTaskModal.modalIsOpen = action.payload
		},
		setCloseTaskId: (state, action: PayloadAction<number | null>)=> {
			state.closeTaskModal.taskId = action.payload
		},
		setCloseTaskAction: (state, action: PayloadAction<'close' | 'activate'>)=> {
			state.closeTaskModal.action = action.payload
		},
		setCreateExecutorModalIsOpen: (state, action: PayloadAction<boolean>)=> {
			state.createExecutorModal.modalIsOpen = action.payload
		},
		setDeleteExecutorModalIsOpen: (state, action: PayloadAction<boolean>)=> {
			state.deleteExecutorModal.modalIsOpen = action.payload
		},
		setDeleteExecutorId: (state, action: PayloadAction<number | null>)=> {
			state.deleteExecutorModal.executorId = action.payload
		},
		setCreateWarehouseActionModalIsOpen: (state, action: PayloadAction<boolean>)=> {
			state.createWarehouseActionModal.modalIsOpen = action.payload
		},
		setCreateWarehouseActionTaskId: (state, action: PayloadAction<number | null>)=> {
			state.createWarehouseActionModal.taskId = action.payload
		},
		setCreateTimeTrackerModalIsOpen: (state, action: PayloadAction<boolean>)=> {
			state.createTimeTrackerModal.modalIsOpen = action.payload
		},
		setDeleteTimeTrackerModalIsOpen: (state, action: PayloadAction<boolean>)=> {
			state.deleteTimeTrackerModal.modalIsOpen = action.payload
		},
		setDeleteTimeTrackerModalExecutorId: (state, action: PayloadAction<number | undefined>)=> {
			state.deleteTimeTrackerModal.executorId = action.payload
		}
	}
})

export const {
	setDeleteTaskModalIsOpen,
	setDeleteTaskId,
	setCloseTaskModalIsOpen,
	setCloseTaskId,
	setCloseTaskAction,
	setCreateExecutorModalIsOpen,
	setDeleteExecutorModalIsOpen,
	setDeleteExecutorId,
	setCreateWarehouseActionModalIsOpen,
	setCreateWarehouseActionTaskId,
	setCreateTimeTrackerModalIsOpen,
	setDeleteTimeTrackerModalIsOpen,
	setDeleteTimeTrackerModalExecutorId
} = taskModalsSlice.actions

export default taskModalsSlice.reducer