//react
import { useEffect, useState } from 'react'

//network
import { authorizedRequest } from '../../../../utils/queries'
import { companyProductivityUrl } from '../../../../utils/urls/employees/productivity'

//redux
import { useAppSelector } from '../../../../customHooks/redux'

//types
import { executor, timeUsage } from '../../../../types/general/generalTypes'

//components
import TimeUsageData from '../../../general/timeUsageData/timeUsageData'
import Loading from '../../../general/loading/loading'

//other
import { formatExecutorResponse } from '../../../../assets/projects/projectsFunctions'

const CompanyProductivity = () => {
	const {userCompanyData} = useAppSelector(state => state.general)
	const companyId = userCompanyData?.companyId || -1
    
	const [ timeUsage, setTimeUsage ] = useState<timeUsage[]>([])
	const [ hoursSpent, setHoursSpent ] = useState(0)
	const [ loading, setLoading ] = useState(false)

	useEffect(() => {
		setLoading(true)
		authorizedRequest(companyProductivityUrl(companyId), 'GET')
			.then((response) => {
				const { result } = response

				let timeUsage: timeUsage[] = []
				let hoursSpent = 0

				const formattedExecutors: executor[] = result.map(formatExecutorResponse)
				formattedExecutors.forEach((executor) => {
					timeUsage = [...executor.timeUsage, ...timeUsage]
					hoursSpent += executor.hoursSpent
				})

				setTimeUsage([...timeUsage])
				setHoursSpent(hoursSpent)

				setLoading(false)
			})
	}, [])

	return (
		<div className="company-productivity-container">
			{
				loading && <Loading />
			}
			<TimeUsageData
				timeUsage={timeUsage}
				hoursSpent={hoursSpent}
			/>
		</div>
	)
}

export default CompanyProductivity