// styles and icons
import './createColumnModal.scss'

// react
import { FC, useState } from 'react'

// components
import Modal from '../../../../general/modals/modal/modal'
import InputField from '../../../../general/inputField/inputField'
import Dropdown from '../../../../general/dropdown/dropdown'
import DropdownOptionEditor from './dropdownOptionEditor/dropdownOptionEditor'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setCreateColumnModalIsOpen } from '../../../../../redux/general/modals'

// network
import { authorizedRequest } from '../../../../../utils/queries'
import { companyExtraColumnsUrl } from '../../../../../utils/urls/general/company/company'

// types
import { dropdownOption, tableHeader, columnTypes, tableTypes, extraColumnsResponse, infoType } from '../../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

//other
import { createDropdownOption, formatExtraColumnResponse } from '../../../../../assets/general/generalFunctions'

type createColumnModalProps = {
	columns: tableHeader[]
	setColumns: (value: tableHeader[]) => void
    tableType: tableTypes
}

type formError = {
	name?: string
}

const CreateColumnModal : FC<createColumnModalProps> = ({columns, setColumns, tableType}) => {
	const { t } = useTranslation('', {keyPrefix: 'general.table.modals.createColumnModal'})  
	const tColumnTypes = useTranslation('', {keyPrefix: 'general.columnTypes'}).t

	const {userCompanyData} = useAppSelector(state => state.general)
	const companyId = userCompanyData?.companyId || -1

	const [name, setName] = useState('')
	const [type, setType] = useState<dropdownOption>({
		key: columnTypes.string,
		title: tColumnTypes(columnTypes.string)
	})

	const [dropdownOptions, setDropdownOptions] = useState<string[]>([])

	const { modalIsOpen } = useAppSelector(state => state.generalModals.createColumnModal)
	const [errors, setErrors] = useState<formError>({})
	
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(setCreateColumnModalIsOpen(false))
		setName('')
		setType({
			key: columnTypes.string,
			title: tColumnTypes(columnTypes.string)
		})
	}

	const checkErrors = () => {
		let hasErrors = false

		if(name === '') {
			hasErrors = true
			setErrors(prevErrors => ({...prevErrors, name: t('enterExecutorsNamePlease')}))
		}
        
		return hasErrors
	}
	const createColumn = async ()=> {
		setErrors({})
		if(checkErrors()) return
		
		setIsLoading(true)
		console.log(type)

		try {
			const { result }: { result: extraColumnsResponse } = await authorizedRequest(companyExtraColumnsUrl(companyId), 'POST', 'accessToken', {
				table_type: tableType,
				name: name,
				data_type: type.key,
				dropdown_options: type.key === 'dropdown' ? dropdownOptions : []
			})
			console.log(result)

			setColumns([...columns, formatExtraColumnResponse(result)])
			setIsLoading(false)
			closeModal()
			
		} catch(err) {
			console.log(err)
		} finally{
			setIsLoading(false)
		}
	}

	return (
		<Modal 
			title={t('createColumn')}
			open={modalIsOpen}
			closeModal={closeModal} 
			submitButton={{text: t('create'), onClick: createColumn}}
			isLoading={isLoading}
		>
			<div className="create-column">
				<InputField type="text" 
					info={errors.name ? {
						type: infoType.error,
						text: errors.name
					} : undefined}
					label={t('columnName')} 
					placeholder={t('columnName')} 
					value={name} 
					onChange={(e)=> setName(e.target.value)}
				/>
				<Dropdown
					label={t('columnType')}
					placeholder={t('columnType')}
					defaultSelectedOption={type}
					dropdownOptions={[
						{
							key: columnTypes.string,
							title: tColumnTypes(columnTypes.string)
						},
						{
							key: columnTypes.number,
							title: tColumnTypes(columnTypes.number)
						},
						{
							key: columnTypes.dropdown,
							title: tColumnTypes(columnTypes.dropdown)
						},
						{
							key: columnTypes.date,
							title: tColumnTypes(columnTypes.date)
						}
					]}
					onSelect={(selectedOption) => setType(selectedOption)}
					selectedOption={type}
					clear={false}
				/>

				{
					type.key === 'dropdown' &&
						<DropdownOptionEditor
							options={dropdownOptions}
							setOptions={setDropdownOptions}
						/>
				}
			</div>
		</Modal>
	)
}

export default CreateColumnModal