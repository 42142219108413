//styles
import { arrowIcon } from '../../../../assets/general/generalIcons'

//components
import Table from '../../table/table'
import BillTable from '../billTable/billTable'
import Pagination from '../../pagination/pagination'
import DocumentTable from '../documentTable/documentTable'
import OrderTable from '../orderTable/orderTable'

//react
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

//types
import { customer } from '../../../../types/sales/customerTypes'
import { columnTypes, extraColumnDataResponse, extraColumnsResponse, tableBodyItem, tableBodyItemContent, tableHeader, tableTypes } from '../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../utils/queries'
import { companyExtraColumnDataUrl, companyExtraColumnsUrl } from '../../../../utils/urls/general/company/company'
import { singleCustomerBillsUrl, singleCustomerUrl, singleCustomerDocumentsUrl, singleCustomerOrdersUrl } from '../../../../utils/urls/sales/customer'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector, useAppDispatch } from '../../../../customHooks/redux'
import { setDeleteCustomerModalIsOpen, setDeleteCustomerRelationshipId } from '../../../../redux/sales/customerList/modals'

//other
import { getTableViewData, formatCustomerResponse, formatExtraColumnResponse, formatExtraColumnDataResponse, updateCellValue } from '../../../../assets/general/generalFunctions'

type customerTableProps = {
    preloadedCustomers?: {
        customers: customer[]
        setCustomers: (value: customer[]) => void
    }
    loadCustomers?: {
        customerUrl: string
    }
}

const CustomerTable: FC<customerTableProps> = ({ preloadedCustomers, loadCustomers }) => {

	const { t } = useTranslation('', { keyPrefix: 'sales.customerList.customerTable' })

	const [ columns, setColumns ] = useState<tableHeader[]>([])
	const [ data, setData ] = useState<Record<string, tableBodyItem>[]>([])


	const [ loading, setLoading ] = useState(false)
	const [ lastPage, setLastPage ] = useState(false)
	const [ page, setPage ] = useState(1) 

	const [ customers, setCustomers ] = useState<customer[]>([])

	const {userCompanyData} = useAppSelector(state => state.general)

	const dispatch = useAppDispatch()

	const companyId = userCompanyData?.companyId || -1

	const formatTableData = (customer: customer): Record<string, tableBodyItem> => {
		const editAccess = customers.some((customer) => customer.editAccess)
    
		return {
			id: {
				content: {
					type: columnTypes.element,
					value: <Link to={`/ri-business/sales/customer/${customer.id}`}>{customer.id}</Link>
				},
				objectId: customer.id
			},
			name: {
				content: {
					type: columnTypes.string,
					value: customer.name
				},
				objectId: customer.id
			},
			phoneNumber: {
				content: {
					type: columnTypes.string,
					value: customer.phone
				},
				objectId: customer.id
			},
			email: {
				content: {
					type: columnTypes.string,
					value: customer.email
				},
				objectId: customer.id
			},
			description: {
				content: {
					type: columnTypes.string,
					value: customer.description
				},
				objectId: customer.id
			},
			bills: {
				content: {
					type: columnTypes.element,
					value: <div className='nested-table-arrow'>{arrowIcon}</div>
				},
				objectId: customer.id,
				extraTable: {
					open: false,
					content: <BillTable
						loadBills={{
							billUrl: singleCustomerBillsUrl(customer.id)
						}}
					/>
				}
			},
			documents: {
				content: {
					type: columnTypes.element,
					value: <div className='nested-table-arrow'>{arrowIcon}</div>
				},
				objectId: customer.id,
				extraTable: {
					open: false,
					content: <DocumentTable
						loadDocuments={{
							documentUrl: singleCustomerDocumentsUrl(customer.id)
						}}
						parent={{
							type: 'customer',
							id: customer.id
						}}
						editAccess={editAccess}
						createAccess={editAccess}
						deleteAccess={editAccess}
					/>
				}
			},
			orders: {
				content: {
					type: columnTypes.element,
					value: <div className='nested-table-arrow'>{arrowIcon}</div>
				},
				objectId: customer.id,
				extraTable: {
					open: false,
					content: <OrderTable
						loadOrders={{
							orderUrl: singleCustomerOrdersUrl(customer.id)
						}}
						parent={{
							type: 'customer'
						}}
					/>
				}
			}
		}
	}
    
	const onCellValueChange = async (objectId: number, columnKey: string, newValue: tableBodyItemContent) => {
		updateCellValue(
			objectId,
			companyId,
			columnKey,
			newValue,
			singleCustomerUrl,
			[
				{
					frontend: 'name',
					backend: 'name',
					type: columnTypes.string
				},
				{
					frontend: 'phoneNumber',
					backend: 'phone',
					type: columnTypes.string
				},
				{
					frontend: 'email',
					backend: 'email',
					type: columnTypes.string
				},
				{
					frontend: 'description',
					backend: 'description',
					type: columnTypes.string
				}
			],
			customers,
			setCustomers,
			preloadedCustomers?.setCustomers,
			formatCustomerResponse,
			tableTypes.customer
		)
	}

	const getColumns = async () => {
		const {result}: {result: extraColumnsResponse[]}  = await authorizedRequest(companyExtraColumnsUrl(companyId) + '?table_type=customer', 'GET')

		const editAccess = customers.some((customer) => customer.editAccess)

		setColumns([
			{ key: 'id', title: t('id'), type: columnTypes.number, editAccess: false },
			{ key: 'name', title: t('name'), type: columnTypes.string, editAccess },
			{ key: 'phoneNumber', title: t('phoneNumber'), type: columnTypes.string, editAccess },
			{ key: 'email', title: t('email'), type: columnTypes.string, editAccess },
			{ key: 'description', title: t('description'), type: columnTypes.string, editAccess },
			{ key: 'bills', title: t('bills'), editAccess },
			{ key: 'documents', title: t('documents'), editAccess },
			{ key: 'orders', title: t('orders'), editAccess },
			...result.map(formatExtraColumnResponse), 
		])
	}

	useEffect(() => {
		getColumns()

		getTableViewData(
			data,
			tableTypes.customer,
			customers,
			formatTableData,
			companyId
		).then((newData) => {
			setData([...data, ...newData])
		})

	}, [customers])


	const loadData = async (page: number) => {
		if(loadCustomers){
			try {
				setLoading(true)
    
				const { result } = await authorizedRequest(loadCustomers.customerUrl + `?page=${page}&per_page=${10}`, 'GET')
    
				if (result.length > 0) {
					const formatedData: customer[] = result.map(formatCustomerResponse)
					return formatedData
				}
    
				return []
    
			} finally {
				setLoading(false)
			}
		}
	}

	const handleLoadMore = () => {

		if (!loading && !lastPage) {
			loadData(page)
				.then((result) => {

					if(result){
						setCustomers([...customers, ...result])
						if (result.length > 0) {
							setPage(page + 1)
						} else {
							setLastPage(true)
						}
					}
				})
		}
	}

	useEffect(() => {
		if(loadCustomers){
			handleLoadMore()
		}
	}, [loadCustomers])

	useEffect(() => {
		if(preloadedCustomers){
			setCustomers(preloadedCustomers.customers)
		}
	}, [preloadedCustomers])

	return (
		loadCustomers ? 
			<Pagination onLoadMore={handleLoadMore} onlyLoadOn='bottom' loading={loading} showLoader={true}>
				<Table
					columns={columns}
					setColumns={setColumns}
					data={data}
					setData={setData}
					onCellValueChange={onCellValueChange}
					tableType={tableTypes.customer}
					deleteAction={customers.some((customer) => customer.deleteAccess) ? (customerId) => {
						dispatch(setDeleteCustomerModalIsOpen(true))
						dispatch(setDeleteCustomerRelationshipId(customerId))
					} : undefined}
					addColumn={customers.some((customer) => customer.editAccess)}
				/>
			</Pagination>
			:
			<Table
				columns={columns}
				setColumns={setColumns}
				data={data}
				setData={setData}
				onCellValueChange={onCellValueChange}
				tableType={tableTypes.customer}
				deleteAction={customers.some((customer) => customer.deleteAccess) ? (customerId) => {
					dispatch(setDeleteCustomerModalIsOpen(true))
					dispatch(setDeleteCustomerRelationshipId(customerId))
				} : undefined}
				addColumn={customers.some((customer) => customer.editAccess)}
			/>
	)
}

export default CustomerTable