//styles
import './dropdown.scss'
import { close, errorAlert } from '../../../assets/general/generalIcons'
import { sliderPointerIcon } from '../../../assets/employees/general/generalIcons'

//components
import Pagination from '../pagination/pagination'
import Avatar from '../avatar/avatar'

//react
import { FC, ReactNode, useEffect, useRef, useState } from 'react'

//types
import { dropdown, dropdownOption } from '../../../types/general/generalTypes'

//components
import DropdownOption from './dropdownOption/dropdownOption'
import InfoPopup from '../infoPopup/infoPopup'
import Loading from '../loading/loading'

type dropdownProps = dropdown

const Dropdown: FC<dropdownProps> = ({
	icon,
	placeholder,
	dropdownOptions = [],
	defaultSelectedOption,
	onSelect,
	loadOptions,
	showAmount,
	disabled = false,
	error,
	selectedOption,
	label,
	popup,
	clear = true
}) => {

	const [isOpen, setIsOpen] = useState(false)
	const [searchValue, setSearchValue] = useState('')
	const [searchOptions, setSearchOptions] = useState<dropdownOption[]>([])

	// Pagination
	const [loading, setLoading] = useState(false)
	const [lastPage, setLastPage] = useState(false)
	const [page, setPage] = useState(0)

	const dropdownRef = useRef<HTMLDivElement>(null)
	const inputRef = useRef<HTMLInputElement>(null)
	const optionsContainerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (selectedOption) {
			setSearchValue(selectedOption.title)
		}

		const handleClickOutside = (event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setIsOpen(false)
			}
		}

		document.addEventListener('click', handleClickOutside)

		return () => {
			document.removeEventListener('click', handleClickOutside)
			setSearchOptions([])
		}
	}, [dropdownRef, selectedOption])

	useEffect(() => {
		if (defaultSelectedOption && !selectedOption) onSelect && onSelect(defaultSelectedOption)
	}, [defaultSelectedOption])

	useEffect(() => {
		if (!isOpen) {
			setSearchValue('')
		}
		if (isOpen) {
			setSearchValue(searchValue)
		}
		if (selectedOption) {
			setSearchValue(selectedOption.title)
		}
	}, [isOpen, selectedOption])

	const handleOptionClick = (option: dropdownOption) => {
		if (disabled) return

		setIsOpen(false)
		onSelect && onSelect(option)
		setSearchValue(option.title)
	}

	const handleDropdownClick = () => {
		if (disabled) return

		if (loadOptions) {
			setTimeout(() => {
				inputRef.current?.focus()
			}, 100)
			setIsOpen(true)
		} else {
			setIsOpen(!isOpen)
		}
	}

	useEffect(() => {

		const timeout = setTimeout(() => {
			onSearch(searchValue)
		}, 500)

		return () => {
			clearTimeout(timeout)
		}

	}, [searchValue])


	const onSearch = (query: string) => {
		if(loadOptions){
			setPage(1)
			setLastPage(false)
			setLoading(true)
			loadOptions(query, 1)
				.then((result) => {
					setSearchOptions([...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
					setLoading(false)
				})
		}
		
	}

	const loadMore = () => {
		if(loadOptions && !loading && !lastPage){
			setLoading(true)
			loadOptions(searchValue, page)
				.then((result) => {
					if (result.length > 0) {
						setSearchOptions([...searchOptions, ...result])
					}
					setLoading(false)
					setLastPage(true)
				})
		}
	}

	return (
		<div className="dropdown-wrapper">
			{label ? <p className="dropdown-label">{label}</p> : null}
			{popup ? <InfoPopup {...popup}/> : null}
			<div className="dropdown" ref={dropdownRef} onClick={handleDropdownClick}>
				<div className={`dropdown-header ${error ? 'error' : ''} ${disabled ? 'disabled' : ''}`}>
					{icon ?
						<div className='dropdown-header-main-icon'>{icon as ReactNode}</div>
						:
						null
					}
					<div className="dropdown-header-content">
						{clear && onSelect && selectedOption && !disabled && selectedOption.key && <span onClick={(event) => {
							event.stopPropagation()
							setSearchOptions([])
							setSearchValue('')
							onSelect({ key: '', title: '' })
						}} className="close-animate">{close}</span>}
						{selectedOption && selectedOption.avatar !== undefined ?
							<Avatar blobAvatar={selectedOption.avatar} /> : null}
						{loadOptions
							?
							<input name='dropdown-search' type='text' className="selected-option search" disabled={disabled} placeholder={placeholder} ref={inputRef} onChange={(e) => {
								setSearchValue(e.target.value)
							}} value={selectedOption && selectedOption.key.length > 0 ? selectedOption.title : searchValue} />
							:
							<p className="selected-option"> {selectedOption ? selectedOption.title : ''} {showAmount ? `(${dropdownOptions.length})` : ''}</p>
						}
					</div>
					<div className={`dropdown-header-icon  ${isOpen ? 'open-dropdown-header-icon' : ''}`}>{sliderPointerIcon}</div>
				</div>

				{error &&
					<div className='error-text'>
						{errorAlert}
						<p>{error}</p>
					</div>
				}

				<div className={`dropdown-options-container ${isOpen ? 'open-dropdown-options-container' : ''}`}>
					{
						<Pagination onLoadMore={() => {
							loadMore()
						}} loading={loading} loadOffset={10} showLoader={false}>

							<div className='option-wrapper' ref={optionsContainerRef}>
								{ loadOptions ? 
									searchOptions.map((option:dropdownOption) => (
										<DropdownOption key={option.title+option.key} option={option} handleOptionClick={handleOptionClick} parentContainer={optionsContainerRef}/>
									))
									:
									dropdownOptions.map((option:dropdownOption) => (
										<DropdownOption key={option.title+option.key} option={option} handleOptionClick={handleOptionClick} parentContainer={optionsContainerRef}/>
									))
								}
								{loading ? <Loading/> : null}
							</div>
						</Pagination>
					}
					
				</div>
			</div>
		</div>
	)
}

export default Dropdown

