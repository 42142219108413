// style
import './offeringInventoryItemList.scss'

// react
import { FC, useEffect, useId, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import OfferingCard from '../../../general/offeringCard/offeringCard'
import Pagination from '../../../general/pagination/pagination'
import ColumnLayout from '../../../general/columnLayout/columnLayout'
import AddOfferingInventoryItemModal from './modals/addOfferingInventoryItemModal/addOfferingInventoryItemModal'
import DeleteOfferingInventoryItemModal from './modals/deleteOfferingInventoryItemModal/deleteOfferingInventoryItemModal'
import OfferingCardSkeleton from '../../../general/skeletons/offeringCard/offeringCardSkeleton'

// redux
import { useAppDispatch, useAppSelector } from '../../../../customHooks/redux'
import { setAddOfferingInventoryItemModalIsOpen, setDeleteOfferingInventoryItemModalIsOpen, setDeleteOfferingInventoryItemId } from '../../../../redux/sales/offering/modals'

// type
import { filterBar, filters, inventoryItem } from '../../../../types/general/generalTypes'

// network
import { authorizedRequest } from '../../../../utils/queries'
import { getInventoryItemsForOfferingUrl } from '../../../../utils/old_urls/warehouse/generalUrls'
import { getOfferingItemFiltersUrl } from '../../../../utils/urls/sales/offerings'

// other
import { createFilteringRequest, getItemsPerColumn, formatInventoryItemResponse, formatFilters } from '../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type offeringInventoryItemList = {
	setFilterBar: (value: filterBar | undefined) => void
}

const OfferingInventoryItemList: FC<offeringInventoryItemList> = ({ setFilterBar }) => {
	const { t } = useTranslation('', { keyPrefix: 'warehouse.inventoryList' })

	const dispatch = useAppDispatch()

	const { offeringId } = useParams()

	const [offeringInventoryItems, setOfferingInventoryItems] = useState<inventoryItem[]>([])
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())
	const [searchRequest, setSearchRequest] = useState('')
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)


	const loadFilterBar = async () => {
		const { result } = await authorizedRequest(getOfferingItemFiltersUrl(Number(offeringId)), 'GET')

		const filterResult = result.filters
		const createAccess = result.create_access

		const formatedFilters: filters[] = formatFilters(filterResult)

		setFilterBar({
			filters: formatedFilters,
			addButton: createAccess && {
				text: t('add'),
				active: true,
				onClick: () => dispatch(setAddOfferingInventoryItemModalIsOpen(true))
			},
			onSearch: onSearch
		})
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		try {
			setLoading(true)
			const filteringRequest = createFilteringRequest(filters)

			const { result } = await authorizedRequest(getInventoryItemsForOfferingUrl(Number(offeringId)) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')
			// const { result } = await authorizedRequest(getInventoryItemsForOfferingUrl(Number(offeringId)) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')
			if (result.length > 0) {
				const formatedItems: inventoryItem[] = result.map(formatInventoryItemResponse)
				return formatedItems
			}

			return []
		} finally {
			setLoading(false)
		}
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		loadData(1, searchValue, filters)
			.then((result) => {
				console.warn('!!!!', result)
				setOfferingInventoryItems([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
			})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					setOfferingInventoryItems([...offeringInventoryItems, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				})
		}
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()
	}, [])


	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	const handleDeleteInventoryItem = (inventoryItemId: number) => {
		dispatch(setDeleteOfferingInventoryItemModalIsOpen(true))
		dispatch(setDeleteOfferingInventoryItemId(inventoryItemId))
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
			setFilterBar(undefined)
		}
	}, [])

	return (
		<div className="inventory-list">
			<Pagination loading={loading && offeringInventoryItems.length > 0} onLoadMore={handleLoadMore} showLoader={false}>
				<ColumnLayout amount={itemsPerColumn}>
					{offeringInventoryItems && offeringInventoryItems.map((item, index) => {
						return (
							<OfferingCard
								{...item}
								key={`inventory-product-${index}`}
								link={`/ri-business/warehouse/inventory-item/${item.id}`}
								handleDelete={() => {
									handleDeleteInventoryItem(item.id)
								}}
							/>
						)
					})}
					{loading && <OfferingCardSkeleton />}
				</ColumnLayout>
			</Pagination>
			<AddOfferingInventoryItemModal offeringInventoryItems={offeringInventoryItems} setOfferingInventoryItems={setOfferingInventoryItems} />
			<DeleteOfferingInventoryItemModal offeringInventoryItems={offeringInventoryItems} setOfferingInventoryItems={setOfferingInventoryItems} />
		</div>
	)
}

export default OfferingInventoryItemList


