//react
import { FC, useEffect, useState } from 'react'

//components
import OverviewCardSkeleton from '../../../general/skeletons/overviewCard/overviewCardSkeleton'
import ListSummaryCard from '../../../general/listSummaryCard/listSummaryCard'
import BillListItem from '../../../general/billListItem/billListItem'
import ListCardSkeleton from '../../../general/skeletons/listCard/listCardSkeleton'
import ColumnLayout from '../../../general/columnLayout/columnLayout'

//other
import { getItemsPerColumn } from '../../../../assets/general/generalFunctions'

// translation
import { useTranslation } from 'react-i18next'

//types
import { bill } from '../../../../types/finance/general'

type billCardListProps = {
    isOverviewLoading: boolean
    billsOverview: { title: string, text: string }[]
    bills: bill[]
    loading: boolean
}

const BillCardList: FC<billCardListProps> = ({ isOverviewLoading, billsOverview, bills, loading }) => {
	const { t } = useTranslation('', { keyPrefix: 'finance.billList' })

	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (

		<ColumnLayout amount={itemsPerColumn}>
			{
				isOverviewLoading ? <OverviewCardSkeleton /> : <ListSummaryCard
					title={t('billsOverview')}
					data={billsOverview.map(overview => {
						return {
							...overview,
							title: t(overview.title)
						}
					})}
				/>
			}
			{bills.map(bill => (<BillListItem key={`bill-item-${bill.id}`} {...bill} />))}
			{loading ? <ListCardSkeleton /> : null}
		</ColumnLayout>
	)

}

export default BillCardList