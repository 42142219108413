//react
import { FC, useState } from 'react'

//components
import ConfirmationModal from '../../confirmationModal/confirmationModal'

// type
import { template } from '../../../../../types/general/generalTypes'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../customHooks/redux'
import { setDeleteTemplateId, setDeleteTemplateModalIsOpen } from '../../../../../redux/general/modals'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { singleTemplateUrl } from '../../../../../utils/urls/general/templates/templates'

//translation
import { useTranslation } from 'react-i18next'

type deleteTemplateModalProps = {
	setTemplates: (value: template[]) => void
	templates: template[]
}

const DeleteTemplateModal: FC<deleteTemplateModalProps> = ({ templates, setTemplates }) => {

	const { t } = useTranslation('', { keyPrefix: 'general.modals.deleteTemplateModal' })

	const { deleteTemplateModal } = useAppSelector((state) => state.generalModals)

	const [loading, setLoading] = useState<boolean>(false)

	const dispatch = useAppDispatch()

	const closeModal = (): void => {
		dispatch(setDeleteTemplateModalIsOpen(false))
		dispatch(setDeleteTemplateId(null))
	}

	const deleteFile = (): void => {
		setLoading(true)
		authorizedRequest(singleTemplateUrl(deleteTemplateModal.templateId!), 'DELETE')
			.then((response) => {
				const updatedFiles = templates.filter(template => template.id !== deleteTemplateModal.templateId)
				setTemplates(updatedFiles)
				dispatch(setDeleteTemplateModalIsOpen(false))
				setLoading(false)
			})
	}

	return (
		<ConfirmationModal
			title={t('deleteThisFile')} 
			buttons={[{ text: t('yes'), onClickHandler: deleteFile }, { text: t('no'), onClickHandler: closeModal }]} 
			isOpen={deleteTemplateModal.modalIsOpen} 
			closeModal={closeModal} 
			loading={loading}
		/>
	)
}

export default DeleteTemplateModal