//react
import { useParams } from 'react-router-dom'
import { FC, useEffect, useState } from 'react'

//components
import InfoDetailsTable from '../../../general/infoDetailsTable/infoDetailsTable'
import Loading from '../../../general/loading/loading'
import ExtraColumnDetails from '../../../general/extraColumnDetails/extraColumnDetails'


//network
import { authorizedRequest } from '../../../../utils/queries'
import { singleProjectUrl } from '../../../../utils/urls/projects/project'

//translation
import { useTranslation } from 'react-i18next'

//redux


//types
import { error, infoDetailsTableDataItem, projectResponse, tableTypes } from '../../../../types/general/generalTypes'

//others
import { formatDate, formatErrorResponse } from '../../../../assets/general/generalFunctions'

type details = {
	name: string
	description: string
	closedTasks: number
	activeTasks: number
	startDate: Date | null
	endDate: Date | null
	editAccess: boolean
}

type projectDetailsProps = {
	setBudgetId: (value: number) => void
	setOrderId: (value: number) => void
	setChatId: (value: number) => void
	setTitle: (value: string) => void
	setErrors: (value: error[]) => void
	setPipelineId: (value: number) => void
	setPipelineName: (value: string) => void
}

const ProjectDetails: FC<projectDetailsProps> = ({ setBudgetId, setOrderId, setTitle, setErrors, setPipelineId, setPipelineName, setChatId }) => {
	const { t } = useTranslation('', { keyPrefix: 'projects.project.projectDetails' })

	const [details, setDetails] = useState<details>({
		name: '',
		description: '',
		closedTasks: 0,
		activeTasks: 0,
		startDate: null,
		endDate: null,
		editAccess: false
	})

	const [editDetails, setEditDetails] = useState(false)

	const [loading, setLoading] = useState(false)


	const projectId = parseInt(useParams().projectId || '-1')


	const infoDetailsTableData: infoDetailsTableDataItem[] = [
		{
			title: t('projectName'),
			data: {
				inputField: {
					value: details.name,
					onChange: (e) => {
						setDetails({ ...details, name: e.target.value })
						setEditDetails(true)
					},
					disabled: !details.editAccess
				}
			}
		},
		{
			title: t('description'),
			data: {
				textArea: {
					value: details.description,
					setValue: (value) => {
						setDetails({ ...details, description: value })
						setEditDetails(true)
					},
					disabled: !details.editAccess
				}
			}
		},
		{
			title: t('startDate'),
			data: {
				inputField: {
					value: details.startDate ? formatDate(details.startDate, false, false) : t('na'),
					disabled: true
				}
			}
		},
		{
			title: t('endDate'),
			data: {
				inputField: {
					value: details.endDate ? formatDate(details.endDate, false, false) : t('na'),
					disabled: true
				}
			}
		},
		{
			title: t('activeTasks'),
			data: {
				inputField: {
					value: `${details.activeTasks}`,
					disabled: true
				}
			}
		},
		{
			title: t('closedTasks'),
			data: {
				inputField: {
					value: `${details.closedTasks}`,
					disabled: true
				}
			}
		}
	]

	const editData = async () => {
		if (editDetails) {
			const res = await authorizedRequest(singleProjectUrl(Number(projectId)), 'PUT', 'accessToken', {
				title: details?.name,
				description: details?.description
			})
			setEditDetails(false)
		}
	}
	const loadData = async () => {
		const { result }: { result: projectResponse } = await authorizedRequest(singleProjectUrl(Number(projectId)), 'GET')

		setErrors(result.errors.map(formatErrorResponse))
		setTitle(result.title)
		setBudgetId(result.budget.id)
		setOrderId(result.order_id)
		setPipelineId(result.pipeline_id)
		setPipelineName(result.pipeline_name)
		setChatId(result.chat_id)

		setDetails({
			name: result.title,
			description: result.description,
			closedTasks: result.closed_tasks || 0,
			activeTasks: result.active_tasks || 0,
			startDate: result.date ? new Date(result.date * 1000) : null,
			endDate: result.due_date ? new Date(result.due_date * 1000) : null,
			editAccess: result.edit_access
		})
	}

	useEffect(() => {
		let timeout: NodeJS.Timeout
		if (editDetails) {
			timeout = setTimeout(() => {
				editData()
			}, 500)
		}
		return () => clearTimeout(timeout)
	}, [details])

	useEffect(() => {
		loadData()
	}, [])

	return (
		<>
			<InfoDetailsTable data={infoDetailsTableData} />
			<ExtraColumnDetails type={tableTypes.project} objectId={Number(projectId)} setLoading={setLoading} />

			{!details || loading  && <Loading style={{ top: '40vh', left: '50vw' }} />}
		</>

	)
}

export default ProjectDetails