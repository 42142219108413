//styles
import { arrowIcon } from '../../../../assets/general/generalIcons'

//components
import Table from '../../table/table'
import Pagination from '../../pagination/pagination'
import InputField from '../../inputField/inputField'
import TaskTableWarehouseAction from './taskTableWarehouseAction/taskTableWarehouseAction'

//react
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

//types
import { columnTypes, employeeResponse, extraColumnsResponse, labelResponse, selectionOption, status, tableBodyItem, tableBodyItemContent, tableHeader, tableTypes, task } from '../../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../../utils/queries'
import { companyExtraColumnsUrl } from '../../../../utils/urls/general/company/company'
import { singleTaskSubtasks, singleTaskUrl } from '../../../../utils/urls/projects/task'

//translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppSelector, useAppDispatch } from '../../../../customHooks/redux'
import { setDeleteCustomerModalIsOpen, setDeleteCustomerRelationshipId } from '../../../../redux/sales/customerList/modals'

//other
import { getTableViewData, formatExtraColumnResponse, formatDate, createDropdownOption, updateCellValue } from '../../../../assets/general/generalFunctions'
import { formatTaskResponse } from '../../../../assets/projects/projectsFunctions'
import { companySearchEmployeesUrl } from '../../../../utils/urls/employees/search'
import { getLabelsUrl } from '../../../../utils/old_urls/general/generalUrls'

type taskTableProps = {
    preloadedTasks?: {
        tasks: task<Date | undefined>[]
        setTasks: (value: task<Date | undefined>[]) => void
    }
    loadTasks?: {
        taskUrl: string
		showClosed: boolean
    }
}

const TaskTable: FC<taskTableProps> = ({ preloadedTasks, loadTasks }) => {

	const { t } = useTranslation('', { keyPrefix: 'general.taskTable' })
	const tStatus = useTranslation('', { keyPrefix: 'general.status' }).t

	const [ columns, setColumns ] = useState<tableHeader[]>([])
	const [ data, setData ] = useState<Record<string, tableBodyItem>[]>([])


	const [ loading, setLoading ] = useState(false)
	const [ lastPage, setLastPage ] = useState(false)
	const [ page, setPage ] = useState(1) 

	const [ tasks, setTasks ] = useState<task<Date | undefined>[]>([])

	const {userCompanyData} = useAppSelector(state => state.general)

	const dispatch = useAppDispatch()

	const companyId = userCompanyData?.companyId || -1

	const formatTableData = (task: task<Date | undefined>): Record<string, tableBodyItem> => {
		console.log(task)
		return {
			id: {
				content: {
					type: columnTypes.element,
					value: <Link to={`/ri-business/projects/task/${task.id}`}>{task.id}</Link>
				},
				objectId: task.id
			},
			title: {
				content: {
					type: columnTypes.string,
					value: task.title
				},
				objectId: task.id
			},
			description: {
				content: {
					type: columnTypes.string,
					value: task.description
				},
				objectId: task.id
			},
			startDate: {
				content: {
					type: columnTypes.date,
					value: task.startDate
				},
				objectId: task.id
			},
			endDate: {
				content: {
					type: columnTypes.date,
					value: task.endDate
				},
				objectId: task.id
			},
			status: {
				content: {
					type: columnTypes.dropdown,
					value: {
						title: tStatus(task.status.text),
						key: task.status.text
					}
				},
				objectId: task.id
			},
			executors: {
				content: {
					type: columnTypes.selection,
					value: task.executors.map((executor) => {
						return {
							text: executor.name,
							color: '#fff',
							avatar: executor.avatar,
							id: executor.employeeId || -1
						}
					})
				},
				objectId: task.id
			},
			labels: {
				content: {
					type: columnTypes.selection,
					value: task.labels.map((label) => {
						return {
							text: label.text,
							bg: label.color,
							color: '#fff',
							id: label.id || -1
						}
					})
				},
				objectId: task.id
			},
			project: {
				content: {
					type: columnTypes.element,
					value: <Link to={`/ri-business/projects/project/${task.project.id}`}>{task.project.title}</Link>
				},
				objectId: task.id
			},
			subtasks: {
				content: {
					type: columnTypes.element,
					value: <div className='nested-table-arrow'>{arrowIcon}</div>
				},
				objectId: task.id,
				extraTable: {
					open: false,
					content: <TaskTable
						loadTasks={{
							taskUrl: singleTaskSubtasks(task.id),
							showClosed: loadTasks?.showClosed || false
						}}
					/>
				}
			},
			warehouseAction: {
				content: {
					type: columnTypes.element,
					value: <div className='nested-table-arrow'>{arrowIcon}</div>
				},
				objectId: task.id,
				extraTable: {
					open: false,
					content: <TaskTableWarehouseAction
						taskId={task.id}
					/>
				}
			}
		}
	}

	const onCellValueChange = async (objectId: number, columnKey: string, newValue: tableBodyItemContent) => {
		updateCellValue(
			objectId,
			companyId,
			columnKey,
			newValue,
			singleTaskUrl,
			[
				{
					frontend: 'title',
					backend: 'title',
					type: columnTypes.string
				},
				{
					frontend: 'description',
					backend: 'description',
					type: columnTypes.string
				},
				{
					frontend: 'startDate',
					backend: 'date',
					type: columnTypes.date
				},
				{
					frontend: 'endDate',
					backend: 'due_date',
					type: columnTypes.date
				},
				{
					frontend: 'status',
					backend: 'status',
					type: columnTypes.string
				},
				{
					frontend: 'executors',
					backend: 'executor_ids',
					type: columnTypes.selection
				},
				{
					frontend: 'labels',
					backend: 'label_ids',
					type: columnTypes.selection
				}
			],
			tasks.map((task) => {
				console.log(task)
				if(task.id === objectId){
					return ({
						...task,
						status: task.status.text,
						executors: task.executors.map((executor) => ({
							text: executor.name,
							color: '#fff',
							avatar: executor.avatar,
							id: executor.employeeId || -1
						})),
						labels: task.labels.map((label) => {
							return {
								text: label.text,
								bg: label.color,
								color: '#fff',
								id: label.id || -1
							}
						})
					})
				}

				return task
			}),
			setTasks,
			preloadedTasks?.setTasks,
			formatTaskResponse,
			tableTypes.task
		)
		
	}

	const getColumns = async () => {
		const {result}: {result: extraColumnsResponse[]}  = await authorizedRequest(companyExtraColumnsUrl(companyId) + '?table_type=customer', 'GET')

		const editAccess = tasks.some((task) => task.editAccess)

		console.warn(editAccess, tasks)

		setColumns([
			{ key: 'id', title: t('id'), type: columnTypes.number, editAccess: false },
			{ key: 'title', title: t('title'), type: columnTypes.string, editAccess },
			{ key: 'description', title: t('description'), type: columnTypes.string, editAccess },
			{ key: 'startDate', title: t('startDate'), type: columnTypes.string, editAccess },
			{ key: 'endDate', title: t('endDate'), type: columnTypes.string, editAccess },
			{ key: 'status', title: t('status'), type: columnTypes.dropdown, dropdown: {
				placeholder: '',
				selectedOption: null,
				dropdownOptions: createDropdownOption(tStatus, status)
			}, editAccess },
			{ key: 'executors', title: t('executors'), type: columnTypes.selection, loadSelectionOptions: getEmployees, editAccess },
			{ key: 'labels', title: t('labels'), type: columnTypes.selection, loadSelectionOptions: getLabels, editAccess },
			{ key: 'project', title: t('project'), editAccess },
			{ key: 'subtasks', title: t('subtasks'), editAccess },
			{ key: 'warehouseAction', title: t('warehouseAction'), editAccess },
			...result.map(formatExtraColumnResponse), 
		])
	}


	const getEmployees = (query: string, page: number) => {
		return authorizedRequest(companySearchEmployeesUrl(companyId) + `?needle=${query}&page=${page}&per_page=5`, 'GET')
			.then((response) => {
				const { result }: { result: employeeResponse[] } = response

				const employeesOptionList: selectionOption[] = result.map(employee => {
					return {
						id: employee.employee_id,
						text: employee.name,
						avatar: employee.avatar
					}
				})

				return employeesOptionList

			})
	}

	const getLabels = (query: string, page: number) => {
		return authorizedRequest(getLabelsUrl(companyId) + `?needle=${query}&page=${page}&per_page=10`, 'GET')
			.then((response) => {
				const { result }: { result: labelResponse[] } = response

				const labelsOptionList: selectionOption[] = result.map(label => {
					return {
						id: label.id,
						text: label.text,
						bg: label.color,
						color: '#fff'
					}
				})

				return labelsOptionList

			})
	}

	useEffect(() => {
		getColumns()
		console.log('tasks', tasks)
		getTableViewData(
			data,
			tableTypes.task,
			tasks,
			formatTableData,
			companyId
		).then((newData) => {
			console.log(11, tasks, [...newData])
			setData([...data, ...newData])
		})

	}, [tasks])


	const loadData = async (page: number) => {
		if(loadTasks){
			try {
				setLoading(true)
    
				const { result } = await authorizedRequest(loadTasks.taskUrl + `?page=${page}&per_page=${10}&show_closed=${loadTasks.showClosed}`, 'GET')
    
				if (result.length > 0) {
					const formatedData: task<Date | undefined>[] = result.map(formatTaskResponse)
					return formatedData
				}
    
				return []
    
			} finally {
				setLoading(false)
			}
		}
	}

	const handleLoadMore = () => {

		if (!loading && !lastPage) {
			loadData(page)
				.then((result) => {

					if(result){
						setTasks([...tasks, ...result])
						if (result.length > 0) {
							setPage(page + 1)
						} else {
							setLastPage(true)
						}
					}
				})
		}
	}

	useEffect(() => {
		if(loadTasks){
			handleLoadMore()
		}
	}, [loadTasks])

	useEffect(() => {
		if(preloadedTasks){
			setTasks(preloadedTasks.tasks)
		}
	}, [preloadedTasks])

	return (
		loadTasks ? 
			<Pagination onLoadMore={handleLoadMore} onlyLoadOn='bottom' loading={loading} showLoader={true}>
				<Table
					columns={columns}
					setColumns={setColumns}
					data={data}
					setData={setData}
					onCellValueChange={onCellValueChange}
					tableType={tableTypes.customer}
					deleteAction={tasks.some((task) => task.deleteAccess) ? (customerId) => {
						dispatch(setDeleteCustomerModalIsOpen(true))
						dispatch(setDeleteCustomerRelationshipId(customerId))
					} : undefined}
					addColumn={tasks.some((task) => task.editAccess)}
				/>
			</Pagination>
			:
			<Table
				columns={columns}
				setColumns={setColumns}
				data={data}
				setData={setData}
				onCellValueChange={onCellValueChange}
				tableType={tableTypes.customer}
				deleteAction={tasks.some((task) => task.deleteAccess) ? (taskId) => {
					dispatch(setDeleteCustomerModalIsOpen(true))
					dispatch(setDeleteCustomerRelationshipId(taskId))
				} : undefined}
				addColumn={tasks.some((task) => task.editAccess)}
			/>
	)
}

export default TaskTable