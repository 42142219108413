//styles
import './layoutTimeTracker.scss'
import { clock } from '../../../../assets/general/generalIcons'

//react
import { useState, useEffect, useRef } from 'react'

//components
import TimeTrackerItem from '../../timeTrackerItem/timeTrackerItem'
import Loading from '../../loading/loading'

//network
import { authorizedRequest } from '../../../../utils/queries'
import { timeTrackersUrl } from '../../../../utils/urls/general/profile/profile'

//types
import { executor } from '../../../../types/general/generalTypes'

//other
import { formatExecutorResponse } from '../../../../assets/projects/projectsFunctions'

//translation
import { useTranslation } from 'react-i18next'  

const LayoutTimeTracker = () => {
	const { t } = useTranslation('', { keyPrefix: 'general.layoutTimeTracker' })  

	const [ listOpen, setListOpen ] = useState(false)
	const [ loading, setLoading ] = useState(false)
	
	const [ timeTrackers, setTimeTrackers ] = useState<executor[]>([])

	const componentRef = useRef<HTMLDivElement>(null)

	const handleClickOutside = (event: MouseEvent) => {
		if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
			setListOpen(false)
		}
	}
  
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
      
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])
  
	useEffect(() => {
		setLoading(true)
		const interval = setInterval(() => {

			authorizedRequest(timeTrackersUrl, 'GET')
				.then((response) => {
					const { result } = response
					setLoading(false)
					setTimeTrackers([...result.map(formatExecutorResponse)])
				})
		}, 3000)

		return () => {
			clearInterval(interval)
		}
	}, [])

	return (
		<div ref={componentRef} className="layout-time-tracker">
			<div className={`layout-time-tracker-list ${listOpen ? 'tracker-list-open' : ''}`}>
				{
					!loading ? 
						timeTrackers.map((timeTracker) => {
							return (
								<TimeTrackerItem
									taskTitle={timeTracker.taskTitle}
									executorId={timeTracker.id}
									timerStartDate={timeTracker.timerStartDate}
									wage={timeTracker.wage}
									hoursSpent={timeTracker.hoursSpent}
									relevantCurrency={timeTracker.currency}
									editAccess={timeTracker.editAccess}
									deleteAccess={timeTracker.deleteAccess}
									isProductivityTrackingActive={timeTracker.isProductivityTrackerActive}
									timeUsage={timeTracker.timeUsage}
								/>
							)
						})
						: <Loading style={{
							position: 'relative'
						}} />
				}
            	{!loading && timeTrackers.length === 0 && <p>{t('noTasksAssigned')}</p>}
			</div>
			<div className="layout-time-tracker-button" onClick={() => {
				setListOpen(!listOpen)
			}}>
				{clock}
			</div>
		</div>
	)
}

export default LayoutTimeTracker