//components
import Dashboard from '../dashboard/dashboard'
import CreateTaskModal from '../modals/task/createTaskModal/createTaskModal'
import DeleteTaskModal from '../modals/task/deleteTaskModal/deleteTaskModal'
import CloseTaskModal from '../modals/task/closeTaskModal/closeTaskModal'

//react
import {useState, FC, useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom'

//network
import { authorizedRequest } from '../../../utils/queries'
import { singleTaskUrl } from '../../../utils/urls/projects/task'

//redux
import { useAppDispatch } from '../../../customHooks/redux'
import {
	setCreateTaskModalIsOpen,
	setCreateTaskModalStageId,
} from '../../../redux/general/modals'
import {
	setCloseTaskId,
	setCloseTaskModalIsOpen,
	setDeleteTaskId,
	setDeleteTaskModalIsOpen,
} from '../../../redux/projects/task/modals'

//types
import {
	dragLocation,
	task,
	dragStage,
	status,
	statusColor,
	dashboardAction,
	taskStatus
} from '../../../types/general/generalTypes'

//other
import { moveItem } from '../../../assets/projects/projectsFunctions'
import { translateDragStages } from '../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type taskDashboardProps = {
	loadTasks: (stageId: number, searchRequest: string, page: number, showClosed: boolean) => Promise<task<Date | undefined>[]>
	loadStages: (showClosed: boolean) => Promise<dragStage<task<Date | undefined>>[]>
	showClosed?:boolean
	isWarehouseAction?: boolean
	isMyTasks?: boolean
}

const TaskDashboard: FC<taskDashboardProps> = ({ loadTasks, loadStages, showClosed = false, isWarehouseAction, isMyTasks}) => {
	const tStatus = useTranslation('', { keyPrefix: 'general.status' }).t

	const dispatch = useAppDispatch()

	const navigate = useNavigate()

	const { taskId } = useParams()

	const { t } = useTranslation('', {
		keyPrefix: 'projects.task.taskDashboard'
	})

	const [stageList, setStageList] = useState<dragStage<task<Date | undefined>>[]>([])
	const [tasks, setTasks] = useState<task<Date | undefined>[]>([])
	const [createAccess, setCreateAccess] = useState(false)

	const moveTask = (dragInfo: dragLocation, dropInfo: dragLocation) => {
		const move = (data: task<Date | undefined>, newStageId: number) => {

			const statuses: { [key: number]: string } = {
				1: 'open',
				2: 'in_progress',
				3: 'completed',
				4: 'accepted',
				5: 'rejected'
			}

			authorizedRequest(singleTaskUrl(data.id), 'PUT', 'accessToken', {
				task_id: data.id,
				status: statuses[newStageId]
			})

			const newStatus: taskStatus = {
				color: Object.values(statusColor)[Object.keys(statusColor).map((status) => status.toLowerCase()).indexOf(statuses[newStageId]!)],
				text: Object.values(status)[Object.keys(status).map((status) => status.toLowerCase()).indexOf(statuses[newStageId]!)]
			}

			const newTasks = tasks.map((task)=>{
				if (task.id === data.id) {
					return {
						...task, 
						status: newStatus
					}
				} else {
					return task
				}
			})

			setTasks([...newTasks])
		}		

		setStageList((newStagesList) => {
			return moveItem(dragInfo, dropInfo, newStagesList, move).map((stage) => {
				return dragInfo.stageId === dropInfo.stageId ? stage : {
					...stage,
					quantity: stage.stageId === dragInfo.stageId ? stage.quantity - 1 : stage.stageId === dropInfo.stageId ? stage.quantity + 1 : stage.quantity
				}
			})
		})

	}

	const taskAction: dashboardAction[] = [
		{
			slug: 'task-delete',
			label: t('delete'),
			color: '#FF4E4E',
			onDrop: (e, drag, drop, data) => {
				if (data && typeof data == 'object' && 'id' in data) {
					dispatch(setDeleteTaskModalIsOpen(true))
					dispatch(setDeleteTaskId(data.id as number))
				}
			},
		},
		{
			slug: 'task-success',
			label: t('close'),
			color: '#1DB954',
			onDrop: (e, drag, drop, data) => {
				if (data && typeof data == 'object' && 'id' in data) {
					dispatch(setCloseTaskModalIsOpen(true))
					dispatch(setCloseTaskId(data.id as number))
				}
			},
		},
	]

	const onLoadMore = (stageId: number)=>{
		const stageIndex = stageList.findIndex(stage => stage.stageId === stageId)

		if(stageIndex < 0){
			return
		}

		const stage = stageList[stageIndex]		

		if(!stage.isLoading && !stage.lastPage ){

			stageList[stageIndex].isLoading = true
			setStageList([...stageList])
	
			loadTasks(stageId, '', stage.page, showClosed)
				.then(tasksRes=>{
					setTasks([...tasks, ...tasksRes.filter(task=>!tasks.some(el=>el.id === task.id))])

					setStageList(prevStages => {
						return prevStages.map((stage, index) => {
							if (index === stageIndex) {
								return {
									...stage,
									page: stage.page + 1,
									isLoading: false, // Ensure isLoading is set to false here
									lastPage: tasksRes.length < 5,
								}
							}
							return stage
						})
					})

				})
		}
	}

	useEffect(() => {
		loadStages(showClosed)
			.then((stages) => {
				setStageList([...stages.map((stage) => {
					setCreateAccess(stage.createAccess)
					return ({...stage, isLoading: true})
				})])
				
				const loadTasksPromises = stages.map((stage) => loadTasks(stage.stageId, '', stage.page, showClosed))

				// Wait for all tasks to be loaded
				Promise.all(loadTasksPromises)
					.then((tasksArrays) => {
						const allTasks = tasksArrays.reduce((accumulator, currentTasks) => accumulator.concat(currentTasks), [])
						setTasks(allTasks)
						// Once all tasks are loaded, update the stage list with the loaded tasks
						const updatedStageList = stages.map((stage) => {
							const statuses: { [key: string]: number } = {
								open: 1,
								in_progress: 2,
								completed: 3,
								accepted: 4,
								rejected: 5
							}
							
							const tasksForStage = allTasks.filter((task) => stage.stageId === statuses[task.status.text.toLowerCase()])
							return { ...stage, items: tasksForStage, page: stage.page + 1, isLoading: false }
						})

						setStageList(updatedStageList)
					})
			})
	}, [taskId, showClosed])

	const sortTasksIntoStages = (tasks: task<Date | undefined>[]) => {
		const updatedStageList: dragStage<task<Date | undefined>>[] = stageList.map((stage) => {
			return {
				...stage,
				items: []
			}
		})

		tasks.forEach((task) => {
			const statuses: { [key: string]: number } = {
				open: 1,
				in_progress: 2,
				completed: 3,
				accepted: 4,
				rejected: 5
			}
			
			const stageIndex = updatedStageList.findIndex((stage) => stage.stageId === statuses[task.status.text.toLowerCase()])
	
			if(stageIndex > -1){
				updatedStageList[stageIndex].items.push(task)	
			}
	
		})

		return updatedStageList
	}

	useEffect(() => {
		const stageList = sortTasksIntoStages(tasks)
		setStageList([...stageList])
	}, [tasks])

	return (
		<>
			<Dashboard
				createAccess={createAccess}
				stages={translateDragStages(tStatus, stageList)}
				onMove={moveTask}
				actions={taskAction}
				onCardClick={(task) => {
					navigate(`/ri-business/projects/task/${task.id}`)
				}}
				onLoadMore={onLoadMore}
				onStageAdd={createAccess ? (stageName: string, stageId: number) => {
					dispatch(setCreateTaskModalStageId(stageId))
					dispatch(setCreateTaskModalIsOpen(true))
				} : undefined}
			/>
			<CreateTaskModal stages={stageList} setStages={setStageList} tasks={tasks} setTasks={setTasks} isWarehouseSectionShow={isWarehouseAction} isMyTasks={isMyTasks}/>
			<DeleteTaskModal navigateBack={false} stages={stageList} setStages={setStageList} tasks={tasks} setTasks={setTasks} />
			<CloseTaskModal navigateBack={false} lastOpen={false} tasks={tasks} setTasks={setTasks} />
		</>
	)
}

export default TaskDashboard