// styles and icons
import './inputField.scss'
import { search, close, errorAlert, eye, eyeoff, checkMark } from '../../../assets/general/generalIcons'

//components
import Avatar from '../avatar/avatar'
import Pagination from '../pagination/pagination'
import InfoPopup from '../infoPopup/infoPopup'

// react
import { FC, useEffect, useState } from 'react'

//types
import { selectionOption, inputField } from '../../../types/general/generalTypes'

type inputFieldProps = inputField

const InputField: FC<inputFieldProps> = ({
	type='text',
	name,
	popup,
	value,
	onSearch,
	placeholder,
	label = null,
	onChange,
	onBlur,
	selection,
	info,
	errorInfoList,
	passwordVisibility,
	disabled,
	letterCounter,
	icon,
	...addtionalProps
}) => {

	const [ selectionDropdownIsOpen, setSelectionDropdownIsOpen ] = useState(false)
	const [ selectionOptions, setSelectionOptions ] = useState<selectionOption[]>([])
	const [ selectionOptionsPage, setSelectionOptionsPage ] = useState(1)
	const [ selectionOptionsLastPage, setSelectionOptionsLastPage ] = useState(false)

	const optionAlreadyAdded = (option: selectionOption)=> {
		if(selection === undefined) return true
		const alreadySelected = selection.selectedList.find(selectedOption => selectedOption.id === option.id)
		if(alreadySelected) return true
		return false
	}

	const addOption = (option: selectionOption) => {
		if(selection?.maxSelected !== undefined && selection.selectedList.length >= selection.maxSelected ) return
		const alreadySelected = optionAlreadyAdded(option)
		console.log(alreadySelected)
		if(alreadySelected || selection === undefined) return
		selection.setSelectedList(selection.selectedList.concat([option]))
	}

	const handleLoadMore = () => {
		if(selectionOptionsLastPage || selection === undefined) return

		selection.loadSelectionOptions(value, selectionOptionsPage)
			.then((options) => {
				if(options.length > 0){
					setSelectionOptions([...selectionOptions, ...options])
					setSelectionOptionsPage(page=>page+1)
				}else{
					setSelectionOptionsLastPage(true)
				}
			})
	}

	useEffect(() => {
		if(selection){
			selection.loadSelectionOptions(value, 1)
				.then((selectionOptions) => {
					if(selectionOptions.length > 0){
						setSelectionOptions([...selectionOptions])
						setSelectionOptionsPage(2)

					}else{
						setSelectionOptionsLastPage(true)
					}
				})
		}
	}, [])

	useEffect(() => {

		const searchTimeout = setTimeout(() => {
			if(onSearch){
				onSearch()
			}

			if(selection){
				setSelectionOptionsPage(1)
				setSelectionOptionsLastPage(false)
				selection.loadSelectionOptions(value, 1)
					.then((selectionOptions) => {
						if(selectionOptions.length > 0){
							setSelectionOptions([...selectionOptions])
							setSelectionOptionsPage(2)
	
						}else{
							setSelectionOptions([])
							setSelectionOptionsLastPage(true)
						}
					})
			}
		}, 500)

		return () => {
			clearTimeout(searchTimeout)
		}
	}, [value])

	return (
		<div className={`input-wrapper ${disabled ? 'disabled' : ''}`}>
			<div className="input-field">
				<div className="input-field-label" >
					<div className="input-field-label-box">
						{label ? <label htmlFor={name}>{label}</label> : null}
						{popup ? <InfoPopup {...popup}/> : null}
					</div>
					{letterCounter ? (
						<p className='input-field-label-letter-counter'>{`${value.length} / ${letterCounter}`}</p>
					) : null}
				</div>
				
				<div className={`input-field-wrapper ${info ? info.type == 'error' ? 'input-error' : 'input-success' : ''}`}>
					{(onSearch || selection) && !disabled ? <div className='input-field-search'>{search}</div> : null}
					<div className="input-field-container">
						{
							selection?.selectedList.map((option) => {
								return (
									<div
										className='input-field-label-container'
										onClick={() => {
											if(!disabled){
												selection.setSelectedList(
													[...selection.selectedList.filter((selectedOption) => selectedOption.id !== option.id)]
												)
											}
										}} 
										style={{
											background: option.bg
										}}
									>
										<div className='input-field-label-info-container'>
											{
												option.avatar !== undefined ?
													<div className='input-field-label-info-avatar'>
														<Avatar blobAvatar={option.avatar || null}/>
													</div>
													: null
											}
											
											<p style={{
												color: option.color
											}} >{option.text}</p>
										</div>
										{!disabled && <div className='close-icon'>{close}</div>}
									</div>
								)
							})
						}
						{
							icon ? icon : null
						}
						<input
							type={type}
							value={value}
							onChange={onChange}
							onFocus={() => setSelectionDropdownIsOpen(true)}
							onBlur={(e) => {
								setSelectionDropdownIsOpen(false)
								onBlur && onBlur(e)
							}}
							disabled={disabled}
							placeholder={placeholder} name={name} {...addtionalProps} />
					</div>
					{
						passwordVisibility ? 
							<span
								className="input-icon"
								onClick={passwordVisibility.onClick}
							>
								{passwordVisibility.visible ? eyeoff : eye}
							</span>
							: null
					}
				</div>

				{info && 
					<div className='info-wrapper'>
						{
							info.type == 'error' ?
								<div className='info-text info-error'>
									{errorAlert}
									<p>{info.text}</p>
								</div>:
								<div className='info-text info-success'>
									{checkMark}
									<p>{info.text}</p>
								</div>
						}
						
						{
							errorInfoList && errorInfoList.length > 0 ?
								<ul className='info-info-list'>
									{errorInfoList.map((info,idx)=> 
										<li key={`password-info-${idx+1}`}>{info}</li>
									)}
								</ul> 
								: null
						}
					</div>
				}
			</div>
			{
				selection ? 
					<div className={`selection-dropdown-list-wrapper ${ selectionDropdownIsOpen && !disabled ? 'selection-dropdown-list-visible' : ''}`} >
						<Pagination onLoadMore={handleLoadMore}>
							{
								selectionOptions.map((option, index) => {
									return (
										
										<div
											key={'option-'+option.text+index}
											className={`selection-dropdown-item ${optionAlreadyAdded(option) ? 'selection-dropdown-item-selected' : ''}`}
											style={{
												background: option.bg
											}}
											onMouseDown={(e)=> {
												e.preventDefault()
												addOption(option)
												return false
											}}
										>
											{
												option.avatar !== undefined ? 
													<div className="selection-dropdown-item-avatar">
														<Avatar blobAvatar={option.avatar || null}/>
													</div>
													: null
											}
											
											<p style={{
												color: option.color
											}} >{option.text}</p>
										</div>
									)	
								})
							}
						</Pagination>
					</div>
					: null
			}
		</div>

	)
}

export default InputField
