//components
import Modal from '../../../../../../modals/modal/modal'
import InputField from '../../../../../../inputField/inputField'

//react
import { useState, useRef, FC } from 'react'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../../../../customHooks/redux'
import { setCreatePhoneNumberModalIsOpen } from '../../../../../../../../redux/general/settings/modals'

//network
import { authorizedRequest } from '../../../../../../../../utils/queries'
import { createPhoneNumberUrl } from '../../../../../../../../utils/old_urls/general/generalUrls'

//translation
import { useTranslation } from 'react-i18next'

//types
import { phoneNumberResponse, phoneNumber, infoType } from '../../../../../../../../types/general/generalTypes'

//other
import { formatPhoneNumberResponse } from '../../../../../../../../assets/general/generalFunctions'

type createPhoneNumberModalProps = {
	phoneNumbers: phoneNumber[]
	setPhoneNumbers: ( value: phoneNumber[] ) => void
}

type formErrors =  {
    phoneNumber?: string
}

const CreatePhoneNumberModal: FC<createPhoneNumberModalProps> = ({ phoneNumbers, setPhoneNumbers }) => {

	const dispatch = useAppDispatch()
	const { modalIsOpen } = useAppSelector((state) => state.settingsModals.createPhoneNumber)
	const { settingsActiveCompanyId } = useAppSelector(state => state.settingsGeneral)

	const { t } = useTranslation('', {keyPrefix: 'general.settings.companySettingsSection.modals.createPhoneNumberModal'})

	const formRef = useRef<HTMLFormElement | null>(null)

	const [ phoneNumber, setPhoneNumber] = useState('+')
	const [ errors, setErrors ] = useState<formErrors>({})

	const closeModal = () => {
		setPhoneNumber('+')
		setErrors({})
		dispatch(setCreatePhoneNumberModalIsOpen(false))
	}

	const checkErrors = () => {
		let result = true
		
		if(phoneNumber === '+' || phoneNumber.length <= 6){
			setErrors(prevErrors => ({...prevErrors, phoneNumber: t('pleaseEnterYourPhoneNumber')}))
			result = false
		}
		
		return result
	}

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>)=> {
		e.preventDefault()
		if(checkErrors()){

			const {result}: {result: phoneNumberResponse} = await authorizedRequest(createPhoneNumberUrl(settingsActiveCompanyId!), 'POST', 'accessToken', {
				phone_number: phoneNumber,
			})
	
			const createdPhoneNumber: phoneNumber = formatPhoneNumberResponse(result)
	
			setPhoneNumbers([createdPhoneNumber, ...phoneNumbers])
			closeModal()
		}
	}
	
	return (
		<Modal 
			title={t('createPhoneNumber')}
			open={modalIsOpen} 
			closeModal={closeModal} 
			submitButton={{
				text: t('create'),
				onClick: () => {
					if (formRef.current) formRef.current.requestSubmit()
				}
			}}
		>
			<form className='create-position-form' ref={formRef} onSubmit={handleSubmit}>
				<InputField 
					label={t('phoneNumber')}
					value={phoneNumber} 
					onChange={(e)=> {
						const value = e.target.value.toLowerCase().replace(/[a-zA-Z]/g, '')
						
						setPhoneNumber(value === '' ? '+' : value)
					}}
					info={errors.phoneNumber ? {
						type: infoType.error,
						text: errors.phoneNumber
					} : undefined}
				/>
			</form>
		</Modal>
	)

}

export default CreatePhoneNumberModal